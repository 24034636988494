import React from 'react'
import { getFormattedDateTimeWithPeriod } from '../../Utils'
import LoaderGif from "../../Gif/loader.gif"

const PhoneNumViewedTable = (props) => {
  const isV3User = localStorage.getItem("isV3User")
  return (
    <>
      {isV3User ?
        <div>
          <div className='hidden md:block'>
            <div className="flex justify-between mt-[1rem] md:mt-0 pl-[1rem] md:pl-0 ">
              <p className=" text-[#000000] text-[16px] font-semibold ">{props.title}</p>
            </div>
            <div className="mt-[1rem] mb-[8rem] md:mb-0 px-[1rem] md:px-0 md:col-span-6">
              <div className=" grid grid-cols-10 space-x-[0.1rem]  md:space-x-[0.15rem] lg:space-x-1 mt-[0.1rem] text-[12px] md:text-[12px] lg:text-[15px] ">
                <p className=" col-span-3 text-[#FFFFFF]  bg-[#D10A11] font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                  {/* Name */}
                  {props.tableHeader[0]}
                </p>
                <p className=" col-span-2 text-[#FFFFFF]   bg-[#D10A11] font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                  {/* Profile ID */}
                  {props.tableHeader[1]}
                </p>
                <p className="  hidden lg:flex col-span-2 text-[#FFFFFF]   bg-[#D10A11]  font-semibold pl-[0.3rem]  items-center h-[40px]  ">
                  {/* Mobile
                 Number */}
                  {props.tableHeader[2]}
                </p>
                <div className=" grid  place-content-center col-span-2 text-[#FFFFFF]   bg-[#D10A11] font-semibold pl-[0.2rem] md:pl-[0.3rem]  items-center h-[40px] lg:hidden ">
                  {/* <p> Mobile </p>
                   <p> Number </p> */}
                  {props.tableHeader[2]}
                </div>
                <p className=" col-span-3 text-[#FFFFFF]   bg-[#D10A11] font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                  {props.tableHeader[3]}
                </p>
              </div>
              {props.isLoading
                ? <div className="flex h-52 items-center justify-center">
                  <img alt="loader" src={LoaderGif} />
                </div>
                : props?.tableBody?.length <= 0
                  ? <div className="flex h-36 items-center justify-center bg-white">
                    <p className="text-[#D10A11] text-[16px] font-semibold">No data found</p>
                  </div>
                  :
                  <div className="bgColor">
                    {props?.tableBody?.map((e, i) => (
                      <div className=" grid grid-cols-10 space-x-[0.1rem]  md:space-x-[0.15rem] lg:space-x-1 mt-[0.1rem] text-[12px] md:text-[12px] lg:text-[14px]  ">
                        <p className=" col-span-3 text-[#7A7A7A]  bg-[#F1F1F1] font-semibold pl-[0.3rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                          {e.groomName}
                        </p>
                        <p className=" col-span-2 text-[#7A7A7A]   bg-[#F1F1F1] font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                          {props?.title == "Phone Numbers Viewed by Me" ? e.vieweeId : e.viewerId}
                        </p>
                        <p className=" col-span-2 text-[#7A7A7A]   bg-[#F1F1F1] font-semibold  pl-[0.2rem] md:pl-[0.3rem] flex items-center h-[40px]  ">
                          {e.isdCode} {e.mobileNumber}
                        </p>
                        <p className=" col-span-3 text-[#7A7A7A]   bg-[#F1F1F1] font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                          {getFormattedDateTimeWithPeriod(e.created)}
                        </p>
                      </div>
                    ))}
                  </div>
              }

              {/* <div className=" flex justify-end mt-3  ">
          <button onClick={()=>{props.loadMore()}} className=" flex items-center justify-center  font-bold text-white bg-[#D10A11] px-[1.5rem] md:px-[3rem] whitespace-nowrap rounded-[3px] md:rounded-[4px] py-2 md:py-2 text-[11px] md:text-[11px] lg:text-[13px] ">
            Load More
          </button>
        </div> */}
              {props?.tableBody?.length <= 0
                ? <></>
                : <div className=" flex justify-end mt-3  ">
                  <button onClick={() => { props.loadMore() }} className=" flex items-center justify-center  font-bold text-white bg-[#D10A11] px-[1.5rem] md:px-[3rem] whitespace-nowrap rounded-[3px] md:rounded-[4px] py-2 md:py-2 text-[11px] md:text-[11px] lg:text-[13px] ">
                    Load More
                  </button>
                </div>}
            </div>
          </div>
          <div className='md:hidden'>
            <div className="flex justify-center bg-[#D10A11] py-1 md:mt-0 pl-[1rem] md:pl-0 ">
              <p className=" text-white text-[16px] font-semibold ">{props.title}</p>
            </div>
            <div className="mt-[1rem] mb-[8rem] md:mb-0  md:px-0 md:col-span-6">

              {props.isLoading
                ? <div className="flex h-52 items-center justify-center">
                  <img alt="loader" src={LoaderGif} />
                </div>
                : props?.tableBody?.length <= 0
                  ? <div className="flex h-36 items-center justify-center bg-white">
                    <p className="text-[#D10A11] text-[16px] font-semibold">No data found</p>
                  </div>
                  :
                  <div className="">
                    {props?.tableBody?.map((e, i) => (
                      <div className="rounded-b-[1.5rem] shadow-[rgba(0,_0,_0,_0.2)_0px_11px_40px_-7px]  md:space-x-[0.15rem] lg:space-x-1 mt-[0.1rem] text-[12px] md:text-[12px] lg:text-[14px]  ">
                        <div className='px-2 flex space-x-2'>
                          <p className="  text-block  font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                            {/* Name */}
                            {props.tableHeader[0]}
                          </p>
                          <span className="font-bold flex justify-center items-center">: </span>

                          <p className="  text-[#7A7A7A]   font-semibold pl-[0.3rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                            {e.groomName}
                          </p>
                        </div>
                        <div className='px-2 flex space-x-2'>


                          <p className="  text-block  font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                            {/* Profile ID */}
                            {props.tableHeader[1]}
                          </p>
                          <span className="font-bold flex justify-center items-center">: </span>

                          <p className="  text-[#7A7A7A]    font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                            {props?.title == "Phone Numbers Viewed by Me" ? e.vieweeId : e.viewerId}
                          </p>
                        </div>
                        <div className='px-2 flex sapce-x-2'>
                          <p className=" text-block  font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                            {/* Mobile
                 Number */}
                            {props.tableHeader[2]}
                          </p>
                          <span className="font-bold flex justify-center items-center">: </span>

                          <p className="  text-[#7A7A7A]    font-semibold  pl-[0.2rem] md:pl-[0.3rem] flex items-center h-[40px]  ">
                            {e.isdCode} {e.mobileNumber}
                          </p>


                        </div>
                        <div className='px-2 flex sapce-x-2'>
                          <p className=" text-block   font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                            {props.tableHeader[3]}
                          </p>
                          <span className="font-bold flex justify-center items-center">: </span>

                          <p className=" text-[#7A7A7A]    font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                            {getFormattedDateTimeWithPeriod(e.created)}
                          </p>
                        </div>

                      </div>
                    ))}
                  </div>
              }

              {/* <div className=" flex justify-end mt-3  ">
          <button onClick={()=>{props.loadMore()}} className=" flex items-center justify-center  font-bold text-white bg-[#D10A11] px-[1.5rem] md:px-[3rem] whitespace-nowrap rounded-[3px] md:rounded-[4px] py-2 md:py-2 text-[11px] md:text-[11px] lg:text-[13px] ">
            Load More
          </button>
        </div> */}
              {props?.tableBody?.length <= 0
                ? <></>
                : <div className=" flex justify-end mt-5 ">
                  <button onClick={() => { props.loadMore() }} className=" flex items-center justify-center  font-bold text-white bg-[#D10A11] px-[1.5rem] md:px-[3rem] whitespace-nowrap rounded-[3px] md:rounded-[4px] py-2 md:py-2 text-[11px] md:text-[11px] lg:text-[13px] ">
                    Load More
                  </button>
                </div>}
            </div>
          </div>
        </div>
        :
        <div>
          <div className="flex justify-between mt-[1rem] md:mt-0 pl-[1rem] md:pl-0 ">
            <p className=" text-[#000000] text-[16px] font-semibold ">{props.title}</p>
          </div>
          <div className={`mt-[1rem] mb-[8rem] md:mb-0 px-[1rem] md:px-0 md:col-span-6 ${isV3User ? "bg-white" : ""}`}>
            <div className=" grid grid-cols-10 space-x-[0.1rem]  md:space-x-[0.15rem] lg:space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] ">
              <p className={`col-span-3 text-[#FFFFFF]  bg-[#D10A11] font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
                {/* Name */}
                {props.tableHeader[0]}
              </p>
              <p className={`col-span-2 text-[#FFFFFF]   bg-[#D10A11] font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
                {/* Profile ID */}
                {props.tableHeader[1]}
              </p>
              <p className={`hidden lg:flex col-span-2 text-[#FFFFFF]   bg-[#D10A11]  font-semibold pl-[0.3rem]  items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
                {/* Mobile
                 Number */}
                {props.tableHeader[2]}
              </p>
              <div className={`grid  place-content-center col-span-2 text-[#FFFFFF]   bg-[#D10A11] font-semibold pl-[0.2rem] md:pl-[0.3rem]  items-center ${isV3User ? "h-[40px]" : "h-[50px]"} lg:hidden`}>
                {/* <p> Mobile </p>
                   <p> Number </p> */}
                {props.tableHeader[2]}
              </div>
              <p className={`col-span-3 text-[#FFFFFF]   bg-[#D10A11] font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
                {props.tableHeader[3]}
              </p>
            </div>
            {props.isLoading
              ? <div className="flex h-52 items-center justify-center">
                <img alt="loader" src={LoaderGif} />
              </div>
              : props?.tableBody?.length <= 0
                ? <div className="flex h-36 items-center justify-center">
                  <p className="text-[#D10A11] text-[16px] font-semibold">No data found</p>
                </div>
                :
                <div className={`${isV3User ? "bgColor" : ""}`}>
                  {props?.tableBody?.map((e, i) => (
                    <div className=" grid grid-cols-10 space-x-[0.1rem]  md:space-x-[0.15rem] lg:space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px]  ">
                      <p className={`col-span-3 text-[#7A7A7A]  bg-[#F1F1F1] font-semibold pl-[0.3rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
                        {e.groomName}
                      </p>
                      <p className={`col-span-2 text-[#7A7A7A]   bg-[#F1F1F1] font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
                        {props?.title == "Phone Numbers Viewed by Me" ? e.vieweeId : e.viewerId}
                      </p>
                      <p className={`col-span-2 text-[#7A7A7A]   bg-[#F1F1F1] font-semibold  pl-[0.2rem] md:pl-[0.3rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
                        {e.isdCode} {e.mobileNumber}
                      </p>
                      <p className={`col-span-3 text-[#7A7A7A]   bg-[#F1F1F1] font-semibold pl-[0.2rem] md:pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
                        {getFormattedDateTimeWithPeriod(e.created)}
                      </p>
                    </div>
                  ))}
                </div>
            }
            {/* <div className=" flex justify-end mt-3  ">
          <button onClick={()=>{props.loadMore()}} className=" flex items-center justify-center  font-bold text-white bg-[#D10A11] px-[1.5rem] md:px-[3rem] whitespace-nowrap rounded-[3px] md:rounded-[4px] py-2 md:py-2 text-[11px] md:text-[11px] lg:text-[13px] ">
            Load More
          </button>
        </div> */}
          </div>
          {props?.tableBody?.length <= 0
            ? <></>
            : <div className=" flex justify-end mt-3  ">
              <button onClick={() => { props.loadMore() }} className=" flex items-center justify-center  font-bold text-white bg-[#D10A11] px-[1.5rem] md:px-[3rem] whitespace-nowrap rounded-[3px] md:rounded-[4px] py-2 md:py-2 text-[11px] md:text-[11px] lg:text-[13px] ">
                Load More
              </button>
            </div>}
        </div>
      }
    </>
  )
}

export default PhoneNumViewedTable
