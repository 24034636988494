import React, { useState, useEffect, useContext } from "react";
import { EditButton, SaveCancelButton } from "../EditProfileUtills";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST } from "../../../Services/api_services";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import config from "../../../config";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { getDeviceInfo, getGroupAuthId } from "../../Kalyanlite/utils";
import { DescriptionPopup } from "../../CommonPopup";
const DescriptionForm = (props) => {

  const { onClickTrack } = useAnalytics()
  const [isShowEdit, setEditView] = useState(true);
  // api/editdescriptioninfosave
  const { loginDetail, logout } = useContext(LoginContext);
  const [descriptionPopup, setDescriptionPopup] = useState(false);
  const [editMyProfielData,setEditMyProfielData] = useState([]);
  const [isDescriptionEdited,setIsDescriptionEdited] = useState(false);

  const formik = useFormik({
    initialValues: {
      moreInfo:  editMyProfielData?.moreInfo != undefined ? editMyProfielData?.moreInfo : props?.data?.moreInfo ? props?.data?.moreInfo : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      moreInfo: Yup.string(),
    }),

    onSubmit:async (values) => {
      await apiCallEditProfile(values);
    },
  });

  useEffect(() => {}, [props.data]);
  useEffect(() => {}, [formik.values, props?.data?.moreInfo]);
  const SubmitCall = (values) => {
    //console.log(values);
  };

  const apiEditProfile = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);
     
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apiCallEditProfile = async (values) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      more_info: values.moreInfo,
      ...(values.moreInfo != "" && {descriptionrequesttypeid:43}),
      requestfulfilledfrom:getDeviceInfo(),
      requestfulfilledvia:"viewprofile",
      recieverprofileid:getGroupAuthId()
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editdescriptioninfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        // alert("Description saved");
        props.callBackReload()
        // setEditView(false)
        apiEditProfile()
        onClickTrack(EventName.profileDescriptionSaveButton , {[EventKeys.profileDescription]:values });
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const onInfoChange = (e)=>{
    let text = e.target.value??"";

    text = text.replace(/  +/g, " ")

    formik.setFieldValue('moreInfo',text)
  }

 useEffect(()=>{
  if(isDescriptionEdited && !descriptionPopup){
    apiEditProfile() 
  }
},[isDescriptionEdited])

  return (
    <div>
      {descriptionPopup && 
        <DescriptionPopup 
          onClose={() =>setDescriptionPopup(false)}
          moreInfo={props?.data?.moreInfo}
          descriptionEdit={props?.data?.descriptionEdit}
          isFrom="viewprofile"
          data={props?.data}
          setIsDescriptionEdited={setIsDescriptionEdited}
        />
      }
      {isShowEdit ? (
        <></>
      ) : (
        <>
          {" "}
          <ActionRestrict>
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />
          </ActionRestrict>
          <p className={`mb-4 w-full mt-2 px-[1rem] md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold break-words ${props?.data?.moreInfo ? "" : "text-center"}`}>
            {props?.data?.moreInfo ? props?.data?.moreInfo : "Not Specified"}
          </p>
        </>
      )}
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <>
          {console.log(props.editfromadmin,'props.editfromadmin')}
            <div className=" m-[1rem] ">
              <p className="  ">
                <textarea
                  id="moreInfo"
                  rows="4"
                  className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-[3px] border border-[#8F8F8F] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                  placeholder=""
                  name="moreInfo"
                  value={formik?.values?.moreInfo?.replace(/\s\s+/g, ' ')}
                  onChange={onInfoChange}
                  disabled={props.editfromadmin == 'Y'}
                  onClick={() =>{
                    setDescriptionPopup(true)
                    setIsDescriptionEdited(false)
                  }}
                ></textarea>
              </p>
            </div>
            <SaveCancelButton
              submitStatus={formik.isSubmitting}
              saveCallBack={(e) => {
                e.preventDefault();
              }}
              cancelCallback={() => {
                formik.resetForm()
                onClickTrack(EventName.profileDescriptionCancelButton)
              }}
              isMyProfileDescription= {props?.isMyProfileDescription}
              editfromadmin = {props.editfromadmin} 
              isFrom="MyProfile"
            />
          </>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};

export default DescriptionForm;
