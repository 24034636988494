import { React, createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import Constants from '../Constants/constants';

export const LoginContext = createContext();

export default function LoginProvider({children}) {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()


    const isLoggedIn = () => {
      const isLoggedIn = localStorage?.getItem(Constants.loginLocalStorageKeys.isLoggedIn);
      if (isLoggedIn && isLoggedIn != null) {
        return true;
      } else {
        return false;
      }
    }

    const loginDetail = () => {
      const userName = localStorage?.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginName).includes("@") ? localStorage.getItem(Constants.loginLocalStorageKeys.loginName) : null;
      const userId = localStorage?.getItem(Constants.loginLocalStorageKeys.loginId);
      return [userName, userId]
    }

    const logout = () => {
      const isLoggedIn = localStorage?.removeItem(Constants.loginLocalStorageKeys.isLoggedIn);
      const loginName = localStorage?.removeItem(Constants.loginLocalStorageKeys.loginName);
      const userId = localStorage?.removeItem(Constants.loginLocalStorageKeys.loginId);
      const userName = localStorage?.removeItem(Constants.loginLocalStorageKeys.loginUserName);
      const userCountryCode = localStorage?.removeItem(Constants.loginLocalStorageKeys.loginCountryCode);
      const loginPhone = localStorage?.removeItem(Constants.loginLocalStorageKeys.loginPhone);
      const afterRegister = localStorage?.removeItem(Constants.loginLocalStorageKeys.afterRegister);
      const isCommunitySite = localStorage?.removeItem(Constants.loginLocalStorageKeys.isCommunitySite);
      const tagContent = localStorage?.removeItem(Constants.loginLocalStorageKeys.tagContent);
      const loginUserStatus = localStorage?.removeItem(Constants.loginLocalStorageKeys.loginUserStatus);
      const showLoader = localStorage?.removeItem(Constants.loginLocalStorageKeys.showLoader);
      const loginEmail = localStorage?.removeItem(Constants.loginLocalStorageKeys.loginEmail);
      const selectedCountryNameId = localStorage?.removeItem("selectedCountryNameId");
      const loglevel = localStorage?.removeItem("loglevel");
      const selectedMonthIncome = localStorage?.removeItem("selectedMonthIncome");
      const jwt = localStorage?.removeItem("jwt");
      const admuserid = localStorage?.removeItem("admuserid");
      localStorage.removeItem(Constants.loginLocalStorageKeys.appDownloadPopupAlreadyShowed)
      localStorage.removeItem(Constants.loginLocalStorageKeys.showDownloadPopup)
      localStorage.removeItem(Constants.loginLocalStorageKeys.showMigrationPopup)
      localStorage.removeItem(Constants.loginLocalStorageKeys.chatLogin)
      localStorage.removeItem(Constants.loginLocalStorageKeys.chatLoginId)
      localStorage.removeItem(Constants.loginLocalStorageKeys.familyLoginId)
      const hasPopupBeenShown = localStorage?.removeItem('popupShown');
      localStorage.removeItem("isShowPaymentPopup")
      localStorage.removeItem("isV3User")
      localStorage.removeItem("submitpref");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("pw");
      localStorage.removeItem(Constants.loginLocalStorageKeys.familyLoginId)
      localStorage.removeItem(Constants.loginLocalStorageKeys.familyLoginMobileNumber)
      localStorage.removeItem(Constants.loginLocalStorageKeys.parentsMobileNumber)
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.filterState)
      localStorage.removeItem(Constants.loginLocalStorageKeys.loginFromAdmin) 
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationPath)
      localStorage.removeItem(Constants.loginLocalStorageKeys.profileId) 
      // localStorage.removeItem(Constants.loginLocalStorageKeys.showNotifyPopup) 
      sessionStorage.removeItem("viewedProfileIds")
      
      // dispatch({ type: "CLEAR_STORE" })
      navigate("/")
      return [jwt, userName, userId,isLoggedIn,loginName,userCountryCode,loginPhone, afterRegister,tagContent,isCommunitySite, loginUserStatus, selectedMonthIncome, loglevel, selectedCountryNameId,loginEmail, showLoader,hasPopupBeenShown ,admuserid]
    }


    const [loginUserDetail, setLoginUserDetail] = useState({
      userMail:"",
      userPhoneNumner:"",
      userCountryCode:""
    })

    const [ phoneNumber, setPhoneNumber ] = useState( ()=>{
      const val = sessionStorage.getItem("otpnumber")
      sessionStorage.removeItem("otpnumber")
      return val
    })

    const [mobileNumberForOTP, setMobileNumberForOTP] = useState()


    const Detail = { isLoggedIn, loginDetail, logout, phoneNumber, setPhoneNumber, loginUserDetail, setLoginUserDetail, mobileNumberForOTP, setMobileNumberForOTP }

  return (
    <LoginContext.Provider value={Detail}>
      {children}
    </LoginContext.Provider>
  )
}
