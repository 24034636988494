
import React from "react"
import CloseIcon from "../assets/Images/cancelicon.svg";
import successicon from "../assets/Images/Group 3560.svg"

const SuccessPopup = ({ closePopup ,menu}) => {
  return (
    <div>
    

        <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-6">
            <div className="">
                <button
                className=' bg-transparent z-40 border-0 text-black  absolute top-0 right-[-1px]'
                onClick={closePopup} 
              
                >
                <img className='h-6 w-6 ' src={CloseIcon} alt='' />
                </button>
            </div>
            <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col lg:w-[30rem] lg:h-[12rem] bg-white outline-none focus:outline-none">

                <div className="relative p-6 flex-auto text-center items-center justify-center mt-6">
                <div className="space-y-2">
                    <p className="text-[#D10A11] font-bold font-Poppins text-[18px]">Success!</p>
                   {menu=="grouppage" ?
                    <p className="text-[#777777] font-regular font-Poppins text-[14px]">Invite Sent Successfully</p>
                    :
                    menu == "profileSelect" ?
                    <p className="text-[#777777] font-regular font-Poppins text-[14px]">Profile is Selected and Shared with Family</p>
                    :
                    <p className="text-[#777777] font-regular font-Poppins text-[14px]">Request Sent Successfully!</p>


                   } 
                </div>
                <div className="flex py-4 justify-center">
                    <img src={successicon} className="w-8 h-8" alt=""/>
                </div>
                </div>
            </div>
            </div>
        </div>
      
    </div>
  );
}
export default SuccessPopup;

