import React, { useContext, useEffect, useMemo, useState } from 'react'
import { BasicEditTextView, BasicTextView, EditButton, SaveCancelButton, SelectCurrencyInput, SelectInput, SelectViewWithHeading } from '../EditProfileUtills';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useAnalytics } from '../../../Hooks/usePageChange';
import { EventKeys, EventName, blockInvalidCharForNumber } from '../../../Constants/constants';
import { validateNumber } from '../../../Utils';
import { LoginContext } from '../../../Contexts/LoginContext';
import { getDeviceInfo } from '../../Kalyanlite/utils';
import { POST } from '../../../Services/api_services';
import config from '../../../config';
import EndPoints from '../../../Constants/EndPoints';
import { MultiSelectView } from '../PartnerPreference/PartnerPreferenceUtils';
import axios from 'axios';
import ActionRestrict from '../../Kalyanlite/Common/ActionRestrict';

let errorNameMessage = ""

export default function AdditionalInformation(props) {
    const { onClickTrack } = useAnalytics();
    const NumbersDropdown = [
      { id: '0', name: '0' },
      { id: '1', name: '1' },
      { id: '2', name: '2' },
      { id: '3', name: '3' },
      { id: '4', name: '4' },
      { id: '5', name: '5' },
      { id: '6', name: '6' },
      { id: '7', name: '7' },
      { id: '8', name: '8' },
      { id: '9', name: '9' },
  ]


    const [isShowEdit, setEditView] = useState(true);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [assetDropdown, setAssetDropdown] = useState(false);
    const [occupationDropdown, setOccupationDropdown] = useState(false);
    const [isdCodeDropdown, setIsdCodeDropdown] = useState([]);
    const [isSiblingDisabled, setIsSiblingDisabled] = useState(false)
    const [fatherNameErrorMessage, setFatherNameErrorMessage] = useState("")
    const [motherNameErrorMessage, setMotherNameErrorMessage] = useState("")
    const [sistersMarriedOptions, setSistersMarriedOptions] = useState(NumbersDropdown);
    const [brothersMarriedOptions, setBrothersMarriedOptions] = useState(NumbersDropdown);

    const { loginDetail, logout } = useContext(LoginContext);
    const relationOfParents = props?.data?.relationshipName
console.log(props?.dropDown,'props?.dropDown');
    useEffect(() => {
      setAssetDropdown(props?.dropDown?.assetData ?? []);
      setOccupationDropdown(props?.dropDown?.occupationData ?? []);
      setIsdCodeDropdown(props?.dropDown?.isdcode ?? []);
    }, [props?.dropDown])

    const showMother = props?.data?.relationshipId == "5";
    const showFather = props?.data?.relationshipId == "6";
    const Male = props.data && props.data.gender == "M"
    const Female = props.data && props.data.gender == "F"

    const formik = useFormik({
        initialValues: {
          
          fathersName: props?.data?.fathersName ? props?.data?.fathersName : "",
          fathersOccupation: props?.data?.fathersOccupation ? props?.data?.fathersOccupation[0].id : "",
          fisdCode: relationOfParents === "Father" ? props?.data?.parentsIsdCode : props?.data?.fathersIsdcode ? props?.data?.fathersIsdcode : "+91",
          fMobileNumber: relationOfParents === "Father" ? props?.data?.parentsMobileNumber : props?.data?.fathersMobilenumber ? props?.data?.fathersMobilenumber : "",
          isFather: relationOfParents ? relationOfParents === "Father" ? true : false : false,

          mothersName: props?.data?.mothersName ? props?.data?.mothersName : "",
          mothersOccupation: props?.data?.mothersOccupation ? props?.data?.mothersOccupation[0].id : "",
          misdCode: relationOfParents === "Mother" ? props?.data?.parentsIsdCode : props?.data?.mothersIsdcode ? props?.data?.mothersIsdcode : "+91",
          motherMobileNumber:relationOfParents === "Mother" ? props?.data?.parentsMobileNumber : props?.data?.mothersMobilenumber ? props?.data?.mothersMobilenumber : "",
          isMother: relationOfParents ? relationOfParents === "Mother" ? true : false : false,

          haveSiblings: props?.data?.ishavenosiblings == "Y" ? true : false,
          
          brotherCount:props?.data?.brothers !== undefined ? props?.data?.brothers : "",
          marriedBrotherCount:props?.data?.marriedBrothers !== undefined ? props?.data?.marriedBrothers : "",
          broIsdCode:props?.data?.brothersIsdcode ? props?.data?.brothersIsdcode : "+91",
          broMobileNumber:props?.data?.brothersMobilenumber ? props?.data?.brothersMobilenumber : "",
          
          sisterCount:props?.data?.sisters !== undefined ? props?.data?.sisters : "",
          marriedSisterCount:props?.data?.marriedSisters !== undefined ? props?.data?.marriedSisters : "",
          sisIsdCode:props?.data?.sistersIsdcode ? props?.data?.sistersIsdcode : "+91",
          sisMobileNumber:props?.data?.sistersMobilenumber ? props?.data?.sistersMobilenumber : "",

          assetInfo: props?.data?.asset?.length > 0 
          ? props?.data?.asset.map(item => item.id) 
          : [],
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: Yup.object().shape({
          fMobileNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { fisdCode } = this.parent;
            if (fisdCode == "499" || fisdCode == "+91") {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "fMobileNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "fMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { motherMobileNumber,broMobileNumber,sisMobileNumber } = this.parent;
            if (value && (value == motherMobileNumber || value == broMobileNumber || value == sisMobileNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "fMobileNumber",
              });
            }
            return true;
          }
        ).when("isFather", (isFather,schema) => {

          if (isFather && isFather[0]) {
            return schema.required("Mobile Number is Required");
          } else {
            return schema;
          }
        }),
        motherMobileNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { misdCode } = this.parent;
            if (misdCode == "499" || misdCode == "+91") {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "motherMobileNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "motherMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber,broMobileNumber,sisMobileNumber } = this.parent;
            if (value && (value == fMobileNumber || value == broMobileNumber || value == sisMobileNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "motherMobileNumber",
              });
            }
            return true;
          }
        ).when("isMother", (isMother,schema) => {

          if (isMother && isMother[0]) {
            return schema.required("Mobile Number is Required");
          } else {
            return schema;
          }
        }),
        broMobileNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { broIsdCode } = this.parent;
            if (broIsdCode == "499" || broIsdCode == "+91") {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "broMobileNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "broMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber,motherMobileNumber,sisMobileNumber } = this.parent;
            if (value && (value == fMobileNumber || value == motherMobileNumber || value == sisMobileNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "broMobileNumber",
              });
            }
            return true;
          }
        ),
        sisMobileNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { sisIsdCode } = this.parent;
            if (sisIsdCode == "499" || sisIsdCode == "+91") {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "sisMobileNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "sisMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber,broMobileNumber,motherMobileNumber } = this.parent;
            if (value && (value == fMobileNumber || value == broMobileNumber || value == motherMobileNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "sisMobileNumber",
              });
            }
            return true;
          }
        )
        }),
        

          onSubmit: (values) => {
          console.log(values,'additionalsubmitvalues');
          if(fatherNameErrorMessage == '' && motherNameErrorMessage == ''){
            if(values.motherMobileNumber !== '' || values.fMobileNumber !== ''){
              if(values.isFather == true || values.isMother == true){
                setSubmitStatus(true)
                apiCallAdditionalInfosave(values)
              }
            }else{
              setSubmitStatus(true)
              apiCallAdditionalInfosave(values)
            }
          }
        },
      });

      console.log(isdCodeDropdown?.filter(e => e?.countryId == props?.data?.parentsIsdCode),'fkojdkojdfojdf');
      const apiCallAdditionalInfosave = async (value) => {

        let request = {
          email: loginDetail()[0],
          userId: loginDetail()[1],
          "fathersName":value?.fathersName,
          "mothersName":value?.mothersName,
          "mothersOccupation":value?.mothersOccupation,
          "fathersOccupation":value?.fathersOccupation,
          "sisters":value?.sisterCount != "" ? value?.sisterCount : null,
          "brothers":value?.brotherCount != "" ? value?.brotherCount : null,
          "marriedSisters":value?.marriedSisterCount != "" ? value?.marriedSisterCount : null,
          "marriedBrothers":value?.marriedBrotherCount != "" ? value?.marriedBrotherCount : null,
          "fathersIsdcode":value?.fisdCode,
          "fathersMobilenumber":value?.fMobileNumber,
          "mothersIsdcode":value?.misdCode,
          "mothersMobilenumber":value?.motherMobileNumber,
          "brothersMobilenumber":value?.broMobileNumber != "" ? value?.broMobileNumber : null,
          "sistersMobilenumber":value?.sisMobileNumber != "" ? value?.sisMobileNumber : null,
          "sistersIsdcode":value?.sisIsdCode != "" ? value?.sisIsdCode : null,
          "brothersIsdcode":value?.broIsdCode != "" ? value?.broIsdCode : null,
          "editedFrom":"EditMyprofile",
          "loginFrom":getDeviceInfo(),
          "isParentsMobilenumberfilled":props?.data?.isParentsMobilenumberfilled == "Y" ? "Y" : "N",
          "assetIds": value?.assetInfo,
          "isfatherAsParentsnumber": value?.isFather ? "Y" : "N",
          "isMotherAsParentsnumber":value?.isMother ? "Y" : "N",
          "ishavenosiblings": value?.haveSiblings ? "Y" : "N",
          "requestfulfilledfrom":getDeviceInfo(),
          "requestfulfilledvia":"viewprofile",
          "recieverprofileid":props?.data?.profileId,
          ...(value?.isFather == "Y" || value?.isMother == "Y" && {"parentnumberrequesttypeid":87}),
          ...(value?.fathersName !== "" && {"fathersnamerequesttypeid":67}),
          ...(value?.fathersOccupation !== "" && {"fathersoccupationrequesttypeid":68}),
          ...(value?.mothersName !== "" && {"mothersnamerequesttypeid":69}),
          ...(value?.mothersOccupation !== "" && {"mothersoccupationrequesttypeid":70}),
          ...((value?.brotherCount != "") && {"brothersrequesttypeid":71}),
          ...((value?.marriedBrotherCount != "") && {"marriedbrothersrequesttypeid":72}),
          ...((value?.sisterCount != "") && {"sistersrequesttypeid":73}),
          ...((value?.marriedSisterCount != "") && {"marriedsistersrequesttypeid":74}),
          ...(value?.assetInfo?.length > 0 && {"assetsrequesttypeid":75}),
        };
        console.log(request,'sfjkkjfskdjfks');
    
        let { statusCode, data } = await POST(
          `${config.api.API_URL}${EndPoints.saveAdditionInfo()}`,
          request
        );
    
        if (statusCode === 200) {
          if (data.data.status === "SUCCESS") {
            setSubmitStatus(false)
            props.callBackReload();
            // setEditView(false);
          }
          else{
            if(formik?.values?.isFather){
              formik.setFieldError('fMobileNumber',data?.data?.statusmessage)
              setSubmitStatus(false)
            }
            else{
              formik.setFieldError('motherMobileNumber',data?.data?.statusmessage)
              setSubmitStatus(false)
            }
          }
        } else if (statusCode === 401) {
          logout();
        }
      };

      useEffect(()=>{
        if(formik.values?.brotherCount != "" || formik.values?.marriedBrotherCount != "" || formik.values?.broMobileNumber != "" || formik.values?.sisterCount != "" || formik.values?.marriedSisterCount != "" || formik.values?.sisMobileNumber != "" ){
          setIsSiblingDisabled(true)
          formik.setFieldValue('haveSiblings',false)
        }
        else{
          setIsSiblingDisabled(false)
        }
      },[formik.values?.brotherCount, formik.values?.marriedBrotherCount, formik.values?.broIsdCode, formik.values?.broMobileNumber, formik.values?.sisterCount, formik.values?.marriedSisterCount, formik.values?.sisIsdCode, formik.values?.sisMobileNumber, formik.values?.assetInfo])

      // useEffect(() => {
      //   if (formik?.values?.motherMobileNumber !== "" ) {
      //     if (formik?.values?.isMother) {
      //       formik.setFieldValue('isFather', false);
      //     }
      //   }
      //   else{
      //     formik.setFieldValue('isMother', false);
      //   }
      // }, [formik?.values?.motherMobileNumber, formik?.values?.isMother, formik?.values?.fMobileNumber]);

      // useEffect(() => {
      //   if (formik?.values?.fMobileNumber !== "") {
      //     formik.setFieldValue('isFather', true);
          
      //     if (formik?.values?.isFather) {
      //       formik.setFieldValue('isMother', false);
      //     }
      //   }
      //   else{
      //     formik.setFieldValue('isFather', false);
      //   }
      // }, [formik?.values?.fMobileNumber]);

      // useEffect(() => {
      //   if (formik?.values?.isMother) {
      //     formik.setFieldValue('isFather', false);
      //   }
      //   else if (!(formik?.values?.isMother) && formik?.values?.fMobileNumber != ""){
      //     formik.setFieldValue('isFather', true);
      //   }
        
      // }, [formik?.values?.isMother, formik?.values?.fMobileNumber]);

      // useEffect(() => {
      //   if (formik?.values?.isFather) {
      //     formik.setFieldValue('isMother', false);
      //   }
      // }, [formik?.values?.isFather]);


      const selectedAssetValues= useMemo(()=>{
        return assetDropdown?.length > 0 &&  assetDropdown?.filter(option=>option?.id === formik?.values?.assetInfo)?.at(0)
      },[assetDropdown, formik?.values?.assetInfo])
      console.log(selectedAssetValues,'jkfojdofjdfif');


      const selectedFatherOccupationValues= useMemo(()=>{
        return occupationDropdown?.length > 0 &&  occupationDropdown?.filter(option=>option?.id === formik?.values?.fathersOccupation)?.at(0)
      },[occupationDropdown, formik?.values?.fathersOccupation])

      const selectedMotherOccupationValues= useMemo(()=>{
        return occupationDropdown?.length > 0 &&  occupationDropdown?.filter(option=>option?.id === formik?.values?.mothersOccupation)?.at(0)
      },[occupationDropdown, formik?.values?.mothersOccupation])

      const selectedFathersIsdValues= useMemo(()=>{
        return isdCodeDropdown?.length > 0 &&  isdCodeDropdown?.filter(option=>option?.isdcode === formik?.values?.fisdCode)[0]
      },[isdCodeDropdown, formik?.values?.fisdCode])

      const selectedMothersIsdValues= useMemo(()=>{
        return isdCodeDropdown?.length > 0 &&  isdCodeDropdown?.filter(option=>option?.isdcode === formik?.values?.misdCode)?.at(0)
      },[isdCodeDropdown, formik?.values?.misdCode])

      const selectedBrothersIsdValues= useMemo(()=>{
        return isdCodeDropdown?.length > 0 &&  isdCodeDropdown?.filter(option=>option?.isdcode === formik?.values?.broIsdCode)?.at(0)
      },[isdCodeDropdown, formik?.values?.broIsdCode])

      const selectedSistersIsdValues= useMemo(()=>{
        return isdCodeDropdown?.length > 0 &&  isdCodeDropdown?.filter(option=>option?.isdcode === formik?.values?.sisIsdCode)?.at(0)
      },[isdCodeDropdown, formik?.values?.sisIsdCode])

      const fatherNameCheckList = async (value,e) => {
        let request = {
          name: value
        };
        let data = await axios.post(
          `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
          request
        );
          if (data.data.status === "Success") {
            errorNameMessage = data?.data?.data?.Messagge
            setFatherNameErrorMessage(data?.data?.data?.Messagge)
            formik.handleChange(e)
          }
    };

    const motherNameCheckList = async (value,e) => {
      let request = {
        name: value
      };
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
        request
      );
        if (data.data.status === "Success") {
          errorNameMessage = data?.data?.data?.Messagge
          setMotherNameErrorMessage(data?.data?.data?.Messagge)
          formik.handleChange(e)
        }
  };

  const updateMarriedBrotherOptions = (count) => {
    const maxBrothers = parseInt(count, 10);
    const newOptions = NumbersDropdown.filter(option => parseInt(option.id, 10) <= maxBrothers);
    setBrothersMarriedOptions(newOptions);
  };

  const handleBrotherCountChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('brotherCount', value);
    updateMarriedBrotherOptions(value);
  };

  // Update options whenever brotherCount changes
  useEffect(() => {
    updateMarriedBrotherOptions(formik.values.brotherCount);
    if(formik?.values?.brotherCount){
      if(formik?.values?.brotherCount == "0"){
        formik.setFieldValue('marriedBrotherCount', "");
      }
      if(formik?.values?.marriedBrotherCount > formik?.values?.brotherCount){
        formik.setFieldValue('marriedBrotherCount', "")
      }
    }
  }, [formik.values.brotherCount]);

  const updateMarriedSisterOptions = (count) => {
    const maxSisters = parseInt(count, 10);
    const newOptions = NumbersDropdown.filter(option => parseInt(option.id, 10) <= maxSisters);
    setSistersMarriedOptions(newOptions);
  };

  const handleSisterCountChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('sisterCount', value);
    updateMarriedSisterOptions(value);
  };

  // Update options whenever sisterCount changes
  useEffect(() => {
    updateMarriedSisterOptions(formik.values.sisterCount);
    if(formik?.values?.sisterCount){
      if(formik?.values?.sisterCount == "0"){
        formik.setFieldValue('marriedSisterCount', '');
      }
      if(formik?.values?.marriedSisterCount > formik?.values?.sisterCount){
        formik.setFieldValue('marriedSisterCount', '')
      }
    } 
  }, [formik.values.sisterCount]);



  return (
    <>
    {isShowEdit ? 
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-5 md:p-0">

                <BasicEditTextView
                    title="Father's Name"
                    inputID="fathersName"
                    inputName="fathersName"
                    className="fathersName"             
                    inputType="text" 
                    required={false}
                    error={fatherNameErrorMessage== ''? '': fatherNameErrorMessage}
                    value={formik.values.fathersName}
                    onChange={(e)=>{
                      fatherNameCheckList (e.target.value,e)
                      let text = e.target.value ?? "";
                      text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                      text = text.charAt(0).toUpperCase() + text.slice(1)
                      var splitStr = text.toLowerCase().split(' ');
                      for (var i = 0; i < splitStr.length; i++) {
                          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                      }
                      text =  splitStr.join(' '); 
                      e.target.value = text;
                      if ((e.target.value.split(".").length - 1) <= 1) {
                        formik.handleChange(e)
                      }
                    }}
                    editfromadmin ={props.editfromadmin}
                />

                 
                {/* <SelectInput
                    title="Father's Occupation"
                    name="fathersOccupation"
                    required={false}
                    value={selectedFatherOccupationValues}
                    defaultValue={formik.values.fathersOccupation}
                    error={formik.errors.fathersOccupation}
                    options={[{id:null,name:"Select"},...occupationDropdown]}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(selected, ev) => {
                        formik.setFieldValue("fathersOccupation", selected.id)
                    }}
                />  */}
                <SelectViewWithHeading
                  title={"Father's Occupation"}
                  inputID={"fathersOccupation"}
                  inputName={"fathersOccupation"}
                  value={formik.values.fathersOccupation}
                  onChange={formik.handleChange}
                  error={formik.errors.fathersOccupation}
                  options={occupationDropdown}
                  required={false}
                  isFrom={"additionalDetails"}
                  defalutValue={formik.values.fathersOccupation}
                  disabled={props.editfromadmin == 'Y'}
                />

            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                    Father's Mobile Number
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
                <p className="">
                  <div className="flex space-x-3">
                      <SelectCurrencyInput
                        menu=""
                        name="fisdCode"
                        id="fisdCode"
                        title="fisdCode"
                        error={formik.errors.fisdCode}
                        defaultValue={formik.values.fisdCode}
                        value={selectedFathersIsdValues}
                        onChange={(selected, ev) => {
                          formik.setFieldValue("fisdCode", selected.isdcode)
                        }}
                        className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${(relationOfParents == "Father" || props.editfromadmin == 'Y')? "cursor-not-allowed": ""}`}
                        options={[{id:null,name:"Select"},...isdCodeDropdown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.isdcode}`}
                        getOptionValue={(option) => option.isdcode}
                        isDisabled={relationOfParents == "Father" || props.editfromadmin == 'Y'}
                      />
                  
                    <p className="flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                        id="fMobileNumber"
                        inputID="fMobileNumber"
                        inputName="fMobileNumber"
                        type="tel"
                        value={formik.values.fMobileNumber}
                        onChange={async(e) => {
                          let inputValue = e.target.value;
                          if (validateNumber(inputValue)) {
                            await formik.setFieldValue("fMobileNumber", inputValue);
                            if(!showMother){
                              if(inputValue.length == 10 && formik?.values?.fisdCode == "+91"){
                                formik.setFieldValue("isFather",true);
                              }else if((inputValue.length < 7 || inputValue.length > 15) && formik?.values?.fisdCode != "+91"){
                                formik.setFieldValue("isFather",true);
                              }
                              else{
                                formik.setFieldValue("isFather",false);
                              }
                            }
                          }
                        }}
                        // onKeyDown={blockInvalidCharForNumber}
                        disabled={relationOfParents == "Father" || props.editfromadmin == 'Y'}
                        className={`RegPhone w-[100%] border border-[#8F8F8F] rounded-[3px] h-[36px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${(relationOfParents == "Father" || props.editfromadmin == 'Y') ? "cursor-not-allowed": ""}`}
                      />
                    </p>
                    
                  </div>
                  {formik.errors.fMobileNumber && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.fMobileNumber}
                      </p>
                    )}
                  
                </p>
              </div>
            </div>
            {showMother ? <></> :
                  <div className="grid grid-cols-9 items-center gap-2 pt-4">
                    <div className="col-start-1 col-span-9 md:col-start-5 md:col-span-5 flex center-align gap-2 pl-0 md:pl-2 lg:pl-4">
                      <input
                          id="isFather"
                          name="isFather"
                          type="checkbox"
                          // checked={formik.values.isMother == true ? false :formik.values.isFather}
                          checked={formik.values.isFather}
                          value={formik.values.isFather}
                          onChange={(e)=>{
                            formik.setFieldValue("isFather",e.target.checked);
                            if(e.target.checked){
                              formik.setFieldValue("isMother",false);
                            }
                          }}
                          className={`form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 relative  ${(relationOfParents != undefined || props.editfromadmin == 'Y') ? "cursor-not-allowed": "cursor-pointer"}`}
                          autoComplete="off"
                          disabled={showFather || props.editfromadmin == 'Y'}
                      />
                      <div className='lg:block md:hidden hidden'>
                        <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[12px] ${relationOfParents != undefined ? "cursor-not-allowed": "cursor-pointer"}`} >
                            Add this number to my profile as Parent's Number
                        </p>
                      </div>
                      <div className='lg:hidden md:block block'>
                        <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[12px] ${relationOfParents != undefined ? "cursor-not-allowed": "cursor-pointer"}`} >
                            Add this number to my profile as Parent's <br/>Number
                        </p>
                      </div>
                    </div>
                </div>
                  }

            <BasicEditTextView
                title="Mother's Name"
                inputID="mothersName"
                inputName="mothersName"
                className="mothersName"             
                inputType="text" 
                required={false}
                error={motherNameErrorMessage == '' ? '' : motherNameErrorMessage}
                value={formik.values.mothersName}
                onChange={(e)=>{
                  motherNameCheckList (e.target.value,e)
                  let text = e.target.value ?? "";
                  text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                  text = text.charAt(0).toUpperCase() + text.slice(1)
                  var splitStr = text.toLowerCase().split(' ');
                  for (var i = 0; i < splitStr.length; i++) {
                      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                  }
                  text =  splitStr.join(' '); 
                  e.target.value = text;
                  if ((e.target.value.split(".").length - 1) <= 1) {
                    formik.handleChange(e)
                  }
                }}
                editfromadmin = {props.editfromadmin}
            />
                 
            {/* <SelectInput
                title="Mother's Occupation"
                name="mothersOccupation"
                required={false}
                value={selectedMotherOccupationValues}
                defaultValue={formik.values.mothersOccupation}
                error={formik.errors.mothersOccupation}
                options={[{id:null,name:"Select"},...occupationDropdown]}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(selected, ev) => {
                    formik.setFieldValue("mothersOccupation", selected.id)
                }}
            />  */}
            <SelectViewWithHeading
                title={"Mother's Occupation"}
                inputID={"mothersOccupation"}
                inputName={"mothersOccupation"}
                value={formik.values.mothersOccupation}
                onChange={formik.handleChange}
                error={formik.errors.mothersOccupation}
                options={occupationDropdown}
                required={false}
                isFrom={"additionalDetails"}
                disabled={props.editfromadmin == 'Y'}
              />

            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                    Mother's Mobile Number
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
                <p className="">
                  <div className="flex space-x-3">
                      <SelectCurrencyInput
                        menu=""
                        name="misdCode"
                        id="misdCode"
                        title="misdCode"
                        error={formik.errors.misdCode}
                        defaultValue={formik.values.misdCode}
                        value={selectedMothersIsdValues}
                        onChange={(selected, ev) => {
                          formik.setFieldValue("misdCode", selected.isdcode)
                        }}
                        className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                        options={[{id:null,name:"Select"},...isdCodeDropdown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.isdcode}`}
                        // getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
                        getOptionValue={(option) => option.isdcode}
                        // isDisabled={formik.values.isMother}
                        isDisabled={relationOfParents == "Mother" || props.editfromadmin == 'Y'}
                      />
                  
                    <p className="flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                        id="motherMobileNumber"
                        inputID="motherMobileNumber"
                        inputName="motherMobileNumber"
                        type="tel"
                        value={formik.values.motherMobileNumber}
                        onChange={async (e) => {
                          let inputValue = e.target.value;
                          if (validateNumber(inputValue)) {
                            await formik.setFieldValue("motherMobileNumber",inputValue)
                            if(!showFather){
                              if(inputValue.length == 10 && formik?.values?.misdCode == "+91"){
                                formik.setFieldValue("isMother",true);
                              }else if((inputValue.length < 7 || inputValue.length > 15) && formik?.values?.misdCode != "+91"){
                                formik.setFieldValue("isMother",true);
                              }
                              else{
                                formik.setFieldValue("isMother",false);
                              }
                            }
                          }
                        }}
                        // disabled={formik.values.isMother}
                        disabled={relationOfParents == "Mother" || props.editfromadmin == 'Y'}
                        className={`RegPhone w-[100%] border border-[#8F8F8F] rounded-[3px] h-[36px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${(relationOfParents == "Mother" || props.editfromadmin == 'Y') ? "cursor-not-allowed": ""}`}
                      />
                    </p>
                  </div>
                  {formik.errors.motherMobileNumber && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.motherMobileNumber}
                      </p>
                  )}
                </p>
              </div>
            </div>
                  {showFather ? <></> :
                  <div className="grid grid-cols-9 items-center gap-2 pt-4">
                    <div className="col-start-1 col-span-9 md:col-start-5 md:col-span-5 flex center-align gap-2 pl-0 md:pl-2 lg:pl-4">
                      <input
                          id="isMother"
                          name="isMother"
                          type="checkbox"
                          // checked={formik.values.isFather == true ? false :formik.values.isMother}
                          checked={formik.values.isFather == true ? '' : formik.values.isMother}
                          value={formik.values.isMother}
                          onChange={(e)=>{
                            formik.setFieldValue("isMother",e.target.checked);
                            if(e.target.checked){
                              formik.setFieldValue("isFather",false);
                            }
                          }}
                          className={`form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 relative ${(relationOfParents == "Mother" || props.editfromadmin == 'Y') ? "cursor-not-allowed": "cursor-pointer"}`}
                          autoComplete="off"
                          disabled={showMother || props.editfromadmin == 'Y'}
                      />
                      <div className='lg:block md:hidden hidden'>
                        <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[12px] ${relationOfParents == "Mother" ? "cursor-not-allowed": "cursor-pointer"}`} >
                            Add this number to my profile as Parent's Number
                        </p>
                      </div>
                      <div className='lg:hidden md:hidden block'>
                        <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[12px] ${relationOfParents == "Mother" ? "cursor-not-allowed": "cursor-pointer"}`} >
                            Add this number to my profile as Parent's <br /> Number
                        </p>
                      </div>
                      <div className='lg:hidden md:block hidden'>
                        <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[12px] ${relationOfParents == "Mother" ? "cursor-not-allowed": "cursor-pointer"}`} >
                            Add this number to my profile as  <br /> Parent's Number
                        </p>
                      </div>
                    </div>
                  </div>
                  }

            <div className="flex items-center gap-2 w-[18rem] whitespace-nowrap pt-4 px-0 md:px-8 lg:px-8">
                <input
                    type="checkbox"
                    name="haveSiblings"
                    disabled={isSiblingDisabled || props.editfromadmin == 'Y'}
                    checked={formik.values.haveSiblings}
                    onChange={formik.handleChange}
                    className={`form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 relative ${(isSiblingDisabled || props.editfromadmin == 'Y')? "cursor-not-allowed" :"cursor-pointer"}`}
                />
                <p className={`text-[#7A7A7A] text-[12px] md:text-[12px] lg:text-[16px] font-extrabold pb-1 ${(isSiblingDisabled || props.editfromadmin == 'Y') ? "cursor-not-allowed" :"cursor-pointer"}`} >
                  No brothers or sisters to {Male ? 'groom' : Female ? 'bride' : ''}
                </p>
            </div>

            {/* <BasicEditTextView
                title="Number of Brothers"
                inputID="brotherCount"
                inputName="brotherCount"
                className="brotherCount"             
                inputType="tel" 
                required={false}
                error={formik.errors.brotherCount}
                value={formik.values.brotherCount}
                onChange={(e)=>{
                    if(validateNumber(e.target.value)){
                        formik.handleChange(e)
                    }
                }}
                disabled={formik.values.haveSiblings}
            /> */}
            <SelectViewWithHeading
                title={"Number of Brothers"}
                inputID={"brotherCount"}
                inputName={"brotherCount"}
                value={formik.values.brotherCount}
                onChange={handleBrotherCountChange}
                error={formik.errors.brotherCount}
                options={NumbersDropdown}
                required={false}
                disabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                isFrom={"additionalDetails"}
              />

            {/* <BasicEditTextView
                title="Number of Brothers Married"
                inputID="marriedBrotherCount"
                inputName="marriedBrotherCount"
                className="marriedBrotherCount"             
                inputType="tel" 
                required={false}
                error={formik.errors.marriedBrotherCount}
                value={formik.values.marriedBrotherCount}
                onChange={(e)=>{
                    if(validateNumber(e.target.value)){
                        formik.handleChange(e)
                    }
                }}
                disabled={formik.values.haveSiblings}
            /> */}
              <SelectViewWithHeading
                title={"Number of Brothers Married"}
                inputID={"marriedBrotherCount"}
                inputName={"marriedBrotherCount"}
                value={formik.values.marriedBrotherCount}
                onChange={formik.handleChange}
                error={formik.errors.marriedBrotherCount}
                options={brothersMarriedOptions}
                required={false}
                disabled={formik.values.haveSiblings || formik.values.brotherCount == 0 || props.editfromadmin == 'Y' }
                isFrom={"additionalDetails"}
              />

            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                    Brother's Mobile Number
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
                <p className="">
                  <div className="flex space-x-3">
                      <SelectCurrencyInput
                        menu=""
                        name="broIsdCode"
                        id="broIsdCode"
                        title="broIsdCode"
                        error={formik.errors.broIsdCode}
                        defaultValue={formik.values.broIsdCode}
                        value={selectedBrothersIsdValues}
                        onChange={(selected, ev) => {
                          formik.setFieldValue("broIsdCode", selected.isdcode)
                        }}
                        className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                        options={[{id:"",name:"Select",isdcode:""},...isdCodeDropdown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.isdcode}`}
                        // getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
                        getOptionValue={(option) => option.isdcode}
                        isDisabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                      />
                  
                    <p className="flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                        id="broMobileNumber"
                        inputID="broMobileNumber"
                        inputName="broMobileNumber"
                        type="tel"
                        value={formik.values.broMobileNumber}
                        onChange={(e)=>{
                        if(validateNumber(e.target.value)){
                            formik.handleChange(e)
                        }
                        }}
                        className={`RegPhone w-[100%] border border-[#8F8F8F] rounded-[3px] h-[36px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${(formik.values.haveSiblings || props.editfromadmin == 'Y') ? "cursor-not-allowed" :""}`}
                        disabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                      />
                    </p>
                  </div>
                  {formik.errors.broMobileNumber && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.broMobileNumber}
                      </p>
                  )}
                </p>
              </div>
            </div>

            {/* <BasicEditTextView
                title="Number of Sisters"
                inputID="sisterCount"
                inputName="sisterCount"
                className="sisterCount"             
                inputType="tel" 
                required={false}
                error={formik.errors.sisterCount}
                value={formik.values.sisterCount}
                onChange={(e)=>{
                    if(validateNumber(e.target.value)){
                        formik.handleChange(e)
                    }
                }}
                disabled={formik.values.haveSiblings}
            /> */}
              <SelectViewWithHeading
                title={"Number of Sisters"}
                inputID={"sisterCount"}
                inputName={"sisterCount"}
                value={formik.values.sisterCount}
                onChange={handleSisterCountChange}
                error={formik.errors.sisterCount}
                options={NumbersDropdown}
                required={false}
                disabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                isFrom={"additionalDetails"}
              />

            {/* <BasicEditTextView
                title="Number of Sisters Married"
                inputID="marriedSisterCount"
                inputName="marriedSisterCount"
                className="marriedSisterCount"             
                inputType="tel" 
                required={false}
                error={formik.errors.marriedSisterCount}
                value={formik.values.marriedSisterCount}
                onChange={(e)=>{
                    if(validateNumber(e.target.value)){
                        formik.handleChange(e)
                    }
                }}
                disabled={formik.values.haveSiblings}
            /> */}

              <SelectViewWithHeading
                title={"Number of Sisters Married"}
                inputID={"marriedSisterCount"}
                inputName={"marriedSisterCount"}
                value={formik.values.marriedSisterCount}
                onChange={formik.handleChange}
                error={formik.errors.marriedSisterCount}
                options={sistersMarriedOptions}
                required={false}
                disabled={formik.values.haveSiblings || formik.values.sisterCount == 0 || props.editfromadmin == 'Y' }
                isFrom={"additionalDetails"}
              />

            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                    Sister's Mobile Number
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
                <p className="">
                  <div className="flex space-x-3">
                      <SelectCurrencyInput
                        menu=""
                        name="sisIsdCode"
                        id="sisIsdCode"
                        title="sisIsdCode"
                        error={formik.errors.sisIsdCode}
                        defaultValue={formik.values.sisIsdCode}
                        value={selectedSistersIsdValues}
                        onChange={(selected, ev) => {
                          formik.setFieldValue("sisIsdCode", selected.isdcode)
                        }}
                        className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                        options={[{id:null,name:"Select",isdcode:""},...isdCodeDropdown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.isdcode}`}
                        // getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
                        getOptionValue={(option) => option.isdcode}
                        isDisabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                        />
                  
                    <p className="flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                        id="sisMobileNumber"
                        inputID="sisMobileNumber"
                        inputName="sisMobileNumber"
                        type="tel"
                        value={formik.values.sisMobileNumber}
                        onChange={(e)=>{
                        if(validateNumber(e.target.value)){
                            formik.handleChange(e)
                        }
                        }}
                        className={`RegPhone w-[100%] border border-[#8F8F8F] rounded-[3px] h-[36px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${(formik.values.haveSiblings || props.editfromadmin == 'Y') ? "cursor-not-allowed" :""}`}
                        disabled={formik.values.haveSiblings || props.editfromadmin == 'Y'}
                      />
                    </p>               
                  </div>
                  {formik.errors.sisMobileNumber && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.sisMobileNumber}
                      </p>
                  )}
                </p>
              </div>
            </div>

            {/* <SelectInput
                title="Asset Information"
                name="assetInfo"
                required={false}
                value={selectedAssetValues}
                defaultValue={formik.values.assetInfo}
                error={formik.errors.assetInfo}
                options={[{id:null,name:"Select"},...assetDropdown]}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(selected, ev) => {
                  console.log(selected,'sdfmkldfldkfl');
                    formik.setFieldValue("assetInfo", selected.id)
                }}
            />  */}

              <MultiSelectView
                title="Assets Owned by Family"
                inputID={"assetInfo"}
                inputName={"assetInfo"}
                value={formik?.values?.assetInfo}
                defalutValue={[formik?.values?.assetInfo]}
                onChange={(e) => {
                  formik.setFieldValue("assetInfo", Array.from(e));
                }}
                page={"additionInfo"}
                error={formik.errors.assetInfo}
                options={assetDropdown}
                // isDisabled={formik.values.haveSiblings}
                placeholder="Select Multiple"
                isFrom='MyProfile'
                editfromadmin = {props.editfromadmin}
              />

                <SaveCancelButton
                    saveCallBack={(e) => {
                        e.preventDefault();
                    }}
                    cancelCallback={() => {
                        // setEditView(false);
                        formik.resetForm()
                    }}
                    submitStatus={submitStatus}
                    editfromadmin = {props.editfromadmin}
                    isFrom="MyProfile"
                />
                </div>
            </form>
        </> 
        :
        <>
            <ActionRestrict>
                <EditButton
                    editCallBack={() => {
                        setEditView(true)
                    }}
                />
            </ActionRestrict>

            <div className="md:mb-8 p-5">
                <BasicTextView
                    title={"Father's Name"}
                    value={props?.data?.fathersName ? props?.data?.fathersName : "Not Specified"}
                />
                <BasicTextView
                    title={"Father's Occupation"}
                    value={props?.data?.fathersOccupation ? props?.data?.fathersOccupation[0].name : "Not Specified"}
                />
                <BasicTextView
                    title={"Father's Mobile Number"}
                    value={showFather ? props?.data?.parentsIsdCode+" - "+props?.data?.parentsMobileNumber : props?.data?.fathersMobilenumber ? props?.data?.fathersIsdcode+" - "+props?.data?.fathersMobilenumber : "Not Specified"}
                />
                <BasicTextView
                    title={"Mother's Name"}
                    value={props?.data?.mothersName ? props?.data?.mothersName : "Not Specified"}
                />
                <BasicTextView
                    title={"Mother's Occupation"}
                    value={props?.data?.mothersOccupation ? props?.data?.mothersOccupation[0].name : "Not Specified"}
                />
                <BasicTextView
                    title={"Mother's Mobile Number"}
                    value={showMother ? props?.data?.parentsIsdCode+" - "+props?.data?.parentsMobileNumber : props?.data?.mothersMobilenumber ? props?.data?.mothersIsdcode+" - "+props?.data?.mothersMobilenumber : "Not Specified"}
                />
                <BasicTextView
                    title={"Number of Brothers"}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.brothers !== undefined  ? props?.data?.brothers : "Not Specified"}
                />
                <BasicTextView
                    title={"Number of Brothers Married"}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.marriedBrothers !== undefined ? props?.data?.marriedBrothers : "Not Specified"}
                />
                <BasicTextView
                    title={"Brother's Mobile Number"}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.brothersMobilenumber ? props?.data?.brothersIsdcode+" - "+props?.data?.brothersMobilenumber : "Not Specified"}
                />
                <BasicTextView
                    title={"Number of Sisters"}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.sisters !== undefined ? props?.data?.sisters : "Not Specified"}
                />
                <BasicTextView
                    title={"Number of Sisters Married"}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.marriedSisters !== undefined ? props?.data?.marriedSisters : "Not Specified"}
                />
                <BasicTextView
                    title={"Sister's Mobile Number"}
                    value={props?.data?.ishavenosiblings == "Y" ? "None" : props?.data?.sistersMobilenumber ? props?.data?.sistersIsdcode+" - "+props?.data?.sistersMobilenumber : "Not Specified"}
                />
                <BasicTextView
                    title={"Assets Owned by Family"}
                    value={props?.data?.asset?.length > 0
                      ? props?.data?.asset.map(item => item.name).join(', ')
                      : "Not Specified"}               
                />
            </div>
        </>
        }
    </>
  )
}
