import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "../../../Images/Group 761@2x.png"

const PasswordPopup = (props) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);
  const [showHideConfirmPassword, changeShowHideConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    // enableReinitialize: true,
    validationSchema: Yup.object().shape({
      password: 
      Yup.string().oneOf([Yup.ref("password"),null],"Please Enter photo password")
      .min(8, "Password must contain at least 8 characters")
      .max(16, "Password must less than 16 characters")
      .required("Please Enter photo password"),
        confirmPassword: Yup.string().oneOf([Yup.ref("password"),null],"Password not matched")
        .min(8, "Password must contain at least 8 characters")
        .max(16, "Password must less than 16 characters")
        // .required("Please Enter photo password"),
    }),

    onSubmit: (values) => {
      //   apiCallVerifyOTP(values);
      props.callBackApply(values.password);
    },
  });
  let {error} = useSelector(state => ({
    error: state.Login?.error?.data?.isPasswordMatches
  }));

  //console.log(error,"state.Login?.error?.data?.message")
  return (
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6 max-w-3xl ">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt=""/>
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              Photo Password
            </h3>
            {/* <button
              className=" bg-transparent border-0 text-black float-right pr-4"
              onClick={() => {
                props.close();
              }}
            >
              <img
                className="h-5 w-5 "
                src={CloseIcon}
              />
            </button> */}
          </div>
          <div className="flex items-center justify-center relative p-6 flex-auto w-[90vw] md:w-[40vw]">
            <form onSubmit={formik.handleSubmit}
            className="flex flex-col items-start"
            >
              <label className="my-2 flex rounded-[3px] border border-[#F18181] items-center">
                <input
                  name={"password"}
                  id={"password"}
                  type={showHidePassword ? "text" : "password"}
                  placeholder="Enter Photo password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className="w-[80vw] md:w-[30vw] p-2.5 my-1 text-sm text-gray-900 border-none outline-none"
                />
                <div className="pr-2.5">
                  <img
                    onClick={() => changeShowHidePassword(!showHidePassword)}
                    src={
                      showHidePassword
                        ? "/Assets/Images/passwordeyeopen.png"
                        : "/Assets/Images/passwordeye.svg"
                    }
                    className="w-[1rem] h-[1rem] md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5"
                    alt=""
                  />
                </div>
                
              </label>
              {formik.errors.password && (
                 <p className="text-[red] text-[12px]">
                  {formik.errors.password}</p> 
                

               ) }
              <label className="my-2 flex rounded-[3px] border border-[#F18181] items-center">
                <input
                  name={"confirmPassword"}
                  id={"confirmPassword"}
                  type={showHideConfirmPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  className="w-[80vw] md:w-[30vw] p-2.5 my-1 text-sm text-gray-900 border-none outline-none"
                />
                <div className="pr-2.5">
                  <img
                    onClick={() => changeShowHideConfirmPassword(!showHideConfirmPassword)}
                    src={
                      showHideConfirmPassword
                        ? "/Assets/Images/passwordeyeopen.png"
                        : "/Assets/Images/passwordeye.svg"
                    }
                    className="w-[1rem] h-[1rem] md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5"
                    alt=""
                  />
                </div>
                
              </label>
              {formik.errors.confirmPassword && (
                  <p className="text-[red] text-[12px]">
                    {formik.errors.confirmPassword}
                  </p>
                )}
              <button
                type="submit"
                className="self-center flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
              >
                Apply
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordPopup;
