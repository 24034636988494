import React, { Component, useEffect, useState, useContext } from "react";
import Modal from "./Modal";
import { RouteHelper } from "../../Routes/RouteSegment";
import Constants, { InboxFilters } from "../../Constants/constants";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "../../Utils";

const SearchResultCardMobileview = (props) => {
  //console.log(props.menu, "menu1")
  //   const [selectReject, setSelectReject] = useState({
  //   select: props?.cardData?.selectedprofile ?? false,
  //   reject: props?.cardData?.selectedrejected ?? false,
  //   message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
  // });
  // useEffect(() => {}, [selectReject]); 
  const [showModal, setShowModal] = useState(false);

  const [selectReject, setSelectReject] = useState({
    select: props?.cardData?.selectedprofile ?? false,
    reject: props?.cardData?.selectedrejected ?? false,
    message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    photoRequest: props?.cardData?.profilerequestcheck ?? false
  });
  const navigate = useNavigate()
  useEffect(() => { }, [selectReject, props]);
  useEffect(() => { }, [showModal])
  useEffect(() => { }, [props.data])

  useEffect(() => {
    setSelectReject({
      select: props?.cardData?.selectedprofile ?? false,
      reject: props?.cardData?.selectedrejected ?? false,
      photoRequest: props?.cardData?.profilerequestcheck ?? false
    });
  }, []);

  useEffect(() => {
    setSelectReject({
      select: selectReject.select,
      reject: selectReject.reject,
      message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    });
  }, [props]);

  const onMessageClick = () => {
    if(props.cardData?.profileblockedByMe == "Y"){
      props?.callBackBlockProfile()
    }
    else{
    props.callbackSelectReject("MESSAGE", "");
    }
  }

  const onlineNowClick = ()=>{
    if(props?.cardData?.profilealreadyContected === "Y" && !Constants.profileStatusOptions.includes(props?.cardData?.profileStatus) && props.data?.status !== "pending" && props.data?.status !== "hidden"){
      navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), { state: { profileId: props?.cardData?.profileid, threadId:props?.cardData?.messageSent[0].subjectId, type:"Sent" } })
    }else{
      onMessageClick()
    }
  }

  return (
    <div className="relative mt-[2.5rem]">
       {props?.cardData?.adminSelected? 
        <div className=" flex justify-center items-center ">
        <div className="">
        <div className =" absolute w-[2rem] top-[-0.3rem] left-[6.7rem] md:top-[1.4rem] lg:top-[1.7rem] z-[1] md:left-[8rem]
  border-l-[15px] border-l-transparent
  border-t-[17px] border-t-[#D10A11]
  border-r-[22px] border-r-transparent">
</div>
        <p className="text-white text-[10px] md:text-[12px] lg:text-[15px] absolute top-[-1.6rem] left-[6rem] md:top-[0rem] md:left-[8rem]  z-[1] font-segeo px-[1rem] rounded-lg font-bold bg-[#D10A11] p-2 ">
          Profile selected by your Relationship Executive
        </p>
        </div>
      </div>
      : "" }
      <div className="bg-white  lg:mt-0">
        <div className="grid grid-cols-5 gap-6 relative">
          <div className="col-span-2 relative w-[125%] h-full">
            <img
              //    className=" h-full w-[17rem]"
              src={props?.cardData?.profileurl}
              alt=""
              width={150}
              className={`${props?.cardData?.profileblurPhoto === "Y" ? "blur-[2px] " : " "
                } h-full md:h-[10.6rem]  w-[10.3rem] imageCard`}
            />
            {/* profile lock */}
            {props?.cardData?.profilefromPasswordProtected === "Y" && (
              <div 
                onClick={() => props.callBackNav()}
                className="absolute top-0 flex justify-center items-center h-[100%] w-[8.5rem] md:w-[8.5rem] lg:w-[10.5rem] cursor-pointer"
              >
                <img
                  className="h-[2rem] w-[2rem]"
                  src="/Assets/Images/lockicon.svg"
                  alt="Person"
                />
              </div>
            )}
            {props?.cardData?.isAvatarPic && (
              <div
                  onClick={() => {

                     if(props?.cardData?.profilerequestcheck === true){

                              }
                    else if (props?.cardData?.profilerequestcheck === false) {
                       setSelectReject({
                        ...selectReject,
                        photoRequest: !selectReject.photoRequest,
                      });
                      props.callbackRequestPhoto(
                        
                      );
                    }
                  }}
                className="absolute top-0 flex justify-center items-end h-[100%] w-[10.3rem] md:w-[8.5rem] lg:w-[10.5rem] cursor-pointer"
              >
                <span className=" text-[#575556] mb-4  w-[13rem] md:w-[8.5rem] lg:w-[10.5rem] h-[2rem] text-center  ">
                  {props?.cardData?.profilerequestcheck
                    ? "PHOTO REQUESTED"
                    : "REQUEST PHOTO"}
                </span>
              </div>
            )}
            {/* profile lock */}

          </div>
          <div
            className="col-span-3 grid border-t border-gray-200 border-r
                         text-left flex-grow">
            <p className="  text-[#039511]  text-end  font-segeo font-semibold text-[10px] px-2 md:hidden lg:hidden">
              {props.cardData.profilelastOnline === "Online Now" ?
                <div onClick={onlineNowClick} className="cursor-pointer flex mt-2 justify-end ">
                  <img
                    className=" w-3 h-3"
                    src="/Assets/Images/Group 619.svg"
                    alt=""
                  />
                  <span
                    className=" px-2 text-[10px] text-[#575556]"
                  >
                    Online Chat Now
                  </span>
                </div>
                : <p className=" text-[#039511] mt-2 text-end  font-segeo font-semibold text-[10px] px-2 md:hidden lg:hidden">
                  Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
                </p>
              }
            </p>
            <div className="px-3">
              <span
               onClick={() => {
                    props.callBackNav();
                    // setNavList([...navList, "Profile Details"])
                  }}
               className="text-[#575556] md:text-[13px] text-[12pt]  font-bold">
                {props.cardData.profilename}
              </span>
              <p className="text-[#7A7A7A] text-[9pt] md:text-[12px]">
                {props.cardData.profileage}, {props.cardData.profileheight},{" "}
                {props.cardData.profilenativelanguage}
              </p>
              <p className="text-[#7A7A7A] text-[9pt] md:text-[12px]">
                {props.cardData.profilecaste},</p>
              <p className="font-segeo text-[#7A7A7A] text-[12px]">
                {props.cardData.profilequalification}/{props.cardData.profileoccupation}
              </p>
              <p className="text-[#7A7A7A] text-[9pt] md:text-[12px]">
                {props.cardData.profilecity},{" "}
                {props.cardData.profilestate}</p>
            </div>
            <div>
              <div className="flex py-1 px-3 md:px-0 justify-around items-center space-x-3">
                <div
                  className="mr-3 flex flex-col items-center w-[10%]"
                  onClick={() => {
                    setSelectReject({
                      ...selectReject,
                      select: !selectReject.select,
                    });

                    props.callbackSelectReject(
                      "SELECT",
                      selectReject.select ? "Selected" : "Select"
                    );
                  }}
                >
                  <img
                    className="w-5 h-5"
                    src={
                      selectReject.select
                        ? "/Assets/Images/Group 169 green.svg"
                        : "/Assets/Images/Group 169.svg"
                    }
                    alt=""
                  />
                  <span
                    className={`${selectReject.select ? "text-[#039511]" : "text-[#7A7A7A]"
                      } text-[11px] `}
                  >
                    {selectReject.select ? "Selected" : "Select"}
                  </span>
                </div>
                <div
                  onClick={() => {
                    setSelectReject({
                      ...selectReject,
                      reject: !selectReject.reject,
                    });

                    props.callbackSelectReject(
                      "REJECT",
                      selectReject.reject ? "Rejected" : "Reject"
                    );
                  }}
                  className=" flex flex-col items-center w-[10%]">
                  <img
                    className="w-5 h-5"
                    src={
                      selectReject.reject
                        ? "/Assets/Images/Group 166 red.svg"
                        : "/Assets/Images/Group 166.svg"
                    }
                    alt=""
                  />
                  <span
                    className={`${selectReject.reject ? "text-[#D10A11]" : "text-[#7A7A7A]"
                      } text-[11px]`}
                  >
                    {selectReject.reject ? "Rejected" : "Reject"}
                  </span>
                </div>
                <div
                  onClick={onMessageClick}
                  className="flex flex-col  items-center">
                  <img
                    className="w-6  h-6"
                    src={
                      selectReject.message
                        ? "/Assets/Images/Group 185 green.svg"
                        : "/Assets/Images/Group 185.svg"
                    }
                    alt=""
                  />
                  <span
                    className={`${selectReject.message
                        ? "text-[#039511]"
                        : "text-[#7A7A7A]"
                      } text-[11px]`}
                  >
                    {selectReject.message ? "Messaged" : "Message"}
                  </span>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-around bg-[#FEBABA] p-2">
          <Modal
            type="Unread" menu="mobile" data={props.data} cardData={props.cardData} requestcount={props.cardData.requestCount} convocount={props.cardData.totalConversationCount}
          />

          <button
            onClick={() => props.callBackPhone()}
            className=" flex text-[8pt] md:text-[12px] text-[#D10A11] font-semibold  font-segeo">
            <img className="h-4  w-4" src="/Assets/Images/Group 173.svg" alt="" />
            <span className="px-2"> Phone Number
            </span></button>
          {props?.cardData?.profileparentsnumber === "" ? 
            <button
              onClick={() =>{ 
                if(props.cardData?.profileblockedByMe == "Y"){
                  props?.callBackBlockProfile()
                }else {
                  if(props?.cardData?.parentnumberrequestcheck === false){
                    props.callBackParentsNumber()
                  }
                }
              }}
              className=" flex text-[#039511] text-[8pt] md:text-[12px] font-semibold  font-segeo ">
              <img className=" h-4 w-4" src="/Assets/Images/Group 172.svg" alt="" />
              <span className="px-2">{props?.cardData?.parentnumberrequestcheck ? "Parent's Num Requested":"Request Parent's Num"}</span>
            </button>
            :
            <button
              onClick={() => props.callBackPhone()}
              className=" flex text-[#039511] text-[8pt] md:text-[12px] font-semibold  font-segeo ">
              <img className=" h-4 w-4" src="/Assets/Images/Group 172.svg" alt="" />
              <span className="px-2">Parents Number</span>
            </button>
          }
        </div>
      </div>
    </div>
  )
}

export default SearchResultCardMobileview
