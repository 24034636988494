import React, { useState, useRef } from 'react';

const PinInput = ({ length = 4, onChange }) => {
  const [values, setValues] = useState(Array(length).fill(''));
  const inputs = useRef([]);

  const handleChange = (e, idx) => {
    const val = e.target.value;
    if (/^\d*$/.test(val)) { // Only allow digits
      const newValues = [...values];
      newValues[idx] = val.slice(0, 1);
      setValues(newValues);
      onChange(newValues.join(''));
      // Move to next input if filled
      if (val && idx < length - 1) {
        inputs.current[idx + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, idx) => {
    if (e.key === 'Backspace' && !values[idx] && idx > 0) {
      inputs.current[idx - 1].focus();
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    if (/^\d{4}$/.test(paste)) {
      const newValues = paste.split('').slice(0, length);
      setValues(newValues);
      onChange(newValues.join(''));
      inputs.current[length - 1].focus();
    }
  };

  return (
    <div className="flex space-x-2" onPaste={handlePaste}>
      {values.map((value, idx) => (
        <input
          key={idx}
          ref={(el) => inputs.current[idx] = el}
          type='number' 
          inputMode='numeric' 
          pattern="[0-9]*"
          value={value}
          onChange={(e) => handleChange(e, idx)}
          onKeyDown={(e) => handleKeyDown(e, idx)}
          maxLength="1"
          className="w-[2rem] h-[2rem] xl:w-[2.3rem] xl:h-[2.3rem] text-center text-2xl border border-gray-400 rounded focus:outline-none focus:border-blue-500 mb-2"
        />
      ))}
    </div>
  );
};

export default PinInput;
