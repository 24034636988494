import React, { useContext, useState } from 'react'
import { Drawer } from "antd"
import { NavBarContext } from '../Contexts/NavBarContext'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { TbArrowBigLeftFilled } from "react-icons/tb"
import { Disclosure } from '@headlessui/react'
import { GoChevronUp } from "react-icons/go"
import { BsHeadset } from "react-icons/bs"
import { MdEmail } from "react-icons/md"
import RouteSegments, { RouteHelper } from '../Routes/RouteSegment'
import Constants, { InboxFilters } from '../Constants/constants'

//Images
import ProfileImage from "../Components/Kalyanlite/assets/Images/Rectangle 10@2x.png";
import Handshake from "../Components/Kalyanlite/assets/Images/Group 2169@2x.png";
import Phone from "../Components/Kalyanlite/assets/Images/Group 2034@2x.png";
import Profiles from "../Components/Kalyanlite/assets/Images/Group 383@2x.png";
import Help from "../Components/Kalyanlite/assets/Images/Group 2028@2x.png";
import MyProfile from "../Components/Kalyanlite/assets/Images/Group 863-white.svg";
import Photo from "../Components/Kalyanlite/assets/Images/Group 865-white.svg";
import TrustMark from "../Components/Kalyanlite/assets/Images/Group 866-white.svg";
import Horoscope from "../Components/Kalyanlite/assets/Images/Group 867-white.svg";
import kalyanJewellery from "../Images/kalyan_logo.svg";
import Settings from "../Components/Kalyanlite/assets/Images/Group 2029@2x.png";
import Plan from "../Components/Kalyanlite/assets/Images/Group 3745@2x.png";
import ProfileView from "../Components/Kalyanlite/assets/Images/Group 1669@2x.png";
import WhoView from "../Components/Kalyanlite/assets/Images/Group 1945@2x.png";
import PhoneNumber from "../Components/Kalyanlite/assets/Images/Group 1651@2x.png";
import WhoViewPhone from "../Components/Kalyanlite/assets/Images/Group 1946@2x.png";
import HobbyWhite from  "../Components/Kalyanlite/assets/Images/HobbyWhite.svg"
import callingPacks from '../Components/Kalyanlite/assets/Images/callPack.svg'
import * as Images from "../Components/Kalyanlite/assets/images" 

import Logout from "../Components/Kalyanlite/assets/Images/LogoutIcon.svg";
import { useSelector } from "react-redux";
import ParentsPhonePopup from './DashboardComponents/ParentsPhonePopup'
import * as MatchSelector from "../Store/KalyanLite/selectors"
import { SwitchBg, SwitchIcon } from './Kalyanlite/assets/images'
import EndPoints from '../Constants/EndPoints'
import { CMS_Login_via } from '../Helpers/api_helpers'
import { GET } from '../Services/api_services'
import config from '../config'
import { LoginContext } from '../Contexts/LoginContext'

// const menuOptions = [
//     {
//         title: "Matches",
//         leadingSrc: "/Assets/Images/MatchesIcon.svg",
//         menuItems: [
//             {
//                 id: 1,
//                 title: "Weekly Matches",
//                 to: `${RouteSegments.DASHBOARD}${RouteSegments.WEEKLYVIEWALL}`,
//                 state: { isfrom: "weekly" },
//             },
//             {
//                 id: 2,
//                 title: "Recommend Matches",
//                 to: `${RouteSegments.DASHBOARD}${RouteSegments.RECOMMENDED}`,
//                 state: { isfrom: "recommended" },
//             },
//             {
//                 id: 3,
//                 // title: "Profiles I am looking for",
//                 title : "Profiles Matching the Partner Expectation",
//                 to: `${RouteSegments.DASHBOARD}${RouteSegments.PROFILELOOKINGFOR}`,
//                 state: { isfrom: "lookingfor" },
//             },
//             {
//                 id: 4,
//                 title: "Profiles looking for me",
//                 to: `${RouteSegments.DASHBOARD}${RouteSegments.PROFILELOOKINGFORME}`,
//                 state: { isfrom: "lookingforme" },
//             },
//             {
//                 id: 5,
//                 title: "Mutual Matches",
//                 to: `${RouteSegments.DASHBOARD}${RouteSegments.MUTUALMATCHES}`,
//                 state: { isfrom: "mutual" },
//             },
//             {
//                 id: 6,
//                 title: "Selected Profiles",
//                 to: `${RouteSegments.DASHBOARD}${RouteSegments.SELECTEDPROFILES}`,
//                 state: { isfrom: "selectedprofile" },
//             },
//         ]
//     },
//     {
//         title: "Search",
//         leadingSrc: "/Assets/Images/SearchIcon.svg",
//         menuItems: [
//             {
//                 id: 7,
//                 title: "Basic Search",
//                 to: `${RouteSegments.searchTab(1)}`,
//             },
//             {
//                 id: 8,
//                 title: "Advanced Search",
//                 to: `${RouteSegments.searchTab(2)}`,
//             },
//         ]
//     },
//     {
//         title: "Inbox",
//         leadingSrc: "/Assets/Images/InboxIcon.svg",
//         menuItems: [
//             {
//                 id: 9,
//                 title: "Message",
//                 to: RouteHelper.getInboxByTab(InboxFilters.message[0].urlPath),
//                 // state: { menu: "MESSAGE", subMenu: 0 },
//             },
//             {
//                 id: 10,
//                 title: "Requests Received",
//                 to: RouteHelper.getInboxByTab(InboxFilters.requestReceived[0].urlPath),
//                 // state: { menu: "REQUESTRECEIVED", subMenu: 0 },
//             },
//             {
//                 id: 11,
//                 title: "Sent Requests",
//                 to: RouteHelper.getInboxByTab(InboxFilters.sentRequest[0].urlPath),
//                 // state: { menu: "REQUESTSENT", subMenu: 0 },
//             },
//         ]
//     },
//     {
//         title: "Edit Profile",
//         leadingSrc: "/Assets/Images/EditProfileIcon.svg",
//         menuItems: [
//             {
//                 id: 12,
//                 title: "My Profile",
//                 to: `${RouteSegments.editProfileTab(1)}`,
//             },
//             {
//                 id: 13,
//                 title: "Partner Preference",
//                 to: `${RouteSegments.editProfileTab(2)}`,
//             },
//             {
//                 id: 14,
//                 title: "Photo",
//                 to: `${RouteSegments.editProfileTab(3)}`,
//             },
//             {
//                 id: 15,
//                 title: "Trust Mark",
//                 to: `${RouteSegments.editProfileTab(4)}`,
//             },
//             {
//                 id: 16,
//                 title: "Horoscope",
//                 to: `${RouteSegments.editProfileTab(5)}`,
//             },
//             {
//                 id: 17,
//                 title: "Settings",
//                 to: `${RouteSegments.editProfileTab(6)}`,
//             },
//             {
//                 id: 18,
//                 title: "My plan",
//                 to: `${RouteSegments.editProfileTab(7)}`,
//             },
//             {
//                 id: 19,
//                 title: "Profiles viewed by me",
//                 to: `${RouteSegments.editProfileTab(8)}`,
//             },
//             {
//                 id: 20,
//                 title: "Who viewed my profile",
//                 to: `${RouteSegments.editProfileTab(9)}`,
//             },
//             {
//                 id: 21,
//                 title: "Phone numbers viewed by me",
//                 to: `${RouteSegments.editProfileTab(10)}`,
//             },
//             {
//                 id: 22,
//                 title: "Who viewed my phone number",
//                 to: `${RouteSegments.editProfileTab(11)}`,
//             },
//         ]
//     },
// ]


  

export const DrawerItem = ({ onClick, children }) => {
    return <div onClick={onClick} className="cursor-pointer text-[12px] text-[#575556] hover:text-[#575556] py-1 ml-[48px]">{children}</div>
}

export function DrawerAccordian({ item, onItemSelect, children = null }) {

    const navigate = useNavigate()


    const userData = useSelector(
        (state) => state?.DashBoardAPI?.ProfileData?.data,
      );

    const handleItemClick = () => {
        // if (item.to) {
        //     // Redirect to the specified route
        //     navigate(item.to, { state: item.state });
        // } else {
            
        //     // Handle other actions, if needed
        //     console.log(`Clicked on ${item.title}`);

        // }

        // Close the drawer
        onItemSelect(item);
    };

    return (
        <>
          
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2">
                        <div className='flex items-center'>
                            <img alt='matches' className='w-9 h-9 bg-[#D10A11] p-2 rounded-full' src={item.leadingSrc} />
                            <span onClick={handleItemClick} className='text-start pl-4 pr-2 text-[14px] font-medium text-[#575556]'>{item.title}</span>
                        </div>
                       
                    </Disclosure.Button>
                    
                    
                            
                        
                    
                </>
            )}
        </Disclosure>
        </>
    )
}


function SidebarLite({ profileData, onLogout ,setShowParentNumPopup}) {

    const menuOptions = [
        // {
        //     title: "My Profile",
        //     leadingSrc: Handshake,
        //     to: `${RouteSegments.editProfileTab(1)}`,
           
        // },
        {
            title: "My Profile",
            leadingSrc: MyProfile,
            to: `${RouteSegments.editProfileTab(1)}`,
         
        },
        {
            title: "Set Partner Expectations",
            leadingSrc: Handshake,
            to: `${RouteSegments.editProfileTab(2)}`,
           
        },
        {
            title: "Add Parent's Phone Number",
            leadingSrc: Phone,
            
          
        },
        {
            title: "Selected Profiles",
            leadingSrc: Profiles,
            to: `${RouteSegments.MATCH_CONVERSATIONS_LITE}`,
            state: { from: "search", onlySelected: true }
        },
        {
            title: "Photos",
            leadingSrc: Photo,
            to: `${RouteSegments.editProfileTab(3)}`,
         
        },
        {
            title: "Trust Mark",
            leadingSrc: TrustMark,
            to: `${RouteSegments.editProfileTab(4)}`,
         
        },
        {
            title: "Horoscope",
            leadingSrc: Horoscope,
            to: `${RouteSegments.editProfileTab(5)}`,
         
        },
        {
            title: "Hobbies & Interests",
            leadingSrc: HobbyWhite,
            to: `${RouteSegments.editProfileTab(12)}`,
         
        },
        {
            title: "Settings",
            leadingSrc: Settings,
            to: `${RouteSegments.editProfileTab(6)}`,
         
        },
        {
            title: "My Plan",
            leadingSrc: Plan,
            to: `${RouteSegments.editProfileTab(7)}`,
         
        },
        {
            title: "Profiles Viewed by Me",
            leadingSrc: ProfileView,
            to: `${RouteSegments.editProfileTab(8)}`,
         
        },
        {
            title: "Who Viewed my Profile",
            leadingSrc: WhoView,
            to: `${RouteSegments.editProfileTab(9)}`,
         
        },
        {
            title: "Phone Numbers Viewed by Me",
            leadingSrc: PhoneNumber,
            to: `${RouteSegments.editProfileTab(10)}`,
         
        },
        {
            title: "Who Viewed my Phone Number",
            leadingSrc:WhoViewPhone,
            to: `${RouteSegments.editProfileTab(11)}`,
         
        },
        {
            title: "Kalyan Rewards",
            leadingSrc: Images.TrophyWhite,
            to: `${RouteSegments.editProfileTab(13)}`,
    
        },
        {   
            title: "Calling Packs",
            leadingSrc: callingPacks,
            to: `${RouteSegments.MEMBERSHIP_REGISTER()}`
        },
        {
            title: "Help",
            leadingSrc: Help,
            to: `${RouteSegments.HELP_AND_FAQ()}`
         
        },
       
      ]

    const { isDrawerOpen, openDrawer, closeDrawer } = useContext(NavBarContext)
    const navigate = useNavigate()

    const { loginDetail } = useContext(LoginContext);

    const { selectedMatchesData } = useSelector(MatchSelector.getSelectedMatches)
    const userData = useSelector(
        (state) => state?.DashBoardAPI?.ProfileData?.data,
    );
    const parentsNumberAvailable = userData?.parentsNumberAvailable === "Yes"

    console.log({ selectedMatchesData });

    const onItemSelect = (item) => {
        if(item.title==="Add Parent's Phone Number"){
            setShowParentNumPopup(true)

        } else if (item.title === "Selected Profiles") {
            if (selectedMatchesData?.length > 0) {
                navigate(item.to, { state: item.state })
            }
        } else {
            navigate(item.to, { state: item.state })
        }

        
        closeDrawer()
    }

    console.log('profileData', profileData);

    const onPayNowClick = () => {
        if (profileData?.isPaid === "Y") {
            navigate(RouteSegments.editProfileTab(7))
        } else {
            navigate(RouteSegments.MEMBERSHIP_REGISTER())
        }
        closeDrawer()
    }

    const onStoreClick = () => {
        window.open(
          RouteSegments.JEWELLERY_MICROSITE,
          '_blank' // <- This is what makes it open in a new window.
        );    
        // navigate(RouteSegments.JEWELLERY_MICROSITE, true);
      }

    const onLogoutClick = ()=>{
        onLogout()
        closeDrawer()
    }

    const onProfileClick = () => {
        closeDrawer()
    }

    const handlePaid = () => {
        if (profileData?.isPaid === "Y") {
            navigate(RouteSegments.editProfileTab(7))
        } else {
            navigate(RouteSegments.MEMBERSHIP_REGISTER())
        }
        closeDrawer()
    }

    const switchApicall = async () => {
        await GET(
            `${config.api.API_URL}${EndPoints.switchtonewversion()}`
        );
        const cms_response = await CMS_Login_via({
            userId: loginDetail()[1]
        })


        console.log({ cms_response });
        if (cms_response.data) {
            console.log(cms_response, "cms_response");
            localStorage.setItem(
                Constants.loginLocalStorageKeys.chatLogin,
                String(cms_response?.data?.access_token) ?? "OLD"
            );
            localStorage.setItem(
                Constants.loginLocalStorageKeys.chatLoginId,
                String(cms_response?.data?.id) ?? ""
            );
        }
    }

    const isV3User = localStorage.getItem("isV3User")
    const onSwitchClick = async () => {
        if (isV3User) {
            localStorage.removeItem("isV3User")
            navigate(RouteSegments.DASHBOARD)
        } else {
            localStorage.setItem("isV3User", "true")
            await switchApicall()
            navigate(RouteSegments.MATCH_LITE)
        }
    }

    const profilePic = profileData?.profile_imageurl?.includes("femaleavatar") ? Images.WomanIcon : profileData?.profile_imageurl?.includes("maleavatar") ? Images.ManIcon : profileData?.profile_imageurl

    const isAvatar = profileData?.profile_imageurl?.includes("femaleavatar") || profileData?.profile_imageurl?.includes("maleavatar") ? true :  false

    return (
        <Drawer
            placement="left"
            closable={false}
            width="75%"
            bodyStyle={{ padding: "0", backgroundColor: "#D10A11" }}
            onClose={closeDrawer}
            open={isDrawerOpen}>
            <div className='flex flex-col h-screen'>
                <div className=''>
                    <div className='flex py-4 pl-4 justify-between items-center'>
                        <TbArrowBigLeftFilled onClick={closeDrawer} size={26} color='#FEBABA' />
                    </div>
                    <div className="flex-shrink-0 justify-center space-x-4 flex items-center ">
                        <div className='w-[75px] h-[75px]'>
                            <Link href="#" onClick={onProfileClick} to={RouteSegments.editProfileTab(1)}>
                                {profileData?.profile_imageurl ? 
                                    <img className={`max-w-full h-full rounded-lg ${isAvatar ? "bg-white" : ""}`} src={profilePic} alt="" />
                                    :
                                    <img className={`max-w-full h-full rounded-lg ${isAvatar ? "bg-white" : ""}`} src={ProfileImage} alt="" />
                                }
                            </Link>
                        </div>
                        <div>
                            <p className="text-white text-[18px] font-segeo font-bold flex ">Hi {profileData?.username},</p>
                            <p className="text-white text-[14px] font-segeo font-bold flex justify-start items-center">{profileData?.smProfileId}</p>
                            <p className="text-white text-[14px] font-segeo font-bold flex justify-start items-center">Welcome Back</p>
                        </div>

                        
                    </div>
                    <div className='flex justify-center items-center py-3 pb-[2rem]'>
              <p className='text-[#ffffff] text-[13px] font-seoge-semibold'>
                To Contact Matches:
              </p>
              <div onClick={handlePaid}>
              {profileData?.isPaid == "Y" ? 
                <button className='bg-[#FDC300] rounded-md text-[10px] px-3 py-1.5 text-[#ffffff] ml-3'>
                    My Plan
                </button>:
                <button className='bg-[#FDC300] rounded-md text-[10px] px-3 py-1.5 text-[#ffffff] ml-3'>
                     Buy a Pack
                </button>
                }
                </div>
            </div>
                </div>
                <div className='bg-white rounded-tl-[30px] pt-4 flex-grow'>
                    {
                        menuOptions?.filter(item => item.title === "Add Parent's Phone Number" ? !parentsNumberAvailable : true)
                            .map(drawerItem => (<DrawerAccordian key={drawerItem.title} onItemSelect={onItemSelect} item={drawerItem} />))
                    }
                    <Disclosure>
                        {({ open }) => (
                            <>
                                {/* <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2">
                                    <div className='flex items-center'>
                                        <img alt='matches' className='w-8 h-8' src="/Assets/Images/HelpIcon.svg" />
                                        <span className='pl-4 pr-2 text-[14px] font-medium text-[#D10A11]'>Help & FAQ</span>
                                    </div>
                                    <GoChevronUp
                                        size={20}
                                        color='#D10A11'
                                        className={`${open ? 'rotate-180 transform' : ''}`}
                                    />
                                </Disclosure.Button> */}
                                <Disclosure.Panel className="px-4">
                                    <div className='ml-[48px]'>
                                        <div className='text-[12px] text-[#575556]'>To Contact the Partner</div>
                                        <button onClick={onPayNowClick} className='text-white text-[12px] font-semibold bg-[#D10A11] px-4 py-0.5 mt-1 rounded'>{profileData?.isPaid === "Y" ? "My Plan" : "Pay Now"}</button>

                                        <div className='flex items-center mt-4'>
                                            <div><BsHeadset color='#FEBABA' size={18} /></div>
                                            <div className='text-[12px] text-[#575556] pl-2'>For Help & Support</div>
                                        </div>
                                        <div className='text-[12px] text-[#D10A11] font-bold'>
                                            <a href={`tel:+${Constants.helpLinePhNo}`}>{Constants.helpLinePhNo}</a>
                                            </div>

                                        <div className='flex items-center mt-4'>
                                            <div><MdEmail color='#FEBABA' size={18} /></div>
                                            <div className='text-[12px] text-[#575556] pl-2'>Mail ID</div>
                                        </div>
                                        <div className='text-[12px] text-[#D10A11] font-bold'>
                                            {/* {Constants.helpLineMailId} */}
                                            <a href={`mailto:${Constants.helpLineMailId}`}>{Constants.helpLineMailId}</a>
                                            </div>
                                    </div>
                                </Disclosure.Panel>

                            </>
                        )}
                    </Disclosure>
                    <div onClick={onStoreClick} className="flex items-center justify-between w-full px-4 py-2 cursor-pointer">
                        <div className='flex items-center'>
                            <img alt='matches' className='w-8 h-8' src={kalyanJewellery} />
                            <span className='pl-4 pr-2 text-[14px] font-medium text-[#D10A11]'>Store</span>
                        </div>
                    </div>
                    <div onClick={onLogoutClick} className="flex items-center justify-between w-full px-4 py-2 cursor-pointer">
                        <div className='flex items-center'>
                            <img alt='matches' className='w-8 h-8' src="/Assets/Images/LogoutIcon.svg" />
                            <span className='pl-4 pr-2 text-[14px] font-medium text-[#575556]'>Logout</span>
                        </div>
                    </div>

                    {/* <Disclosure>
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2">
                                    <div className='flex items-center'>
                                        <img alt='matches' className='w-8 h-8' src="/Assets/Images/LogoutIcon.svg" />
                                        <span className='pl-4 pr-2 text-[14px] font-medium text-[#D10A11]'>Logout</span>
                                    </div>
                                    <GoChevronUp
                                        size={20}
                                        color='#D10A11'
                                        className={`${open ? 'rotate-180 transform' : ''}`}
                                    />
                                </Disclosure.Button>
                            </>
                        )}
                    </Disclosure> */}
                    {/* <div className="mt-5 mb-4 px-3">
                        <p className=" text-center items-center  justify-center text-[16px] md:text-[11px] lg:text-[14px]  flex  text-[#575556] font-semibold">Download our App</p>
                        <div className="flex space-x-2 md:space-x-3 md:mt-[0.3rem] lg:mt-0">
                            <a href={RouteSegments.staticLinkForAppDownload.android} target="_blank">
                                
                            <img className='w-[8rem] lg:w-[8.5rem] xl:w-[8.5rem]' src={GPlayIcon} alt="" />
                            </a>
                            <a href={RouteSegments.staticLinkForAppDownload.ios} target="_blank">
                                
                            <img className='w-[8rem] lg:w-[8.5rem] xl:w-[8.5rem]' src={AppStoreIcon} alt="" />
                            </a>
                        </div>
                                                    
                        </div>


 */}

                </div>

            </div>
        </Drawer>
    )
}

export default SidebarLite