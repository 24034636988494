import React, { useCallback, useEffect, useState, useRef } from "react";
import "./MultiRangeSlider.css";
import { Slider } from 'antd';
import Alert from "../../Components/Kalyanlite/assets/Images/danger.svg";



const MultiRangeSliderAge = ({ min, max, onChange, value, data }) => {
  const [val, setVal] = useState([min,max]);

  useEffect(() => {
    setVal([parseInt(data?.minage),parseInt(data?.maxage)])
  },[data])


  return (
    <>
    <div className="mt-[2rem]" >
                            <p className="text-[#9F9F9F] text-[13px] font-bold  ">
                                {value}
                            </p>
                        </div>
                        <div   className="flex justify-between ">
                            <p className="text-[#575556] flex text-[13px] font-bold ">
                                Min {Math.floor(val[0])} yrs
                            </p>
                            <p className="text-[#575556] flex text-[13px] font-bold ">
                               Max {Math.floor(val[1])} yrs
                            </p>
                        </div>
    {data?.minage &&
    <Slider
    range={{
      draggableTrack: true,
    }}
    min={min}
    max={max}
    step={1}
    defaultValue={[parseInt(data?.minage),parseInt(data?.maxage)]}
    value={[parseInt(data?.minage),parseInt(data?.maxage)]}
    onChange={(event) => {
      onChange(event[0],event[1])
      setVal(event)
    }}
  />
}
{Math.abs(data?.maxage - data?.minage)  < 3 && (
        <div className='mt-4 flex items-center'>
          <img src={Alert} alt="alert" className='w-4 h-4 mr-2' />
          <p className='text-[#D10A11] text-[11px] lg:text-[15px]'>
            Broaden this Parameter a little more to get better results
          </p>
        </div>
      )}
  </>
  );
};


export default MultiRangeSliderAge;
