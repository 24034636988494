import React, { useState, useEffect, useContext } from "react";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { GET, POST } from "../../Services/api_services";
import Constants, {
  loginFrom,
  EventName,
  EventKeys,
} from "../../Constants/constants";
import axios from "axios";
import { LoginContext } from "../../Contexts/LoginContext";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  BasicDatePicker,
  BasicEditTextView,
  HoroscopeEditTextView,
  HoroscopeSearchView,
  HoroscopeSelectView,
} from "../../Components/Edit Profile/EditProfileUtills";
import { useSearchParams } from "react-router-dom";
import { validateImage } from "../../Utils";
import { NotificationPopup } from "../CommonPopup";
import config from "../../config";
import Loader from "../Loader";
import UploadingImage from "../../Images/Group 964.svg";
import HoroscopeGenarateImage from "../../Images/Group 963@2x.png";
import { useAnalytics } from "../../Hooks/usePageChange";
import DatePicker from "react-datepicker";
import "../../Components/ReasonforDeletion/datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
// import 'antd/dist/antd.css'; // Import Ant Design styles
import moment from "moment"; // Import moment library for date formatting
import { NavBarContext } from "../../Contexts/NavBarContext";
import ImageHoroscopePopup from "../Edit Profile/ImageHoroscopePopup";
import { getDeviceInfo, getGroupAuthId } from "../Kalyanlite/utils";
import { useSelector } from "react-redux";

const HoroComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const hoursArray = [...new Array(12)];
  const minutesSecondsArray = [...new Array(59)];
  const [placeDataList, setplaceDataList] = useState([]);
  const [isUploading, setIsUploading] = useState(false)
  // const [isGenerating, setIsGenerating] = useState(false)
  const [notification, setNotification] = useState({show:false,title:"",message:""})
  const [packdata, setPackData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams()
  const isV3User = localStorage.getItem("isV3User")
  const { onClickTrack } = useAnalytics()
  const[disableSave,setDisableSave] = useState(true)
  const [commonError, setCommonError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const notificationId = sessionStorage.getItem(
    Constants.loginLocalStorageKeys.notificationId
  )
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );
   const [packStatusData,setPackStatusData] = useState(null)
  const {isGenerating, setIsGenerating, completeNow,setCompleteNow ,showHoroPopup,setShowHoroPopup ,clickAddHoro, setClickAddHoro} = useContext(NavBarContext);
  useEffect(() => {
    //console.log("horoscope", props.data);
    //console.log(
    //   "chartstyle",
    //   props?.data?.chartStyle.filter((e) => e.selected === "Y").name
    // );
  }, [props.data]);
  const formik = useFormik({
    initialValues: {
      dob: props?.data?.dob ? props?.data?.dob : "",
      hours: props?.data?.hours
        ? `${(props?.data?.hours).toString().padStart(2, "0")}`
        : "",
      minutes: props?.data?.minutes
        ? `${(props?.data?.minutes).toString().padStart(2, "0")}`
        : "",
      seconds: props?.data?.seconds
        ? `${(props?.data?.seconds).toString().padStart(2, "0")}`
        : "",
      ampm: props?.data?.ampm ? props?.data?.ampm : "AM",
      placeOfBirth: props?.data?.regionSelected
        ? props?.data?.regionSelected
        : "",
      placeOfBirthId: props?.data?.cityId ? `${props?.data?.cityId}` : "",
      chartStyle: props?.data?.chartStyle
        ? props?.data?.chartStyle?.filter((e) => e.selected === "Y")[0]?.id
        : "",
      language: props?.data?.language
        ? props?.data?.language.filter((e) => e.selected === "Y")[0]?.id
        : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      dob: Yup.string()
      ,
      hours: Yup.string()
      .test('is-number', 'Please Select Time of Birth', value => /^\d+$/.test(value)),
      minutes: Yup.string()
      .test('is-number', 'Please Select Time of Birth', value => !isNaN(value)),
      seconds: Yup.string() 
      .test('is-number', 'Please Select Time of Birth', value => /^\d+$/.test(value)),
      ampm: Yup.string(),
      placeOfBirth: Yup.string().required("Please Select place of birth"),
      chartStyle: Yup.string().required("Please Select chart style"),
      language: Yup.string().required("Please Select language"),
    }),

    onSubmit: (values) => {
      setSubmitted(true)
      apiCallGenerateHoroscope(values);
      if(props?.isFrom == "clcRedirection"){
        props?.callNotificationStatus(true,"Generate Horoscope")
      }
    },
  });
  // const SubmitCall = (value) => {
  //   //console.log(value);
  // };

  const [generatedUrl, setgeneratedurl] = useState();
  useEffect(() => {}, [generatedUrl, placeDataList]);

  const navigateToProfile = ()=>{
    searchParams.set('tab',"1");
    setSearchParams(searchParams)
  }

  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description })
  }

  const onHoroScopeFileChange = (file,type)=>{
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({ title: "Invalid image", description: "Height and Width must be more than 100px" })
      },
      onExtensionError: (fileType) => {
        showNotification({ title: "Invalid image", description: "Invalid extension!" })
      },
      onFileSizeError:(img)=>{
        showNotification({ title: "Invalid image", description: "Image size should not be greater than 10mb" })
      },
      onSuccess: (img, readerStr) => {
        handleUpload(file,type)
      }
    })
  }

  const handleUpload = async (file, type) => {
    setIsUploading(true)
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("trustDocumentUploadFrom", loginFrom);
      fileData.append("documents", file);
      fileData.append("type", type);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("requestfulfilledvia",  props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("recieverprofileid", getGroupAuthId());
      fileData.append("horoscoperequesttypeid", 86);


      // let config = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };

      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.trustDocumentUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      //console.log("callBackPopup",data);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          // alert("Image upload succesfully");
          // props.callBackReload();
          props.callBackPopup(data?.data?.data?.fileUploadpath,true);
          // navigateToProfile()
        }
      }
    } else {
      alert("Please upload file less than 10 mb");
    }

    setIsUploading(false)
  };

  const callBackPinApi = async (e) => {
    //console.log("callBackPinApi", e);

    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getPlaceUrl(e)}`
    );
    //console.log("pindata", data);
    if (statusCode === 200) {
      setplaceDataList(data.region);
    } else if (statusCode === 401) {
      logout();
    }
  };

 
  useEffect(() => {
    const hasErrors = Object.values(formik.errors).length > 0;
    const hasEmptyFields = Object.values(formik.values).some(
      (value) => value === ""
    );
  
    setDisableSave(hasErrors || hasEmptyFields);
    
    if (submitted) {
      if (hasErrors || hasEmptyFields) {
        setCommonError("All fields are mandatory");
      } else {
        setCommonError("");
      }
    }
  }, [formik.errors, formik.values, submitted]);
const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId
  const apiCallGenerateHoroscope = async (value) => {
    setIsGenerating(true)
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      hrs: value.hours,
      mins: value.minutes,
      ampm: value.ampm,
      chartStyle: value.chartStyle,
      cityId: `${value.placeOfBirthId}`,
      horoscopeUploadFrom: loginFrom,
      language: value.language,
      secs: value.seconds,
      ...(props?.isFrom == "clcRedirection" && {notificationId: notificationId}),
      requestfulfilledfrom:getDeviceInfo() ,
      requestfulfilledvia : props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile",
      hororequesttypeid : 86,
      recieverprofileid: getGroupAuthId(),
      // notificationId: notificationId,
      ...(props.isFrom === 'TrailPack' && {notificationId:  NotificationId }),
      ...(props.isFrom === 'TrailPack' && { editviatrialpack: props.editviatrialpack }),
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.generateHoroscopeUrl()}`,
      request
    );
   
    if (statusCode === 200) {
      //console.log("data.data.", data.data);
      if (data.data.status == "SUCCESS") {
        setgeneratedurl(data.data.url);
        props.callBackPopup(data.data.url,false);
        setIsGenerating(false)
        if(props?.isFrom == "clcRedirection" || props?.isFrom == "chat" || props?.isFromSms == "yes"){
          onClickTrack(EventName.CLC_Generate_Button_V3_Site_Generate_Upload_Horoscope_Popup , {[EventKeys.generateHoroscopeDetails]:value})
        }
        else{
          onClickTrack(EventName.profileGenerateHoroscope , {[EventKeys.profileGenerateHoroscope]:value})
        setTimeout(() => {
          // setCompleteNow(true);
          // setClickAddHoro(false);
          props?.postData()
        }, 5000);
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
      };
  const apiCallHorscopeRedirection = async (url) => {
    let { statusCode, data } = await POST(
      url
      // `${config.api.API_URL}${EndPoints.generateHoroscopeUrl()}`,
      // request
    );
    //console.log("data.", data);
    if (statusCode === 200) {
      if (data.data.status == "SUCCESS") {
        // window.open(data.data.url, "_blank");
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const closeNotification = ()=>{
    setNotification({show:false,message:"",title:""})
  }

  return (
    <div x-show="tab === 5">
      {
      notification.show
        ? <NotificationPopup close={closeNotification} title={notification.title} content={notification.message} />
        : null
      }
      {isUploading? <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" /> :""}
      <form onSubmit={formik.handleSubmit}>
        <div className="md:block">
         <div className={`${props?.isFrom == "clcRedirection" ? "flex justify-center mb-4 md:mb-6" : "flex justify-between md:pl-[1rem]"}`}>
            <p className="text-[#000000] items-center font-semibold text-[18px] pt-2 justify-center  text-center">
            {props?.isFrom == "clcRedirection" ? "Generate / Upload Horoscope" : "Generate Horoscope"}
              </p>
          </div>

          <div className={`mt-[1rem]  text-[13px]   lg:p-0 bg-[#ffffff] ${props?.isFrom == "clcRedirection" ? "md:py-[1rem] md:my-[1rem] lg:my-[0.5rem] xl:my-[1rem]" : "md:p-[1rem] m-[1rem]"} md:m-0" } rounded-[14px]`}>
            {localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite)
              ?
               <div className={`h-[12rem] flex flex-col items-center justify-center`}>
                <label className=" flex justify-end  ">
                  <div
                    className="flex items-center space-x-2 justify-end  font-bold text-white bg-[#D10A11] px-[1rem] rounded-[4px] py-2 md:text-[11px] lg:text-[13px]  "
                  >
                    <img
                      alt=""
                      className=" md:w-[1rem] lg:w-[1.3rem]"
                      src={UploadingImage}
                    />
                    <p onClick={() => { onClickTrack(EventName.profileUploadHoroscope) }}> {isUploading ? "Uploading..." : "Upload Horoscope"} </p>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      disabled={isUploading}
                      onChange={(event) => {
                        if (event.target.files[0]) {
                          onHoroScopeFileChange(event.target.files[0], "horoscope");
                        }

                      }}
                      accept="image/jpeg, image/png"
                    />
                  </div>
                </label>
                <p className="text-[#575556] py-[0.5rem] md:py-0 md:pt-[0.5rem] lg:pt-[1rem] md:text-[10px] lg:text-[12px] font-bold ">
                  PNG JPG GIF (Less Than 10MB)
                </p>
              </div>
              : 
              <div >
              <div className="grid md:grid-cols-2 gap-5"> 
              <HoroscopeEditTextView
                  title={"Date of Birth"}
                  inputType={"text"}
                  inputID={"dob"}
                  inputName={"dob"}
                  value={formik.values.dob}
                  // onChange={formik.handleChange}
                  error={formik.errors.dob}
                  isPaid={props?.dashboard1Data?.isPaid==='Y'}
                  menu="horoscope"
                />
                           <div >
                        <div className="md:col-span-3 flex items-center pl-[1rem]">
                          <p className="text-[13px] flex text-[#000000] font-bold">
                            Time of Birth<span className="text-[#D10A11]">*</span>{" "}
                          </p>
                        </div>
                        <div className="pl-[1rem] md:col-span-6 md:pl-[1rem] flex justify-between space-x-1 ">
                          <div className="horoscope select font-bold text-[#575556]">
                            <select
                              name="hours"
                              id="hours"
                              value={formik.values.hours}
                              onChange={(e)=>{formik.handleChange(e)
                                setDisableSave(false)
                              }}
                              className="w-full md:p-[0.4rem] lg:p-2.5 border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[13px] md:text-[12px] lg:text-[12px] bg-white rounded-[8px]"
                            >
                              <option value="hh">-hh-</option>
                              {hoursArray.map((e, i) => (
                                <option
                                  key={i}
                                  value={(i + 1).toString().padStart(2, "0")}
                                >
                                  {(i + 1).toString().padStart(2, "0")}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="horoscope select font-bold text-[#575556]">
                            <select
                              name="minutes"
                              id="minutes"
                              value={formik.values.minutes}
                              onChange={(e)=>{formik.handleChange(e)
                                setDisableSave(false)
                              }}
                              className="w-full md:p-[0.4rem] lg:p-2.5 border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[13px] md:text-[12px] lg:text-[12px] bg-white rounded-[8px]"
                            >
                              <option value="mm">-mm-</option>
                              {minutesSecondsArray.map((e, i) => (
                                <option
                                  key={i}
                                  value={i.toString().padStart(2, "0")}
                                >
                                  {i.toString().padStart(2, "0")}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="horoscope select font-bold text-[#575556]">
                            <select
                              name="seconds"
                              id="seconds"
                              value={formik.values.seconds}
                              onChange={(e)=>{formik.handleChange(e)
                                setDisableSave(false)
                              }}
                              className="w-full md:p-[0.4rem] lg:p-2.5 border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[13px] md:text-[12px] lg:text-[12px] bg-white rounded-[8px]"
                            >
                              <option value="ss">-ss-</option>
                              {minutesSecondsArray.map((e, i) => (
                                <option
                                  key={i}
                                  value={i.toString().padStart(2, "0")}
                                >
                                  {i.toString().padStart(2, "0")}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="horoscope select font-bold text-[#575556]">
                            <select
                              name="ampm"
                              id="ampm"
                              value={formik.values.ampm}
                              onChange={formik.handleChange}
                              className="w-full md:p-[0.4rem] lg:p-2.5 border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[13px] md:text-[12px] lg:text-[12px] bg-white rounded-[8px]"
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                              <option value="AM/PM" disabled>AM/PM</option>
                            </select>
                          </div>
                        </div>
                        <div className="pl-[1rem] md:col-span-6 flex space-x-1 pr-[1rem]">
                          {formik.errors.hours ? (
                            <p className="text-[red] text-[12px] whitespace-nowrap">
                              {formik.errors.hours}
                            </p>
                          ) : formik.errors.minutes ? (
                            <p className="text-[red] text-[12px] whitespace-nowrap">
                              {formik.errors.minutes}
                            </p>
                          ) : formik.errors.seconds ? (
                            <p className="text-[red] text-[12px] whitespace-nowrap">
                              {formik.errors.seconds}
                            </p>
                          ) : (
                            <p className="text-[red] text-[12px] whitespace-nowrap">
                              {formik.errors.hours}
                            </p>
                          )}
                        </div>
                      </div>

                <HoroscopeSearchView
                  title={"Place of Birth"}
                  inputID={"placeOfBirth"}
                  inputName={"placeOfBirth"}
                  value={formik.values.placeOfBirth}
                  onChange={(e) => {
                    formik.setFieldValue("placeOfBirthId", e);
                    const selected = placeDataList?.filter(place=>place.cityId === e)?.at(0)
                    formik.setFieldValue("placeOfBirth",`${selected?.placeName}-${selected?.regionName}-${selected?.countryName}`)
                    setDisableSave(false)
                  }}
                  error={formik.errors.placeOfBirth}
                  callBackApi={callBackPinApi}
                  options={placeDataList}
                  placeholder="Enter first three letters of your city/town"
                   menu="addhoroscope"
                   
                />
                <HoroscopeSelectView
                  title={"Chart Style"}
                  inputID={"chartStyle"}
                  inputName={"chartStyle"}
                  value={formik.values.chartStyle}
                  onChange={(e)=>{formik.handleChange(e)
                    setDisableSave(false)
                  }}
                  error={formik.errors.chartStyle}
                  options={props?.data?.chartStyle}
                   menu="addhoroscope"
                />
                <HoroscopeSelectView
                title={"Language"}
                inputID={"language"}
                inputName={"language"}
                value={formik.values.language}
                onChange={(e)=>{formik.handleChange(e)
                  setDisableSave(false)
                }}
                error={formik.errors.language}
                options={props?.data?.language}
                 menu="addhoroscope"
              />
              </div> 
              {commonError && (
        <div className="mt-2 text-center text-red-500">{commonError}</div>
      )}   
              <div className={`${props?.isFrom == "clcRedirection" ? "" : "mr-[1rem]"} flex justify-center mt-[2rem]`}>
                {props?.isFrom == "clcRedirection" ?
                  <button
                      disabled={isGenerating}
                      className="
                      flex items-center space-x-2 justify-end  font-bold text-white bg-[#D10A11] px-[2.5rem] rounded-[4px] py-2 md:text-[11px] lg:text-[11px]">
                        <img
                          alt=""
                          className=" w-[1.3rem] md:w-[1rem] lg:w-[1rem]"
                          src={HoroscopeGenarateImage}
                        />
                        <p>
                          {" "}
                          
                          Generate Horoscope
                        </p>
                  </button>
                  :
                  <button
                    type="submit"
                    disabled={isGenerating}
                    // onClick={() => {
                    //   alert(`${props.from}`)
                    //   props.from == 'trailpack' && onClickTrack(EventName.Trial_Pack_popup_Add_Horoscope_Generate_Button)
                    // }}
                    className={`
                      text-[13px] py-1.5 px-[3rem] ${disableSave?"bg-[#E78387] cursor-not-allowed ":"bg-[#D10A11] cursor-pointer"} text-[#FFFFFF]  rounded-[6px] font-bold bg-[#D10A11]   
                      `}
                  >
                    
                    <p> {isGenerating ? "Saving...":"Save"} </p>
                  </button>
                }
                </div>  
                              </div>}
          </div>
        </div>

        </form>
       
    </div>
  );
};

export default HoroComponent;
