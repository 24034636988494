import React, { useEffect, useState } from "react";
import Constants, { EventName } from "../../Constants/constants";
import { useNavigate } from "react-router";
import RouteSegments from "../../Routes/RouteSegment";
import CloseIcon from "../../Images/Group 761@2x.png"
import lockicon from '../../Images/lockicon.svg'
import OnlineImage from '../../Images/Group 619.svg'
import { CommonCheckButton } from "./DashboardUtills";
import { useFormik } from "formik";
import { useAnalytics } from "../../Hooks/usePageChange";
const PhonePopup = (props) => {
  console.log("PhonePopup",props);
  const navigate = useNavigate()
  const { onClickTrack } = useAnalytics()
  useEffect(() => {
    //console.log("phoneNumData", props.phoneNumData);
  }, [props.phoneNumData]);

  const showParentsNumber = ((props.profileData?.profileparentsnumber == null || props.profileData?.profileparentsnumber == "") && props?.profileData?.parentnumberrequestcheck === false)
  const  showRequestPhoto = props?.profileData?.isAvatarPic === true && props?.profileData?.profilerequestcheck === false

  const formik = useFormik({
    initialValues: {
      requestParentsNumber: showParentsNumber,
      requestPhoto: showRequestPhoto,
      sendSms:"Y"
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      console.log("message", values);
      values = {
        ...values,
        typeList: [
          ...values?.requestParentsNumber ? ["Parents Number"] : [],
          ...values?.requestPhoto ? ["Photo"] : []
        ]
      }
      props.onSubmit(props?.profileData?.profileid, values);
      props.close();
    }
  });

  

  return (
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[90%] md:w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-10px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-7 w-7 " src={CloseIcon} alt=""/>
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className="relative p-6 flex-auto">
            <div className="flex ">
              <div className="flex relative w-max">
                <img
                  src=
                  {props?.phoneNumData?.phoneNumberVieweeData[0]?.profilepic} 
                  // "/Assets/Images/profile1.png"
                  alt=""
                  className={`${props?.phoneNumData?.phoneNumberVieweeData[0]?.blurPhoto === "Y" ? "blur-[2px]" : ""
                    } w-16 h-16 md:w-20 md:h-20 rounded-[50%]`} 
                /> 
                 {props?.phoneNumData?.phoneNumberVieweeData[0]?.fromPasswordProtected === "Y" && (
                  <div
                  className="absolute top-[-1.4rem] right-[0rem] flex justify-center items-center h-[100%] w-[100%]  cursor-pointer"
                  >
                <img
                  className="h-[2rem] w-[2rem] "
                  src={lockicon}
                  alt="Person"
                />
                  </div>
                 )}
              </div>
              <div className="flex flex-col ml-2">
                <div className="border-b flex flex-col item-start justify-start">
                  {props?.phoneNumData?.phoneNumberVieweeData[0].lastOnline ===
                    "Online Now" ? (
                    <div className="flex  justify-start item-center">
                      <img
                        className=" w-4 h-4 md:w-2 md:h-2 lg:w-3 lg:h-3"
                        src={OnlineImage}
                        alt=""
                      />
                      <span className=" px-2 md:px-1 lg:px-2 text-[9px] md:text-[8px] lg:text-[10px] text-[#575556]">
                        Online Chat Now
                      </span>
                    </div>
                  ) : (
                    <span className="md:flex justify-start text-[#039511] font-segeo font-semibold text-[10px] px-1">
                      Last Online:{" "}
                      {props?.phoneNumData?.phoneNumberVieweeData[0].lastOnline}
                    </span>
                  )}

                  <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[10pt]  font-bold my-1">
                    {props?.phoneNumData?.phoneNumberVieweeData[0]?.name} |{" "}
                    <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[10pt] font-normal">
                      {
                        props?.phoneNumData?.phoneNumberVieweeData[0]
                          ?.smProfileId
                      }
                    </span>
                  </span>
                  <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[10pt] font-semibold my-1">
                    {props?.phoneNumData?.phoneNumberVieweeData[0]?.age} |{" "}
                    {props?.phoneNumData?.phoneNumberVieweeData[0]?.height} |{" "}
                    {props?.phoneNumData?.phoneNumberVieweeData[0]?.religion} |{" "}
                    {props?.phoneNumData?.phoneNumberVieweeData[0]?.country}
                  </span>
                </div>
                <div className="flex lg:w-[35vw] md:w-auto w-auto relative md:relative lg:static">
                  {props?.phoneNumData?.status === "SUCCESS" ? (
                    (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                    "Your profile is hidden"
                    :
                    (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "pending"?
                        props?.phoneNumData?.statusmessage
                    :
                    props?.phoneNumData?.phoneNumberVieweeData[0]?.profileStatus == "open" || props?.phoneNumData?.phoneNumberVieweeData[0]?.profileStatus == "pending"?
                    <div className="whitespace-nowrap	">
                      <div className="block gap-3 md:grid lg:flex justify-between space-x-3 mt-3 md:grid-cols-2">
                        <span className="text-[#575556] pt-[0.1rem]  md:text-[12px] lg:text-[14px] text-[8pt] font-normal whitespace-nowrap">
                          Verified Profile Phone number
                        </span>
                        <span className="text-[#575556]  md:text-[13px] lg:text-[16px] text-[10pt] font-semibold">
                          {/* +91 - 3242234433 */}
                          {props?.phoneNumData?.MobileNumber}
                        </span>
                      </div>
                      {props?.phoneNumData?.phoneNumberVieweeData[0]?.parentsnumber ?
                        <div className="block gap-3 md:grid lg:flex justify-between space-x-3 mt-2 md:grid-cols-2">
                        <span className="text-[#575556] pt-[0.1rem] md:text-[12px] lg:text-[14px] text-[8pt] font-normal">
                          Parent's Phone number
                        </span>
                        <span className="text-[#575556]  md:text-[13px] lg:text-[16px] text-[10pt] font-semibold">
                          {/* +91 - 3242234433 */}
                          {/* {//console.log(props.phoneNumData,"phone")} */}
                          {props?.phoneNumData?.phoneNumberVieweeData[0]?.parentsisdcode
                            + "-"+ props?.phoneNumData?.phoneNumberVieweeData[0]?.parentsnumber}
                        </span>
                      </div>
                      :""

                      }

                            {showRequestPhoto || showParentsNumber
                              ? <form onSubmit={formik.handleSubmit}>
                                <div className="flex items-center justify-around py-4">
                                  {showRequestPhoto
                                    ? <CommonCheckButton
                                      id={"requestPhoto"}
                                      name={"requestPhoto"}
                                      selected={formik.values.requestPhoto}
                                      checktext={"Request Photo"}
                                      value={formik.values.requestPhoto}
                                      onChange={(item) => {
                                        //console.log("check", item.target.value);
                                        formik.setFieldValue(
                                          "requestPhoto",
                                          !formik.values.requestPhoto
                                        );
                                      }}
                                    /> : null}
                                  {showParentsNumber
                                    ? <CommonCheckButton
                                      id={"requestParentsNumber"}
                                      name={"requestParentsNumber"}
                                      selected={formik.values.requestParentsNumber}
                                      checktext={"Request Parent's number"}
                                      value={formik.values.requestParentsNumber}
                                      onChange={(item) => {
                                        //console.log("check", item.target.value);
                                        formik.setFieldValue(
                                          "requestParentsNumber",
                                          !formik.values.requestParentsNumber
                                        );
                                      }}
                                    />
                                    : null
                                  }
                                </div>
                                <div className="flex justify-center mt-2">
                                  <button
                                    type="submit"
                                    className=" flex items-center justify-center lg:w-[2.5rem] lg:h-[1rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                                  >
                                    Send Request
                                  </button>
                                </div>
                              </form>
                              : null
                            }
                    </div>
                    :
                    <div className="text-center">
                      {props?.phoneNumData?.phoneNumberVieweeData[0]?.profileStatus== "hidden"?
                      "profile has been hidden"
                      :props?.phoneNumData?.phoneNumberVieweeData[0]?.profileStatus== "inactive"?
                      "profile has been inactive"
                      :props?.phoneNumData?.phoneNumberVieweeData[0]?.profileStatus== "delete"?
                      "profile has been deleted"
                      :props?.phoneNumData?.phoneNumberVieweeData[0]?.profileStatus== "suspension"?
                      "profile has been suspended"
                      :""}
                    </div>
                  ) : props?.phoneNumData?.status === "Upgrade" ? (
                    <div>
                      {(localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                        "Your profile is hidden"
                        :
                        <>
                      <span className="text-[#575556]  md:text-[12px] lg:text-[14px] text-[8px]  font-bold">
                        {props?.phoneNumData?.statusmessage}
                      </span>
                      <div className="flex justify-center mt-2">
                        <button
                          onClick={() => {  
                            onClickTrack(EventName.View_Profile_All_Unpaid_Popup_Pay_Now_Button)                          
                                  navigate(RouteSegments.MEMBERSHIP_REGISTER());
                            props.close();
                          }}
                          className=" flex items-center justify-center lg:w-[2.5rem] lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                        >
                          Buy Nowss
                        </button>
                      </div>
                      </>}
                    </div>
                  ) : props?.phoneNumData?.status === "Validate" ? (
                    <div className="mt-4">
                      <span className="text-[#575556]  md:text-[12px] lg:text-[14px] text-[8px]  font-bold">
                        {(localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                        "Your profile is hidden"
                        :
                        props?.phoneNumData?.statusmessage}
                      </span>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <span className="text-[#575556]  md:text-[12px] lg:text-[14px] text-[8px]  font-bold">
                      {(localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                        "Your profile is hidden"
                        :
                        props?.phoneNumData?.statusmessage}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhonePopup;
