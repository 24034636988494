import React from "react";

export default function FairPolicyDeatil() {
  return (
    <div className="text-left text-[#676767]">
      <p className=" ">We wish you happy search and quick marriage.</p>
      <br />
      <p className=" ">
        The below restrictions in the usage of the unlimited packs is to avoid
        misuse of packages by scammers, spammers and fraudsters. Do reach out to
        our helpline anytime to reinstate the product for genuine usage.
      </p>
      <p className=" underline pt-[1rem] ">Unlimited Validity Packages:</p>
      <p className="pl-[1rem] flex pt-[1rem] ">
       <p> 1. </p>
       <p> You will be unable to use the package after any of the following
        dates: </p>
      </p>
      <div className="pl-[2rem]">
        <p>1.Date of Marriage</p>
        <p>2.Two years from purchase date</p>
        <p>3.90 days after you have stopped viewing phone numbers.</p>
        <p>4.Date of profile deletion or suspension</p>
      </div>
      <p className="pl-[1rem] flex ">
      <p>  2. </p>
       <p> You can send a Maximum of 100 messages per day in Unlimited Send
        Messages
        </p>
      </p>
      <p className="pl-[1rem]">3.Case for extension of validity days:</p>
      <div className="pl-[2rem]">
        <p>
          1.You have to buy a new package once the Phone count is exhausted.
        </p>
        <p>
          2.If the phone count is not exhausted, the member is still unmarried
          after two years, and initially-set two years are over, please write to
          help@kalyanmatrimony.com, requesting for another two years of
          extensions of validity. You may need to submit an undertaking that you
          are still unmarried and want to use the services. Also, please attach
          an identity proof such as Aadhar. A new or similar pack will be
          renewed at zero cost to you.
        </p>
      </div>
      {/* ///ulim */}
      <p className=" underline pt-[1rem] ">
        Unlimited Phone Numbers Packages:
      </p>
      <p className="pl-[1rem] flex pt-[1rem] ">
       <p> 1. </p>
        <p> You will be unable to use the package after any one of the conditions is met:
      </p>
      </p>
      <div className="pl-[2rem]">
        <p>1.If you use more than 10 phone numbers in a day, or</p>
        <p>2.If you use more than 1000 phone numbers</p>
         
      </div>
      <p className="pl-[1rem] flex ">
        <p> 2. </p>
        <p>You can send a Maximum of 100 messages per day in Unlimited Send Messages </p>
        
      </p>
      
      <p className="pl-[1rem]">3.Case for Extension:</p>
      <div className="pl-[2rem]">
        <p>
          1.You have to buy a new package once the validity dates are exhausted.
        </p>
        <p>
          2. If the validity is not over, the member is still unmarried, and allotted phone counts are exhausted, please write to help@kalyanmatrimony.com, requesting for addition of 1000 phone numbers. You may need to submit an undertaking that you are still unmarried and want to use the services. Also, please attach an identity proof such as Aadhar. A new or similar pack will be renewed at zero cost to you.
        </p>
      </div>
      {/* ///ulimited*/}
      <p className=" underline pt-[1rem] ">
         Truly Unlimited Package:
      </p>
      <p className="pl-[1rem] flex pt-[1rem] ">
       <p> 1. </p>
        <p> You will be unable to use the package after any of the following dates: </p>    
      </p>
      <div className="pl-[2rem]">
        <p>1.Date of Marriage</p>
        <p>2.Two years from purchase date</p>
         <p>3.After 90 days after you have stopped viewing of phone numbers</p>
         <p>4.Date of profile deletion or suspension</p>
      </div>
      <p className="pl-[1rem] flex ">
        <p>2.</p>
       <p> You will be unable to use the package after any one of the conditions is met:  
        </p>      
      </p>
      <div className="pl-[2rem]">
        <p>1.If you use more than 10 phone numbers in a day, or</p>
        <p>2.If you use more than 1000 phone number</p>
          
      </div>
      <p className="pl-[1rem] flex ">
       <p>3.</p>
       <p>You can send a Maximum of 100 messages per day in Unlimited Send Message</p>
       </p>
      <p className="pl-[1rem]">4.Case for Extension:</p>
      <div className="pl-[2rem]">
        <p>
          1.In the event, the member is unmarried even after exhaustion of initially allotted 1000 phone counts or expiry of two years from the date of purchase of the package, please write to help@kalyanmatrimony.com, requesting for another two years of extensions of validity or addition of 1000 phone counts. You may need to submit an undertaking that you are still unmarried and want to use the services. Also, please attach an identity proof such as Aadhar. A new or similar pack will be renewed at zero cost to you.
        </p>
         
      </div>
      <p className=" underline pt-[1rem] ">
      Matches via WhatsApp:
      </p>
      <p className="pl-[1rem] flex pt-[1rem] ">
       <p> 1. </p>
        <p> Matches via WhatsApp is an Add-on Pack and it cannot be purchased separately by free users.
        </p>
      </p>
      <p className="pl-[1rem] flex">
       <p> 2. </p>
        <p> The validity of Matches via WhatsApp will be the same as the pack’s validity.
        </p>
      </p>
      <p className="pl-[1rem] flex">
       <p> 3. </p>
        <p> All fair usage restrictions of the purchased main pack also applies to the Matches via WhatsApp. 
        </p>
      </p>
      <p className="pl-[1rem] flex">
       <p> 4. </p>
        <p> If the main pack is suspended due to any reason the Matches via WhatsApp is also suspended.
        </p>
      </p>
    </div>
  );
}
