import React, { useContext, useEffect, useState } from "react";
import RouteSegments, { RouteHelper, RoutePaths } from "../../Routes/RouteSegment";
import { useLocation, useNavigate } from "react-router-dom";
import Constants, { EditProfileMenu } from "../../Constants/constants";
import ParentsPhonePopup from "../DashboardComponents/ParentsPhonePopup";
import { useDispatch, useSelector } from "react-redux";
import { contentCardOnclick } from "../../Store/ContentCard/Action";
import { UploadPhotoPopup, CommonSuccessPopup } from '../../Components/CommonPopup';
import { object } from "yup";
import EndPoints from "../../Constants/EndPoints";
import config from "../../config";
import { POST } from "../../Services/api_services";
import { LoginContext } from "../../Contexts/LoginContext";



const ContentCard = (props) => {
    const dispatch = useDispatch();
    const location = useLocation()
    const [showParentNumPopup, setShowParentNumPopup] = useState(false)
    const [cardName, setCardName] = useState("")
    
     const [showRequestPopup, setShowRequestpopup] = useState(false);
    const [cardData, setCardData] = useState(false)
     const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
    const [showSuccessPopup, setshowSuccessPopup] = useState(false);
    const [successMessage, setsuccessMessage] = useState("");
    const [contentResponseData, setContentResponseData] = useState("")
    const { logout } = useContext(LoginContext);

    const navigate = useNavigate();

 

    console.log(location.pathname.split("/")[2],"content card");

    const bannerOnclickRedirection = async (name) => {
      let request = {
        userId: localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
        loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
        page:location.pathname.split("/")[2] == RoutePaths?.WEEKLYVIEWALL?"Weekly Matches"
             :location.pathname.split("/")[2] == RoutePaths?.RECOMMENDED?"Recommend Matches"
             :location.pathname.split("/")[2] == RoutePaths?.ResponseReceived?"Response Received"
             :location.pathname.split("/")[2] == RoutePaths?.PROFILELOOKINGFOR?"Profiles I am looking for"
             :location.pathname.split("/")[2] == RoutePaths?.PROFILELOOKINGFORME?"Profiles looking for me"
             :location.pathname.split("/")[2] == RoutePaths?.PHOTOREQUESTRECEIVED?"Photo Requests Received"
             :location.pathname.split("/")[2] == RoutePaths?.MUTUALMATCHES?"Mutual Matches"
             :location.pathname.split("/")[2] == RoutePaths?.SELECTEDPROFILES?"Selected Profiles"
             :location.pathname.split("/")[2] == RoutePaths?.RECENTLYVIEW?"Recently Viewed"
             :location.pathname.split("/")[2] == RoutePaths?.REMOVE_PROFILE?"Reject Matches"
             :location.pathname.split("/")[2] == RoutePaths?.SIMILARMATCHES?"Similar Matches Profiles"
             :location.pathname.split("/")[2] == RoutePaths?.SEARCH?"Search Result"
             :location.pathname.split("/")[2] == RoutePaths?.EXPLOREMATCHES && props?.searchBy == "city" ?"Search By City"
             :location.pathname.split("/")[2] == RoutePaths?.EXPLOREMATCHES && props?.searchBy == "education" ?"Search By Educaion"
             :location.pathname.split("/")[2] == RoutePaths?.EXPLOREMATCHES && props?.searchBy == "occupation" ?"Search By Profession"
             :"Filter Matches",
        subject:name,
        isClick:"True"
      };
      let { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.updateContentCardstatusUrl()}`,
        request
      );
  
      if (statusCode === 200) {
        // console.log("response", data);
        if (data.status === "Success") {
          setContentResponseData(data?.data);
          if(name == "Add partner preference"){
            navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath), { state: { tab: "2", contentId: data.data.contentId, name:name} });
            }
            else if(name == "Add Photo"){
                // navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3", contentId: contentResponse?.data?.contentId, name:cardName  } });
                setShowUploadImagePopup(true)
            }
            else if(name == "Add parents number"){
                setShowParentNumPopup(true)
            }
            else if(name == "App Download"){
                window.open(RouteSegments.staticLinkForAppDownload.android,'_blank')
            }
        
        }
      } else if (statusCode === 401) {
        logout();
      }
    };

    console.log(contentResponseData,"contentResponseData");

    const contentCardRedirection = (name) => {
        setCardName(name)
        bannerOnclickRedirection(name)    
    }

    useEffect(() => { 
        // setCardData(props?.contentcard)
        // console.log(Object.keys(props?.data),"datavalue");
        props?.data? setCardData(true) : setCardData(false)
        console.log(props,"valueprops");
        console.log(cardData,"valuestate");
    },[props?.data])

    

    // useEffect(() => {
       
        
    // },[])
 
     console.log(props,"valueprops");

  return (
    <>
        <div>
            {showUploadImagePopup ? (
            <UploadPhotoPopup
                close={() => setShowUploadImagePopup(false)}
                title={"Upload Photo"}
                callBack={() => {
                setsuccessMessage("Image uploaded succesfully")
                setshowSuccessPopup(true)
                }}
                isFrom ={"contentcard"}
            />
            ) : (
            <></>
            )}
            {showSuccessPopup && (

            <CommonSuccessPopup
            close={() => setshowSuccessPopup(false)}
            title={"Success!"}
            content={successMessage}
            />
            )}
        </div>
        {showParentNumPopup &&
            <ParentsPhonePopup close={() => setShowParentNumPopup(false)}
            title={"Parent's Number"}
            callBackApply={() => { }}
            contentId= {contentResponseData?.contentId}
            name={cardName}
            onSubmitSuccess={props.onSubmitSuccess}
            parentsIsdCode={props?.dashboardData?.parentsIsdCode}
            parentsMobileNumber={props?.dashboardData?.parentsMobileNumber}
            refresh={props?.refresh}
            />
        }
       
          {showUploadImagePopup ? (
        <UploadPhotoPopup
          close={() => setShowUploadImagePopup(false)}
          title={"Upload Photo"}
          Contentname={"Add Photo"}
          contentId= {contentResponseData?.contentId}
          callBack={() => {
            setsuccessMessage("Image uploaded succesfully")
            setshowSuccessPopup(true)

          }}
        />
      ) : (
        <></>
      )}
      {showSuccessPopup && (

        <CommonSuccessPopup
          close={() => setshowSuccessPopup(false)}
          title={"Success!"}
          content={successMessage}
        />
      )}
      

         {props?.data?.content?  
        <div className="grid grid-cols-9 gap-3 rounded-[17px] border border-[#E9E9E9] p-4  mx-1 md:mx-0 mt-[1rem]">
            <div className="md:col-span-1 col-span-9 justify-self-center flex items-center">
                {props?.data?.name == "App Download"?
                <div 
                className=" flex space-x-3 md:space-x-0 justify-around md:block"
                // className="flex space-x-3 justify-around"
                >
                    <img src="/Assets/Images/googleplay.png" className=" w-[5rem] md:w-[4rem]" />
                    <img src="/Assets/Images/Download_on_the_App_Store_Badge.svg" className=" w-[5rem] md:w-[4rem] md:mt-2 " />
                </div>
                :
                <img src={
                    props?.data?.name == "Add partner preference"?"/Assets/Images/Group 2378.svg"
                    :props?.data?.name == "Add Photo"?"/Assets/Images/Group 1801.svg"
                    :props?.data?.name == "Add parents number"?"/Assets/Images/Group 2382.svg"
                    :props?.data?.name == "App Download"?"/Assets/Images/googleplay.png"
                    // :props?.data?.name == "Add partner preference"?"/Assets/Images/Group 1801.svg"
                    // :props?.data?.name == "Add partner preference"?"/Assets/Images/Group 1801.svg"
                    :""} className="w-[3rem]" alt=""/>
                }
            </div>
            <div className="md:col-span-6 col-span-9 text-[#575556] text-[14px] text-center">
                <p dangerouslySetInnerHTML={{ __html: props?.data?.content?.replace("Click", `<br/>Click`) }} />
            </div>
            <div className="md:col-span-2 col-span-9 flex md:justify-end justify-center items-center ">
                <button 
                onClick={()=>contentCardRedirection(props?.data?.name)}
                className="text-[#FFFFFF] text-[9px] md:text-[9px] h-[2.2rem] lg:text-[12px] px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                    {props?.data?.name=="App Download"?"Download Now":props?.data?.name}
                </button>
            </div>
        </div>
        : 
        ""
         }
    </>
  );
};

export default ContentCard;
