import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//validation 
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import RouteSegments from "../../Routes/RouteSegment";

import { helpDataDetailBeforeLogin, helpDataDetailBeforeLoginSubmit } from "../../Store/Contact/Action";
import { CommonPopup } from "../CommonPopup";
import Constants from "../../Constants/constants";
import { validateNumber } from "../../Utils";

//Error msg for Login form
const DisplayingErrorMessagesSchema = Yup.object().shape({
  name: Yup.string()
  .required('Please enter your name')
  .matches(/^[a-zA-Z\s]+$/, 'Name should only contain alphabets')
  .test('no-numbers', 'Name should only contain alphabets', (value) => {
    return !/[0-9!@#$%^&*(),.?":{}|<>]/.test(value);
  }),
  // name: Yup.string().required("Please enter your name").matches(/^[A-Za-z]+$/, 'Name should only contain alphabets'),
  email: Yup.string().email('Invalid email format').required("Please enter email").matches(/^[^@\s]+@[^@\s]+\.[^.]{2,}$/, 'Invalid email format'),
  type: Yup.string(),
  subject: Yup.string().required("Subject should not be empty"),
  phonenumber: Yup.string()
  .min(7, "Please enter a valid Mobile Number")
  .max(15, "Please enter a valid Mobile Number")
  .required("Please enter your mobile number"),
question: Yup.string().required("Question should not be empty"),
});

const QueryForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showServicePopup, setShowServicePopup] = useState(false)
  const [showPopup, setShowPopup] = useState(true)
  useEffect(() => {
    //console.log(props?.menu, "props.page");
  }, [props.data, props?.menu ,props.page],[]);

  const dispatch = useDispatch();


  // form submit
  const LoginFormSubmission = (values) => {
    //console.log(values,"value123");
    dispatch(helpDataDetailBeforeLoginSubmit(values))
  };

  let { helpFormDetail } = useSelector((state) => ({
    helpFormDetail: state.Contact?.helpFormSubmitDetail?.data,
  }));

  useEffect(() => {
    helpFormDetail=""
  },[])
  //console.log(helpFormDetail,"helpFormDetail"); 

  useEffect(()=>{
    helpFormDetail?.status == "SUCCESS"&&
      setShowServicePopup(true)
  },[helpFormDetail])

  const closePopup = () => {
    setShowPopup(false)
    setShowServicePopup(false)
    if(location.state?.isfrom === "report"){
      navigate(-1,{replace:true})
    }else{
      navigate(RouteSegments.DASHBOARD,{replace:true})
    }
  }


  return (
    <>
    {(showServicePopup && showPopup) &&
    <CommonPopup menu ="report" title="Kalyan Matrimony Service" close={closePopup}>
      <p>Thank you for choosing Kalyan Matrimony Service.Our help desk team will get in touch with you shortly.</p>
    </CommonPopup>
    }
    <Formik
      initialValues={{
        name: localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName)?localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName):"",
        type: localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName)&& location?.state?.isfrom==="report" ? "Complaint": "Query",
        email: localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName)?localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail):"",
        subject:  localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName)&& location?.state?.isfrom==="report" ?"Abuse and Block": location?.state?.isfrom==="navbarhelp"?"":"",
        phonenumber: localStorage.getItem(Constants.loginLocalStorageKeys.loginPhone)?localStorage.getItem(Constants.loginLocalStorageKeys.loginPhone):"",
        question:  localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName)&& location?.state?.isfrom==="report" ?`ID ${location?.state?.senderId} has blocked ${location?.state?.receiverId} on ${new Date().toLocaleString()}`:"",      }}
      // validateOnChange={false}
      validateOnBlur={false}
      validationSchema={DisplayingErrorMessagesSchema}
      onSubmit={(values) => {
        LoginFormSubmission(values);
      }}
    > 
      {({ errors }) => (
        <Form className="">
          <div>
            <p className="text-[#575556] font-bold text-[18px] pt-2 pl-4 ">
              Post your query
            </p>
            <p className="text-[#D10A11] font-semibold md:text-[12px] lg:text-[14px] pl-4 pt-2 ">
              * marked fields are mandatory
            </p>
            <div className=" mx-[1rem] ">
              <div className=" grid grid-cols-9 mt-[2rem] ">
                <div className="col-span-3   md:col-span-2 flex items-center ">
                  <p className=" pt-4 md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold   ">
                    {" "}
                    Name <span className="text-[#D10A11]  ">*</span>{" "}
                  </p>
                </div>
                <div className=" col-span-6  md:col-span-7 md:pr-[0rem] pl-3  md:pl-[3.5rem]">
                  <p className="   ">
                  <div className="text-[red] text-[10px] lg:text-[12px] md:h-[2rem] lg:h-[1rem]">
                        {errors.name}
                      </div>
                    <Field
                    
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="off"
                      placeholder=""
                      className="px-2  font-bold placeholder-[#575556]  border border-[#8F8F8F]  md:text-[12px] lg:text-sm  rounded-[3px] h-[35px]  block w-full md:p-[0.4rem] lg:p-2.5     "
                    />
                  </p>
                </div>
              </div>

              <div className=" grid grid-cols-9 mt-[2rem] ">
                <div className="col-span-3  md:col-span-2 flex items-center ">
                  <p className=" pt-4 md:text-[12px] lg:text-[14px]  whitespace-nowrap flex text-[#575556] font-semibold   ">
                    {" "}
                    e-mail ID <span className="text-[#D10A11] ">*</span>{" "}
                  </p>
                </div>
                <div className="col-span-6  md:col-span-7 md:pr-[0rem] pl-3  md:pl-[3.5rem]">
                  <p className="   ">
                  <div className="text-[red] text-[10px] lg:text-[12px] md:h-[2rem] lg:h-[1rem]">
                        {errors.email}
                      </div>
                     <Field
                      id="email"
                      name="email"
                      type="text"
                      autoComplete="off"
                      placeholder=""
                      className="px-2  font-bold  border border-[#8F8F8F]  md:text-[12px] lg:text-sm  placeholder-[#575556] rounded-[3px] h-[35px]  block w-full md:p-[0.4rem] lg:p-2.5   "
                    />
                  </p>
                </div>
              </div>

              <div className=" grid grid-cols-9 mt-[2rem] ">
                <div className="col-span-3  md:col-span-2 flex items-center ">
                  <p className="pt-4 md:text-[12px] lg:text-[14px] md:flex lg:block whitespace-nowrap  text-[#575556] font-semibold   ">
                    {" "}
                    Phone Number <span className="text-[#D10A11]">*</span>{" "}
                  </p>
                </div>
                <div className="col-span-6  md:col-span-7 md:pr-[0rem] pl-3  md:pl-[3.5rem]">
                  <p className=" RegPhone ">
                  <div className="text-[red] text-[10px] lg:text-[12px] md:h-[2rem] lg:h-[1rem]">
                        {errors.phonenumber}
                      </div>
                     <Field
                      name="phonenumber">
                      {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }) => (
                            <input 
                            id="phonenumber"
                            type="tel"
                            autoComplete="off"
                            placeholder=""
                            className="px-2  RegPhone font-bold  border border-[#8F8F8F]  md:text-[12px] lg:text-sm placeholder-[#575556] rounded-[3px] h-[35px]  block w-full md:p-[0.4rem] lg:p-2.5     "
                            {...field} 
                            onChange={(e)=>{
                              if(validateNumber(e.target.value)){
                                field.onChange(e)
                              }
                            }}/>
                        )}
                    </Field>
                  </p>
                </div>
              </div>

              <div className=" grid grid-cols-9 mt-[2rem] ">
                <div className="col-span-3  md:col-span-2 flex items-center  ">
                  <p className="pt-4 md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  ">
                    Type
                    <span className="text-[#D10A11] ">*</span>
                  </p>
                </div>
                <div className="col-span-6  md:col-span-7 md:pr-[0rem] pl-3  md:pl-[3.5rem]">
                  <p className="  ">
                  <div className="text-[red] text-[10px] lg:text-[12px] md:h-[2rem] lg:h-[1rem]">
                        {errors.type}
                      </div>
                    <div className="flex ">
                      <label className=" select font-bold text-[#575556] w-full ">
                        {/* {props.menu !== "help" ? (
                            <Field as="select"
                            name=""
                            id=""
                            className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
                          >
                            {props?.data?.type.map((sample) => {
                              return (
                                <option key={sample.id} value="">
                                  {sample.name}
                                </option>
                              );
                            })}
                          </Field>
                         
                        ) : ( */}
                        <Field as="select"
                            name="type"
                            id="type"
                            className="px-2  w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
                          >
                            {props?.data?.type?.map((type,i) => (
                              <option id={type.name}>{type.name}</option>
                            ))}
                          </Field> 
                        {/* )} */}
                      </label>
                    </div>
                  </p>
                </div>
              </div>

              <div className=" grid grid-cols-9 mt-[2rem] ">
                <div className="col-span-3  md:col-span-2 flex items-center ">
                  <p className=" pt-4 md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold   ">
                    {" "}
                    Subject <span className="text-[#D10A11] ">*</span>{" "}
                  </p>
                </div>
                <div className="col-span-6  md:col-span-7 md:pr-[0rem] pl-3  md:pl-[3.5rem]">
                  <p className="   ">
                  <div className="text-[red] text-[10px] lg:text-[12px] md:h-[2rem] lg:h-[1rem]">
                        {errors.subject}
                      </div>
                     
                    <Field
                      id="subject"
                      name="subject"
                      type="text"
                      // autoComplete="off"
                      autoComplete="off" autoCorrect="off" spellCheck="false"
                      placeholder=""
                      className="px-2  font-bold  border border-[#8F8F8F]  md:text-[12px] lg:text-sm placeholder-[#575556] rounded-[3px] h-[35px]  block w-full md:p-[0.4rem] lg:p-2.5    "
                    />
                  </p>
                </div>
              </div>
              <div className=" grid grid-cols-9  ">
                <div className="col-span-3 md:col-span-2 flex items-center ">
                  <p className=" pt-4 md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold   ">
                    {" "}
                    Question <span className="text-[#D10A11] ">*</span>{" "}
                  </p>
                </div>
                <div className="col-span-6  md:col-span-7 md:pr-[0rem] pl-3  md:pl-[3.5rem]">
                  <p className=" mt-[2.3rem] ">
                      <div className="text-[red] text-[10px] lg:text-[12px] md:h-[2rem] lg:h-[1rem]">
                        {errors.question}
                      </div>
                    <Field as="textarea"
                      id="question"
                      name="question"
                      type="text"
                      autoComplete="off" autoCorrect="off" spellCheck="false"
                      rows="4"
                      className="block px-2  p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-[3px] border border-[#8F8F8F]"
                      placeholder=""
                    ></Field>
                  </p>
                </div>
              </div>
              <div className="flex justify-end mt-[2rem] md:pr-[1rem] ">
                <button type="submit" className=" flex items-center justify-center h-[2rem] md:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
    </>
  );
};

export default QueryForm;
