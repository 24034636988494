import React from "react";
import { MultiSelectView } from "./PartnerPreferenceUtils";

const HabitsPreference = (props) => {
  return (
    <div>
      {console.log(props.editfromadmin,'props.editfromadmin')}
      <MultiSelectView
        title={"Food Habits"}
        inputID={"foodhabits"}
        inputName={"foodhabits"}
        placeholder="Any"
        value={props.data.values.foodhabits}
        defalutValue={[props.data.values.foodhabits]}
        onChange={(e) => {
          props.data.setFieldValue("foodhabits", Array.from(e));
          if(props.isTrailPack =='Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.foodhabits}
        options={props?.managePreferenceData?.food}
        isDisabled={props.editfromadmin == 'Y'}
      />
      <MultiSelectView
        title={"Smoking"}
        inputID={"smoking"}
        placeholder="Any"
        inputName={"smoking"}
        value={props.data.values.smoking}
        defalutValue={[props.data.values.smoking]}
        onChange={(e) => {
          props.data.setFieldValue("smoking", Array.from(e));
          if(props.isTrailPack =='Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.smoking}
        options={props?.managePreferenceData?.smoking}
        isDisabled={props.editfromadmin == 'Y'}
      />
      <MultiSelectView
        title={"Drinking"}
        placeholder="Any"
        inputID={"drinking"}
        inputName={"drinking"}
        value={props.data.values.drinking}
        defalutValue={[props.data.values.drinking]}
        onChange={(e) => {
          props.data.setFieldValue("drinking", Array.from(e));
          if(props.isTrailPack =='Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.drinking}
        options={props?.managePreferenceData?.drinking}
        isDisabled={props.editfromadmin == 'Y'}
      />
    </div>
  );
};

export default HabitsPreference;
