import {
   REGISTER_DETAIL,
   REGISTER_DETAIL_SUCCESS,
   API_ERROR,
   CASTE_LIST,
   GET_CASTE_LIST_SUCCESS,
   SUB_CASTE_LIST,
   GET_SUB_CASTE_LIST_SUCCESS,
   RAASI_LIST,
   GET_RAASI_LIST_SUCCESS,
   COUNTRY_LIST,
   GET_COUNTRY_LIST_SUCCESS,
   PINCODE_LIST,
   GET_PINCODE_LIST_SUCCESS,
   STATE_LIST,
   GET_STATE_LIST_SUCCESS,
   REGISTER_SUBMIT,
   REGISTER_SUBMIT_SUCCESS,
   CITY_LIST,
   GET_CITY_LIST_SUCCESS,
   STAR_LIST,
   GET_STAR_LIST_SUCCESS,
   PROFILE_SUB_CASTE_LIST,
   GET_PROFILE_SUB_CASTE_LIST_SUCCESS,
   VALIDATE_NAME,
   VALIDATE_NAME_SUCCESS,
   VALIDATE_NAME_ERROR,
   DENOMINATION_LIST,
   GET_DENOMINATION_LIST_SUCCESS,
   GET_DENOMINATION_LIST_FAIL
  } from "./ActionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const home = (state = initialState, action) => {
    ////console.log(action.type,"12345");
    switch (action.type) {
      case VALIDATE_NAME:
        state = {
          ...state,
          loading: true,
        };
        break;
        case VALIDATE_NAME_SUCCESS:
        ////console.log(action.payload.data,"statse");
        state = {
          ...state,
          nameErrorData:action.payload.data,
          loading: false,
        };
        break;
      case VALIDATE_NAME_ERROR:
        ////console.log( action.payload.data,"123456");
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;
      case REGISTER_DETAIL:
        state = {
          ...state,
          loading: true,
        };
        break;
        case REGISTER_DETAIL_SUCCESS:
        ////console.log(action.payload.data,"statse");
        state = {
          ...state,
          rData:action.payload.data,
          loading: false,
        };
        break;
      case API_ERROR:
        ////console.log( action.payload.data,"123456");
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;
        case CASTE_LIST:
          ////console.log( action,"123456");
        state = {
          ...state,
          data:action.payload,
          loading: true,
        };
        break;
        case GET_CASTE_LIST_SUCCESS:
          ////console.log( action,"123456");
        state = {
          ...state,
          cList:action.payload,
          loading: true,
        };
        break;
        case SUB_CASTE_LIST:
          ////console.log( action,"123456");
        state = {
          ...state,
          data:action.payload,
          loading: true,
        };
        break;
        case GET_SUB_CASTE_LIST_SUCCESS:
        state = {
          ...state,
          scList:action.payload,
          loading: true,
        };
        break;
        case PROFILE_SUB_CASTE_LIST:
        state = {
          ...state,
          data:action.payload,
          loading: true,
        };
        break;
        case GET_PROFILE_SUB_CASTE_LIST_SUCCESS:
        state = {
          ...state,
          pscList:action.payload,
          loading: true,
        };
        break;
        case STAR_LIST:
          ////console.log( action,"123456");
        state = {
          ...state,
          data:action.payload,
          loading: true,
        };
        break;
        case GET_STAR_LIST_SUCCESS:
          ////console.log( action,"123456");
        state = {
          ...state,
          starList:action.payload,
          loading: true,
        };
        break;
        case RAASI_LIST:
          ////console.log( action,"123456");
        state = {
          ...state,
          data:action.payload,
          loading: true,
        };
        break;
        case GET_RAASI_LIST_SUCCESS:
          ////console.log( action,"123456");
        state = {
          ...state,
          raasiList:action.payload,
          loading: true,
        };
        break;
        case COUNTRY_LIST:
          ////console.log( action,"123456");
        state = {
          ...state,
          data:action.payload,
          loading: true,
        };
        break;
        case GET_COUNTRY_LIST_SUCCESS:
          ////console.log( action,"123456");
        state = {
          ...state,
          countryList:action.payload,
          loading: true,
        };
        break;
        case STATE_LIST:
          ////console.log( action,"123456");
        state = {
          ...state,
          data:action.payload,
          loading: true,
        };
        break;
        case GET_STATE_LIST_SUCCESS:
          //console.log( action,"123456");
        state = {
          ...state,
          stateList:action.payload,
          loading: true,
        };
        break;
        case CITY_LIST:
          ////console.log( action,"123456");
        state = {
          ...state,
          data:action.payload,
          loading: true,
        };
        break;
        case GET_CITY_LIST_SUCCESS:
          //console.log( action,"123456");
        state = {
          ...state,
          cityList:action.payload,
          loading: true,
        };
        break;
        case PINCODE_LIST:
          ////console.log( action,"123456");
        state = {
          ...state,
          data:action.payload,
          loading: true,
        };
        break;
        case GET_PINCODE_LIST_SUCCESS:
          ////console.log( action,"123456");
        state = {
          ...state,
          pincodeList:action.payload,
          loading: true,
        };
        break;
      case DENOMINATION_LIST:
        state = {
          ...state,
          denominationList: [],
          loading: true,
        };
        break;
      case GET_DENOMINATION_LIST_SUCCESS:
        state = {
          ...state,
          denominationList: action.payload,
          loading: false,
        };
        break;
      case GET_DENOMINATION_LIST_FAIL:
        state = {
          ...state,
          loading: false,
        };
        break;

        case REGISTER_SUBMIT:
          //console.log( action,"123456");
        state = {
          ...state,
          loading: true,
        };
        break;
        case REGISTER_SUBMIT_SUCCESS:
          ////console.log( action,"123456");
        state = {
          ...state,
          formSubmitData:action.payload,
          loading: true,
        };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default home;
  