import React, { useState, useEffect, useContext } from "react";
import Groupicon from "../../../Components/Kalyanlite/assets/Images/Group 2017.png";
import GroupImage from "../../../Components/Kalyanlite/assets/Images/Group 2269.svg";
import addmoreicon from "../../../Components/Kalyanlite/assets/Images/addmoreicon.svg";
import SuccessPopup from "../Popup/SuccessPopup.jsx";
import inviteicon from "../../../Components/Kalyanlite/assets/Images/inviteicon.svg";
import {
  CommonAddMorePopup,
  CommonErrorPopup,
  ReInviteModal,
  RemovePopup,
} from "../../CommonPopup.jsx";
import familyGroupicon from "../../../Components/Kalyanlite/assets/Images/familygroup.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import EndPoints from "../../../Constants/EndPoints";
import config from "../../../config";
import axios from "axios";
import {
  registerDetail,
  registerCasteList,
  validateName,
} from "../../../Store/Home/Action";
import Constants, {
  EditProfileMenu,
  emailid,
  EventName,
  InboxFilters,
  MenuFunction,
} from "../../../Constants/constants";
import { POST, GET } from "../../../Services/api_services";
import * as KalyanLiteSelector from "../../../Store/KalyanLite/selectors";
import CloseIconRed from "../../../Components/Kalyanlite/assets/Images/cancelicon.svg";
import * as Images from "./../../../Components/Kalyanlite/assets/images";
import { validateNumber } from "../../../Utils.js";
import { useAnalytics } from "../../../Hooks/usePageChange.js";

const MemberItem = ({
  isParentsNumber,
  relationshipid,
  parentsNumber,
  avatar,
  title,
  subtitle,
  showClear,
  onClearClick,
  reinvitebutton,
  userData,
  handleRemoveFamilyNumber,
  isParent,
  setIsLoading,
  apiCallFamilyData
}) => {
  console.log(parentsNumber, relationshipid,isParentsNumber, "parentsNumberb");
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const handleReInviteClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSmsShare = () => {
    const message =
      encodeURIComponent(`Let’s find My Perfect Match together on Kalyan Matrimony. It's a simple, secure matrimony app. We can share, chat and discuss about profiles as a family together for free. 

Just Click the link below and download the App: https://play.google.com/store/apps/details?id=com.kalyanmatrimony.app

Let’s search and find matches together`);
    window.location.href = `sms:?body=${message}`;
    handleCloseModal();
  };

  const onRemoveClick = () => {
    setShowRemoveModal(true);
  };

  const parentsMobileNumber = localStorage.getItem(
    Constants.loginLocalStorageKeys.parentsMobileNumber
  );

  console.log(parentsMobileNumber, "vparentsMobileNumber");
  return (
    <>
    <div
      className={`w-[20rem] md:mt-0 md:h-[4rem] lg:mt-0 px-4 py-2 md:p-2 lg:px-3 xl:p-2 lg:h-[4rem] h-[5rem] xl:h-[5rem] overflow-hidden ${
        showClear ? "bg-[#E2E8F0]" : ""
      } cursor-pointer`}
    >
      <div className="w-full-20 flex items-center">
        <div className="w-[20%] h-full flex items-center ">
          <img
            alt=""
            className="rounded-full lg:h-[48px] md:h-[46px] object-cover w-[44px] h-[43px] md:w-[46px]"
            src={avatar} // Assuming you have an image property in your familydata objects
          />
        </div>
        <div className={` ${(isParentsNumber == true && userData?.showremovebutton == "no") || reinvitebutton == "yes" ?"w-[50%]":"w-[60%]  md:pl-2 lg:pl-2"} h-full grow flex flex-col justify-center`}>
          <h3 className="font-Poppins text-[#4C5B71] md:text-[13px] xl:text-[16px] lg:text-[13px] flex gap-2 items-center">
            {title}
          </h3>
          <p className="font-Poppins-Regular xl:w-[16rem] 2xl:w-[21rem] lg:w-[11rem] md:w-[13rem] w-[18rem] text-[#8292A8] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] overflow-hidden whitespace-nowrap overflow-ellipsis">
            {subtitle}
          </p>
        </div>
        {showClear && (
          <div className=" right-0 flex h-full items-center justify-center">
            <img
              onClick={onClearClick}
              alt=""
              src={CloseIconRed}
              className="w-5 h-5"
            />
          </div>
        )}
        <div className="w-[23%] pb-2 lg:pt-2">
          {isParentsNumber == true || userData?.showremovebutton == "no" ? 
            ""
           : 
            !showClear &&
            <button
              onClick={onRemoveClick}
              className="text-[11px]  underline font-Poppins text-[#4C5B71]"
            >
              Remove
            </button>
          
          }

          {reinvitebutton == "yes" ? (
            <button
              onClick={handleReInviteClick}
              className="text-[11px] whitespace-nowrap underline font-Poppins text-[#D10A11]"
            >
              Re-Invite
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {showRemoveModal && (
        <RemovePopup
          relationshipid={relationshipid}
          parentsNumber={parentsNumber}
          parentsMobileNumber={parentsMobileNumber}
          OnClose={() => setShowRemoveModal(false)}
          title={"Remove Family Member"}
          handleRemoveFamilyNumber={handleRemoveFamilyNumber}
          setIsLoading={setIsLoading}
          apiCallFamilyData={apiCallFamilyData}
        />
      )}
      {showModal && (
        <ReInviteModal
          parentsNumber={parentsNumber}
          userData={userData}
          onClose={handleCloseModal}
          handleSmsShare={handleSmsShare}
        />
      )}
    </div>
     {isParent && 
      <div className="relative left-[1rem] w-[22rem] 2xl:whitespace-nowrap lg:whitespace-normal">
          <p className="text-[12px] text-[#D10A11]" >*This number will be visible to Matches. Matches can also contact you on this number</p>
      </div>
      
     }
     </>
  );
};

const MatchGroupItem = ({
  handleRemoveFamilyNumber,
  familydata,
  formik,
  addMoreData,
  setAddMoreData,
  removeNumber,
  addmore,
  setAddmore,
  isUserNumber,
  setIsUserNumber,
  duplicateNumCheck,
  userData,
  setIsLoading,
  apiCallFamilyData
  
}) => {
  const { onClickTrack } = useAnalytics();

  const [addmoreError, setAddmoreError] = useState(false);

  const isParents = familydata?.filter(data => data?.relationshipName?.includes("Parent"))?.length > 0
  const isReachedMax = (familydata.length + addMoreData?.length + (isParents ? 0 : 1) + (addmore ? 1 : 0)) > 6;

  const [hideButton, setHideButton] = useState(false);

  const [formData, setFormData] = useState({
    parentsMobileNumber: "",
    parentsIsdcode: "",
    relationshipId: "",
  });

  const onAddmoreClick = async () => {
    onClickTrack(EventName.Family_Add_More_Add_More_Button);
    if (isReachedMax) {
      setAddmoreError(true);
      return;
    }

    if(formik?.values?.parentCheck == true){
      setHideButton(false);
    }

    if (formik?.values?.parentsMobileNumber && formik?.values?.relationshipId) {

      setAddMoreData(prev => ([...prev, {
        parentsMobileNumber: formik?.values?.parentsMobileNumber,
        parentsIsdcode: formik?.values?.parentsIsdcode,
        relationshipId: formik?.values?.relationshipId,
        parentCheck: formik?.values?.parentCheck,
      }]));
      formik.resetForm();
    } else {
      if (addmore) {
        formik.validateForm();
      }
    }

    setAddmore(true);
  };

  console.log(
    formik?.values?.parentsMobileNumber,
    "formik?.values?.parentsMobileNumber"
  );

  const { dropdownData } = useSelector((state) => ({
    dropdownData: state?.Home?.rData?.data,
  }));

  console.log(dropdownData, "dropdownData");

  const userProfileData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data
  );

  const isParentNumberAvailable = userProfileData?.parentsNumberAvailable == "No";







  return (
    <div className="">
      {addmoreError && (
        <CommonErrorPopup closePopup={() => setAddmoreError(false)} />
      )}

      <div className=' flex flex-col justify-center items-center   mt-[2rem]'>
         <img className="rounded-full w-[71px] h-[70px]" src={userProfileData?.avatar === "N" ? userProfileData?.profile_imageurl :
          userProfileData?.avatar === "Y" ? familyGroupicon : familyGroupicon
        } alt='' />
        <p className='text-[#4C5B71] text-[16px] px-4 py-3'>Family</p>
      </div>

      <div className="flex  flex-col justify-center  xl:pl-[3rem] pl-[4rem] lg:pl-0 items-center">
        {/* {userProfileData?.parentsNumberAvailable === "Yes" && !isParents ?
          <MemberItem
            subtitle={"Parent"}
            title={userProfileData?.parentsMobileNumber}
            avatar={userProfileData?.avatar === "N" ? userProfileData?.profile_imageurl :
              userProfileData?.avatar === "Y" ? Images.DefaultAvatar : Images.DefaultAvatar}
          /> : ""} */}
        <div>
          {familydata.length > 0 &&
            familydata.map((member, index) =>
               (
                <MemberItem
                  subtitle={member?.relationshipName}
                  title={member?.parentsMobileNumber}
                  avatar={GroupImage}
                  reinvitebutton={member?.reinvitebutton}
                  userData={userData}
                  handleRemoveFamilyNumber={handleRemoveFamilyNumber}
                  parentsNumber={member?.parentsMobileNumber}
                  relationshipid={member?.relationshipId}
                  isParentsNumber={member?.isParentsNumber}
                  isParent={member?.isParentsNumber}
                  setIsLoading={setIsLoading}
                  apiCallFamilyData={apiCallFamilyData}
                />
              )
            )}
        </div>
      </div>
      <div className="flex  flex-col justify-center  xl:pl-[3rem] pl-[4rem] lg:pl-0 items-center space-y-1">
        {addMoreData?.map((data) => {
          return (
            <MemberItem
              subtitle={
                dropdownData?.relationShip?.find(
                  (relation) => relation.id === data?.relationshipId
                )?.name
              }
              title={data?.parentsMobileNumber}
              avatar={GroupImage}
              showClear={true}
              isParent={data?.isParentsNumber}
              onClearClick={() => removeNumber(data?.parentsMobileNumber)}
              reinvitebutton={data?.reinvitebutton}
              userData={userData}
              handleRemoveFamilyNumber={handleRemoveFamilyNumber}
            />
          );
        })}
      </div>

      {addmore && familydata?.length < 6 && (
        <div>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col my-3 justify-center items-center xl:pl-[1rem] pl-[4rem] lg:pl-0"
          >
            <div className="flex space-x-2 w-[18rem] ">
              <label className="selected family select font-bol outline-none d text-[#575556] w-[5rem] md:w-[4rem] lg:w-[4.3rem]">
                <select
                  id="parentsIsdcode"
                  name="parentsIsdcode"
                  value={formik.values.parentsIsdcode}
                  onChange={formik.handleChange}
                  className="selected  form-input shadow-sm pt-[0.4rem] bg-[#E9E9E9]  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F]  py-[8px] px-2   md:py-2 md:px-2  w-full block !bg-[position:calc(100%_-_5px)_8px] md:!bg-[position:calc(100%_-_5px)_14px] lg:!bg-[position:calc(100%_-_5px)_14px]"
                >
                  {/* <option value="" disabled selected>
                    Select
                  </option> */}

                  {dropdownData?.isdcode?.map((isdcode, i) => (
                    <option className="p-2" value={isdcode.countryId}>
                      {isdcode.isdcode}
                    </option>
                  ))}
                </select>
              </label>

              <label className="">
                <input
                  name="parentsMobileNumber"
                  id="parentsMobileNumber"
                  type="tel"
                  placeholder="Enter Phone Number"
                  value={formik.values.parentsMobileNumber}
                  onChange={(e) => {
                    if (validateNumber(e.target.value)) {
                      duplicateNumCheck(e.target.value);
                      formik.handleChange(e);
                    }
                  }}
                  className="no-spinner outline-none p-1.5 my-1 w-full lg:h-[2.3rem] h-[2.3rem] text-[13px] font-Poppins text-[#575556] rounded-[3px] border border-[#C4C4C4]"
                />
                <div className="h-2 mb-2">
                  {formik.errors.parentsMobileNumber && (
                    <p className="text-[red] text-[12px]">
                      {formik.errors.parentsMobileNumber}
                    </p>
                  )}
                  {isUserNumber && (
                    <p className="text-[red] text-[12px]">
                      {"Profile number cannot be added"}
                    </p>
                  )}
                </div>
              </label>
            </div>
            <div className=" space-x-2 my-2 w-[18rem]">
              <label className="selected select text-[#575556] w-full">
                <select
                  id="relationshipId"
                  name="relationshipId"
                  value={formik.values.relationshipId}
                  onChange={(e)=>{
                    if(isParentNumberAvailable){
                      if(e.target.value == "5" || e.target.value == "6"){
                        if(hideButton){
                          formik.setFieldValue("parentCheck", false);
                        }else{
                          formik.setFieldValue("parentCheck", true);
                          setHideButton(true);
                        }
                      }else{
                        formik.setFieldValue("parentCheck", false);
                        setHideButton(false);
                      }
                    }
                    formik.handleChange(e)
                  }}
                  className=" selected form-input shadow-sm bg-[#F0F2F5] text-[#495057] text-[13px] md:text-[13px]  focus:outline-none rounded-[8px] focus:ring-[#2D2C6F] py-[8px] px-1 md:py-2 md:px-2  w-full block "
                >
                  <option className="p-2" value="" disabled selected hidden>
                    select relation
                  </option>
                  {dropdownData?.relationShip?.map((profile, i) => (
                    <option className="p-2" value={profile.id}>
                      {profile.name}
                    </option>
                  ))}
                </select>

                {formik.errors.relationshipId && (
                  <p className="text-[red] text-[12px]">
                    {formik.errors.relationshipId}
                  </p>
                )}
              </label>
            </div>
            {isParentNumberAvailable && hideButton &&
              <div className={`flex items-center gap-2 w-[18rem] whitespace-nowrap pt-4 ${formik.values.relationshipId == "5" || formik.values.relationshipId == "6" ? "" : "hidden" }`}>
                <input
                      type="checkbox"
                      name="parentCheck"
                      checked={formik.values.parentCheck ?? false}
                      onChange={formik.handleChange}
                      className="form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 relative cursor-pointer"
                    />
                <p className="text-gray-600 text-[14px] md:text-[12px] lg:text-[12px]" >
                  Add this number to my profile as Parent's Number
                </p>
              </div>
            }
            <div className="flex justify-center mt-5 items-center space-x-8 ">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="flex items-center cursor-pointer gap-2 justify-center my-1 h-[1.5rem] text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.8rem] whitespace-nowrap rounded-[5px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
              >
                <span>
                  {formik.isSubmitting ? "Sending..." : "Send Invite"}
                </span>{" "}
                <img alt="" className="w-4 h-4" src={inviteicon} />
              </button>
            </div>
          </form>
        </div>
      )}

      {familydata?.length <= 6 && (
        <div className=" flex justify-end items-end md:justify-center md:items-center lg:justify-end lg:items-end ">
          <button
            disabled={formik.isSubmitting}
            onClick={onAddmoreClick}
            className="flex gap-2  md:ml-[8rem] ml-[7rem] xl:mr-[2rem] lg:mr-[1rem] py-5 cursor-pointer "
          >
            <img alt="" className="w-4 h-4" src={addmoreicon} />
            <p className="text-[13px] font-bold text-[#575556]">Add More</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default MatchGroupItem;
