import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import { ProfileMenuItem } from "../Components/Edit Profile/EditProfileUtills";
import ProfileBasicComponent from "../Components/Edit Profile/ProfileBasicComponent";
import ProfileHoroscopeComponent from "../Components/Edit Profile/ProfileHoroscopeComponent";
import ProfileMyPlanComponent from "../Components/Edit Profile/ProfileMyPlanComponent";
import ProfilePartnerPreferences from "../Components/Edit Profile/ProfilePartnerPreferences";
import ProfilePhotoComponent from "../Components/Edit Profile/ProfilePhotoComponent";
import ProfileSettingsComponents from "../Components/Edit Profile/ProfileSettingsComponents";
import ProfileTrustMarkComponent from "../Components/Edit Profile/ProfileTrustMarkComponent";
import ProfileWhoViewMyProfileComponent from "../Components/Edit Profile/ProfileWhoViewMyProfileComponent";
import ProfilrViewByMeComponent from "../Components/Edit Profile/ProfilrViewByMeComponent";
import NavBar, { LiteNavBar } from "../Components/NavBar";
import { ProfileAccordianComponent } from "../Components/ViewMessageComponents/AccordianComponents";
import EndPoints from "../Constants/EndPoints";
import Constants, { EditProfileMenu, loginFrom , EventName } from "../Constants/constants";
import { IP_Address } from "../Constants/IpAddress";
import { POST } from "../Services/api_services";
import { LoginContext } from "../Contexts/LoginContext";
import ViewedPhoneProfileTable from "../Components/Edit Profile/ViewedPhoneProfileTable";
import PhoneNumViewedTable from "../Components/Edit Profile/PhoneNumViewedTable";
import WarningPopup from "../Components/Edit Profile/SettingsComponents/WarningPopup";
import ImageHoroscopePopup from "../Components/Edit Profile/ImageHoroscopePopup";
import {TrustDocumentPopup, TrustDocumentViewPopupLite} from "../Components/Edit Profile/TrustDocumentPopup";
import { useNavigate,useLocation } from "react-router";
import ViewMessageNav from "../Components/ViewMessageComponents/ViewMessageNav";
import { NavListContext } from "../Contexts/navContext";
import Footer from "../Components/Footer/Footer";
import RouteSegments, { RouteHelper } from "../Routes/RouteSegment";
import { NavLink, useSearchParams, useParams } from "react-router-dom";
import {NotificationPopup,CommonSuccessPopup, CommonPopup, UploadPhotoPopup,ImageSuccessPopup, UploadPhotoPopuplite } from "../Components/CommonPopup";
import ViewMessageBottomNav from "../Components/ViewMessageComponents/ViewMessageBottomNav";
import Loader from "../Components/Loader";
import { NavBarContext } from "../Contexts/NavBarContext";
import KjBannerPopup from "../Components/DashboardComponents/KjBannerPopup";
import ParentsPhonePopup from "../Components/DashboardComponents/ParentsPhonePopup";
import config from "../config";
import LoaderGif from "../Gif/loader.gif"
import { useDispatch, useSelector } from "react-redux";
import DownloadAppBar from "../Components/DownloadAppBar";
import { getDenominationList } from "../Store/Home/Action";
import { dashboardLeftPanelData, dashboardProfileData } from '../Store/DashboardAPI/Action';
import { RoutePaths } from "../Routes/RouteSegment";
import { validateImage } from "../Utils";
import { imageSizeReducer,imageSizeCompression,handleImageSizeCompress } from "../Components/ImageSizeReducer";
import axios from "axios";
import Compressor from 'compressorjs';
import { useAnalytics, usePageTrack } from "../Hooks/usePageChange";
// import { useAnalytics } from "../Hooks/usePageChange";
import * as KalyanLiteSelector from "../Store/KalyanLite/selectors";
import { Link } from "react-router-dom";
import ParentsPhonePopupLite from "../Components/DashboardComponents/ParentsPhonePopupLite";
import useModule from "../Hooks/useModule";
import { Modal } from "antd";

import CloseIcon from "../Images/Group 761@2x.png"
import greenTickIcon from "../Images/Group 908@2x.png"
import HobbyGrey from  "../Components/Kalyanlite/assets/Images/HobbyGrey.svg"
import HobbyWhite from  "../Components/Kalyanlite/assets/Images/HobbyWhite.svg"
import HobbiesInterests from "../Components/Edit Profile/HobbiesInterests";
import { useFormik } from "formik";
import KalyanRewards from "../Components/Edit Profile/KalyanRewards";
import TrophyGreyIcon from "../Components/Kalyanlite/assets/Images/trophy-grey.svg";
import TrophyWhiteIcon from "../Components/Kalyanlite/assets/Images/trophy-white.svg";
import { getDeviceInfo, getGroupAuthId } from "../Components/Kalyanlite/utils";

const EditProfile = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { loginDetail, logout } = useContext(LoginContext);
   const { navList, setNavList } = useContext(NavListContext);
   const { menuSelect, setMenuSelect,setCompleteNow,packStatusData, setPackStatusData } = useContext(NavBarContext);

  const [searchParams,setSearchParams] = useSearchParams();

  const [showAddPhoto, setShowAddPhoto] = useState(false);
   const [loading, setLoading]  = useState(false)
   const [showKjBannerPopup, setShowKjBannerPopup] = useState(false);
  const [showKjThankPopup, setShowKjThankPopup] = useState(false);
  const [isFromInitialPopup,setIsFromInitialPopup] = useState(location?.state?.isFromInitialPopup)
  const [isFromTrailPopup, setIsFromTrailPopup] = useState(location?.state?.isFromTrailPopup)
  const [notificationId,setNotificationId] = useState(location?.state?.notificationId)
  const {tab} = useParams()
  console.log("tab-EditProfile",tab);
  const [selectedProfileMenu, setSelectedProfileMenu] = useState(
    EditProfileMenu.find(menu => menu.urlPath == tab)?.id
  );
  const editProfileMenu = EditProfileMenu.find(menu => menu.urlPath == tab)

console.log(setMenuSelect,'setMenuSelect');
const [error, setError] = useState(null);
const postData = async () => {
  try {
    const response = await axios.post(
      EndPoints.trialpackstatus(),
      {}, // Empty body
      {
        headers: {
          'Content-Type': 'application/json', // Adjust headers if necessary
        },
      }
    );
    if (response.status === 200 && response.data.status === "Success") {
      setPackStatusData(response.data.data);
    } else {
      setError('Failed to fetch data');
    }
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};
  useEffect(() => {
    setActiveTab(editProfileMenu?.id)
    setMenuSelect("2")
    console.log(location?.state, "selectedProfileMenu");
  }, [editProfileMenu?.id])

  const setActiveTab = (tab) => {
    setSelectedProfileMenu(tab?.toString())
    navigate({
      pathname: RouteHelper.getEditProfileUrl(EditProfileMenu.find(menu => menu.id == tab)?.urlPath),
      search: location.search
    }, { replace: true })
  }
  const isV3User = localStorage.getItem("isV3User")
  const isCommunitySite = localStorage?.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

 
  

  // const selectedProfileMenu = searchParams.get("tab") ??"1"
  // //console.log('selectedProfileMenu',selectedProfileMenu);

  // const setSelectedProfileMenu =(tab)=>{
  //   searchParams.set("tab",tab)
  //   setSearchParams(searchParams)
  // }

  const [navitem, setNavItem] = useState( 
    location?.state?.isfrom ?? ""
  ) 
  //console.log(navitem,"nav");
  const [navtab, setNavTab] = useState(
    location?.state?.tab ?? ""
  )
  //console.log(navtab,"navtab");
  const profileRef = useRef(null);

  const [managePreferenceData, setPreferenceData] = useState();
  const [myPlansData, setMyPlansData] = useState();
  const [horoscopeData, setHoroscopeData] = useState();
  const [settingsData, setSettingsData] = useState();
  const [viewedPhoneProfileData, setViewedPhoneProfileData] = useState([]);
  console.log(viewedPhoneProfileData,"viewedPhoneProfileData");
  const [showParentNumPopup, setShowParentNumPopup] = useState(false)
  const [viewedPhoneProfilePage, setViewedPhoneProfilePage] = useState({
    profilesviewedbymePage: 0,
    phonesviewedbymePage: 0,
    profilesviewedbyothersPage: 0,
    phonesviewedbyothersPage: 0,
  });
  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [editMyProfileData, setEditMyProfielData] = useState();
  const [imageList, setImageList] = useState([]);
  const [popupContent, setpopupContent] = useState({
    title: "",
    bodyValue: "",
  });
  const [showWarningPopup, setShowWarningpopup] = useState(false);
  // const [showImageHoroScopePopup, setShowImageHoroScopepopup] = useState(false);
  const [generatedHoroScopeUrl, setgeneratedHoroscopeurl] = useState({url:"",flag:"",show:false});
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showTrustPopup, setTrustPopup] = useState(false);
  const [imageUrl, setImageurl] = useState("");
  const [isLoading, setIsLoading] = useState(true)
  const [isSettingsLoading, setIsSettingsLoading] = useState(true)
  const [isPartnerPrefLoading, setIsPartnerPrefLoading] = useState(true)
  const [isEditProfileLoading, setIsEditProfileLoading] = useState(true)
  const [accordianSelect,setAccordianSelect]  = useState("");
  const [showRejectPopup, setShowRejectPopup] = useState(false)
  const [rejectedData, setRejectedData] = useState()
  const [addBanner, setAddBanner] = useState();
  const [showHoroScopeLoader,setShowHoroScopeLoader  ] = useState(true)
  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [contentCardData, setContentCardData] = useState({})

  const [unpaidprofilecount, setUnpaidprofilecount] = useState();
  const [hobbiesInterests, setHobbiesInterests] = useState([])
  console.log(unpaidprofilecount,"unpaidprofilecount");

  const [approvedImageList, setApprovedImageList] = useState([]);
  const [approvedImagePopup, setApprovedImagePopup] = useState(false);
  const [changeImagePopup, setChangeImagePopup] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [successImagePopup, setSuccessImagePopup] = useState(false);
  const [setIsFrom, setSetIsFrom] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState("-1");
  const [isScroll, setIsScroll] = useState()
  const [onchangedata, setOnchangeData] = useState(true)

  const{isEditProfileMatch} = useModule('')

  let {viewByIdProfileStatus} = useSelector(state => ({
    viewByIdProfileStatus:state.KalyanLite?.viewByIdError
  }));
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );

  const profileData = useSelector((state) => {
    const userId = state.Chats?.currentChat?.receiverId;
    return state.KalyanLite?.matchProfiles[userId];
  });

  console.log(profileData,"userData12345", userData);
  const loggedInUserPaid = userData?.isPaid === "Y"
  const [notification, setNotification] = useState({show:false,title:"",message:""})
  useEffect(() => {
    // apicallDashboard();
    apiCallEditProfile();
    apiCallManagePreference();
    setContentCardData(location?.state)
    dispatch(getDenominationList())
  }, []);

  
  

  useEffect(() => {
    setTab();
  }, [selectedProfileMenu]);

  useEffect(() => {
    //console.log("imageList", imageList);
  }, [
    editMyProfileData,
    myPlansData,
    viewedPhoneProfileData,
    viewedPhoneProfilePage,
    imageList,
    horoscopeData,
    settingsData,
    generatedHoroScopeUrl,
    managePreferenceData,
    selectedProfileMenu,accordianSelect,
  ]);

  // useEffect(() => {
  //   if (selectedProfileMenu === "2") {
  //     if(localStorage.getItem(Constants.loginLocalStorageKeys.isFirstLogin)){
  //       setShowUploadImagePopup(true)
  //     }
  //   }
  // },[])
    //matches List API Call and Response
  


    //Left Panel API Call and Response  
    const { leftPanelData } = useSelector(state => ({
      leftPanelData:  state.DashBoardAPI?.leftPanelData?.data
    }));

     //Profile Data API Call and Response  
     const { ProfileData } = useSelector(state => ({
      ProfileData: state.DashBoardAPI?.ProfileData?.data
    }));
    
  
    useEffect(()=>{
      console.log(ProfileData?.popup[0]?.showpopup,'ProfileData?.popup[0]?.showpopup');
      if(ProfileData?.popup[0]?.showpopup == "RejectedProfile"){
              setShowRejectPopup(true)
              setRejectedData(ProfileData?.popup[0])
            }
    },[ProfileData])

    console.log(ProfileData,'rejectedData');
     
    console.log(showRejectPopup,'showRejectPopup');

  const setTab = () => {
    if (selectedProfileMenu === "2") {
      // if(localStorage.getItem(Constants.loginLocalStorageKeys.isFirstLogin)){
      //   setShowUploadImagePopup(true)
      // }
      apiCallManagePreference();
    }else if (selectedProfileMenu === "3") {
      apiCallImageList();
      //  setNavList([...navList, "Photo"])
    } else if (selectedProfileMenu === "4") {
      // setNavList([...navList, "TRUST MARK"])
    } else if (selectedProfileMenu === "5") {
      apiCallHoroscope();
      // setNavList([...navList, "HOROSCOPE"])
    } else if (selectedProfileMenu === "6") {
      settingsApicall();
      // setNavList([...navList, "SETTINGS"])
    } else if (selectedProfileMenu === "7") {
      apiCallMyPlans();
      // setNavList([...navList, "MY PLAN"])
    } else if (selectedProfileMenu === "8") {
      viewedPhoneProfilePage.profilesviewedbymePage = 0;
      setViewedPhoneProfileData([]);
      setIsLoading(true)
      apiCallViewedPhoneProfile(
        "profilesviewedbyme",
        viewedPhoneProfilePage.profilesviewedbymePage,
        "fromMenu"
      );
      // setNavList(["Edit Profile", "Profile Viewed By Me"])
    } else if (selectedProfileMenu === "9") {
      
      viewedPhoneProfilePage.profilesviewedbyothersPage = 0;
      setViewedPhoneProfileData([]);
      setIsLoading(true)
      apiCallViewedPhoneProfile(
        "profilesviewedbyothers",
        viewedPhoneProfilePage.profilesviewedbyothersPage,
        "fromMenu"
      );
      // if (dashboard1Data?.isPaid === "N") {
        // setShowWarningpopup(true);
        setpopupContent({
          title: "WHO VIEWED MY PROFILE",
          bodyValue: setShowUploadImagePopup(true),
        });
      //   return;
      // }
      // setNavList(["Edit Profile", "Who Viewed My Profile"])
    } else if (selectedProfileMenu === "10") {
      viewedPhoneProfilePage.phonesviewedbymePage = 0;
      setViewedPhoneProfileData([]);
      setIsLoading(true)
      apiCallViewedPhoneProfile(
        "phonesviewedbyme",
        viewedPhoneProfilePage.phonesviewedbymePage,
        "fromMenu"
      );
      // setNavList(["Edit Profile", "Phone Numbers Viewed By Me"])
    } else if (selectedProfileMenu === "11") {
      
      viewedPhoneProfilePage.phonesviewedbyothersPage = 0;
      setViewedPhoneProfileData([]);
      setIsLoading(true)
      apiCallViewedPhoneProfile(
        "phonesviewedbyothers",
        viewedPhoneProfilePage.phonesviewedbyothersPage,
        "fromMenu"
      );
      //  if (ProfileData?.isPaid === "N")
      //  if(ProfileData?.myphoto === "N") {
        // setShowWarningpopup(true);}
        
        setpopupContent({
          title: "WHO VIEWED MY PHONE NUMBER",
          bodyValue: setShowUploadImagePopup(true),
        });
      
        return;
      // }
      // setNavList(["Edit Profile", "Who Viewed My Phone Number"])
    }
    else if (selectedProfileMenu === "12") {
      setIsLoading(true);
      getHobbiesandInterests();
    }
  };

  // const apicallDashboard = async () => {
  //   let request = {
  //     email: loginDetail()[0],
  //     userId: loginDetail()[1],
  //     pageno: "1",
  //     status: "new",
  //   };
  //   let { statusCode, data } = await POST(
  //     `${config.api.API_URL}${EndPoints.dashboardUrl()}`,
  //     request
  //   );
  //   if (statusCode === 200) {
  //     localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, data?.data.status)
  //     if(data?.data?.popup[0]?.showpopup == "RejectedProfile"){
  //       setShowRejectPopup(true)
  //       setRejectedData(data?.data?.popup[0])
  //     }
  //     setDashboard1Data(data?.data);
  //     // isPaid
  //     // profile_imageurl
  //     // profilecompletationcount
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  // };
   
  const apiCallManagePreference = async () => {
    setIsPartnerPrefLoading(true)
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.managepreferencesdataUrl()}`,
      request
    );

    if (statusCode === 200) {
      setPreferenceData(data?.data);
      setLoading(true)
    } else if (statusCode === 401) {
      logout();
    }
    setIsPartnerPrefLoading(false)
  };
  const apiCallEditProfile = async (setHoroscope = true) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
    };
    setIsEditProfileLoading(true);

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);
      setAddBanner(data?.data?.bannerWeb);
      if(setHoroscope){
        setgeneratedHoroscopeurl({url:data?.data?.horoscope,flag:data?.data?.horoflag,show:false});
      }
    } else if (statusCode === 401) {
      logout();
    }
    setIsEditProfileLoading(false);
  };
  const apiCallMyPlans = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
    };
    setIsLoading(true)
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.profileMyplansURl()}`,
      request
    );
    //console.log("statusCode", statusCode);
    if (statusCode === 200) {
      setMyPlansData(data?.data);
    } else if (statusCode === 401) {
      logout();
    }
    setIsLoading(false)
  };
  const apiCallHoroscope = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      frommanagePage: "Y",
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.getHoroscopeUrl()}`,
      request
    );
    //console.log("statusCode", data?.data);
    if (statusCode === 200) {
      setHoroscopeData(data?.data);
    } else if (statusCode === 401) {
      logout();
    }
  };
  const settingsApicall = async () => {
    setIsSettingsLoading(true);
      let request = {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
        frommanagePage: "Y",
      };

      let { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.settingsUrl()}`,
        request
      );
      
      //console.log("statusCode", statusCode);
      if (statusCode === 200) {
        setSettingsData(data?.data);
      } else if (statusCode === 401) {
        logout();
      }
      setIsSettingsLoading(false);
  };

  const apiCallImageList = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.profileImageListUrl()}`,
      request
    );
    //console.log("statusCode", statusCode);
    if (statusCode === 200) {
      setImageList(data?.data?.photos);
    } else if (statusCode === 401) {
      logout();
    }
  };
  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description })
  }
  const callBackDelete  = async(image) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      imagePath: `${image}`,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.profilePhotoDeleteUrl()}`,
      request
    );
    if (statusCode === 200) {
      apiCallEditProfile();
      showNotification({description:"Profile image removed successfully",title:"Success!"})
    } else if (statusCode === 401) {
      logout();
    }
  }
  const callBackHoroscope = async(url,type) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      imagePath: `${url}`,
      type : type
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.trustDoumentDeleteUrl()}`,
      request
    );
    if (statusCode === 200) {
      apiCallEditProfile();
      showNotification({description:type=='horoscope'?"Horoscope deleted successfully":"Document deleted successfully",title:"Success!"})
    } else if (statusCode === 401) {
      logout();
    }
  }
 



  const apiCallViewedPhoneProfile = async (type, page,isFrom) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      pageno: `${page}`,
      type: type,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.viewedphoneandprofilesUrl()}`,
      request
    );
    setIsLoading(false)

    if (statusCode === 200) {
      if (data?.data?.profileList?.length > 0) {
        setUnpaidprofilecount(data?.data?.unpaidprofilecount)
        console.log("statusCode", data?.data?.profileList);
        data?.data?.profileList.map((e, i) => {
          setViewedPhoneProfileData((oldValue) => [...oldValue, e]);
        });

        if (type === "profilesviewedbyme") {
          viewedPhoneProfilePage.profilesviewedbymePage =
            viewedPhoneProfilePage.profilesviewedbymePage + 1;
        } else if (type === "phonesviewedbyme") {
          viewedPhoneProfilePage.phonesviewedbymePage =
            viewedPhoneProfilePage.phonesviewedbymePage + 1;
        } else if (type === "profilesviewedbyothers") {
          viewedPhoneProfilePage.profilesviewedbyothersPage =
            viewedPhoneProfilePage.profilesviewedbyothersPage + 1;
        } else if (type === "phonesviewedbyothers") {
          viewedPhoneProfilePage.phonesviewedbyothersPage =
            viewedPhoneProfilePage.phonesviewedbyothersPage + 1;
        }
        if (type === "profilesviewedbyothers") {
          // if (ProfileData?.isPaid=== "N")
          // if(ProfileData?.myphoto === "N")
          //  {
            // setShowWarningpopup(true);
            if(isFrom == "fromMenu"){
              setpopupContent({
                title: "WHO VIEWED MY PROFILE",
                bodyValue: 
                setShowUploadImagePopup(true)
              });
              return;
            }
          // }
        }
      } else {
        if (type === "profilesviewedbyme") {
          setpopupContent({
            title: "PROFILES VIEWED BY ME",
            bodyValue: "You have not viewed any profiles.",
          });
          setShowWarningpopup(true);
        } else if (type === "phonesviewedbyme") {
          setpopupContent({
            title: "PHONE NUMBERS VIEWED BY ME",
            bodyValue: "You have not viewed any phone number.",
          });
          setShowWarningpopup(true);
        } else if (type === "profilesviewedbyothers") {
          // if (ProfileData?.isPaid === "N") {
          //   setShowWarningpopup(true);
          //   setpopupContent({
          //     title: "WHO VIEWED MY PROFILE",
          //     bodyValue: <>
          //       <p className="text-[14px] font-semibold mb-4 text-center">{data?.data?.unpaidprofilecount}</p>
          //       <p className="text-[14px] font-semibold">
          //         Please{" "}
          //         <NavLink
          //           to={RouteSegments.MEMBERSHIP_REGISTER()}
          //           className="underline cursor-pointer text-[#0000ff]"
          //           onClick={() => {
          //             setMenuSelect("6")
          //           }}
          //         >
          //           Upgrade your Profile
          //         </NavLink>{" "}
          //         to view and Call them.
          //       </p>
          //     </>,
          //   });
          //   return;
          // }
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const remainingProfileNav = (value) => {
    console.log("remainingProfileNav", value);
    if (value === "Enter Astrological information") {
      // Edit my profile page
      setAccordianSelect("HOROSCOPE");
      setActiveTab("1");
      setIsScroll(null); 
      setTimeout(() => {
        setIsScroll('ASTRO'); // Set the scroll target
      }, 100); 
    } else if (value === "Enter Family details") {
      setActiveTab("1");
      setAccordianSelect("FAMILY");
      // Edit my profile page
      setIsScroll(null); 
      setTimeout(() => {
        setIsScroll('FAMILY'); // Set the scroll target
      }, 100); 
    } else if (value === "Enter Habits information") {
      // Edit my profile page
      setActiveTab("1");
      setAccordianSelect("HABITS");
      setIsScroll(null); 
      setTimeout(() => {
        setIsScroll('HABITS') // Set the scroll target
      }, 100); 
    } else if (value === "Add Hobbies & Interest") {
      // Edit my profile page
      setActiveTab("1");
      setIsScroll(null); 
      setTimeout(() => {
        setIsScroll('HOBBIES') // Set the scroll target
      }, 100);
    }else if (value === "Add Additional Information") {
      // Edit my profile page
      setActiveTab("1");
      setIsScroll(null); 
      setTimeout(() => {
        setIsScroll('ADDITIONAL') // Set the scroll target
      }, 100);
    }else if (value === "Set Partner Expectations") {
      // Edit my profile page
      setActiveTab("2");
      apiCallEditProfile();
      apiCallManagePreference();
    } else if (value === "Become a Paid member") {
      navigate(RouteSegments.MEMBERSHIP_REGISTER(), {
        replace: true,
      });
      // Payment page
    } else if (value === "Add Photo") {
      // Gallery page
      setActiveTab("3");
      apiCallImageList();
    } else if (value === "Login Frequently") {
      setActiveTab("1");
    } else if (value === "Add ID Proof") {
      // Trust Mark page
      setActiveTab("4");
    } else if (value === "Add Education Proof") {
      // Trust Mark page
      setActiveTab("4");
    } else if (value === "Add Occupation Proof") {
      // Trust Mark page
      setActiveTab("4");
    } else if (value === "Add Horoscope") {
      // Horoscope page
      setActiveTab("5");
      apiCallHoroscope();
    }
  };
  const accordianRef = useRef(null);
  const hobbiesRef = useRef(null)

  
  useEffect(() => {
    // Scroll to specific ref when isScroll matches
    if (isScroll) {
      switch (isScroll) {
        case 'HOBBIES':
          if (hobbiesRef.current) {
            hobbiesRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          break;
        case 'ADDITIONAL':
          if (accordianRef.current) {
            accordianRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          break;
        default:
          const element = document.getElementById(isScroll);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
      }
      setIsScroll(null); // Reset scroll flag after scroll completes
    }
  }, [isScroll]);
  
  

  const callBackHoroscopePopup = ()=>{
    if (editMyProfileData?.horoscope !== "" && editMyProfileData?.horoscope !== undefined) {
      setgeneratedHoroscopeurl({url:editMyProfileData?.horoscope,flag:editMyProfileData?.horoflag,show:true})
    } 
    else {
      apiCallHoroscope();
      setActiveTab("5");
    }
  }

  // //console.log('editMyProfileData',editMyProfileData);
  const closeNotification = ()=>{
    setNotification({show:false,message:"",title:""})
  }

  const refreshPartnerPref = ()=>{

  }

  //console.log(rejectedData,"rejectedDatarejectedData");

  const bannerRedirect = (name, redirect) => {
    if(name == "imageupload"){
      setActiveTab("3")
    }
    else if(name == "parentnumber"){
      setShowParentNumPopup(true)
    }
    else if(name == "managepartner"){
      setActiveTab("2")
    }
    else if(name == "goldscheme"){
      window.open("https://www.kalyanmatrimony.com/gold-saving-scheme-online?sentFrom=Banner",'_blank')
    }
    else{
      window.open(redirect,'_blank')
    }
  }
  const closeModel = () => {
    setShowKjBannerPopup(false)
    setShowKjThankPopup(false)
  }

  useEffect(() => {
   return ()=>{
    localStorage.removeItem("phoneNumberEdit")
   }
  }, [])

  useEffect(() => {

    if(searchParams.get("addPhoto")){
      setShowAddPhoto(true);
    }
    
    dispatch(dashboardLeftPanelData())
    if(location?.state?.isfrom =='dashboard'){
      console.log(location?.state?.isfrom,'isFrom');
    }
    else{
      dispatch(dashboardProfileData())
    }
  },[])


  // const onProfileClick = (id) => {
  //   //old
  //   // navigate(RoutePaths.PROFILE, {
  //   //   state: { id: element?.profileid },
  //   // })

  //   //new
  //   // localStorage.setItem(Constants.loginLocalStorageKeys.currentProfileId, id)
  //   const win = window.open(`/redirect?page=${RoutePaths.PROFILE}&pageUrl=/${RoutePaths.DASHBOARD}/${RoutePaths.PROFILE}`)
  //   if (win != null) {
  //     win[Constants.loginLocalStorageKeys.currentProfileId] = id
  //   }
    
  //   console.log("onProfileClick",win[Constants.loginLocalStorageKeys.currentProfileId] = id);
  // }

  const onProfileClick = (id) => {
    // const profileUrl = `/redirect?page=${RoutePaths.PROFILE}&pageUrl=/${RoutePaths.DASHBOARD}/${RoutePaths.PROFILE}&profileId=${id}`;
    navigate(RoutePaths.PROFILE, { state: { id: id } });
    // Navigate to the new URL
    // window.location.href = profileUrl;
  };
  
  const { onClickTrack } = useAnalytics()

  const { onPageTrack } = usePageTrack()

  const approvedImage = async () => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.approveImageListUrl()}`,
      request
    );
    if (statusCode === 200) {
      if(data?.data?.photos.length > 0){
        if(data?.data?.photos.length == 1){
          if(data?.data?.photos[0]?.profileimage == "Y"){
            setSetIsFrom(true);
            setChangeImagePopup(true)
          }else{
            setApprovedImageList(data?.data?.photos.filter((e,i) => e.profileimage == "N").map((e,i) => e));
            setApprovedImagePopup(true)
            setOpen(true);
          }

        }else{
          setApprovedImageList(data?.data?.photos.filter((e,i) => e.profileimage == "N").map((e,i) => e));
          setApprovedImagePopup(true)
          setOpen(true);
        }
        
      }else{
        setSetIsFrom(true);
        setChangeImagePopup(true)
      }
      
    } else if (statusCode === 401) {
      logout();
    }
  }

  const handleImage = () => {
    if (isSelected != -1) {
      const selected_image = approvedImageList[isSelected];
      callBackSetProfile(selected_image?.photos)
      
    } else {
      console.log("Please select an image before clicking Ok");
    }
  };

  const callBackSetProfile = async (image) => {
    
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      imagePath: `${image}`,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.makeProfileImageUrl()}`,
      request
    );
    //console.log("statusCode", statusCode);
    if (statusCode === 200) {
      // alert("Profile Image Changed");
      if (data?.data?.validated === true) {
        setOpen(false);
        setIsSelected("-1");
        setsuccessMessage("Profile Image Changed")
        setshowSuccessPopup(true)
        apiCallEditProfile()
      } else if (data?.data?.status === Constants.verificationStatusKeys.rejected) {
        
        showNotification({description:`${Constants.photoRejectedMessage}: <b>${data?.data?.reason}</b>`})
      } else {
        showNotification({description:`${Constants.waitForValidationMessage}`})
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  console.log("isV3User-ssss", isV3User, props);

  const getHobbiesandInterests = async() => {
    
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.getprofilehobbiesUrl()}`,
      request
    );

    if (statusCode === 200) {
      setHobbiesInterests(data?.data);
      apiCallEditProfile();
      setIsLoading(false);
      formik.setSubmitting(false);
    } else if (statusCode === 401) {
      logout();
    }
  }

  const formik = useFormik({
    initialValues: {
      hobbiesInterest: hobbiesInterests.hobbiesAndInterest 
      ? hobbiesInterests.hobbiesAndInterest
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : [],
      musicListen:hobbiesInterests.musicListen 
      ? hobbiesInterests.musicListen
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : [],
      musicDirectors:hobbiesInterests.favoriteMusicDirectors 
      ? hobbiesInterests.favoriteMusicDirectors
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : [],
      booksRead:hobbiesInterests.hobbiesAndInterest 
      ? hobbiesInterests.TypeofBooksYouRead
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : [],
      moviesWatch:hobbiesInterests.TypeOfMoviesYouWatch 
      ? hobbiesInterests.TypeOfMoviesYouWatch
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : [],
      movieDirectors:hobbiesInterests.FavoriteMovieDirectors 
      ? hobbiesInterests.FavoriteMovieDirectors
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : [],
      sportsFitnessActivitiesGames:hobbiesInterests.SportsAndFitnessActivitiesAndGames 
      ? hobbiesInterests.SportsAndFitnessActivitiesAndGames
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : [],
      favouriteCuisine:hobbiesInterests.FavouriteCuisine 
      ? hobbiesInterests.FavouriteCuisine
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : [],
      languagesKnow:hobbiesInterests.languagesYouKnow 
      ? hobbiesInterests.languagesYouKnow
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : [],
      languagesWishToKnow:hobbiesInterests.languagesThatIwishToKnow 
      ? hobbiesInterests.languagesThatIwishToKnow
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : []
    },
    enableReinitialize: true,
    
    onSubmit: (values) => {
      saveHobbiesandInterests(values);
    },
  });

  const saveHobbiesandInterests = async(values) =>{
    const request = {
      hobbiesInterest : values?.hobbiesInterest,
      musicListen : values?.musicListen,
      musicDirectors : values?.musicDirectors,
      booksRead : values?.booksRead,
      moviesWatch : values?.moviesWatch,
      movieDirectors : values?.movieDirectors,
      sportsFitnessActivitiesGames : values?.sportsFitnessActivitiesGames,
      favouriteCuisine : values?.favouriteCuisine,
      languagesKnow : values?.languagesKnow,
      languagesWishToKnow : values?.languagesWishToKnow,
      // request ids
      ...(values?.hobbiesInterest?.length > 0 && {hobbiesInterestrequesttypeid:45}),
      ...(values?.musicListen?.length > 0 && {musicListenrequesttypeid:46}),
      ...(values?.musicDirectors?.length > 0 && {musicDirectorsrequesttypeid:47}),
      ...(values?.booksRead?.length > 0 &&  {booksReadrequesttypeid:48}),
      ...(values?.moviesWatch?.length > 0 &&  {moviesWatchrequesttypeid:49}),
      ...(values?.movieDirectors?.length > 0 && {movieDirectorsrequesttypeid:50}),
      ...(values?.sportsFitnessActivitiesGames?.length > 0 && {sportsrequesttypeid:51}),
      ...(values?.favouriteCuisine?.length > 0 && {cuisinerequesttypeid:52}),
      ...(values?.languagesKnow?.length > 0 && {languagesKnowrequesttypeid:63}),
      ...(values?.languagesWishToKnow?.length > 0 && {languagesWishrequesttypeid:64}),
      requestfulfilledfrom: getDeviceInfo(),
      requestfulfilledvia: "viewprofile",
      recieverprofileid: getGroupAuthId()
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.getprofilehobbiesSaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      getHobbiesandInterests();
    } else if (statusCode === 401) {
      logout();
    }
  }
  
  useEffect(() => {
    
  }, [hobbiesInterests])
  

  return (
    <>
    {/* <div className={`${isV3User ? "overflow-x-hidden" : ""}`}>
      <div className={`${isV3User ? "font-segeo bg-[#eae6df] h-screen w-screen overflow-y-hidden overflow-hidden" : ""}`}>
        <div className={`${isV3User ? "hidden md:block w-full h-[8.7rem] bg-[#d10a11] absolute top-0" : ""}`} />
        <div className={`${isV3User ? "flex items-center justify-center h-full w-full overflow-x-hidden overflow-y-hidden" : ""}`}>
          <div className={`${isV3User ? "z-[1] flex flex-col items-center justify-center " : " "}`}> */}
            <div className={`${isV3User ? "w-[100vw] lg:w-[86vw]" : "sticky top-0 z-50"}`}>
              {isV3User ? null : <NavBar data={leftPanelData} ProfileData={ProfileData} />}
            </div>
            <div className={`${isV3User ? 'overflow-y-auto lite-scrollbar  w-[100vw] lg:w-[86vw] md:h-[93vh] h-[92vh] xl:h-[82vh] 2xl:h-[85vh]  lg:h-[80vh]' : '' }`}>
              
              {loading?
              <>
              <main>
              <div className={`overflow-x-hidden ${isV3User ? "" : "pb-[4.5rem]"} md:pb-0`}>
                  {
                  notification.show
                    ? <CommonSuccessPopup close={closeNotification} title={notification.title} content={notification.message} />
                    : null
                  }
                  {showRejectPopup && (
                    <CommonPopup title={rejectedData?.bannerHeader} close={()=>setShowRejectPopup(false)} menu="profileRejection">
                    <p>{rejectedData?.content}</p>
                    <p>{rejectedData?.bannerFooter}</p>
                  </CommonPopup>
                  )
                  }
                  {showUploadImagePopup ? (
                    <UploadPhotoPopup
                      count={unpaidprofilecount}
                      title={popupContent.title}
                      content={popupContent.bodyValue}
                      close={() => setShowUploadImagePopup(false)}
                      menu="profileview"
                      callBack={() => {
                        setsuccessMessage("Image uploaded succesfully")
                        setshowSuccessPopup(true)
                        }}
                        />
                        ) : (
                          <></>
                          )}
                          {showSuccessPopup && (
                            <CommonSuccessPopup
                            close={() => setshowSuccessPopup(false)}
                            title={"Success!"}
                            content={successMessage}
                            />
                          )}
{changeImagePopup ? (
        <UploadPhotoPopup
          close={() => setChangeImagePopup(false)}
          title={"Upload Photo"}
          callBack={() => {

            setsuccessMessage("Image uploaded succesfully")
            setshowSuccessPopup(true)
            apiCallImageList();

          }}
          callBackImage={(value) => {
            var objectURL = URL.createObjectURL(value);
            console.log(value,"iii",objectURL)
            setCroppedImage(objectURL);
            setsuccessMessage("Image uploaded succesfully!")
            setSuccessImagePopup(true)

          }}
          menu={setIsFrom ? "changephoto" : "newphoto"}
          isV3User={isV3User}
        />
      ) : (
        <></>
      )}
      {successImagePopup && (
        <ImageSuccessPopup
          close={() => setSuccessImagePopup(false)}
          title={"Success!"}
          content={successMessage}
          image={croppedImage}
          goto={()=>{
            setMenuSelect("4")
            navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
              replace: isEditProfileMatch,
            });
          }}
        />
      )}

      <Modal
        title={"Select Photo"}
        centered
        open={open}
        // onOk={() => setOpen(false)}
        onCancel={() => {setOpen(false);setIsSelected("-1");}}
        width={700}
        footer={null}
        closeIcon={<button class=" bg-transparent z-40 border-0 text-black  absolute top-[-25px] right-[-25px]"><img className="h-5 w-5 " src={CloseIcon} alt=""/></button>}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            gap: "1rem",
            overflow: "auto",
            height: "70vh",
          }}
          className=""
        >
          {approvedImageList.map((photo, index) => (
            <div>
              <div className={`relative ${index == isSelected ? "" : "hidden"}`} key={index}>
                <div className="absolute right-0">
                  <img src={greenTickIcon} className="h-4 w-4 md:h-5 md:w-5 lg:h-5 lg:w-5" alt=""/>
                </div>
              </div>
              <img
                width={200}
                src={photo?.photos}
                key={index}
                alt=""
                className={`
                  ${index == isSelected
                    ? "p-[2px] shadow-[5px_5px_10px_#039511]"
                    : "border border-[#DADADAE0] p-2 md:p-4 lg:p-4"} 
                `}
                onClick={() => setIsSelected(index)}
              />
            </div>
          ))}
          
        </div>
        <div className="flex justify-end pt-3">
          <button
            onClick={() => {setOpen(false);setIsSelected("-1");}}
            className="mr-4 text-[10px] md:text-[12px] lg:text-[14px] p-2 rounded-[5px] cursor-pointer md:w-[5rem] lg:w-[5rem] text-black bg-white border border-[#D10A11] font-bold"
          >
            Cancel
          </button>
          <button
            onClick={handleImage}
            className="text-white bg-[#D10A11] text-[10px] md:text-[12px] lg:text-[14px] p-2 rounded-[5px]  md:w-[5rem] lg:w-[5rem] cursor-pointer font-bold"
          >
            Select
          </button>
        </div>
      </Modal>

                  {showParentNumPopup &&(
                      isV3User ? 
                      <ParentsPhonePopupLite close={() => setShowParentNumPopup(false)}
                        title={"Add Your Parent's Phone Number"}
                        callBackApply={() => { }}
                        parentsIsdCode={ProfileData?.parentsIsdCode}
                        parentsMobileNumber={ProfileData?.parentsMobileNumber}
                        isFrom={"myprofile"}
                      />
                      :
                      <ParentsPhonePopup close={() => setShowParentNumPopup(false)}
                        title={"Parent's Number"}
                        callBackApply={() => { }}
                        parentsIsdCode={ProfileData?.parentsIsdCode}
                        parentsMobileNumber={ProfileData?.parentsMobileNumber}
                      />
                  )
                    }
                  {showKjBannerPopup ? (
                      <KjBannerPopup
                        closeModel={closeModel}
                        setShowKjThankPopup={setShowKjThankPopup}
                        setShowKjBannerPopup={setShowKjBannerPopup}
                        title={"Send Message"}
                        dashboard1Data={dashboard1Data}
                      />
                    ) : (
                      <></>
                    )}
                  {showWarningPopup ? (
                    <WarningPopup
                      close={() => setShowWarningpopup(false)}
                      title={popupContent.title}
                      content={popupContent.bodyValue}
                    >
                      <p className="text-[14px] font-semibold">
                        Please{" "}
                        <NavLink
                              to={RouteSegments.MEMBERSHIP_REGISTER()}
                      className="underline cursor-pointer text-[#0000ff]"

                      onClick={()=>{
                        setMenuSelect("6")
                      }}
                    >
                      
                      Upgrade your Profile
                    </NavLink>{" "}
                        to enjoy this service.
                      </p>
                    </WarningPopup>
                  ) : (
                    <></>
                  )}
                  {generatedHoroScopeUrl.show ? (
                    // <ImageHoroscopePopup
                    //   close={() => setgeneratedHoroscopeurl(prev=>({...prev,show:false}))}
                    //   title={"View Horoscope"}
                    //   onButtonCallback={() => {
                    //     apiCallHoroscope();
                    //     setActiveTab("5");
                    //   }}
                    //   callBackDelete={() => {
                    //     callBackHoroscope(generatedHoroScopeUrl.url,'horoscope');
                    //   }}
                    //   deleteImage={true}              
                    //   isShowEditBtn={true}
                    // >
                    //   {generatedHoroScopeUrl.flag === "GenerateHoroscope" ? (
                    //     <div className="relative">
                    //     <iframe
                    //       title="Horoscope"
                    //       src={generatedHoroScopeUrl.url}
                    //       onLoad={()=>setShowHoroScopeLoader(false)}
                    //       className="w-[70vw] lg:w-[60vw] h-[50vh]"
                    //       // style={{ width: "60vw", height: "500px" }}
                    //     />
                    //     {showHoroScopeLoader === true? <div className="absolute maxabsolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div>:null}
                    //     </div>
                    //   ) : (
                    //     <img
                    //       alt=""
                    //       src={generatedHoroScopeUrl.url}
                    //       className="w-[70vw] lg:w-[60vw] h-[50vh]"
                    //       // style={{ width: "50vw", height: "400px" }}
                    //     />
                    //   )}
                    // </ImageHoroscopePopup>
                    <ImageHoroscopePopup
                    close={() => setgeneratedHoroscopeurl(false)}
                    // openHoroMatch={() => showMatchHoroPopup()}
                    title={"View Horoscope"}
                    // isMatch={false}
                    // isMatch={(userData?.status !== "pending" && !userData?.statusMessageHoroscope) ? "Match this Horoscope" : ""}
          
                    onButtonCallback={() => { }}
                    isShowEditBtn={false}>
                    <>
                      { editMyProfileData?.horoscopeType === "GenerateHoroscope" ? (
                        <>
                          {showHoroScopeLoader === true ? (
                            <div className='absolute maxabsolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                              <Loader />
                            </div>
                          ) : null}
                          <iframe
                            title='Horoscope'
                            onLoad={() => setShowHoroScopeLoader(false)}
                            src={generatedHoroScopeUrl.url}
                            className='w-[70vw] lg:w-[60vw] h-[70vh]'
                          />
                        </>
                      ) : (
                        <iframe
                          title='horoscope'
                          onLoad={() => setShowHoroScopeLoader(false)}
                          alt=''
                          src={generatedHoroScopeUrl.url}
                          className='w-[70vw] lg:w-[60vw] h-[70vh]'
                                  />
                      )}
                     
                      {/* {userData?.status == "pending" ? (
                        ""
                      ) : profileData?.statusMessageHoroscope &&
                       (
                        <p className='popup-warning-msg-paid'>
                          <center>
                            {profileData?.statusMessageHoroscope ==
                              "Only Paid Members can view the Horoscope Upgrade your Profile Now" ? (
                              <Link to={RouteSegments?.MEMBERSHIP_REGISTER()} onClick={()=>onClickTrack(EventName.View_Profile_All_Unpaid_Popup_Pay_Now_Button)}>
                                {profileData?.statusMessageHoroscope}
                              </Link>
                            ) : (
                              profileData?.statusMessageHoroscope
                            )}
                          </center>
                        </p>
                      ) } */}
                    </>
                  </ImageHoroscopePopup>
                  ) : (
                    <></>
                  )}
                  {showImagePopup ? (
                    <ImageHoroscopePopup
                      close={() => setShowImagePopup(false)}
                      title={"View Photo"}
                      onButtonCallback={() => {
                        apiCallImageList();
                        setActiveTab("3");
                      }}
                      // callBackDelete={() => {
                      //   callBackDelete(imageUrl);
                      // }}
                      changeProfilePhoto={()=>{
                        approvedImage()
                      }}
                      addNewPhoto={()=>{
                        setSetIsFrom(false);
                        setChangeImagePopup(true)
                      }}
                      isShowEditBtn={true}
                      deleteImage={true}
                      page={"edit"}
                    >
                      <img
                        alt=""
                        src={imageUrl}
                        className="w-[70vw] lg:w-[50vw] h-[50vh]"
                        // style={{ width: "50vw", height: "400px" }}
                      />
                    </ImageHoroscopePopup>
                  ) : (
                    <></>
                  )}
                  {isV3User? showTrustPopup&&(
                    <TrustDocumentViewPopupLite
                    close={() => setTrustPopup(false)}
                    idproof={editMyProfileData?.idproof}
                    eduproof={editMyProfileData?.eduproof}
                    incomeproof={editMyProfileData?.incomeproof}
                    isPaid={true}
                    isFrom={"edit"}
                    onButtonCallback={() => {
                      setActiveTab("4");
                    }}
                    callBackDelete={(url,selectedDoc) => {
                      let type;
                      if(selectedDoc == 'ID Proof'){
                        type = 'idproof';
                      }
                      else if(selectedDoc == 'Education Proof'){
                        type = 'educationproof';
                      }
                      else if(selectedDoc == 'Income Proof'){
                        type = 'incomeproof';
                      }
                      callBackHoroscope(url,type);
                    }}
                    />
                  )
                   :showTrustPopup &&(
                    <TrustDocumentPopup
                      close={() => setTrustPopup(false)}
                      idproof={editMyProfileData?.idproof}
                      eduproof={editMyProfileData?.eduproof}
                      incomeproof={editMyProfileData?.incomeproof}
                      onButtonCallback={() => {
                        setActiveTab("4");
                      }}
                      callBackDelete={(url,selectedDoc) => {
                        let type;
                        if(selectedDoc=='Add ID Proof'){
                          type = 'idproof';
                        }
                        else if(selectedDoc=='Add Education Proof'){
                          type = 'educationproof';
                        }
                        else if(selectedDoc=='Add Income Proof'){
                          type = 'incomeproof';
                        }
                        callBackHoroscope(url,type);
                      }}
                      deleteImage={true}
                    />
                  ) }
                  {!isV3User ? (
                  navitem ==="notification" || navitem === "inbox" || navitem==="profiledetails" ? 
                  <ViewMessageNav state={navitem} tab={selectedProfileMenu} /> 
                  :
                  <ViewMessageNav page={props.menu} tab={selectedProfileMenu} /> 
                  ):
                  null
              }

                {showAddPhoto && 
                  <UploadPhotoPopuplite
                      close={()=>setShowAddPhoto(false)}
                      title={"Add Photo"}
                      callBack={() => {
                        showSuccessPopup("Image uploaded succesfully")
                      }}
                      callBackReload={() => {}}
                    />
                  }
                  
                  <div>
                    <div className={`${isV3User ?  "overflow-hidden  flex w-[100vw] lg:w-[86vw] h-[92vh] lg:h-[82vh]" : "md:grid md:grid-cols-12 lg:grid-cols-12   md:h-full"}`}>
                      <div className={`${isV3User ? "lite-scrollbar overflow-y-auto lg:w-[30%] md:w-[50%] w-[100%] hidden md:block   bg-[#F7F8FA] pt-[1.5rem] px-[2rem] md:pb-[2rem]" : "hidden md:block  md:col-span-3 lg:col-span-3 bg-[#F3F3F3] pt-[1.5rem] px-[1.5rem] md:pb-[15rem] lg:pb-[20rem]"}`}>
                        <div className={`${isV3User ? "left-container overflow-y-auto" : "" }`}>
                          {isV3User ? 
                          <div className=" space-y-5 ">
                          <ProfileMenuItem
                            title={"My Profile"}
                            image={
                              selectedProfileMenu === "1"
                                ? "/Assets/Images/Group 863-white.svg"
                                : "/Assets/Images/Group 863.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "1"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "1"}
                            onClickCallBack={() => {
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("1");
                              onPageTrack("My Profile_My Profile - My Profile");
                            }}
                          />
                          <ProfileMenuItem
                            title={"Partner Expectations"}
                            image={
                              selectedProfileMenu === "2"
                                ? "/Assets/Images/Group 864-white.svg"
                                : "/Assets/Images/Group 864.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "2"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "2"}
                            onClickCallBack={() => {
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("2");
                              // apiCallManagePreference();
                              onClickTrack(EventName.profilePartnerPrefernceButton);
                            }}
                          />
                          <ProfileMenuItem
                            title={"Photos"}
                            image={
                              selectedProfileMenu === "3"
                                ? "/Assets/Images/Group 865-white.svg"
                                : "/Assets/Images/Group 865.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "3"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "3"}
                            onClickCallBack={() => {
                              // apiCallImageList();
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("3");
                            }}
                          />
                          <ProfileMenuItem
                            title={"Trust Mark"}
                            image={
                              selectedProfileMenu === "4"
                                ? "/Assets/Images/Group 866-white.svg"
                                : "/Assets/Images/Group 866.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "4"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "4"}
                            onClickCallBack={() => {
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("4");
                            }}
                          />
                          <ProfileMenuItem
                            title={"Horoscope"}
                            image={
                              selectedProfileMenu === "5"
                                ? "/Assets/Images/Group 867-white.svg"
                                : "/Assets/Images/Group 867.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "5"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "5"}
                            onClickCallBack={() => {
                              // apiCallHoroscope();
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("5");
                            }}
                          />

                          <ProfileMenuItem
                            title={"Hobbies & Interests"}
                            image={
                              selectedProfileMenu === "12"
                                ? HobbyWhite
                                : HobbyGrey
                            }
                            backgroundColor={
                              selectedProfileMenu === "12"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "12"}
                            onClickCallBack={() => {
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("12");
                            }}
                          />

                          <ProfileMenuItem
                            title={"Settings"}
                            image={
                              selectedProfileMenu === "6"
                                ? "/Assets/Images/Group 868-white.svg"
                                : "/Assets/Images/Group 868.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "6"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "6"}
                            onClickCallBack={() => {
                              // settingsApicall();
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("6");
                            }}
                          />
                          <ProfileMenuItem
                            title={"My Plan"}
                            image={
                              selectedProfileMenu === "7"
                                ? "/Assets/Images/Group 869-white.svg"
                                : "/Assets/Images/Group 869.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "7"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "7"}
                            onClickCallBack={() => {
                              // apiCallMyPlans();
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("7");
                              onClickTrack(EventName.profileMyPlanButton);
                            }}
                          />
                          <ProfileMenuItem
                            title={"Profiles Viewed By Me"}
                            image={
                              selectedProfileMenu === "8"
                                ? "/Assets/Images/Group 870-white.svg"
                                : "/Assets/Images/Group 870.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "8"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "8"}
                            onClickCallBack={() => {
                              // viewedPhoneProfilePage.profilesviewedbymePage = 0;
                              // setViewedPhoneProfileData([]);
                              // setIsLoading(true)
                              // apiCallViewedPhoneProfile(
                              //   "profilesviewedbyme",
                              //   viewedPhoneProfilePage.profilesviewedbymePage
                              // );
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("8");
                              // setNavList(["Edit Profile", "Profile Viewed By Me"])
                              onClickTrack(EventName.profileViewByMeButton);
                            }}
                          />
                          <ProfileMenuItem
                            title={"Who Viewed My Profile"}
                            image={
                              selectedProfileMenu === "9"
                                ? "/Assets/Images/Group 871-white.svg"
                                : "/Assets/Images/Group 871.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "9"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "9"}
                            onClickCallBack={() => {
                              if (dashboard1Data?.isPaid === "N") {
                                setShowWarningpopup(true);
                                setpopupContent({
                                  title: "WHO VIEWED MY PROFILE",
                                  bodyValue: "",
                                });
                                return;
                              }
                              viewedPhoneProfilePage.profilesviewedbyothersPage = 0;
                              setViewedPhoneProfileData([]);
                              // setIsLoading(true)
                              // apiCallViewedPhoneProfile(
                              //   "profilesviewedbyothers",
                              //   viewedPhoneProfilePage.profilesviewedbyothersPage
                              // );
                              setActiveTab("9");
                              // setNavList(["Edit Profile", "Who Viewed My Profile"])
                              onClickTrack(EventName.profileWhoViewedMyProfileButton);
                            }}
                          />
                          <ProfileMenuItem
                            title={"Phone Numbers Viewed by Me"}
                            image={
                              selectedProfileMenu === "10"
                                ? "/Assets/Images/Group 872-white.svg"
                                : "/Assets/Images/Group 872.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "10"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "10"}
                            onClickCallBack={() => {
                              // viewedPhoneProfilePage.phonesviewedbymePage = 0;
                              // setViewedPhoneProfileData([]);
                              // // setIsLoading(true)
                              // // apiCallViewedPhoneProfile(
                              // //   "phonesviewedbyme",
                              // //   viewedPhoneProfilePage.phonesviewedbymePage
                              // // );
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("10");
                              // setNavList(["Edit Profile", "Phone Numbers Viewed By Me"])
                              onClickTrack(EventName.profilePhoneNumbersViewedByMeButton);
                            }}
                          />
                          <ProfileMenuItem
                            title={"Who Viewed My Phone Number"}
                            image={
                              selectedProfileMenu === "11"
                                ? "/Assets/Images/Group 873-white.svg"
                                : "/Assets/Images/Group 873.svg"
                            }
                            backgroundColor={
                              selectedProfileMenu === "11"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "11"}
                            onClickCallBack={() => {
                              // //   setViewedPhoneProfilePage(0);
                              // if (dashboard1Data?.isPaid === "N") {
                              //   setShowWarningpopup(true);
                              //   setpopupContent({
                              //     title: "WHO VIEWED MY PHONE NUMBER",
                              //     bodyValue: "",
                              //   });
                              //   return;
                              // }
                              // viewedPhoneProfilePage.phonesviewedbyothersPage = 0;
                              // setViewedPhoneProfileData([]);
                              // // setIsLoading(true)
                              // // apiCallViewedPhoneProfile(
                              // //   "phonesviewedbyothers",
                              // //   viewedPhoneProfilePage.phonesviewedbyothersPage
                              // // );
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("11");
                              // setNavList(["Edit Profile", "Who Viewed My Phone Number"])
                              onClickTrack(EventName.profileWhoViewedMyPhoneNumberButton);
                            }}
                          />
                            <ProfileMenuItem
                            title={"Kalyan Rewards"}
                            image={
                              selectedProfileMenu === "13"
                                ? TrophyWhiteIcon
                                : TrophyGreyIcon
                            }
                            backgroundColor={
                              selectedProfileMenu === "13"
                                ? "#D10A11"
                                : "#DCDCDC"
                            }
                            isSelected={selectedProfileMenu === "13"}
                            onClickCallBack={() => {
                              if(profileRef.current){
                                profileRef.current.scrollTop = 0;
                              }
                              setActiveTab("13");
                            }}
                          />
                          {addBanner?.name === "kjbanners" ?
                            <img
                              className="cursor-pointer w-[100%] rounded-[2rem]"
                              alt=""
                              src={addBanner?.showphotobanner}
                              closeModel={closeModel}
                              onClick={() => {
                                setShowKjBannerPopup(true);
                              }} />
                            :
                            <img
                              className="cursor-pointer w-[100%] rounded-[2rem]"
                              alt=""
                              src={addBanner?.showphotobanner}

                              onClick={() => {
                                bannerRedirect(addBanner?.name, addBanner?.redirect)
                                // navigate(addBanner?.redirect);
                              }} />

                          }
                        </div> :
                          <div className=" space-y-5 ">
                            <ProfileMenuItem
                              title={"MY PROFILE"}
                              image={
                                selectedProfileMenu === "1"
                                  ? "/Assets/Images/Group 863-red.svg"
                                  : "/Assets/Images/Group 863.svg"
                              }
                              isSelected={selectedProfileMenu === "1"}
                              onClickCallBack={() => {
                                setActiveTab("1");
                                
                              }}
                            />
                            <ProfileMenuItem
                              title={"PARTNER PREFERENCES"}
                              image={
                                selectedProfileMenu === "2"
                                  ? "/Assets/Images/Group 864-red.svg"
                                  : "/Assets/Images/Group 864.svg"
                              }
                              isSelected={selectedProfileMenu === "2"}
                              onClickCallBack={() => {
                                setActiveTab("2");
                                // apiCallManagePreference();
                              }}
                            />
                            <ProfileMenuItem
                              title={"PHOTOS"}
                              image={
                                selectedProfileMenu === "3"
                                  ? "/Assets/Images/Group 865-red.svg"
                                  : "/Assets/Images/Group 865.svg"
                              }
                              isSelected={selectedProfileMenu === "3"}
                              onClickCallBack={() => {
                                // apiCallImageList();
                                setActiveTab("3");
                              }}
                            />
                            <ProfileMenuItem
                              title={"TRUST MARK"}
                              image={
                                selectedProfileMenu === "4"
                                  ? "/Assets/Images/Group 866-red.svg"
                                  : "/Assets/Images/Group 866.svg"
                              }
                              isSelected={selectedProfileMenu === "4"}
                              onClickCallBack={() => {
                                setActiveTab("4");
                              }}
                            />
                            <ProfileMenuItem
                              title={"HOROSCOPE"}
                              image={
                                selectedProfileMenu === "5"
                                  ? "/Assets/Images/Group 867-red.svg"
                                  : "/Assets/Images/Group 867.svg"
                              }
                              isSelected={selectedProfileMenu === "5"}
                              onClickCallBack={() => {
                                // apiCallHoroscope();
                                setActiveTab("5");
                              }}
                            />
                            <ProfileMenuItem
                              title={"SETTINGS"}
                              image={
                                selectedProfileMenu === "6"
                                  ? "/Assets/Images/Group 868-red.svg"
                                  : "/Assets/Images/Group 868.svg"
                              }
                              isSelected={selectedProfileMenu === "6"}
                              onClickCallBack={() => {
                                // settingsApicall();
                                setActiveTab("6");
                              }}
                            />
                            <ProfileMenuItem
                              title={"MY PLAN"}
                              image={
                                selectedProfileMenu === "7"
                                  ? "/Assets/Images/Group 869-red.svg"
                                  : "/Assets/Images/Group 869.svg"
                              }
                              isSelected={selectedProfileMenu === "7"}
                              onClickCallBack={() => {
                                // apiCallMyPlans();
                                setActiveTab("7");
                                
                              }}
                            />
                            <ProfileMenuItem
                              title={"PROFILES VIEWED BY ME"}
                              image={
                                selectedProfileMenu === "8"
                                  ? "/Assets/Images/Group 870-red.svg"
                                  : "/Assets/Images/Group 870.svg"
                              }
                              isSelected={selectedProfileMenu === "8"}
                              onClickCallBack={() => {
                                // viewedPhoneProfilePage.profilesviewedbymePage = 0;
                                // setViewedPhoneProfileData([]);
                                // setIsLoading(true)
                                // apiCallViewedPhoneProfile(
                                //   "profilesviewedbyme",
                                //   viewedPhoneProfilePage.profilesviewedbymePage
                                // );
                                setActiveTab("8");
                                // setNavList(["Edit Profile", "Profile Viewed By Me"])
                              }}
                            />
                            <ProfileMenuItem
                              title={"WHO VIEWED MY PROFILE"}
                              image={
                                selectedProfileMenu === "9"
                                  ? "/Assets/Images/Group 871-red.svg"
                                  : "/Assets/Images/Group 871.svg"
                              }
                              isSelected={selectedProfileMenu === "9"}
                              onClickCallBack={() => {
                                // if (dashboard1Data?.isPaid === "N") {
                                //   setShowWarningpopup(true);
                                //   setpopupContent({
                                //     title: "WHO VIEWED MY PROFILE",
                                //     bodyValue: "",
                                //   });
                                //   return;
                                // }
                                // viewedPhoneProfilePage.profilesviewedbyothersPage = 0;
                                // setViewedPhoneProfileData([]);
                                // // setIsLoading(true)
                                // // apiCallViewedPhoneProfile(
                                // //   "profilesviewedbyothers",
                                // //   viewedPhoneProfilePage.profilesviewedbyothersPage
                                // // );
                                setActiveTab("9");
                                // setNavList(["Edit Profile", "Who Viewed My Profile"])
                              }}
                            />
                            <ProfileMenuItem
                              title={"PHONE NUMBERS VIEWED BY ME"}
                              image={
                                selectedProfileMenu === "10"
                                  ? "/Assets/Images/Group 872-red.svg"
                                  : "/Assets/Images/Group 872.svg"
                              }
                              isSelected={selectedProfileMenu === "10"}
                              onClickCallBack={() => {
                                // viewedPhoneProfilePage.phonesviewedbymePage = 0;
                                // setViewedPhoneProfileData([]);
                                // // setIsLoading(true)
                                // // apiCallViewedPhoneProfile(
                                // //   "phonesviewedbyme",
                                // //   viewedPhoneProfilePage.phonesviewedbymePage
                                // // );
                                setActiveTab("10");
                                // setNavList(["Edit Profile", "Phone Numbers Viewed By Me"])
                              }}
                            />
                            <ProfileMenuItem
                              title={"WHO VIEWED MY PHONE NUMBER"}
                              image={
                                selectedProfileMenu === "11"
                                  ? "/Assets/Images/Group 873-red.svg"
                                  : "/Assets/Images/Group 873.svg"
                              }
                              isSelected={selectedProfileMenu === "11"}
                              onClickCallBack={() => {
                                // //   setViewedPhoneProfilePage(0);
                                // if (dashboard1Data?.isPaid === "N") {
                                //   setShowWarningpopup(true);
                                //   setpopupContent({
                                //     title: "WHO VIEWED MY PHONE NUMBER",
                                //     bodyValue: "",
                                //   });
                                //   return;
                                // }
                                // viewedPhoneProfilePage.phonesviewedbyothersPage = 0;
                                // setViewedPhoneProfileData([]);
                                // // setIsLoading(true)
                                // // apiCallViewedPhoneProfile(
                                // //   "phonesviewedbyothers",
                                // //   viewedPhoneProfilePage.phonesviewedbyothersPage
                                // // );
                                setActiveTab("11");
                                // setNavList(["Edit Profile", "Who Viewed My Phone Number"])
                              }}
                            />
                            {addBanner?.name === "kjbanners" ?
                              <img
                                className="cursor-pointer w-[100%]"
                                alt=""
                                src={addBanner?.showphotobanner}
                                closeModel={closeModel}
                                onClick={() => {
                                  setShowKjBannerPopup(true);
                                }} />
                              :
                              <img
                                className="cursor-pointer w-[100%]"
                                alt=""
                                src={addBanner?.showphotobanner}

                                onClick={() => {
                                  bannerRedirect(addBanner?.name, addBanner?.redirect)
                                  // navigate(addBanner?.redirect);
                                }} />

                            }
                          </div>
        }
                        </div>
                      </div>
                      <div className={`${isV3User ? " lite-scrollbar overflow-y-auto  w-[100%] lg:w-[70%] duration-1000 ease-linear  bg-[#F0F2F5] md:pt-[1.5rem] lg:px-[3.5rem] xl:px-[4rem] p-[1rem] pb-[2rem] lg:pb-4" : "md:col-span-7 lg:col-span-7 mx-[0.2rem] md:mx-0 md:ml-[0.7rem] lg:mx-[1.5rem] md:mb-[4rem]  md:mt-7 lg:mt-8"}`} ref={profileRef}>
                        <div className={`${isV3User ? "right-container overflow-y-auto overflow-x-hidden" : "" }`}>
                          <div
                            className={`${selectedProfileMenu === "1"   ? "" : "hidden"}`}
                          >
                            <ProfileBasicComponent
                              data={editMyProfileData}
                              dashboard1Data={ProfileData}
                              proileCount={leftPanelData} 
                              callBackReload={()=>{
                                apiCallEditProfile()
                                apiCallManagePreference()
                              }}
                              managePreferenceData={managePreferenceData}
                              isPartnerPrefLoading={isPartnerPrefLoading}
                              remainingProfileNav={remainingProfileNav}
                              isEditProfileLoading={isEditProfileLoading}
                              accordianSelect = {accordianSelect}
                              callbackPhotoNav={() => {
                                setActiveTab("3");
                                apiCallImageList();
                              }}
                              onOpenCallback={(image) => {
                                setImageurl(image);
                                setShowImagePopup(true);
                              }}
                              callBackHoroscopePopup={callBackHoroscopePopup}
                              callBackTrustPopup={() => {
                                if (
                                  editMyProfileData?.incomeproof ||
                                  editMyProfileData?.eduproof ||
                                  editMyProfileData?.idproof
                                ) {
                                  setTrustPopup(true);
                                } else {
                                  setActiveTab("4");
                                }
                              }}
                              callHobbiesAndInterest={() =>{
                                if(profileRef.current){
                                  profileRef.current.scrollTop = 0;
                                }
                                setActiveTab("12");
                              }}
                              isScroll={isScroll}
                              accordianRef={accordianRef}
                              hobbiesRef={hobbiesRef}
                            />
                          </div>
                        </div>
                        <div
                          className={`${selectedProfileMenu === "2" ? "" : "hidden"}`}
                        >
                          {isPartnerPrefLoading
                            ? <Loader />
                            : <ProfilePartnerPreferences
                              dashboard1Data={ProfileData}
                              callBackReload={() => {
                                apiCallEditProfile();
                                apiCallManagePreference();
                              }}
                              activeTab = {"2"}
                              contentCard={contentCardData}
                              managePreferenceData={managePreferenceData}
                              ref={profileRef}
                              isFromInitialPopup={isFromInitialPopup}
                              isFromTrailPopup={isFromTrailPopup}
                              setCompleteNow={setCompleteNow}
                              onchangedata={onchangedata} 
                              setOnchangeData ={ setOnchangeData}
                              postData={postData}
                            />}
                        </div>
                        <div
                          className={`${selectedProfileMenu === "3"  ? "" : "hidden"}`}
                        >
                          <ProfilePhotoComponent
                            contentCard={contentCardData}
                            data={imageList}
                            callBackReload={() => {
                              setImageList([]);
                              apiCallImageList();
                            }}
                            onOpenCallback={(image) => {
                              setImageurl(image);
                              setShowImagePopup(true);
                            }}
                            onProfileUpdateCallBack={() =>{
                              apiCallEditProfile()}
                            }
                            isFromInitialPopup={isFromInitialPopup}
                          />
                        </div>
                        <div
                          className={`${selectedProfileMenu === "4" ? "" : "hidden"}`}
                        >
                          
                          <ProfileTrustMarkComponent
                            editProfileData={editMyProfileData}
                            callBackReload={apiCallEditProfile}
                          />
                        </div>
                        <div
                          className={`${selectedProfileMenu === "5" ? "" : "hidden"}`}
                        >
                          <ProfileHoroscopeComponent
                            data={horoscopeData}
                            dashboard1Data={ProfileData} 
                            callBackPopup={(url,isUpload) => {
                              console.log('url-12',url);
                              if(isUpload === false){
                                setgeneratedHoroscopeurl({url,flag:'GenerateHoroscope',show:true});
                                setShowHoroScopeLoader(true)
                              }
                              setActiveTab("1")
                              apiCallEditProfile(false)
                              // if(isUpload){
                              //   // setImageurl(url)
                              // }else{
                              //   setgeneratedHoroscopeurl({url:url,flag:"GenerateHoroscope",show:true});
                              // }
                            }}
                          />
                        </div>
                        <div
                          className={`${selectedProfileMenu === "6" ? "" : "hidden"}`}
                        >
                          <ProfileSettingsComponents
                            data={settingsData}
                            isPaid={ProfileData?.isPaid}
                            isLoading={isSettingsLoading}
                            refresh={settingsApicall}
                            editprofilestatus={ProfileData?.editprofilestatus}
                            isFromInitialPopup={isFromInitialPopup}
                            notificationId={notificationId}
                          />
                        </div>
                        <div
                          className={`${selectedProfileMenu === "7" ? "" : "hidden"}`}
                        >
                          <ProfileMyPlanComponent isPaid={ProfileData?.isPaid} data={myPlansData} isLoading={isLoading}/>
                        </div>
                        <div
                          className={`${selectedProfileMenu === "8" ? "" : "hidden"}`}
                        >
                          <ViewedPhoneProfileTable
                            menu={"profileviewedbyme"}
                            title={"Profile Viewed by Me"}
                            tableHeader={["Name", "Profile ID", "Viewed Date"]}
                            tableBody={viewedPhoneProfileData}
                            isLoading={isLoading}
                            loadMore={() => {
                              apiCallViewedPhoneProfile(
                                "profilesviewedbyme",
                                viewedPhoneProfilePage.profilesviewedbymePage,
                                "loadmore"
                              );
                            }}
                          />
                        </div>
                        <div
                          className={`${selectedProfileMenu === "9" ? "" : "hidden"}`}
                        >
                          {" "}
                          {isV3User ?
                             <div
                             className={`${selectedProfileMenu === "9" ? "" : "hidden"}`}
                           >
                             {" "}
                             <ViewedPhoneProfileTable
                               title={"Who Viewed My Profile"}
                               tableHeader={["Name", "Profile ID", "Viewed Date"]}
                               tableBody={viewedPhoneProfileData}
                               isLoading={isLoading}
                               loadMore={() => {
                                 apiCallViewedPhoneProfile(
                                   "profilesviewedbyothers",
                                   viewedPhoneProfilePage.profilesviewedbyothersPage
                                 );
                               }}
                               callBackNav={(e) =>{
                                 setNavList([...navList, "Profile Page"])
                                 viewByIdProfileStatus = ""
                                 navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, { state: { isFrom : "editprofile",id: "KM"+e } })
                                 setMenuSelect("")
                               }
                               }
                               ref={profileRef}
                             />
                           </div>
                           :
                          <div className={`${selectedProfileMenu === "9" ? "" : "hidden"}`}>
                                
                                  <ViewedPhoneProfileTable
                                  menu="viewmyprofile"
                                  title={"Who Viewed My Profile"}
                                  tableHeader={["Name", "Profile ID", "Viewed Date"]}
                                  tableBody={viewedPhoneProfileData}
                                  isLoading={isLoading}
                                
                                  loadMore={() => {
                                    apiCallViewedPhoneProfile(
                                      "profilesviewedbyothers",
                                      viewedPhoneProfilePage.profilesviewedbyothersPage,
                                      "loadmore"
                                    );
                                  }}
                                  callBackNav={(e) =>{
                                    setNavList([...navList, "Profile Page"])
                                    navigate(RouteHelper.getProfileUrl, { state: { id: e } })
                                    setMenuSelect("")
                                  }
                                  }
                                  />
                              
                              </div>
}


                        </div>
                        <div
                          className={`${selectedProfileMenu === "10" ? "" : "hidden"}`}
                        >
                          <PhoneNumViewedTable
                            title={"Phone Numbers Viewed by Me"}
                            isLoading={isLoading}
                            tableHeader={[ 
                              "Name",
                              "Profile ID",
                              "Mobile Number",
                              "Viewed Date",
                            ]}
                            tableBody={viewedPhoneProfileData}
                            loadMore={() => {
                              apiCallViewedPhoneProfile(
                                "phonesviewedbyme",
                                viewedPhoneProfilePage.phonesviewedbymePage,
                                "loadmore"
                              );
                            }}
                          />
                        </div>
                        {isV3User ?
                          <div
                          className={`${selectedProfileMenu === "11" ? "" : "hidden"}`}
                        >
                          <ViewedPhoneProfileTable
                            title={"Who Viewed My Phone Number"}
                            tableHeader={["Name", "Profile ID", "Viewed Date"]}
                            tableBody={viewedPhoneProfileData}
                            isLoading={isLoading}
                            loadMore={() => {
                              apiCallViewedPhoneProfile(
                                "phonesviewedbyothers",
                                viewedPhoneProfilePage.phonesviewedbyothersPage
                              );
                            }}
                            callBackNav={(e) =>{
                              setNavList([...navList, "Profile Page"])
                              viewByIdProfileStatus = ""
                              navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, { state: { isFrom : "editprofile",id: "KM"+e } })
                              setMenuSelect("")
                            }}
                            ref={profileRef}
                          />
                        </div>:
                        <div
                          className={`${selectedProfileMenu === "11" ? "" : "hidden"}`}
                        >
                          <ViewedPhoneProfileTable
                          menu="viewphoneno"
                            title={"Who Viewed My Phone Number"}
                            tableHeader={["Name", "Profile ID", "Viewed Date"]}
                            tableBody={viewedPhoneProfileData}
                            isLoading={isLoading}
                            loadMore={() => {
                              apiCallViewedPhoneProfile(
                                "phonesviewedbyothers",
                                viewedPhoneProfilePage.phonesviewedbyothersPage,
                                "loadmore"
                              );
                            }}
                            callBackNav={(e) =>{
                              setNavList([...navList, "Profile Page"])
                              navigate(RouteHelper.getProfileUrl, { state: { id: e } })
                              setMenuSelect("")
                            }}
                          />
                        </div>
                        }
                        {isV3User && 
                          <div className={`${selectedProfileMenu === "12" ? "" : "hidden"}`}>
                            <HobbiesInterests
                              data={hobbiesInterests}
                              formik={formik}
                              ref={profileRef}
                            />
                          </div>
                        }
                        <div className={`${selectedProfileMenu === "13" ? "" : "hidden"}`}>
                            <KalyanRewards/>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              {isV3User ? null :
              <Footer />
              }
              </>
              :
              <center>
                  <img alt='' src={LoaderGif} />
                </center>
        }
              
               {isV3User ? null :
              <ViewMessageBottomNav />}
              {isCommunitySite ? null : <DownloadAppBar dashboardData={ProfileData}/>}
            </div>
          {/* </div> 
        </div>
      </div>
    </div> */}
    </>
  );
};

export default EditProfile;
