import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../Loader';
import SuccessIcon from '../../../Images/Group 809.svg'
import { validateNumber } from '../../../Utils';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setFamilyCode } from '../../../Store/Chats/Action';
import Constants from '../../../Constants/constants';
import { POST } from '../../../Services/api_services';
import config from '../../../config';
import EndPoints from '../../../Constants/EndPoints';
import CloseIcon from "../../../Images/Group 761@2x.png"
import { dashboardProfileData } from '../../../Store/DashboardAPI/Action';
import * as Yup from "yup";
import PinInput from '../Common/PinInput';
import { useMediaQuery } from 'react-responsive';

function FamilyCodePopup({ close }) {
    const dispatch = useDispatch()
    const [isLoading,setIsLoading] = useState(false)
    const [isCodeSet, setIsCodeSet] = useState(false)
    const closePopupRef = useRef(null);
    const isMobile = useMediaQuery({ maxWidth: 678 });

    const formik = useFormik({
        initialValues: {
            code: ""
        },
        validationSchema : Yup.object().shape({
            code: Yup.string()
            .required("Please Enter Family Code")
            .length(4, "Please enter valid Family Code")
        }),
        onSubmit: (values) => {
            apiCallSubmit(values)
        }
    })

    const apiCallSubmit = async (values) =>{
        setIsLoading(true)
        const request = { 
            "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
            "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
            "familycode" : values.code 
        }
        try{
            let { statusCode, data } = await POST(
                    `${config.api.API_URL}${EndPoints.SetFamilycodeUrl()}`,
                    request
                  );

            if(statusCode === 200){
                setIsLoading(false)
                if(data?.data?.status == "SUCCESS"){
                    setIsCodeSet(true)
                    dispatch(dashboardProfileData());
                }
            }
        }
        catch(error){
            console.log("error on set family match code",error);
        }
    }

    useEffect(()=>{
        if(isMobile){
            let handler = (e) => {
            if(!closePopupRef.current.contains(e.target)){
                close()
            }
            }
            document.addEventListener('mousedown',handler)
        
            return() => {
            document.removeEventListener('mousedown',handler)
            }
        }
      })

    return (
        createPortal(<div className='flex justify-center bg-opacityblack items-end md:items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-[100%] lg:w-[35%] xl:w-[33%] md:my-6 md:mx-6 md:flex md:justify-center md:items-center' ref={closePopupRef}>
                <div className="rounded-tr-[35px] rounded-tl-[35px] shadow-lg relative lg:mt-[0.5rem] lg:mr-[0.5rem] flex flex-col md:w-[70%] lg:w-full bg-white outline-none focus:outline-none md:rounded-[22px] md:py-3 lg:py-1">

                {/* {isCodeSet && */}
                    <button
                        className='hidden md:block bg-transparent z-40 border-0 text-black  absolute  md:top-[-.5rem] md:right-[-0.4rem] lg:right-[-0.4rem] lg:top-[-0.6rem]'
                        onClick={() => {
                        close();
                        }}>
                        <img className='h-6 w-6 ' src={CloseIcon} alt='' />
                    </button>
                {/* } */}
                    {!isCodeSet &&
                    <div className="relative px-6 pt-3 flex-auto justify-center items-center text-center">

                        <p className="text-[18px] text-[#D10A11] mt-2 md:pt-2 lg:pt-1  md:ml-2 font-semibold font-poppins text-center">
                            Set And Share Your Family Code
                        </p>

                    </div>
                    }
                    <div className="flex items-center justify-center relative px-8 md:px-10 md:pb-2 pt-3 lg:py-3 flex-auto ">
                        {/* {formik.isSubmitting ? */}
                        {isLoading ?
                            <div className=" flex items-center justify-center  pb-8 pt-3 lg:py-3 flex-auto  h-[8rem] relative w-[100%] px-10"> <Loader /></div>
                            : isCodeSet ?
                                <div className='relative flex flex-col w-full bg-white '>
                                    {/* <div className='flex items-center py-1 justify-center mx-2  rounded-t '>
                                        <h3 className='text-[20px] text-[#D10A11] ml-2 font-semibold text-center'>
                                            Success
                                        </h3>
                                    </div> */}
                                    <div className='relative p-6 flex-auto'>
                                        <div className='flex flex-col justify-center items-center w-full space-y-2 md:w-full '>
                                            <h3 className='text-[#D10A11] font-bold font-Poppins text-[18px]'>
                                                Success
                                            </h3>
                                            <span className='text-[#777777] font-regular font-Poppins text-[14px]'>
                                                Family code saved successfully
                                            </span>
                                            <img className='w-[2rem] h-[2rem]' alt='' src={SuccessIcon} />
                                        </div>
                                    </div>
                                </div>
                                : <form
                                    onSubmit={formik.handleSubmit}
                                    className="flex flex-col items-center justify-center">
                                    <div className='flex justify-center mb-2'>
                                        <p className='text-[10px] xl:text-[12px] 2xl:text-[14px] md:px-5 lg:px-0 text-black text-center font-medium font-segoe'>Your Family Code enables your family members to login to Kalyan Matrimony and Search, Select, Share, Chat and Call Matches
                                        </p>
                                    </div>
                                    {/* <div className="flex space-x-2 mb-2 ">
                                        <label className="">
                                            <input
                                                name={"code"}
                                                type={"number"}
                                                maxLength="4"
                                                placeholder="Enter Family code"
                                                value={formik.values.code}
                                                onChange={(e) => {
                                                    if (validateNumber(e.target.value)) {
                                                        formik.handleChange(e)
                                                    }
                                                }}
                                                className="no-spinner w-[15rem] p-2.5 my-1  text-sm font-semibold font-Poppins text-[#575556]  rounded-[6px] border border-[#C4C4C4]"
                                            />                                                                                       {formik.errors.code && (
                                            <div className="h-2 mb-2">
                                                    <p className="text-[red] text-[12px]">
                                                        {formik.errors.code}
                                                    </p>
                                            </div>
                                            )}

                                        </label>
                                    </div> */}
                                    <div>
                                    <PinInput
                                        length={4}
                                        onChange={(value) => formik.setFieldValue('code', value)}
                                    />
                                    {formik.errors.code && (
                                        <div className="h-2 mb-2">
                                                <p className="text-[red] text-[12px]">
                                                    {formik.errors.code}
                                                </p>
                                        </div>
                                    )}
                                    </div>

                                    <div className="flex  justify-center items-center space-x-8 w-full lg:px-2 mt-1 mb-2">
                                        {isCodeSet
                                            ? <button
                                                onClick={close}
                                                type="button"
                                                className=" w-full flex items-center justify-center my-1 h-[1.5rem]  text-[#575556] bg-white border border-[#707070] px-[2.3rem] md:px-[2.3rem] lg:px-[2.5rem] whitespace-nowrap rounded-[6px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]">
                                                Close
                                            </button>
                                            : <button
                                                type="submit"
                                                className="flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.5rem] whitespace-nowrap rounded-[6px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                                            >
                                                {isLoading ? "Submitting..." : "Submit"}
                                            </button>}
                                    </div>
                                </form>
                        }
                    </div>
                </div>
            </div>
        </div>,
            document.body
        )
    )
}

export default FamilyCodePopup