import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  memo,
  useRef,
  useLayoutEffect,
} from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

//validation
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

//components
import NavBar from "../Components/NavBar";
import Constants, { EventName } from "../Constants/constants";
import {
  clearDynamicPixel,
  coupenCode,
  dynamicPixel,
  getMemberShipDetail,
  payNow,
} from "../Store/Payment/Action";
import RouteSegments from "../Routes/RouteSegment";
import { CommonPopup, CommonSuccessPopup, PaymentWhatsAppPopup } from "../Components/CommonPopup";
import CallBackForm from "./CallBackForm";
import Advisor from "../Images/Advisor.svg"
import FairPolicyDeatil from "../Components/FairPolicyDeatil";
import { LoginContext } from "../Contexts/LoginContext";
import ViewMessageNav from "../Components/ViewMessageComponents/ViewMessageNav";
import Footer from "../Components/Footer/Footer";
import { GET, POST } from "../Services/api_services";
import { IP_Address } from "../Constants/IpAddress";
import endPoints from "../Constants/EndPoints";
import config from '../config';
import LoaderGif from "../Gif/loader.gif"
import Loader from '../Components/Loader';
import CalendarIcon from "../Images/Group 708.svg"
import MailBoxIcon from "../Images/Group 695.svg"
import Greentick from "../Images/selected.svg"
import ChatBoxIcon from "../Images/Group 696.svg"
import HoroscopeIcon from "../Images/Group 697.svg"
import IncreasedVisibility from "../Images/IncreasedVisibility.svg"
import DownloadAppBar from '../Components/DownloadAppBar';
import $, { event } from 'jquery'
import { dashboardLeftPanelData, dashboardProfileData } from '../Store/DashboardAPI/Action';
import { convertToCurrency } from "../Utils";
import useNewLineDetect from "../Hooks/useNewLineDetect";
import { useMediaQuery } from 'react-responsive';
import { useAnalytics } from "../Hooks/usePageChange";
import EndPoints from "../Constants/EndPoints";
import { registerDetail } from "../Store/Home/Action";
import { setCurrentChat } from "../Store/Chats/Action";
import { NavBarContext } from "../Contexts/NavBarContext";

import WhatsappIcon from "../Images/whatsapp-icon.svg"

export default function MembershipRegister() {
  const dispatch = useDispatch();
  const { loginUserDetail } = useContext(LoginContext);
  const [showCallBackPopup, setShowCallBackPopup] = useState(false);
  const [showCallBackSubmitPopup, setShowCallBackSubmitPopup] = useState(false);
  const [validityPackage, setValidityPackage] = useState([]);
  const [phoneNumberPackage, setPhoneNumberPackage] = useState([]);
  const [phoneNumbersPackage, setPhoneNumbersPackage] = useState([]);
  const [trulyPackage, setTrulyPackage] = useState([]);
  const [premierPack, setPremierPack] = useState([])
  const [validityPackageCoupon, setValidityPackageCoupon] = useState([]);
  const [phoneNumberPackageCoupon, setPhoneNumberPackageCoupon] = useState([]);
  const [phoneNumbersPackageCoupon, setPhoneNumbersPackageCoupon] = useState(
    []
  );
  const [premierPackCoupon, setpremierPackCoupon] = useState([])
  const [trulyPackageCoupon, setTrulyPackageCoupon] = useState([]);
  const [showPolicyPopup, setShowPolicyPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showFailurePopup, setShowFailurePopup] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedAddOnPackage, setSelectedAddOnPackage] = useState("");
  const [selectedOfflinePackage, setSelectedOfflinePackage] = useState("");
  const [whatsAppPackAmount, setWhatsAppPackAmount] = useState("");


  const [isApplied, setIsApplied] = useState(false);
  const [isDefaultSelectedId, setIsDefaltSelectedId] = useState("");
  const [coupenCodeResponse, setCoupenCodeResponse] = useState("");
  const [selectedAddOnPackageAmount, setSelectedAddOnPackageAmount] = useState("0");
  const [selectedOfflinePackageAmount, setSelectedOfflinePackageAmount] = useState("0");
  const [offlineTaskAmount, setOfflineTaskAmount] = useState(0);
  const [selectedPackageAmount, setSelectedPackageAmount] = useState("0");
  const [selectedDiscountCode, setSelectedDiscountCode] = useState("");
  const [paymentToken, setPaymentToken] = useState("");
  const [activeTab, setActiveTab] = useState();
  const [showPayNowErrorMsg, setShowPayNowErrorMsg] = useState(false);
  const [isAddOnSelect, setIsAddOnSelect] = useState(false);
  const [isAstroAddOnSelect, setIsAstroAddOnSelect] = useState(false);
  const [isOfflineAddOnSelect, setIsOfflineAddOnSelect] = useState(false);
  const [selectCupon, setSelectCupon] = useState(false);
  const [packData, setPackData] = useState({
    normalPackage: [],
    addOnPackage: [],
    offlinePackage: [],
  });
  const [isSmoothScrollDone, setSmoothScrollDone] = useState(false);
  console.log(selectedPackageAmount, "selectedPackageAmount");
  const unlimitedTextRef = useRef(null);
  const unlimitedValidityTextRef = useRef(null);
  const unlimitedTrulyTextRef = useRef(null);
  const isV3User = localStorage.getItem("isV3User")
  const isMobile = window.innerWidth <= 411;
  const isMobileView = useMediaQuery({ maxWidth: 768 });

  const [whatsappPopup, setWhatsappPopup] = useState(false);

  const [whatsAppSuccessPopup, setwhatsAppSuccessPopup] = useState(false);

  const [isClose, setIsClose] = useState(false);

  const [from, setFrom] = useState("");
  const [isChecked, setIsChecked] = useState([false, false, false, false]);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const isCommunitySite = localStorage?.getItem(Constants.loginLocalStorageKeys.isCommunitySite);



  const navigate = useNavigate();

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );

  const isPaidUser = userData?.isPaid
  console.log(isPaidUser, 'isPaidUser');


  const { isNewLine: isUnlimitedTextNewLine, refCallback } = useNewLineDetect();
  console.log({ isUnlimitedTextNewLine });
  const [searchParams] = useSearchParams();

  const { onClickTrack } = useAnalytics()

  useEffect(() => {
    if (searchParams?.get("status") == "success") {
      setShowSuccessPopup(true);
    }
    if (searchParams?.get("status") == "failure") {
      setShowFailurePopup(true);
    }
    if (searchParams.get("policy")) {
      setShowPolicyPopup(true);
    }
  }, []);

  useEffect(() => {
    dispatch(
      getMemberShipDetail(
        localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
        localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null : localStorage.getItem(Constants.loginLocalStorageKeys.loginName)
      )
    );
  }, []);

  const { responseData } = useSelector((state) => ({
    responseData: state.Payment?.data?.data,
  }));

  console.log(responseData, "responseData", whatsAppPackAmount);

  const normalPackageDep = packData?.normalPackage.join("");
  const addonPackageDep = packData?.addOnPackage.join("");

  useEffect(() => {
    if (
      selectedDiscountCode != null &&
      selectedDiscountCode !== "" &&
      (packData?.normalPackage?.length > 0 || packData?.offlinePackage?.length > 0)
    ) {
      dispatch(
        coupenCode(
          localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
          packData?.normalPackage?.includes("22") ? packData.normalPackage.concat(packData.addOnPackage) : packData.normalPackage.concat(packData.addOnPackage, packData?.offlinePackage),
          selectedDiscountCode
        )
      );
    }
    // {responseData?.discountCode && packData &&
    //    dispatch(coupenCode(
    //       localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
    //       packData?.normalPackage?.concat(packData?.addOnPackage),
    //       selectedDiscountCode
    //    ))
    // }
  }, [dispatch, normalPackageDep, addonPackageDep, selectedDiscountCode, packData]);

  const { applyCoupenData } = useSelector((state) => ({
    applyCoupenData: state?.Payment?.applyCoupenData?.data,
  }));

  console.log(applyCoupenData, "applyCoupenData");

  useEffect(() => {
    applyCoupenData && applyCouponPriceData();

    if (applyCoupenData?.status == "success") {
      setShowLoader(false);
      setCoupenCodeResponse(applyCoupenData);
    } else if (
      applyCoupenData?.status ==
      "Discount amount is greater than choosen amount"
    ) {
      alert(applyCoupenData?.status);
      // setCoupenCodeResponse("");
      setCoupenCodeResponse(applyCoupenData);
      setShowLoader(false);
    } else if (applyCoupenData?.status == "Invalid") {
      // setCoupenCodeResponse("");
      setCoupenCodeResponse(applyCoupenData);
      setShowLoader(false);
    }
  }, [applyCoupenData]);

  console.log(coupenCodeResponse, "coupon");

  const callBackPopup = () => {
    setShowCallBackPopup(true);
  };

  const close = () => {
    setShowCallBackPopup(false);
    setShowCallBackSubmitPopup(false);
    setShowPolicyPopup(false);
    setShowSuccessPopup(false);
    setShowFailurePopup(false);
  };

  const callBackSubmit = () => {
    setShowCallBackPopup(false);
    setShowCallBackSubmitPopup(true);
  };

  //console.log(responseData,"responseData");

  let constPhoneNumbersCoupon = [];
  let constValidityPackageCoupon = [];
  let constPhoneNumberPackageCoupon = [];
  let constTrulyPackageCoupon = [];
  let premierPackageCoupon = [];

  const applyCouponPriceData = () => {
    applyCoupenData?.subscriptionPackages?.map((data, i) => {
      if (data.membershipId == "3") {
        constValidityPackageCoupon.push(data);
      } else if (data.membershipId == "16") {
        constPhoneNumberPackageCoupon.push(data);
      } else if (data.membershipId == "19") {
        constPhoneNumbersCoupon.push(data);
      } else if (data.membershipId == "21") {
        constTrulyPackageCoupon.push(data);
      } else if (data.membershipId == "22") {
        premierPackageCoupon.push(data);
      }

      console.log(
        constValidityPackageCoupon,
        constPhoneNumbersCoupon,
        constPhoneNumberPackageCoupon,
        constTrulyPackageCoupon,
        "karthick"
      );
      setValidityPackageCoupon(constValidityPackageCoupon);
      setPhoneNumbersPackageCoupon(constPhoneNumbersCoupon);
      setPhoneNumberPackageCoupon(constPhoneNumberPackageCoupon);
      setTrulyPackageCoupon(constTrulyPackageCoupon);
      setpremierPackCoupon(premierPackageCoupon)
    });
  };

  useEffect(() => {
    responseData && packageData();
    if(responseData){
      localStorage.setItem('assistId', responseData?.paymentassistanceid)
      localStorage.setItem("isRefreshed", true);
    }
  }, [responseData]);

  let constPhoneNumbers = [];
  let constValidityPackage = [];
  let constPhoneNumberPackage = [];
  let constTrulyPackage = [];
  let premierPackage = [];
  const packageData = () => {
    responseData?.subscriptionPackages?.map((data, i) => {
      if (data.membershiptype == "Limited Pack*") {
        constValidityPackage.push(data);
      } else if (data.membershiptype == "Unlimited Validity Pack*") {
        constPhoneNumberPackage.push(data);
      } else if (data.membershiptype == "Truly Unlimited Pack*") {
        constTrulyPackage.push(data);
      } else if (data.membershiptype == "Unlimited Phone Number Pack*") {
        constPhoneNumbers.push(data);
      } else if (data.membershiptype == "Kalyan Premier*") {
        premierPackage.push(data);
      }
      if (data?.defaultpack == "Y") {
        setIsDefaltSelectedId(data?.membershipId);
        // setSelectedPackageAmount(data?.price);

        setSelectedPackageAmount(parseInt(data?.price) + parseInt(responseData?.AddonPackages[1]?.price));
        setPackData({
          ...packData,
          normalPackage: [data.membershipId],
          offlinePackage: ["23"]
        });
        setSelectedPackage(data.membershiptype + "- ₹ " + convertToCurrency(data?.price));
        // setSelectedPackage(data.membershiptype+ data.viewPhoneNumber)
        setIsApplied(true);
      }
      if (responseData?.discountCode?.length > 0) {
        setSelectedDiscountCode(
          responseData?.discountCode[responseData?.discountCode?.length - 1]
            .amount
        );
      }
      //console.log(constValidityPackage, constPhoneNumberPackage, constTrulyPackage,"fytfghdcfx");
      setValidityPackage(constValidityPackage);
      setPhoneNumbersPackage(constPhoneNumbers);
      setPhoneNumberPackage(constPhoneNumberPackage);
      setTrulyPackage(constTrulyPackage);
      setPremierPack(premierPackage)
    });
    setWhatsAppPackAmount(responseData?.AddonPackages[1]?.price)
  };

  console.log(validityPackage, "validityPackagevalidityPackage");
  useEffect(() => {

  }, [isDefaultSelectedId])

  const packageSelect = (id, type, view, price, index, event) => {
    // if(responseData?.discountCode?.length>0){
    //    setSelectedDiscountCode(responseData?.discountCode[responseData?.discountCode?.length-1].amount)
    // }
    if (isPaidUser == "Y") {
      if (id == 3) {
        setIsChecked([true, false, false, false])
      }
      else if (id == 16) {
        setIsChecked([false, true, false, false])
      }
      else if (id == 19) {
        setIsChecked([false, false, true, false])
      }
      else if (id == 21) {
        setIsChecked([false, false, false, true])
      } else if (id == 22) {
        setIsChecked([false, false, false, false])
      }
    }
    else {
      const updatedIsChecked = isChecked.map((value, i) => (i === index ? !value : false));
      setIsChecked(updatedIsChecked);
    }
    let offlineAmount = selectedOfflinePackageAmount;
    if (id != "22") {
      offlineAmount = 1100;
      setOfflineTaskAmount(1100)
    }
    else {
      offlineAmount = 0;
      setOfflineTaskAmount(0)
    }
    setShowPayNowErrorMsg(false);
    setIsDefaltSelectedId(id);

    var selectedId = {
      normalPackage: [],
      addOnPackage: packData.addOnPackage,
      offlinePackage: ["23"]
    };

    // const updatedIsChecked = isChecked.map((value, i) => (i === index ? true : false));
    // const updatedIsChecked = isChecked.map((value, i) => (i === index ? !value : false));

    // setIsChecked(updatedIsChecked);
    localStorage.setItem("isClose", 23)
    if (isChecked[index]) {
      setSelectedOfflinePackage("")
    }
    else {
      setSelectedOfflinePackage("Matches via WhatsApp " + "- ₹ " + convertToCurrency(1100));
    }
    // setSelectedOfflinePackage("Offline Service Pack " + "- ₹ " + convertToCurrency(1100));

    selectedId.normalPackage = [id];
    setIsApplied(true);
    if (event == undefined || event.target.checked) {
      setSelectedPackageAmount(
        parseInt(price) + parseInt(selectedAddOnPackageAmount) + parseInt(offlineAmount)
      );

      setSelectedOfflinePackage("Matches via WhatsApp " + "- ₹ " + convertToCurrency(1100));
    } else {
      setSelectedPackageAmount(parseInt(price) + parseInt(selectedAddOnPackageAmount));
      selectedId.offlinePackage = []
    }

    setSelectedPackage(type + "- ₹ " + convertToCurrency(price));

    //remove selected add on if user select premier pack
    if (id == "22") {
      setSelectedAddOnPackage("")
      setSelectedAddOnPackageAmount("0");
      setSelectedOfflinePackage("")
      setSelectedOfflinePackageAmount("0");
      setSelectedPackageAmount(parseInt(price));
      selectedId.addOnPackage = []
      setIsAddOnSelect(false)
      setIsOfflineAddOnSelect(false)
    }

    setPackData(selectedId);
  };


  useEffect(() => {
    if (isPaidUser == "N") {

      setIsChecked([false, false, false, true])
      setSelectedOfflinePackageAmount(responseData?.AddonPackages[1]?.price)
      setSelectedOfflinePackage("Matches via WhatsApp " + "- ₹ " + convertToCurrency(responseData?.AddonPackages[1]?.price));
      applyCouponPriceData()

    }
  }, [isPaidUser, responseData])
  // console.log(selectedPackageAmount,'lpl');

  const [currentIndex, setCurrentIndex] = useState()

  const offlineSelect = (event, isSelect, id, price, index) => {

    const updatedIsChecked = isChecked.map((value, i) => (i == index ? !value : false));

    setIsChecked(updatedIsChecked);

    // console.log(isChecked,'loiu')
    setIsOfflineAddOnSelect(!isOfflineAddOnSelect)

    if (isChecked[index]) {
      setSelectedOfflinePackageAmount("0")
      // setSelectedOfflinePackage(false);
      setSelectedOfflinePackage("");
      setSelectedPackageAmount(parseInt(selectedPackageAmount) - parseInt(price));

    } else {
      if (isDefaultSelectedId == 22) {
        const kalyanPremierPack = responseData?.subscriptionPackages[4]
        setSelectedPackage("")
        setSelectedPackageAmount("0")
        setIsDefaltSelectedId("")
        if (offlineTaskAmount > 0) {
          setSelectedPackageAmount(parseInt(selectedPackageAmount) - kalyanPremierPack.price);
        }
        else {
          setSelectedPackageAmount(parseInt(selectedPackageAmount) + parseInt(price) - kalyanPremierPack.price);
        }
      }
      else {
        if (offlineTaskAmount > 0) {
          setSelectedPackageAmount(parseInt(selectedPackageAmount));
        }
        else {
          setSelectedPackageAmount(parseInt(selectedPackageAmount) + parseInt(price));
        }
      }
      setSelectedOfflinePackageAmount(price);
      setShowPayNowErrorMsg(false);

      setIsApplied(true);
      localStorage.setItem("isClose", id)

      setSelectedOfflinePackage("Matches via WhatsApp " + "- ₹ " + convertToCurrency(price));
    }
    if (event.target.checked) {
      setOfflineTaskAmount(price)
      if (isDefaultSelectedId == 22) {
        setPackData({
          ...packData,
          normalPackage: [],
          offlinePackage: ["23"],
        });
      }
      else {

        let normalpack = [];
        if (index == 0 && packData.normalPackage.includes("3")) {
          // normalpack = packData.normalPackage;
        } else if (index == 1 && packData.normalPackage.includes("16")) {
          // normalpack = packData.normalPackage;
        } else if (index == 2 && packData.normalPackage.includes("19")) {
          // normalpack = packData.normalPackage;
        } else if (index == 3 && packData.normalPackage.includes("21")) {
          // normalpack = packData.normalPackage;
        } else {
          setIsDefaltSelectedId("")
          setSelectedPackage("")
          setSelectedPackageAmount(
            parseInt(price) + parseInt(selectedAddOnPackageAmount)
          );
        }
        setPackData({
          ...packData,
          normalPackage: index == 0 && packData.normalPackage.includes("3") || index == 1 && packData.normalPackage.includes("16") || index == 2 && packData.normalPackage.includes("19") || index == 3 && packData.normalPackage.includes("21") ? packData.normalPackage : [],
          offlinePackage: ["23"],
        });
      }

    }
    else {
      setOfflineTaskAmount(0)
      if (isDefaultSelectedId == 22) {
        setPackData({
          ...packData,
          normalPackage: [],
          offlinePackage: ["23"],
        });
      }
      else {
        setPackData({
          ...packData,
          offlinePackage: [],
        });
      }
    }

    setCurrentIndex(index)

    setIsOfflineAddOnSelect(!isOfflineAddOnSelect)

  };

  // useEffect(() => {
  //   // if(isPaidUser == "N"){
  //     offlineSelect({ target: { checked: true } ,isOfflineAddOnSelect,
  //     id : responseData?.AddonPackages[1].membershipId,
  //     price : responseData?.AddonPackages[1].price, index : 3 });
  //   // }
  // }, []);

  const addOnSelectWeb = (isSelect, id, price) => {
    setSelectCupon(true);
    var selectedId = {
      normalPackage: packData.normalPackage,
      addOnPackage: [],
      offlinePackage: packData.offlinePackage
    };
    if (packData.addOnPackage.indexOf(id) > -1) {
      selectedId.addOnPackage = [];
    } else {
      selectedId.addOnPackage = [id];
    }
    setPackData(selectedId);
    //console.log(packData,"12345678934567890");
    if (isSelect) {
      setSelectedAddOnPackageAmount("0");
      setSelectedAddOnPackage(false);
      setSelectedPackageAmount(
        parseInt(selectedPackageAmount) - parseInt(price)
      );
      if (selectedId.normalPackage?.length > 0) {
        setIsApplied(true);
        setSelectCupon(false);
      } else {
        setIsApplied(false);
        setShowPayNowErrorMsg(false);
        setSelectedAddOnPackage();
        setSelectCupon(false);
      }
    } else {
      setSelectedAddOnPackageAmount(price);
      setShowPayNowErrorMsg(false);
      setSelectedPackageAmount(
        parseInt(selectedPackageAmount) + parseInt(price)
      );
      setIsApplied(true);
      setSelectedAddOnPackage("Horoscope Package " + "- ₹ " + convertToCurrency(price));
      setSelectCupon(false);
    }
    setIsAddOnSelect(!isAddOnSelect);
  };
  //   const addOnSelectWeb = (isSelect, id, price) => {
  //     setSelectCupon(true);
  //     const updatedPackData = { ...packData };

  //     const updatedSelectedId = {
  //         normalPackage: updatedPackData.normalPackage,
  //         addOnPackage: [],
  //     };

  //     const index = updatedPackData.addOnPackage.indexOf(id);
  //     if (index > -1) {
  //         updatedSelectedId.addOnPackage = updatedPackData.addOnPackage.filter(item => item !== id);
  //     } else {
  //         updatedSelectedId.addOnPackage = [...updatedPackData.addOnPackage, id];
  //     }

  //     setPackData(updatedSelectedId);
  //     console.log(updatedSelectedId.addOnPackage.length,"loiu");
  //     // Update other state variables based on the condition
  //     if (isSelect) {
  //         setSelectedAddOnPackageAmount("0");
  //         setSelectedAddOnPackage(false);
  //         setSelectedPackageAmount(parseInt(selectedPackageAmount) - parseInt(price));
  //         setIsApplied(updatedSelectedId.normalPackage?.length > 0);
  //     } else {
  //         setSelectedAddOnPackageAmount(price);
  //         setShowPayNowErrorMsg(false);
  //         setSelectedPackageAmount(parseInt(selectedPackageAmount) + parseInt(price));
  //         setIsApplied(true);

  //         // Set selectedAddOnPackage content based on the provided IDs
  //         if (updatedSelectedId.addOnPackage.length == 1) {
  //             if (updatedSelectedId.addOnPackage[0] == 23) {
  //                 setSelectedAddOnPackage("Offline Service Pack " + "- ₹ " + convertToCurrency(price));
  //             } else if (updatedSelectedId.addOnPackage[0] == 8) {
  //                 setSelectedAddOnPackage("Horoscope Package " + "- ₹ " + convertToCurrency(price));
  //             } else {
  //                 setSelectedAddOnPackage("--");
  //             }
  //         } else if (updatedSelectedId.addOnPackage.length == 2) {
  //             setSelectedAddOnPackage("Offline Service Pack " + "- ₹ " + convertToCurrency(price),"Horoscope Package " + "- ₹ " + convertToCurrency(price));
  //         } else {
  //             setSelectedAddOnPackage("No Content");
  //         }
  //     }

  //     setSelectCupon(false);
  //     setIsAddOnSelect(!isAddOnSelect);
  // };


  const addOnSelectMob = (event, id, price) => {
    // if(responseData?.discountCode?.length>0){
    //    setSelectedDiscountCode(responseData?.discountCode[responseData?.discountCode?.length-1].amount)
    // }
    setSelectCupon(true);
    var selectedId = {
      normalPackage: packData.normalPackage,
      addOnPackage: [],
      offlinePackage: packData.offlinePackage
    };
    if (packData.addOnPackage.indexOf(id) > -1) {
      selectedId.addOnPackage = [];
    } else {
      selectedId.addOnPackage = [id];
    }
    setPackData(selectedId);
    //console.log(packData,"12345678934567890");
    if (event.target.checked) {
      setSelectedAddOnPackageAmount(price);
      setShowPayNowErrorMsg(false);
      setSelectedPackageAmount(
        parseInt(selectedPackageAmount) + parseInt(price)
      );
      setIsApplied(true);
      setSelectedAddOnPackage("Horoscope Package " + "- ₹ " + convertToCurrency(price));
      setSelectedAddOnPackage(convertToCurrency(price));
      setSelectCupon(false);
    } else {
      setSelectedAddOnPackageAmount("0");
      setSelectedAddOnPackage(false);
      setSelectedPackageAmount(
        parseInt(selectedPackageAmount) - parseInt(price)
      );
      if (selectedId.normalPackage?.length > 0) {
        setIsApplied(true);
        setSelectCupon(false);
      } else {
        setIsApplied(false);
        setShowPayNowErrorMsg(false);
        setSelectedAddOnPackage();
        setSelectCupon(false);
      }
    }
    setIsAddOnSelect(!isAddOnSelect);
  };
  //Error msg for apply coupen form
  const DisplayingErrorMessagesSchema = Yup.object().shape({
    discountCode: Yup.string().required(" Choose any Package"),
  });

  // form submit
  const ApplyCoupenFormSubmission = (values) => {
  };


  const discountCodeClick = (code) => {
    if (selectedDiscountCode == code) {

    } else {
      setSelectedDiscountCode(code);
      setShowLoader(true);
    }
    // dispatch(
    //   coupenCode(
    //     localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
    //     packData?.normalPackage?.concat(packData?.addOnPackage),
    //     code
    //   )
    // );
  };

  const payNowClicked = () => {
    if (
      packData.normalPackage?.length > 0 ||
      packData.addOnPackage?.length > 0 ||
      packData.offlinePackage?.length > 0
    ) {
      setShowLoader(true);
      sessionStorage.removeItem('isRefreshed');
      localStorage.removeItem("isRefreshed");
      paymentVisitTime();
      dispatch(
        payNow(
          localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
          packData?.normalPackage?.includes("22") ? packData.normalPackage.concat(packData.addOnPackage) : packData.normalPackage.concat(packData.addOnPackage, packData?.offlinePackage),
          selectedDiscountCode
        )
      );
    } else {
      setShowPayNowErrorMsg(true);
    }
  };

  const { payNowResponse } = useSelector((state) => ({
    payNowResponse: state?.Payment?.payNowData,
  }));

  useEffect(() => {
    if (payNowResponse?.status == "Success") {
      // fetchGroupData()
      setShowLoader(false);
      setPaymentToken(payNowResponse.data.paymentToken);
      if (payNowResponse?.data?.paymentToken) {
        window.location.href = payNowResponse.data.paymentToken;
      }
    }
  }, [payNowResponse]);

  const [dashboard1Data, setDashboard1Data] = useState([]);
  const { loginDetail, logout } = useContext(LoginContext);

  //   const apicallDashboard = async () => {
  //    let request = {
  //      email: loginDetail()[0],
  //      userId: loginDetail()[1],
  //      pageno: "1",
  //      status: "new",
  //    };
  //    let { statusCode, data } = await POST(
  //      `${config.api.API_URL}${endPoints.dashboardUrl()}`,
  //      request
  //    );

  //    if (statusCode === 200) {
  //      setDashboard1Data(data?.data);
  //    } else if (statusCode === 401) {
  //      logout();
  //    }
  //  };

  useEffect(() => {
    dispatch(dashboardLeftPanelData())
    dispatch(dashboardProfileData())
    dispatch(registerDetail());
  }, [])

  //Left Panel API Call and Response  
  const { leftPanelData } = useSelector(state => ({
    leftPanelData: state.DashBoardAPI?.leftPanelData?.data
  }));

  //Profile Data API Call and Response  
  const { ProfileData } = useSelector(state => ({
    ProfileData: state.DashBoardAPI?.ProfileData?.data
  }));

  // useEffect(() => {
  //    if(localStorage.getItem(Constants.loginLocalStorageKeys.isFirstLogin)){
  //       //only enable in LIVE if client asked
  //       if(localStorage.getItem(Constants.loginLocalStorageKeys.verificationLoginFrom) !== "partial"){
  //          dispatch(dynamicPixel("data"))
  //       }
  //       localStorage.removeItem(Constants.loginLocalStorageKeys.verificationLoginFrom)
  //       localStorage.removeItem(Constants.loginLocalStorageKeys.isFirstLogin)
  //    }
  // },[])





  // let { dynmaicPixelData } = useSelector(state => ({
  //    dynmaicPixelData: state.Payment?.dynmaicPixelData?.data
  //  }));  

  //  useEffect(() => {
  //    if(dynmaicPixelData?.pixels){
  //       setConversionScript(dynmaicPixelData?.pixels)
  //    }
  //   }, [dynmaicPixelData?.pixels])

  //  console.log(dynmaicPixelData?.pixels,"dynmaicPixelData");

  // useEffect(() => {
  //    dynmaicPixelData=""
  // },[dynmaicPixelData])

  const scriptToInject = `
`

  const conversionScript = `
`


  // useEffect(() => {
  //    if (dynmaicPixelData?.pixels) {
  //       if ($('.pixelScript').length > 0) {
  //          $('.pixelScript').remove()
  //          $('head').append(conversionScript)
  //       } else {
  //          $('head').append(conversionScript)
  //       }
  //    } else {
  //       if ($('.pixelScript').length > 0) {
  //          $('.pixelScript').remove()
  //          $('head').append(scriptToInject)
  //       } else {
  //          $('head').append(scriptToInject)
  //       }
  //    }

  //    return () => {
  //       $('.pixelScript').remove()
  //    }

  // }, [conversionScript, dynmaicPixelData?.pixels])

  useEffect(() => {

    return () => {
      dispatch(clearDynamicPixel())
      $('.pixelScript').remove()
    }
  }, [dispatch])





  // useEffect(() => {
  //   apicallDashboard();
  // }, []);


  const payNowPopupClicked = () => {
    if (!isSmoothScrollDone) {
      const addonPackSection = document.getElementById('addonPackSection');
      if (addonPackSection) {
        addonPackSection.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        setSmoothScrollDone(true);
      }
    } else {
      dispatchPayNow();
    }
  };

  const dispatchPayNow = () => {
    if (packData.normalPackage?.length > 0 || packData.addOnPackage?.length > 0 || packData.offlinePackage?.length > 0) {
      setShowLoader(true);
      sessionStorage.removeItem('isRefreshed');
      localStorage.removeItem("isRefreshed");
      paymentVisitTime();
      dispatch(
        payNow(
          localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
          packData?.normalPackage?.includes("22") ? packData.normalPackage.concat(packData.addOnPackage) : packData.normalPackage.concat(packData.addOnPackage, packData?.offlinePackage),
          selectedDiscountCode
        )
      );
    } else {
      setShowPayNowErrorMsg(true);
    }
  };

  const paynowClick = () => {
    dispatchPayNow();
  };

  const RupeeSymbol = () => <span className="rupeefont">₹</span>;

  console.log(isDefaultSelectedId, 'lio', selectedOfflinePackage);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sessionStorage.setItem('isRefreshed', 'true');
    };

    const checkRefresh = () => {
      if (sessionStorage.getItem('isRefreshed') && localStorage.getItem("isRefreshed")) {
        setIsRefreshed(true);
        sessionStorage.removeItem('isRefreshed');
        paymentVisitTime();
      } else {
        setIsRefreshed(false);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    checkRefresh();

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const paymentVisitTime =  async () => {

    let { statusCode, data } =  await GET(
      `${config.api.API_URL}${EndPoints.payemtPageVisitEndtime(localStorage.getItem('assistId'))}`,
    );

    if (statusCode === 200) {
      if (data.status === "Success") {
        
      }
    } else if (statusCode === 401) {
      
    }
  }
  

  return (
    <>
      {isV3User ? null :
        <NavBar data={leftPanelData} ProfileData={ProfileData} />
      }

      {whatsappPopup &&
        <PaymentWhatsAppPopup
          heading={"Transaction Successful !"}
          title={"Add Phone Number !"}
          close={() => setWhatsappPopup(false)}
          isClose={isClose}
        />
      }

      {whatsAppSuccessPopup && (
        <CommonSuccessPopup
          close={() => {
            setwhatsAppSuccessPopup(false)
            if (from == "addmore") {
              navigate(RouteSegments.MATCH_GROUP, { state: { isFrom: "payment" } })
            }
          }}
          title={"Success!"}
          content={"WhatsApp Number Saved Successfully"}
        />
      )}

      {showCallBackPopup && (
        <CommonPopup title="Request Call Back" close={close}>
          <CallBackForm
            callBackSubmit={callBackSubmit}
            mobileNumber={localStorage.getItem(
              Constants.loginLocalStorageKeys.loginPhone
            )}
          />
        </CommonPopup>
      )}
      {showCallBackSubmitPopup && (
        <CommonPopup title="Request Call Back" close={close}>
          <p className="w-[19rem] md:w-[100%] text-center">
            Thank you ,our team will call you shortly
          </p>
        </CommonPopup>
      )}
      {isDefaultSelectedId && (
        <div className={selectedAddOnPackage ? " md:hidden fixed bottom-0 left-0 right-0  bg-[#E9E9E9] z-[1]  py-1 px-4   rounded-tr-[35px] rounded-tl-[35px] shadow-lg" :
          "md:hidden fixed bottom-0 left-0  mb-3 right-0 h-[12rem] bg-[#E9E9E9] z-[1]  py-1 px-4   rounded-tr-[35px] rounded-tl-[35px] shadow-lg"}
          style={{ boxShadow: '0 -11px 12px -3px #00000029' }}>
          <div className="space-y-3 ">
            <div className="justify-start items-start grid grid-cols-12  mt-[1rem] ">
              <div className=" col-span-5 flex justify-start text-[#575556] text-[13px]  font-semibold whitespace-nowrap">
                <p> Selected Pack </p>
              </div>

              <div className=" col-span-7 flex justify-start items-center">
                {isV3User ?
                  <p className={isDefaultSelectedId == premierPack[0]?.membershipId ? "  font-bold  text-[#000000] text-[14px] w-[13rem] " :
                    isDefaultSelectedId == trulyPackage[0]?.membershipId ? "font-bold  text-[#000000] text-[14px] w-[10rem] " : "font-bold  text-[#000000] text-[15px] w-[11rem]"}>
                    {" "}
                    {typeof selectedPackage === 'string' && selectedPackage.includes("₹") ? (
                      <>
                        {selectedPackage.split("₹")[0]}
                        <RupeeSymbol />
                        {selectedPackage.split("₹").slice(1).join("₹")}
                      </>
                    ) : (
                      selectedPackage
                    )} </p>
                  :
                  <p className={isDefaultSelectedId == premierPack[0]?.membershipId ? "  font-bold  text-[#000000] text-[15px] w-[13rem] " :
                    isDefaultSelectedId == trulyPackage[0]?.membershipId ? "font-bold  text-[#000000] text-[15px] w-[10rem] " : "font-bold  text-[#000000] text-[15px] w-[11rem]"}>
                    {" "}
                    {selectedPackage} {" "}

                  </p>
                }
              </div>
            </div>
            {selectedAddOnPackage || selectedOfflinePackage ? <div className=" grid grid-cols-12 mt-[2rem]">
              <div className=" col-span-5 flex justify-start text-[#575556] text-[13px] font-semibold whitespace-nowrap">
                <p> Selected Add-on </p>
              </div>

              <div className=" col-span-7 flex justify-start items-center">
                {isV3User ?
                  <p className=" font-bold  text-[#000000]  text-[15px] w-[82%]">
                    {typeof selectedAddOnPackage === 'string' && selectedAddOnPackage.includes("₹") || selectedOfflinePackage == 'string' && selectedOfflinePackage.includes("₹") ? (
                      <>
                        {selectedAddOnPackage?.split("₹")[0]}
                        <RupeeSymbol />
                        {selectedAddOnPackage.split("₹").slice(1).join("₹")}
                        {selectedAddOnPackage}
                        {selectedOfflinePackage && selectedAddOnPackage ? <br /> : " "}
                        <p>{selectedOfflinePackage}</p>
                      </>
                    ) : (
                      <>
                        {isAddOnSelect ? responseData?.AddonPackages[0].membershiptype + " " + "-"+" " : ""}
                        {isAddOnSelect ? <RupeeSymbol /> : ""}
                        {" "}{selectedAddOnPackage}
                        {/* {isAddOnSelect ? responseData?.AddonPackages[0].membershiptype + "-" + <RupeeSymbol /> + selectedAddOnPackage : ""} */}


                        {selectedOfflinePackage && selectedAddOnPackage ? <br /> : " "}
                        {selectedOfflinePackage.split("₹")[0]}{selectedOfflinePackage || selectedAddOnPackage ? <RupeeSymbol />:""}{selectedOfflinePackage.split("₹").slice(1).join("₹")}
                      </>
                    )}
                  </p>
                  :
                  <p className=" font-bold  text-[#000000]  text-[14px] whitespace-nowrap">
                    {selectedAddOnPackage}{" "}
                  </p>
                }
              </div>
            </div>
              : ""}
            <div className=" grid grid-cols-12 mt-1 ">
              <div className=" col-span-5 flex justify-start items-center text-[#575556] font-semibold text-[13px]  whitespace-nowrap">
                <p>  Amount Payable </p>
              </div>
              {/* <div className="col-span-1 flex justify-center"><p className=" flex items-center "></p></div> */}
              <div className=" col-span-7 flex justify-start items-center font-bold text-[14px]">
                {isDefaultSelectedId == trulyPackage[0]?.membershipId && selectedDiscountCode ? "" :
                  isDefaultSelectedId == trulyPackage[0]?.membershipId ? trulyPackage[0]?.topprice &&
                    <span className="text-[#000000] text-[15px] font-bold line-through mr-2 ">
                      <span className="rupeefont">₹ </span>{convertToCurrency(parseInt(trulyPackage[0]?.topprice) + parseInt(selectedAddOnPackageAmount) + (selectedOfflinePackage ? whatsAppPackAmount : 0))}
                    </span>
                    : ""}
                <span className="gap-2">{coupenCodeResponse?.amount && (

                  <span className=" font-bold  text-[#000000] text-[15px]  line-through  ">
                    <span className="rupeefont">₹ </span>{convertToCurrency(selectedPackageAmount)}
                  </span>

                )}
                  <span className="font-bold  text-[#000000] text-[15px] ">
                    {" "}
                    <span className="rupeefont">₹ </span>{" "}
                    {convertToCurrency(
                      coupenCodeResponse?.amount
                        ? coupenCodeResponse.amount
                        : selectedPackageAmount
                    )}{" "}

                  </span>
                </span>


              </div>

            </div>
          </div>
          <div className=" flex justify-center pt-1 mb-[0.6rem] ">
            {isDefaultSelectedId ==
              premierPack[0]?.membershipId || isAddOnSelect || isOfflineAddOnSelect ? (
              <div className="flex justify-end items-end h-[3rem]">
                <button
                  onClick={() => {
                    paynowClick();
                    onClickTrack(EventName.SelectPaynowButton);
                  }}
                  className=" bg-[#D10A11] px-[3rem]   text-center  py-[0.4rem]  text-white font-bold text-[16px] rounded-[8px]  "
                >
                  Pay Now
                </button>
              </div>
            )
              : (
                <div className="flex justify-end items-end h-[3rem]">
                  <button
                    // onClick={payNowPopupClicked}
                    onClick={() => {
                      payNowPopupClicked();
                      onClickTrack(EventName.SelectPaynowButton);
                    }}
                    className=" bg-[#D10A11] px-[3rem]   text-center  py-[0.4rem]  text-white font-bold text-[16px] rounded-[8px]  "
                  >
                    Pay Now
                  </button>
                </div>
              )
            }

          </div>
        </div>
      )}

      {isDefaultSelectedId == "22" ? "" : isDefaultSelectedId == "21" ? "" :
        isDefaultSelectedId == "19" ? "" :
          isDefaultSelectedId == "16" ? "" :
            isDefaultSelectedId == "3" ? "" :
              isAddOnSelect || isOfflineAddOnSelect || selectedOfflinePackage != false ?
                (
                  <div className="md:hidden fixed bottom-0 left-0 right-0  bg-[#E9E9E9] z-10  py-1 px-4   rounded-tr-[35px] rounded-tl-[35px] shadow-lg" style={{ boxShadow: '0 -11px 12px -3px #00000029' }}>
                    <div className="space-y-3 "><div className="justify-start items-start grid grid-cols-12  mt-[1rem]">
                      <div className=" col-span-5 flex justify-start text-[#575556] text-[14px]  font-semibold whitespace-nowrap">
                        <p> Selected Pack </p>
                      </div>

                      <div className=" col-span-7 flex justify-start items-center">
                        {isV3User ?
                          <p className={isDefaultSelectedId == premierPack[0]?.membershipId ? "  font-bold  text-[#000000] text-[15px] w-[13rem] " :
                            isDefaultSelectedId == trulyPackage[0]?.membershipId ? "font-bold  text-[#000000] text-[15px] w-[10rem] " : "font-bold  text-[#000000] text-[15px] w-[11rem]"}>
                            {" "}
                            {typeof selectedPackage === 'string' && selectedPackage.includes("₹") ? (
                              <>
                                {selectedPackage.split("₹")[0]}
                                <RupeeSymbol />
                                {selectedPackage.split("₹").slice(1).join("₹")}
                              </>
                            ) : (
                              selectedPackage
                            )} </p>
                          :
                          <p className={isDefaultSelectedId == premierPack[0]?.membershipId ? "  font-bold  text-[#000000] text-[15px] w-[13rem] " :
                            isDefaultSelectedId == trulyPackage[0]?.membershipId ? "font-bold  text-[#000000] text-[15px] w-[10rem] " : "font-bold  text-[#000000] text-[15px] w-[11rem]"}>
                            {" "}
                            {selectedPackage} {" "}

                          </p>
                        }
                      </div>
                    </div>
                      <div className=" grid grid-cols-12 mt-[2rem] ">
                        <div className=" col-span-5 flex justify-start text-[#575556] text-[14px] font-semibold whitespace-nowrap">
                          <p> Selected Add-on </p>
                        </div>

                        <div className=" col-span-7 flex justify-start items-center">
                          {isV3User ?
                            <p className=" font-bold  text-[#000000]  text-[15px] w-[87%]">
                              {typeof selectedAddOnPackage === 'string' && selectedAddOnPackage.includes("₹") || selectedOfflinePackage === 'string' && selectedOfflinePackage.includes("₹") ? (
                                <>
                                  {selectedAddOnPackage?.split("₹")[0]}
                                  <RupeeSymbol />
                                  {selectedAddOnPackage.split("₹").slice(1).join("₹")}
                                  {selectedOfflinePackage && selectedAddOnPackage ? <br /> : " "}
                                  {selectedOfflinePackage}


                                </>
                              ) : (
                                <>
                                 {isAddOnSelect ? responseData?.AddonPackages[0].membershiptype + " " + "-" : ""}
                        {isAddOnSelect ? <RupeeSymbol /> : ""}
                        {" "}{selectedAddOnPackage}
                                  {/* {selectedAddOnPackage} */}
                                  {selectedOfflinePackage && selectedAddOnPackage ? <br /> : " "}
                                  {selectedOfflinePackage.split("₹")[0]}{selectedOfflinePackage || selectedAddOnPackage ? <RupeeSymbol />:""}{selectedOfflinePackage.split("₹").slice(1).join("₹")}
                                </>
                              )}
                            </p>
                            :
                            <p className=" font-bold  text-[#000000]  text-[15px] whitespace-nowrap">
                              {" "}
                              {selectedAddOnPackage}{" "}
                            </p>
                          }
                        </div>
                      </div>
                      <div className=" grid grid-cols-12 ">
                        <div className=" col-span-5 flex justify-start items-center text-[#575556] font-semibold text-[14px]  whitespace-nowrap">
                          <p>  Amount Payable </p>
                        </div>
                        {/* <div className="col-span-1 flex justify-center"><p className=" flex items-center "></p></div> */}
                        <div className=" col-span-7 flex justify-start items-center font-bold text-[16px]">

                          {isDefaultSelectedId == trulyPackage[0]?.membershipId && selectedDiscountCode ? "" :
                            isDefaultSelectedId == trulyPackage[0]?.membershipId ? trulyPackage[0]?.topprice &&
                              <span className="text-[#000000] text-[16px] font-bold line-through mr-2 ">
                                <span className="rupeefont">₹ </span> {convertToCurrency(parseInt(trulyPackage[0]?.topprice) + parseInt(selectedAddOnPackageAmount) + (selectedOfflinePackage ? whatsAppPackAmount : 0))}
                              </span>
                              : ""}
                          <span className="gap-2">{coupenCodeResponse?.amount && (

                            <span className=" font-bold  text-[#000000] text-[16px]  line-through  ">
                              <span className="rupeefont">₹ </span>{convertToCurrency(selectedPackageAmount)}
                            </span>

                          )}
                            <span className="font-bold  text-[#000000] text-[16px] ">
                              {" "}
                              <span className="rupeefont">₹ </span>{" "}
                              {convertToCurrency(
                                coupenCodeResponse?.amount
                                  ? coupenCodeResponse.amount
                                  : selectedPackageAmount
                              )}{" "}

                            </span>
                          </span>


                        </div>

                      </div>
                    </div>
                    <div className=" flex justify-center pt-1 mb-[0.6rem] ">
                      {isDefaultSelectedId ==
                        premierPack[0]?.membershipId || isAddOnSelect || isOfflineAddOnSelect ? (
                        <div className="flex justify-end items-end h-[3rem]">
                          <button
                            onClick={() => {
                              paynowClick();
                              onClickTrack(EventName.SelectPaynowButton);
                            }}
                            className=" bg-[#D10A11] px-[3rem]   text-center  py-[0.4rem]  text-white font-bold text-[16px] rounded-[8px]  "
                          >
                            Pay Now
                          </button>
                        </div>
                      )
                        : (
                          <div className="flex justify-end items-end h-[3rem]">
                            <button
                              // onClick={payNowPopupClicked}
                              onClick={() => {
                                payNowPopupClicked();
                                onClickTrack(EventName.SelectPaynowButton);
                              }}
                              className=" bg-[#D10A11] px-[3rem]   text-center  py-[0.4rem]  text-white font-bold text-[16px] rounded-[8px]  "
                            >
                              Pay Now
                            </button>
                          </div>
                        )
                      }

                    </div>
                  </div>) : null}

      {showPolicyPopup && (
        <CommonPopup title="Fair Usage Policy" menu="policypopup" close={close}>
          <p className="w-full text-center h-[30rem] overflow-auto">
            <FairPolicyDeatil />
          </p>
        </CommonPopup>
      )}
      {showSuccessPopup && (
        <CommonPopup title="Transaction Success" close={close}>
          <p className="w-[100%] text-center">Success</p>
        </CommonPopup>
      )}
      {showFailurePopup && (
        <CommonPopup title="Transaction Failed" close={close}>
          <p className="w-[100%] text-center">Please try again later</p>
        </CommonPopup>
      )}
      {showLoader ? (
        <Loader
          className="loaderTransperancy"
          loaderClassName="loaderTransperancySpin"
        />
      ) : (
        ""
      )}
      {responseData ? (
        <div className="h-full">
          <div className={`${isDefaultSelectedId || isAddOnSelect || isOfflineAddOnSelect ? isV3User ? " bg-[#F7F8FA]  overflow-y-auto mblpagepaynow-hight-reduce h-[100%]   lite-scrollbar relative "
            : " overflow-y-auto lite-scrollbar"
            : " overflow-y-auto bg-[#F7F8FA] h-[100%] lite-scrollbar "}`}>
            {!isV3User &&
              <ViewMessageNav page="payment" menu="" callBackPopup={callBackPopup} />
            }
            <div className={`${isV3User ? "text-center md:flex md:justify-end mt-[1rem] md:mt-2 px-8 text-[#D23C27] " : "md:hidden text-center md:float-right mt-[1rem] md:mt-2 px-8 text-[#D23C27]"}`}>
              <p>
                Need Help? Request a{" "}
                <span className="cursor-pointer" onClick={callBackPopup}>
                  {" "}
                  <u> Call Back </u>{" "}
                </span>
              </p>
            </div>

            <div className="mt-[1rem] hidden md:grid grid-cols-10  px-2  md:px-4  md:gap-2 lg:gap-[1rem] 2xl:gap-[1.75rem]">
              <div
                className={
                  "md:col-span-2 border-b-2 pb-[1rem] pd:mb-0 border-[#00000029] md:border-none "
                }
              >
                <div className="relative md:mt-[2rem] lg:mt-[3rem] ">
                  <div className="bg-[#FFFFFF]  rounded-lg shadow-[1px_3px_11px_#FFA5A8]">
                    <div className=" md:pt-[0.5rem] lg:pt-[1.1rem] md:min-h-[22rem] md:max-h-[24rem] lg:min-h-[32.4rem] lg:max-h-[35rem] grid   mt-[1.5rem] rounded-lg ">
                      <div className="">
                        <p className={`pl-[1rem] lg:text-[20px] 2xl:text-[22px] font-cabin_semibold   mt-[1rem] text-[14px]  text-[#000000] `}>
                          {validityPackage[0]?.membershiptype.replace("*", "")}
                          {isUnlimitedTextNewLine && (<><br /><br /></>)}
                        </p>
                        <p className="text-[#D71920] mt-[0.5rem] lg:mt-[1rem]  pl-[1rem]  font-cabin_semibold  ">

                          {coupenCodeResponse != "" ? (
                            validityPackageCoupon[0]?.strikePrice ? (
                              <>
                                <span className="text-[#545454] text-[24px] line-through mr-2 ">
                                  ₹ {convertToCurrency(validityPackageCoupon[0]?.price)}
                                </span>

                                <span className="inline-flex text-[26px]">
                                  {" "}
                                  ₹ {convertToCurrency(validityPackageCoupon[0]?.strikePrice)}{" "}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="inline-flex text-[26px]"> ₹ {convertToCurrency(validityPackageCoupon[0]?.price)} </span>
                              </>
                            )
                          ) : validityPackage[0]?.strikePrice ? (
                            <>
                              <span className="text-[#545454] line-through mr-2 text-[24px]">
                                ₹ {convertToCurrency(validityPackage[0]?.price)}
                              </span>

                              <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(validityPackage[0]?.strikePrice)} </span>
                            </>
                          ) : (
                            <>
                              <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(validityPackage[0]?.price)} </span>
                            </>
                          )}
                        </p>
                        <div className=" border gap-1 flex border-[#F18181] px-0.5 py-1 md:px-2 lg:px-2 lg:py-1.5 mx-2 mt-2 rounded">
                          <label className="flex gap-1  items-center" for="limited">
                            <input id="limited" className="form-checkbox" style={{ width: "13px", height: "13px" }} type="checkbox"
                              checked={isChecked[0]}
                              onChange={(event) => {
                                if (isPaidUser == "N") {
                                  offlineSelect(
                                    event,
                                    isOfflineAddOnSelect,
                                    responseData?.AddonPackages[1].membershipId,
                                    responseData?.AddonPackages[1].price,
                                    0
                                  );
                                  packageSelect(
                                    validityPackage[0]?.membershipId,
                                    validityPackage[0]?.membershiptype,
                                    validityPackage[0]?.viewPhoneNumber,
                                    validityPackage[0]?.price,
                                    0, event
                                  );
                                } else {
                                  offlineSelect(
                                    event,
                                    isOfflineAddOnSelect,
                                    responseData?.AddonPackages[1].membershipId,
                                    responseData?.AddonPackages[1].price, 0
                                  );
                                }

                              }
                              }
                            />
                            <span className="text-[#D71920] md:text-[9px] lg:text-[11px] xl:text-[15px] font-semibold flex">
                              <RupeeSymbol /> {" "} {responseData?.AddonPackages[1].price}
                            </span>

                          </label>
                          <div className="flex items-center gap-[.3rem]">
                            <img src={WhatsappIcon} className="w-[0.5rem] h-[0.5rem] lg:w-[0.6rem] lg:h-[0.6rem] xl:w-[0.8rem] xl:h-[0.8rem]" alt="" />
                            <span className="md:text-[7px] lg:text-[10px] xl:text-[10px]  text-[#8F8F8F]  font-bold">{responseData?.AddonPackages[1].message}</span>
                          </div>
                        </div>
                        {isDefaultSelectedId ==
                          validityPackage[0]?.membershipId ? (
                          <button className="ml-[1rem] bg-[#FFC758] mt-[1rem] font-cabin_regular lg:mt-[2rem] text-[#000000] md:text-[12px] lg:text-[15px]  rounded-lg md:px-[1.5rem] lg:px-[2rem] py-2 shadow-[4px_4px_6px_#00000029]">
                            Selected
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              packageSelect(
                                validityPackage[0]?.membershipId,
                                validityPackage[0]?.membershiptype,
                                validityPackage[0]?.viewPhoneNumber,
                                validityPackage[0]?.price,
                                0
                              );
                              onClickTrack(EventName.paymentSelectLimitedPack);
                            }
                            }
                            className="bg-[#D71920] ml-[1rem] font-cabin_regular text-[12px] lg:text-[15px]  mt-[1rem] lg:mt-[2rem] text-white rounded-lg md:px-[2rem] lg:px-[2.5rem] py-2 "
                          >
                            Select
                          </button>
                        )}
                        <div className="pl-[1rem] font-cabin_regular mt-[2rem]">
                          <p className="text-[#5F5F5F] text-[14px] ">
                            This plan includes :
                          </p>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src="/Assets/Images/Group 708.svg"
                                alt=""
                              />
                            </div>
                            <p className=" md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              {validityPackage[0]?.validity}
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src="/Assets/Images/Group 709.svg"
                                alt=""
                              />
                            </div>
                            <p className=" md:text-[12px]  lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center ">
                              {validityPackage[0]?.viewPhoneNumber}
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={ChatBoxIcon}
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px]  lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              {validityPackage[0]?.sendMessage}
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={HoroscopeIcon}
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px]  lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              {validityPackage[0]?.sendHoroscope}
                            </p>
                          </div>
                        </div>
                      </div>
                      {isDefaultSelectedId ==
                        validityPackage[0]?.membershipId && (
                          <div className="flex justify-end items-end ">
                            <button
                              onClick={() => {
                                payNowClicked();
                                onClickTrack(EventName.SelectPaynowButton);
                              }
                              }
                              className="bg-[#D71920] rounded-br-lg rounded-tl-lg  font-cabin_regular text-[14px] lg:text-[16px]  text-white md:px-[1rem]  md:h-[2.2rem] lg:h-[2.4rem] lg:px-[2rem] py-2 "
                            >
                              Pay Now
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  "md:col-span-2 border-b-2 pb-[1rem] pd:mb-0 border-[#00000029] md:border-none "
                }
              >
                <div className=" md:mt-[2rem] lg:mt-[3rem]  relative">
                  <div className="bg-[#FFFFFF]  rounded-lg shadow-[1px_3px_11px_#FFA5A8]">
                    <div className=" md:pt-[0.5rem] lg:pt-[1.1rem] rounded-lg grid   md:min-h-[22rem] md:max-h-[24rem] lg:min-h-[32.4rem] lg:max-h-[35rem]   mt-[1.5rem] ">
                      <div className="">
                        <p ref={unlimitedValidityTextRef} className=" pl-[1rem] text-[14px] lg:text-[20px] 2xl:text-[22px] font-cabin_semibold  mt-[1rem] text-[#000000] h-[30px] md:h-[40px] lg:h-[60px]  xl:h-[60px] 2xl:h-[35px]">
                          {phoneNumberPackage[0]?.membershiptype.replace("*", "")}
                          {/* {isUnlimitedTextNewLine  ? (<><br/><br/></>):null} */}
                        </p>
                        <p className="text-[#D71920] pl-[1rem] font-cabin_semibold mt-[0.5rem] lg:mt-[1rem]   ">
                          {coupenCodeResponse != "" ? (
                            phoneNumberPackageCoupon[0]?.strikePrice ? (
                              <>
                                <span className="text-[#545454] line-through mr-2  text-[24px]">
                                  ₹ {convertToCurrency(phoneNumberPackageCoupon[0]?.price)}
                                </span>

                                <span className="inline-flex text-[26px]" >
                                  {" "}
                                  ₹ {convertToCurrency(phoneNumberPackageCoupon[0]?.strikePrice)}{" "}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(phoneNumberPackageCoupon[0]?.price)} </span>
                              </>
                            )
                          ) : phoneNumberPackage[0]?.strikePrice ? (
                            <>
                              <span className="text-[#545454]  text-[24px] line-through mr-2 ">
                                ₹ {convertToCurrency(phoneNumberPackage[0]?.price)}
                              </span>

                              <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(phoneNumberPackage[0]?.strikePrice)} </span>
                            </>
                          ) : (
                            <>
                              <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(phoneNumberPackage[0]?.price)} </span>
                            </>
                          )}
                        </p>
                        <div className=" border flex gap-1 border-[#F18181] px-0.5 py-1 md:px-2 lg:px-2 lg:py-1.5 mx-2 mt-2 rounded">

                          <label className="flex gap-1   items-center " for="unlimited">
                            <input id="unlimited" className="form-checkbox" style={{ width: "13px", height: "13px" }} type="checkbox"
                              checked={isChecked[1]}

                              onChange={(event) => {
                                if (isPaidUser == "N") {
                                  offlineSelect(
                                    event,
                                    isOfflineAddOnSelect,
                                    responseData?.AddonPackages[1].membershipId,
                                    responseData?.AddonPackages[1].price, 1
                                  );
                                  packageSelect(
                                    phoneNumberPackage[0]?.membershipId,
                                    phoneNumberPackage[0]?.membershiptype,
                                    phoneNumberPackage[0]?.viewPhoneNumber,
                                    phoneNumberPackage[0]?.price,
                                    1, event
                                  );
                                } else {
                                  offlineSelect(
                                    event,
                                    isOfflineAddOnSelect,
                                    responseData?.AddonPackages[1].membershipId,
                                    responseData?.AddonPackages[1].price, 1
                                  );
                                }

                              }
                              } />
                            <span className="text-[#D71920] md:text-[9px] lg:text-[11px] xl:text-[15px] font-semibold flex">
                              <RupeeSymbol /> {" "} {responseData?.AddonPackages[1].price}
                            </span>

                          </label>
                          <div className="flex items-center gap-[.3rem]">
                            <img src={WhatsappIcon} className="w-[0.5rem] h-[0.5rem] lg:w-[0.6rem] lg:h-[0.6rem] xl:w-[0.8rem] xl:h-[0.8rem]" alt="" />
                            <span className="md:text-[7px] lg:text-[10px] xl:text-[10px]  text-[#8F8F8F]  font-bold">{responseData?.AddonPackages[1].message}</span>
                          </div>
                        </div>
                        {isDefaultSelectedId ==
                          phoneNumberPackage[0]?.membershipId ? (
                          <button className="bg-[#FFC758] ml-[1rem] mt-[1rem] font-cabin_regular lg:mt-[2rem] text-[#000000] md:text-[12px] lg:text-[15px]  rounded-lg md:px-[1.5rem] lg:px-[2rem] py-2 shadow-[4px_4px_6px_#00000029]">
                            Selected
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              packageSelect(
                                phoneNumberPackage[0]?.membershipId,
                                phoneNumberPackage[0]?.membershiptype,
                                phoneNumberPackage[0]?.viewPhoneNumber,
                                phoneNumberPackage[0]?.price,
                                1
                              );
                              onClickTrack(EventName.paymentSelectUnLimitedPack);
                            }
                            }
                            className="bg-[#D71920] ml-[1rem] font-cabin_regular text-[12px] lg:text-[15px] mt-[1rem] lg:mt-[2rem] text-white rounded-lg md:px-[2rem] lg:px-[2.5rem] py-2 "
                          >
                            Select
                          </button>
                        )}
                        <div className="pl-[1rem] font-cabin_regular mt-[2rem] md:mb-0 lg:mb-0 ">
                          <p className="text-[#5F5F5F] text-[14px] ">
                            This plan includes :
                          </p>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src="/Assets/Images/Group 708.svg"
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: phoneNumberPackage[0]?.validity.replace(
                                    "Unlimited",
                                    `<span style="color: #D10A11;">Unlimited*</span>`
                                  ),
                                }}
                              ></p>
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src="/Assets/Images/Group 709.svg"
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center ">
                              {phoneNumberPackage[0]?.viewPhoneNumber}
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div>
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={ChatBoxIcon}
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              {phoneNumberPackage[0]?.sendMessage}
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={HoroscopeIcon}
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              {phoneNumberPackage[0]?.sendHoroscope}
                            </p>
                          </div>
                        </div>
                      </div>
                      {isDefaultSelectedId ==
                        phoneNumberPackage[0]?.membershipId && (
                          <div className="flex justify-end items-end ">
                            <button
                              onClick={() => {
                                payNowClicked();
                                onClickTrack(EventName.SelectPaynowButton);
                              }
                              }
                              className="bg-[#D71920] rounded-br-lg rounded-tl-lg font-cabin_regular text-[14px] lg:text-[16px]  text-white md:px-[1rem]  md:h-[2.2rem] lg:h-[2.4rem] lg:px-[2rem] py-2 "
                            >
                              Pay Now
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  "md:col-span-2  mt-[2rem] md:mt-0 border-b-2 pb-[1rem] pd:mb-0 border-[#00000029] md:border-none "
                }
              >
                <div className="md:mt-[2rem] lg:mt-[3rem] ">
                  <div className="bg-[#FFFFFF] lg:min-h-[32.4rem] lg:max-h-[35rem] rounded-lg shadow-[1px_3px_11px_#FFA5A8]">
                    <div className="md:pt-[0.5rem] flex flex-col lg:pt-[1.1rem]  mt-[1.5rem] rounded-lg h-full">
                      <div className="md:mb-[0.4rem] lg:mb-0 ">
                        <p ref={refCallback} className="pl-[1rem]  text-[14px] lg:text-[20px] 2xl:text-[22px] font-cabin_semibold  mt-[1rem] text-[#000000] h-[30px] md:h-[60px]  xl:h-[60px]">
                          {phoneNumbersPackage[0]?.membershiptype.replace(
                            "*",
                            ""
                          )}
                        </p>
                        <p className="text-[#D71920] mt-[0.5rem] lg:mt-[1rem] pl-[1rem]   font-cabin_semibold   ">
                          {coupenCodeResponse != "" ? (
                            phoneNumbersPackageCoupon[0]?.strikePrice ? (
                              <>
                                <span className="text-[#545454] text-[24px] line-through mr-2 ">
                                  ₹ {convertToCurrency(phoneNumbersPackageCoupon[0]?.price)}
                                </span>

                                <span className="inline-flex text-[26px]" >
                                  {" "}
                                  ₹ {convertToCurrency(phoneNumbersPackageCoupon[0]?.strikePrice)}{" "}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(phoneNumbersPackageCoupon[0]?.price)} </span>
                              </>
                            )
                          ) : phoneNumbersPackage[0]?.strikePrice ? (
                            <>
                              <span className="text-[#545454] text-[24px] line-through mr-2 ">
                                ₹ {convertToCurrency(phoneNumbersPackage[0]?.price)}
                              </span>

                              <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(phoneNumbersPackage[0]?.strikePrice)} </span>
                            </>
                          ) : (
                            <>
                              <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(phoneNumbersPackage[0]?.price)} </span>
                            </>
                          )}
                        </p>
                        <div className="mx-2 border flex gap-1 border-[#F18181] px-0.5 py-1 md:px-2 lg:px-2 lg:py-1.5 mx-2 mt-2 rounded">

                          <label className="flex gap-1   items-center " for="unlimitedPhone">
                            <input id="unlimitedPhone" className="form-checkbox" style={{ width: "13px", height: "13px" }} type="checkbox"
                              checked={isChecked[2]}
                              onChange={(event) => {
                                if (isPaidUser == "N") {
                                  offlineSelect(
                                    event,
                                    isOfflineAddOnSelect,
                                    responseData?.AddonPackages[1].membershipId,
                                    responseData?.AddonPackages[1].price, 2
                                  );
                                  packageSelect(
                                    phoneNumbersPackage[0]?.membershipId,
                                    phoneNumbersPackage[0]?.membershiptype,
                                    phoneNumbersPackage[0]?.viewPhoneNumber,
                                    phoneNumbersPackage[0]?.price,
                                    2, event
                                  );
                                } else {
                                  offlineSelect(
                                    event,
                                    isOfflineAddOnSelect,
                                    responseData?.AddonPackages[1].membershipId,
                                    responseData?.AddonPackages[1].price, 2
                                  );
                                }
                              }
                              } />
                            <span className="text-[#D71920] md:text-[9px] lg:text-[11px] xl:text-[15px] font-semibold flex">
                              <RupeeSymbol /> {" "} {responseData?.AddonPackages[1].price}
                            </span>

                          </label>
                          <div className="flex items-center gap-[.3rem]">
                            <img src={WhatsappIcon} className="w-[0.5rem] h-[0.5rem] lg:w-[0.6rem] lg:h-[0.6rem] xl:w-[0.8rem] xl:h-[0.8rem]" alt="" />
                            <span className="md:text-[7px] lg:text-[10px] xl:text-[10px]  text-[#8F8F8F] font-bold">{responseData?.AddonPackages[1].message}</span>
                          </div>
                        </div>
                        {isDefaultSelectedId ==
                          phoneNumbersPackage[0]?.membershipId ? (
                          <button className="bg-[#FFC758] ml-[1rem] mt-[1rem] lg:mt-[2rem] text-[#000000] md:text-[12px] lg:text-[15px] font-cabin_regular rounded-lg md:px-[1.5rem] lg:px-[2rem] py-2 shadow-[4px_4px_6px_#00000029]">
                            Selected
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              packageSelect(
                                phoneNumbersPackage[0]?.membershipId,
                                phoneNumbersPackage[0]?.membershiptype,
                                phoneNumbersPackage[0]?.viewPhoneNumber,
                                phoneNumbersPackage[0]?.price,
                                2
                              );
                              onClickTrack(EventName.paymentSelectUnLimitedPhonePack);
                            }
                            }
                            className="bg-[#D71920] text-[12px] ml-[1rem] lg:text-[15px] font-cabin_regular  mt-[1rem] lg:mt-[2rem] text-white rounded-lg md:px-[2rem] lg:px-[2.5rem] py-2 "
                          >
                            Select
                          </button>
                        )}
                        <div className="pl-[1rem]  font-cabin_regular  mt-[2rem]">
                          <p className="text-[#5F5F5F] text-[14px] ">
                            This plan includes :
                          </p>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 mt-[1rem]">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src="/Assets/Images/Group 708.svg"
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              {phoneNumbersPackage[0]?.validity}
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src="/Assets/Images/Group 709.svg"
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    phoneNumbersPackage[0]?.viewPhoneNumber.replace(
                                      "Unlimited",
                                      `<span style="color: #D10A11;">Unlimited*</span>`
                                    ),
                                }}
                              ></p>
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={ChatBoxIcon}
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              {phoneNumbersPackage[0]?.sendMessage}
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={HoroscopeIcon}
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              {phoneNumbersPackage[0]?.sendHoroscope}
                            </p>
                          </div>
                        </div>
                      </div>
                      {isDefaultSelectedId ==
                        phoneNumbersPackage[0]?.membershipId ? (
                        <div className="flex justify-end items-end h-[3rem] lg:h-full">
                          <button
                            onClick={() => {
                              payNowClicked();
                              onClickTrack(EventName.SelectPaynowButton);
                            }
                            }
                            className="bg-[#D71920] rounded-br-lg rounded-tl-lg  font-cabin_regular  text-[14px] lg:text-[16px]  text-white md:px-[1rem]  md:h-[2.2rem] lg:h-[2.4rem] lg:px-[2rem] py-2 "
                          >
                            Pay Now
                          </button>
                        </div>
                      ) : (
                        <div className="h-[3rem] lg:h-full"></div>
                      )
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className=" md:col-span-2 mt-[2rem]  md:mt-0 border-b-2   border-[#00000029] md:border-none  ">
                <div className="  relative">
                  <div className="flex justify-center  ">
                    <button className="bg-[#FFC758] md:text-[8px] flex justify-center items-center lg:text-[12px] xl:text-[14px] absolute top-[24px]  text-[#000000]  font-cabin_regular  rounded-lg md:px-2 py-2 ">
                      <div className="flex items-center pr-2 ">
                        <img
                          className=" w-[1rem] lg:w-[1.5rem] "
                          src="/Assets/Images/30. Crown.svg"
                          alt=""
                        />
                      </div>
                      Most Popular For Best Value
                    </button>
                  </div>
                  <div className="bg-[#FFFFFF] border-t-[0.7rem] border-t-[#FFC758] rounded-lg shadow-[1px_3px_11px_#FFA5A8] md:mt-[2rem] lg:mt-[2.3rem]">
                    <div className="  lg:pt-[1.1rem]  grid  mt-[1rem] rounded-lg ">
                      <div className=" ">
                        <p ref={unlimitedTrulyTextRef} className={`pl-[1rem] text-[14px] h-[56px] lg:text-[20px] 2xl:text-[22px] font-cabin_semibold    text-[#000000] `}>
                          {trulyPackage[0]?.membershiptype.replace("*", "")}

                          {isUnlimitedTextNewLine ? (<><br /><br /></>) : null}
                        </p>
                        <p className="text-[#D71920] pl-[1rem] mt-[0.3rem] lg:mt-[1rem]   font-cabin_semibold ">
                          {coupenCodeResponse != "" ? (
                            <>
                              {trulyPackageCoupon[0]?.topprice ?
                                <span className="text-[#545454] line-through mr-2 text-[24px] ">
                                  ₹ {convertToCurrency(trulyPackageCoupon[0]?.topprice)}
                                </span>
                                :
                                ""}
                              {trulyPackageCoupon[0]?.strikePrice ? (
                                <>
                                  <span className="text-[#545454] text-[24px] line-through mr-2 ">
                                    ₹ {convertToCurrency(trulyPackageCoupon[0]?.price)}
                                  </span>

                                  <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(trulyPackageCoupon[0]?.strikePrice)} </span>
                                </>
                              ) : (
                                <>

                                  <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(trulyPackageCoupon[0]?.price)} </span>
                                </>
                              )
                              }
                            </>
                          ) :
                            <>
                              {trulyPackage[0]?.topprice ?
                                <span className="text-[#545454] line-through mr-2 text-[24px]">
                                  ₹ {convertToCurrency(trulyPackage[0]?.topprice)}
                                </span>
                                :
                                ""}
                              {trulyPackage[0]?.strikePrice ? (
                                <>
                                  <span className="text-[#545454] line-through mr-2 text-[24px]">
                                    ₹ {convertToCurrency(trulyPackage[0]?.price)}
                                  </span>

                                  <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(trulyPackage[0]?.strikePrice)} </span>
                                </>
                              ) : (
                                <>

                                  <span className="inline-flex text-[26px]" > ₹ {convertToCurrency(trulyPackage[0]?.price)} </span>
                                </>
                              )
                              }
                            </>
                          }
                        </p>
                        <div className=" mx-2 border flex gap-1 border-[#F18181] px-0.5 py-1 md:px-2 lg:px-2 lg:py-1.5 mt-2 rounded">

                          <label className="flex gap-1   items-center " for="premier">
                            <input id="premier" className="form-checkbox" style={{ width: "13px", height: "13px" }} type="checkbox"
                              checked={isChecked[3]}
                              onChange={(event) => {
                                if (isPaidUser == "N") {
                                  offlineSelect(
                                    event,
                                    isOfflineAddOnSelect,
                                    responseData?.AddonPackages[1].membershipId,
                                    responseData?.AddonPackages[1].price, 3
                                  );
                                  packageSelect(
                                    trulyPackage[0]?.membershipId,
                                    trulyPackage[0]?.membershiptype,
                                    trulyPackage[0]?.viewPhoneNumber,
                                    trulyPackage[0]?.price,
                                    3, event
                                  );
                                } else {
                                  offlineSelect(
                                    event,
                                    isOfflineAddOnSelect,
                                    responseData?.AddonPackages[1].membershipId,
                                    responseData?.AddonPackages[1].price, 3
                                  );
                                }
                              }
                              }

                            />
                            <span className="text-[#D71920] md:text-[9px] lg:text-[11px] xl:text-[15px] font-semibold flex">
                              <RupeeSymbol /> {" "} {responseData?.AddonPackages[1].price}
                            </span>

                          </label>
                          <div className="flex items-center gap-1">
                            <img src={WhatsappIcon} className="w-[0.5rem] h-[0.5rem] lg:w-[0.6rem] lg:h-[0.6rem] xl:w-[0.8rem] xl:h-[0.8rem]" alt="" />
                            <span className="md:text-[7px] lg:text-[10px] xl:text-[10px]  text-[#8F8F8F]  font-bold">{responseData?.AddonPackages[1].message}</span>
                          </div>
                        </div>
                        {isDefaultSelectedId == trulyPackage[0]?.membershipId ? (
                          <button className="bg-[#FFC758] mt-[1rem] ml-[1rem] lg:mt-[2rem] text-[#000000] md:text-[12px] lg:text-[15px]  font-cabin_regular  rounded-lg md:px-[1.5rem] lg:px-[2rem] py-2 shadow-[4px_4px_6px_#00000029]">
                            Selected
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              packageSelect(
                                trulyPackage[0]?.membershipId,
                                trulyPackage[0]?.membershiptype,
                                trulyPackage[0]?.viewPhoneNumber,
                                trulyPackage[0]?.price,
                                3
                              );
                              onClickTrack(EventName.paymentSelectTrulyUnLimitedPack);
                            }
                            }
                            className="bg-[#D71920] ml-[1rem] text-[12px] font-cabin_regular lg:text-[15px]  mt-[1rem] lg:mt-[2rem] text-white rounded-lg md:px-[2rem] lg:px-[2.5rem] py-2 "
                          >
                            Select
                          </button>
                        )}
                        <div className=" pl-[1rem] font-cabin_regular mt-[2rem]">
                          <p className="text-[#5F5F5F] text-[14px] ">
                            This plan includes :
                          </p>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src="/Assets/Images/Group 708.svg"
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: trulyPackage[0]?.validity.replace(
                                    "Unlimited",
                                    `<span style="color: #D10A11;">Unlimited*</span>`
                                  ),
                                }}
                              ></p>
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src="/Assets/Images/Group 709.svg"
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    trulyPackage[0]?.viewPhoneNumber.replace(
                                      "Unlimited",
                                      `<span style="color: #D10A11;">Unlimited*</span>`
                                    ),
                                }}
                              ></p>
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={ChatBoxIcon}
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: trulyPackage[0]?.sendMessage.replace(
                                    "Unlimited",
                                    `<span style="color: #D10A11;">Unlimited*</span>`
                                  ),
                                }}
                              ></p>
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1.1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={HoroscopeIcon}
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: trulyPackage[0]?.sendHoroscope.replace(
                                    "Unlimited",
                                    `<span style="color: #D10A11;">Unlimited*</span>`
                                  ),
                                }}
                              ></p>
                            </p>
                          </div>
                        </div>
                      </div>
                      {isDefaultSelectedId == trulyPackage[0]?.membershipId ?
                        (
                          <div className="flex justify-end  items-end  h-[3rem] ">
                            <button
                              onClick={() => {
                                payNowClicked();
                                onClickTrack(EventName.SelectPaynowButton);
                              }
                              }
                              className="bg-[#D71920] rounded-br-lg rounded-tl-lg  text-[14px] lg:text-[16px]  text-white md:px-[1rem]  md:h-[2.2rem] lg:h-[2.4rem] lg:px-[2rem] py-2 font-cabin_regular "
                            >
                              Pay Now
                            </button>
                          </div>
                        ) : (
                          <div className="h-[3rem]"></div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>

              {/* Premier pack */}
              <div
                className={
                  "md:col-span-2  mt-[2rem] md:mt-0 border-b-2 pb-[1rem] pd:mb-0 border-[#00000029] md:border-none "
                }
              >
                <div className="md:mt-[2rem] lg:mt-[3rem] ">
                  <div className="bg-[#FFFFFF]  rounded-lg shadow-[1px_3px_11px_#FFA5A8]">
                    <div className="md:pl-[1rem] md:pt-[0.5rem] grid lg:pt-[1.1rem]  lg:pl-[1rem]  mt-[1.5rem] rounded-lg ">
                      <div className="md:mb-[0.4rem] lg:mb-0 ">
                        <p className={` text-[14px] lg:text-[20px] 2xl:text-[22px] font-cabin_semibold  mt-[1rem] text-[#000000] `}>
                          {premierPack[0]?.membershiptype.replace(
                            "*",
                            ""
                          )}
                          {isUnlimitedTextNewLine && (<><br /><br /></>)}
                        </p>
                        <p className="text-[#D71920] mt-[0.5rem] lg:mt-[1rem]   font-cabin_semibold   ">
                          {coupenCodeResponse != "" ? (
                            premierPackCoupon[0]?.strikePrice ? (
                              <>
                                <span className="text-[#545454] text-[24px] line-through mr-2 ">
                                  ₹ {convertToCurrency(premierPackCoupon[0]?.price)}
                                </span>

                                <span className="inline-flex text-[26px]" >
                                  {" "}
                                  ₹ {convertToCurrency(premierPackCoupon[0]?.strikePrice)}{" "}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="inline-flex  text-[26px]" > ₹ {convertToCurrency(premierPackCoupon[0]?.price)} </span>
                              </>
                            )
                          ) : premierPack[0]?.strikePrice ? (
                            <>
                              <span className="text-[#545454]  text-[24px] line-through mr-2 ">
                                ₹ {convertToCurrency(premierPack[0]?.price)}
                              </span>

                              <span className="inline-flex  text-[26px]" > ₹ {convertToCurrency(premierPack[0]?.strikePrice)} </span>
                            </>
                          ) : (
                            <>
                              <span className="inline-flex  text-[26px]" > ₹ {convertToCurrency(premierPack[0]?.price)} </span>
                            </>
                          )}
                        </p>
                        {isDefaultSelectedId ==
                          premierPack[0]?.membershipId ? (
                          <button className="bg-[#FFC758] mt-[1rem] lg:mt-[2rem] text-[#000000] md:text-[12px] lg:text-[15px] font-cabin_regular rounded-lg md:px-[1.5rem] lg:px-[2rem] py-2 shadow-[4px_4px_6px_#00000029]">
                            Selected
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              packageSelect(
                                premierPack[0]?.membershipId,
                                premierPack[0]?.membershiptype,
                                premierPack[0]?.viewPhoneNumber,
                                premierPack[0]?.price,
                                4
                              );
                              onClickTrack(EventName.paymentSelectKalyanPremierPack);
                            }
                            }
                            className="bg-[#D71920] text-[12px] lg:text-[15px] font-cabin_regular  mt-[1rem] lg:mt-[2rem] text-white rounded-lg md:px-[2rem] lg:px-[2.5rem] py-2 "
                          >
                            Select
                          </button>
                        )}
                        <div className=" font-cabin_regular  mt-[2rem]">
                          <p className="text-[#5F5F5F] text-[14px] ">
                            This plan includes :
                          </p>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 mt-[1rem]">
                            <div className="flex justify-center items-center shrink-0">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src="/Assets/Images/Group 708.svg"
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center pr-1">
                              {premierPack[0]?.validity}
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src="/Assets/Images/Group 709.svg"
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    premierPack[0]?.viewPhoneNumber.replace(
                                      "Unlimited",
                                      `<span style="color: #D10A11;">Unlimited*</span>`
                                    ),
                                }}
                              ></p>
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={ChatBoxIcon}
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    premierPack[0]?.sendMessage?.replace(
                                      "Unlimited",
                                      `<span style="color: #D10A11;">Unlimited*</span>`
                                    ),
                                }}
                              ></p>
                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 md:mt-[0.5rem] lg:mt-[1rem] ">
                            <div className="flex justify-center items-center shrink-0">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={HoroscopeIcon}
                                alt=""
                              />
                            </div>
                            <p dangerouslySetInnerHTML={{
                              __html:
                                premierPack[0]?.sendHoroscope?.replace(
                                  "Unlimited",
                                  `<span style="color: #D10A11;">Unlimited*</span>`
                                ),
                            }} className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000]">

                            </p>
                          </div>
                          <div className="flex md:space-x-2 lg:space-x-2 xl:space-x-4 mt-[1rem]">
                            <div className="flex justify-center items-center ">
                              <img
                                className=" w-[1rem]  md:w-[1rem] lg:w-[1.1rem] xl:w-[1.5rem]"
                                src={IncreasedVisibility}
                                alt=""
                              />
                            </div>
                            <p className="md:text-[12px] lg:text-[15px] xl:text-[16px] text-[#000000] flex  justify-center items-center pr-1">
                              {premierPack[0]?.visiblility}
                            </p>
                          </div>
                        </div>
                      </div>
                      {isDefaultSelectedId ==
                        premierPack[0]?.membershipId ? (
                        <div className="flex justify-end items-end h-[3rem]">
                          <button
                            onClick={() => {
                              payNowClicked();
                              onClickTrack(EventName.SelectPaynowButton);
                            }
                            }
                            className="bg-[#D71920] rounded-br-lg rounded-tl-lg  font-cabin_regular  text-[14px] lg:text-[16px]  text-white md:px-[1rem]  md:h-[2.2rem] lg:h-[2.4rem] lg:px-[2rem] py-2 "
                          >
                            Pay Now
                          </button>
                        </div>
                      )
                        : (
                          <div className="h-[3rem]"></div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block md:hidden ">
              <div className=" mt-[0.3rem] relative px-[1.2rem]">
                <label htmlFor="option1" >
                  <div className="flex justify-center  ">
                    <label htmlFor="option1" className={isDefaultSelectedId === validityPackage[0]?.membershipId ? "bg-[#039511] text-[14px] flex justify-start pl-[1rem] absolute w-[20rem]  text-white top-[0.5rem] font-segeo font-semibold  rounded-lg  py-2 "
                      : "bg-[#D10A11] text-[14px] flex justify-start pl-[1rem] absolute w-[20rem]  text-white top-[0.5rem] font-segeo font-semibold  rounded-lg  py-2"}>
                      Pack 1 : {validityPackage[0]?.membershiptype}
                    </label>
                  </div>
                  <div className={isDefaultSelectedId === validityPackage[0]?.membershipId ? "grid grid-cols-4 pb-[0.5rem] pt-[1.2rem] px-[0.3rem] bg-[#ECFFE5] shadow-[0px_0px_5px_#039511]    mt-[1.5rem] rounded-lg " :
                    "grid grid-cols-4 pb-[0.5rem] pt-[1.2rem] px-[0.3rem] bg-[#FFFFFF] shadow-[0px_2px_5px_#FFA5A8]  mt-[1.5rem] rounded-lg"}>
                    <div className=" mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex justify-center items-center ">
                        <img
                          className=" w-[1.3rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src="/Assets/Images/Group 708.svg"
                          alt=""
                        />
                      </div>
                      <p className="text-[12px] pt-[0.25rem] leading-[17px] text-center text-[#8F8F8F] ">
                        {validityPackage[0]?.validity}
                      </p>
                    </div>
                    <div className="mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex justify-center items-center ">
                        <img
                          className=" w-[1rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src="/Assets/Images/Group 709.svg"
                          alt=""
                        />
                      </div>
                      <p className="text-[12px] pt-[0.4rem] leading-[17px] text-center text-[#8F8F8F] ">
                        {validityPackage[0]?.viewPhoneNumber}
                      </p>
                    </div>
                    <div className=" mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex justify-center ">
                        <img className="w-[1.5rem]" src={ChatBoxIcon} alt="" />
                      </div>
                      <p className="text-[12px] pt-[0.32rem] leading-[17px] text-center text-[#8F8F8F] ">
                        {validityPackage[0]?.sendMessage}
                      </p>
                    </div>
                    <div className="  mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex justify-center items-center ">
                        <img
                          className=" w-[1.3rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src={HoroscopeIcon}
                          alt=""
                        />
                      </div>
                      <p className="text-[12px] pt-[0.33rem] leading-[16px] text-center text-[#8F8F8F] ">
                        {validityPackage[0]?.sendHoroscope}
                      </p>
                    </div>
                    <div
                      className={
                        validityPackage[0]?.strikePrice
                          ? "pl-[1rem] col-span-4 "
                          : "pl-[1rem] col-span-4 "
                      }
                    >
                      <div className=" pt-[0.4rem] flex justify-between space-x-2 items-center mx-1 ">
                        <div className="flex items-center space-x-2">
                          <div
                            onClick={() => setActiveTab(1)}
                            className="bg-white dark:bg-gray-100 rounded-full w-[1.2rem] h-[1.2rem] flex flex-shrink-0 justify-center items-center relative"
                          >
                            <input

                              //  onClick={() => setActiveTab(1)}
                              onClick={() =>
                                packageSelect(
                                  validityPackage[0]?.membershipId,
                                  validityPackage[0]?.membershiptype,
                                  validityPackage[0]?.viewPhoneNumber,
                                  validityPackage[0]?.price,
                                  0
                                )
                              }
                              checked={
                                isDefaultSelectedId ==
                                  validityPackage[0]?.membershipId
                                  ? true
                                  : false
                              }
                              id="option1"
                              type="radio"
                              name="radio"
                              className="checkbox appearance-none   accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer w-full h-full"
                            />
                            <div
                              className={`${isDefaultSelectedId ==
                                validityPackage[0]?.membershipId
                                ? "check-icon flex  border-4 border-[#D10A11] rounded-full w-full h-full z-1"
                                : "check-icon hidden  border-4 border-[#D10A11] rounded-full w-full h-full z-1"
                                }`}
                            ></div>
                            {/* <input aria-labelledby="label1" type="radio" name="radio" 
                        
                            className="accent-[#D10A11] checkbox cursor-pointer" /> */}
                          </div>
                          <p className="text-[#D71920] mr-2  font-semibold text-[17px] font-segeo whitespace-nowrap">
                            {coupenCodeResponse != "" ? (
                              validityPackageCoupon[0]?.strikePrice ? (
                                <>
                                  <span className="text-[#545454] line-through mr-2 block whitespace-nowrap">
                                    ₹ {convertToCurrency(validityPackageCoupon[0]?.price)}
                                  </span>
                                  <span>
                                    {" "}
                                    ₹ {convertToCurrency(validityPackageCoupon[0]?.strikePrice)}{" "}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span> ₹ {convertToCurrency(validityPackageCoupon[0]?.price)} </span>
                                </>
                              )
                            ) : validityPackage[0]?.strikePrice ? (
                              <>
                                <span className="text-[#545454] line-through mr-2 block whitespace-nowrap">
                                  ₹ {convertToCurrency(validityPackage[0]?.price)}
                                </span>

                                <span> ₹ {convertToCurrency(validityPackage[0]?.strikePrice)} </span>
                              </>
                            ) : (
                              <>
                                <span> ₹ {convertToCurrency(validityPackage[0]?.price)} </span>
                              </>
                            )}
                          </p>
                        </div>
                        <label className="flex space-x-2 items-center border border-[#F18181] py-1 px-2 rounded" for="limitedMob">
                          <input id="limitedMob" className="form-checkbox" style={{ width: "13px", height: "13px" }} type="checkbox"
                            disabled={isDefaultSelectedId == 22}
                            checked={isChecked[0]}

                            onChange={(event) => {
                              if (isPaidUser == "N") {
                                offlineSelect(
                                  event,
                                  isOfflineAddOnSelect,
                                  responseData?.AddonPackages[1].membershipId,
                                  responseData?.AddonPackages[1].price, 0
                                );
                                packageSelect(
                                  validityPackage[0]?.membershipId,
                                  validityPackage[0]?.membershiptype,
                                  validityPackage[0]?.viewPhoneNumber,
                                  validityPackage[0]?.price,
                                  0, event
                                );
                              } else {
                                offlineSelect(
                                  event,
                                  isOfflineAddOnSelect,
                                  responseData?.AddonPackages[1].membershipId,
                                  responseData?.AddonPackages[1].price, 0
                                );
                              }

                            }
                            } />
                          <span className="text-[#D71920] text-[12px] font-semibold flex">
                            <RupeeSymbol /> {" "} {responseData?.AddonPackages[1].price}
                          </span>
                          <div className="flex items-center">
                            <img src={WhatsappIcon} className="w-[0.7rem] h-[0.7rem]" alt="" />
                            <span className="text-[9px] md:text-[10px] lg:text-[12px] text-[#8F8F8F] ml-1 font-bold">{responseData?.AddonPackages[1].message}</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
              <div className=" mt-[2.5rem] relative px-[1.2rem]">
                <label htmlFor="option2"  >
                  <div className="flex justify-center  ">
                    <label htmlFor="option2" className={isDefaultSelectedId == phoneNumberPackage[0]?.membershipId ? "bg-[#039511] text-[14px] flex justify-start pl-[1rem] absolute w-[20rem]  text-white top-[0.5rem] font-segeo font-semibold  rounded-lg  py-2 " : "bg-[#D10A11] text-[14px] flex justify-start pl-[1rem] absolute w-[20rem]  text-white top-[0.5rem] font-segeo font-semibold  rounded-lg  py-2 "}>
                      Pack 2 : {phoneNumberPackage[0]?.membershiptype}
                    </label>
                  </div>
                  <div className={isDefaultSelectedId == phoneNumberPackage[0]?.membershipId ? "grid grid-cols-4 pb-[0.5rem] pt-[1.2rem] px-[0.3rem] bg-[#ECFFE5] shadow-[0px_0px_5px_#039511]  mt-[1.5rem] rounded-lg" : "grid grid-cols-4 pb-[0.5rem] pt-[1.2rem] px-[0.3rem] bg-[#FFFFFF] shadow-[0px_2px_5px_#FFA5A8]  mt-[1.5rem] rounded-lg "}>
                    <div className=" mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex relative justify-center items-center ">
                        <img
                          className=" w-[1.3rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src="/Assets/Images/Group 708.svg"
                          alt=""
                        />
                        <div>
                          <img className="absolute bottom-[-4px] left-[27px] " src={Greentick} alt="" />
                        </div>
                      </div>
                      <p className="text-[12px] pt-[0.25rem] leading-[17px] text-center text-[#8F8F8F] ">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: phoneNumberPackage[0]?.validity.replace(
                              "Unlimited",
                              `<p style="color: #D10A11;font-weight: 600;">Unlimited</p>`
                            ),
                          }}
                        ></div>
                      </p>
                    </div>
                    <div className="mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex  justify-center items-center ">
                        <img
                          className=" w-[1rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src="/Assets/Images/Group 709.svg"
                          alt=""
                        />

                      </div>
                      <p className="text-[12px] pt-[0.38rem] leading-[17px] text-center text-[#8F8F8F] flex  justify-center items-center ">
                        {phoneNumberPackage[0]?.viewPhoneNumber}
                      </p>
                    </div>
                    <div className=" mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex justify-center items-center ">
                        <img className="w-[1.5rem]" src={ChatBoxIcon} alt="" />
                      </div>
                      <p className="text-[12px] pt-[0.34rem] leading-[17px] text-center text-[#8F8F8F] ">
                        {phoneNumberPackage[0]?.sendMessage}
                      </p>
                    </div>
                    <div className="  mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex justify-center items-center ">
                        <img
                          className=" w-[1.3rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src={HoroscopeIcon}
                          alt=""
                        />
                      </div>
                      <p className="text-[12px] pt-[0.25rem] leading-[17px] text-center text-[#8F8F8F] ">
                        {phoneNumberPackage[0]?.sendHoroscope}
                      </p>
                    </div>
                    <div
                      className={
                        phoneNumberPackage[0]?.strikePrice
                          ? "pl-[1rem] col-span-4"
                          : "pl-[1rem] col-span-4"
                      }
                    >
                      <div className="  pt-[0.4rem] flex justify-between space-x-2 items-center mx-1">
                        <div className="flex items-center space-x-2">
                          <div
                            onClick={() => setActiveTab(2)}
                            className="bg-white dark:bg-gray-100 rounded-full w-[1.2rem] h-[1.2rem] flex flex-shrink-0 justify-center items-center relative"
                          >
                            <input
                              onClick={() =>
                                packageSelect(
                                  phoneNumberPackage[0]?.membershipId,
                                  phoneNumberPackage[0]?.membershiptype,
                                  phoneNumberPackage[0]?.viewPhoneNumber,
                                  phoneNumberPackage[0]?.price,
                                  1
                                )
                              }
                              checked={
                                isDefaultSelectedId ==
                                  phoneNumberPackage[0]?.membershipId
                                  ? true
                                  : false
                              }
                              aria-labelledby="label2"
                              type="radio"
                              name="radio"
                              id="option2"
                              className="checkbox appearance-none   accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer w-full h-full"
                            />
                            <div
                              className={`${isDefaultSelectedId ==
                                phoneNumberPackage[0]?.membershipId
                                ? "check-icon flex  border-4 border-[#D10A11] rounded-full w-full h-full z-1"
                                : "check-icon hidden  border-4 border-[#D10A11] rounded-full w-full h-full z-1"
                                }`}
                            ></div>
                          </div>
                          <p className="text-[#D71920]  mr-2 font-semibold text-[17px] font-segeo whitespace-nowrap">
                            {coupenCodeResponse != "" ? (
                              phoneNumberPackageCoupon[0]?.strikePrice ? (
                                <>
                                  <span className="text-[#545454] line-through mr-2 block whitespace-nowrap">
                                    ₹ {convertToCurrency(phoneNumberPackageCoupon[0]?.price)}
                                  </span>

                                  <span>
                                    {" "}
                                    ₹ {convertToCurrency(phoneNumberPackageCoupon[0]?.strikePrice)}{" "}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span> ₹ {convertToCurrency(phoneNumberPackageCoupon[0]?.price)} </span>
                                </>
                              )
                            ) : phoneNumberPackage[0]?.strikePrice ? (
                              <>
                                <span className="text-[#545454] line-through mr-2 block whitespace-nowrap">
                                  ₹ {convertToCurrency(phoneNumberPackage[0]?.price)}
                                </span>

                                <span> ₹ {convertToCurrency(phoneNumberPackage[0]?.strikePrice)} </span>
                              </>
                            ) : (
                              <>
                                <span> ₹ {convertToCurrency(phoneNumberPackage[0]?.price)} </span>
                              </>
                            )}
                          </p>
                        </div>
                        <label className="flex space-x-2 items-center border border-[#F18181] py-1 px-2 rounded" for="unlimitedMob">
                          <input id="unlimitedMob" className="form-checkbox" style={{ width: "13px", height: "13px" }} type="checkbox"
                            checked={isChecked[1]}
                            onChange={(event) => {
                              if (isPaidUser == "N") {
                                offlineSelect(
                                  event,
                                  isOfflineAddOnSelect,
                                  responseData?.AddonPackages[1].membershipId,
                                  responseData?.AddonPackages[1].price, 1
                                );
                                packageSelect(
                                  phoneNumberPackage[0]?.membershipId,
                                  phoneNumberPackage[0]?.membershiptype,
                                  phoneNumberPackage[0]?.viewPhoneNumber,
                                  phoneNumberPackage[0]?.price,
                                  1, event
                                );
                              } else {
                                offlineSelect(
                                  event,
                                  isOfflineAddOnSelect,
                                  responseData?.AddonPackages[1].membershipId,
                                  responseData?.AddonPackages[1].price, 1
                                );
                              }

                            }
                            } />
                          <span className="text-[#D71920] text-[12px] font-semibold flex">
                            <RupeeSymbol /> {" "} {responseData?.AddonPackages[1].price}
                          </span>
                          <div className="flex items-center">
                            <img src={WhatsappIcon} className="w-[0.7rem] h-[0.7rem]" alt="" />
                            <span className="text-[9px] md:text-[10px] lg:text-[12px] text-[#8F8F8F] ml-1 font-bold">{responseData?.AddonPackages[1].message}</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
              <div className=" mt-[2.5rem] relative px-[1.2rem]">
                <label htmlFor="option3"  >
                  <div className="flex justify-center  ">
                    <label htmlFor="option3" className={isDefaultSelectedId == phoneNumbersPackage[0]?.membershipId ? "bg-[#039511] text-[13px] flex  justify-start pl-[1rem] absolute w-[20rem]  text-white top-[0.5rem] font-segeo font-semibold  rounded-lg  py-2 " : "bg-[#D10A11] text-[13px] flex  justify-start pl-[1rem] absolute w-[20rem]  text-white top-[0.5rem] font-segeo font-semibold  rounded-lg  py-2 "}>
                      Pack 3 : {phoneNumbersPackage[0]?.membershiptype}
                    </label>
                  </div>
                  <div className={isDefaultSelectedId == phoneNumbersPackage[0]?.membershipId ? "grid grid-cols-4 pb-[0.5rem] pt-[1.2rem] px-[0.3rem] bg-[#ECFFE5] shadow-[0px_0px_5px_#039511]  mt-[1.5rem] rounded-lg " : 'grid grid-cols-4 pb-[0.5rem] pt-[1.2rem] px-[0.3rem] bg-[#FFFFFF] shadow-[0px_2px_5px_#FFA5A8]  mt-[1.5rem] rounded-lg'}>
                    <div className=" mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex justify-center items-center ">
                        <img
                          className=" w-[1.3rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src="/Assets/Images/Group 708.svg"
                          alt=""
                        />
                      </div>
                      <p className="text-[12px] pt-[0.25rem] leading-[17px] text-center text-[#8F8F8F] ">
                        {phoneNumbersPackage[0]?.validity}
                      </p>
                    </div>
                    <div className="mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex relative justify-center items-center ">
                        <img
                          className=" w-[1rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src="/Assets/Images/Group 709.svg"
                          alt=""
                        />
                        <div>
                          <img className="absolute bottom-[-4px] left-[28px] " src={Greentick} alt="" />
                        </div>
                      </div>
                      <p className="text-[12px] pt-[0.4rem] leading-[17px] text-center text-[#8F8F8F] ">
                        <div
                          className=" md:whitespace-nowrap  "
                          dangerouslySetInnerHTML={{
                            __html: phoneNumbersPackage[0]?.viewPhoneNumber.replace(
                              "Unlimited",
                              `<p style="color: #D10A11;font-weight: 600;">Unlimited</p>`
                            ),
                          }}
                        ></div>
                      </p>
                    </div>
                    <div className=" mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex justify-center ">
                        <img className="w-[1.5rem]" src={ChatBoxIcon} alt="" />
                      </div>
                      <p className="text-[12px] pt-[0.3rem] leading-[17px] text-center text-[#8F8F8F] ">
                        {phoneNumbersPackage[0]?.sendMessage}
                      </p>
                    </div>
                    <div className="  mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex justify-center  items-center ">
                        <img
                          className=" w-[1.3rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src={HoroscopeIcon}
                          alt=""
                        />
                      </div>
                      <p className="text-[12px] pt-[0.23rem] leading-[17px] text-center text-[#8F8F8F] ">
                        {phoneNumbersPackage[0]?.sendHoroscope}
                      </p>
                    </div>
                    <div
                      className={
                        phoneNumbersPackage[0]?.strikePrice
                          ? "pl-[1rem] col-span-4 "
                          : "pl-[1rem] col-span-4 "
                      }
                    >
                      <div
                        onClick={() => setActiveTab(3)}
                        className="pt-[0.4rem] flex justify-between space-x-2 items-center mx-1"
                      >
                        <div className="flex items-center space-x-2">
                          <div className="bg-white dark:bg-gray-100 rounded-full w-[1.2rem] h-[1.2rem] flex flex-shrink-0 justify-center items-center relative">
                            <input
                              onClick={() =>
                                packageSelect(
                                  phoneNumbersPackage[0]?.membershipId,
                                  phoneNumbersPackage[0]?.membershiptype,
                                  phoneNumbersPackage[0]?.viewPhoneNumber,
                                  phoneNumbersPackage[0]?.price,
                                  2
                                )
                              }
                              checked={
                                isDefaultSelectedId ==
                                  phoneNumbersPackage[0]?.membershipId
                                  ? true
                                  : false
                              }
                              aria-labelledby="label3"
                              type="radio"
                              name="radio"
                              id="option3"
                              className="checkbox appearance-none   accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer w-full h-full"
                            />
                            <div
                              className={`${isDefaultSelectedId ==
                                phoneNumbersPackage[0]?.membershipId
                                ? "check-icon flex  border-4 border-[#D10A11] rounded-full w-full h-full z-1"
                                : "check-icon hidden  border-4 border-[#D10A11] rounded-full w-full h-full z-1"
                                }`}
                            ></div>
                          </div>
                          <p className="text-[#D71920] mr-2  font-semibold text-[17px] font-segeo whitespace-nowrap">
                            {coupenCodeResponse != "" ? (
                              phoneNumbersPackageCoupon[0]?.strikePrice ? (
                                <>
                                  <span className="text-[#545454] line-through mr-2 block whitespace-nowrap">
                                    ₹ {convertToCurrency(phoneNumbersPackageCoupon[0]?.price)}
                                  </span>
                                  <span>
                                    {" "}
                                    ₹ {convertToCurrency(phoneNumbersPackageCoupon[0]?.strikePrice)}{" "}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span> ₹ {convertToCurrency(phoneNumbersPackageCoupon[0]?.price)} </span>
                                </>
                              )
                            ) : phoneNumbersPackage[0]?.strikePrice ? (
                              <>
                                <span className="text-[#545454] line-through mr-2 block whitespace-nowrap">
                                  ₹ {convertToCurrency(phoneNumbersPackage[0]?.price)}
                                </span>

                                <span> ₹ {convertToCurrency(phoneNumbersPackage[0]?.strikePrice)} </span>
                              </>
                            ) : (
                              <>
                                <span> ₹ {convertToCurrency(phoneNumbersPackage[0]?.price)} </span>
                              </>
                            )}


                          </p>
                        </div>
                        <label className="flex space-x-2 items-center border border-[#F18181] py-1 px-2 rounded" for="unlimitedPhoneMob">
                          <input id="unlimitedPhoneMob" className="form-checkbox" style={{ width: "13px", height: "13px" }} type="checkbox"
                            checked={isChecked[2]}
                            onChange={(event) => {
                              if (isPaidUser == "N") {
                                offlineSelect(
                                  event,
                                  isOfflineAddOnSelect,
                                  responseData?.AddonPackages[1].membershipId,
                                  responseData?.AddonPackages[1].price, 2
                                );
                                packageSelect(
                                  phoneNumbersPackage[0]?.membershipId,
                                  phoneNumbersPackage[0]?.membershiptype,
                                  phoneNumbersPackage[0]?.viewPhoneNumber,
                                  phoneNumbersPackage[0]?.price,
                                  2, event
                                );
                              } else {
                                offlineSelect(
                                  event,
                                  isOfflineAddOnSelect,
                                  responseData?.AddonPackages[1].membershipId,
                                  responseData?.AddonPackages[1].price, 2
                                );
                              }
                            }
                            }
                          />
                          <span className="text-[#D71920] text-[12px] font-semibold flex">
                            <RupeeSymbol /> {" "} {responseData?.AddonPackages[1].price}
                          </span>
                          <div className="flex items-center">
                            <img src={WhatsappIcon} className="w-[0.7rem] h-[0.7rem]" alt="" />
                            <span className="text-[9px] md:text-[10px] lg:text-[12px] text-[#8F8F8F] ml-1 font-bold">{responseData?.AddonPackages[1].message}</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
              <div className=" mt-[4.5rem]  relative mx-[1.2rem]">
                <label htmlFor="option4" >
                  <div className="absolute top-[-1.77rem] left-[4rem] z-[2] ">
                    <img src="/Assets/Images/Group 2336.svg" alt="" />
                  </div>
                  <div className="absolute top-[-1.77rem] left-[5rem] z-0 bg-[#FFE797] p-[0.2rem] rounded-tr-lg ronded-br-g ">
                    <p className="pl-[1rem]  font-semibold text-[12px] ">
                      Most Popular For Best Value{" "}
                    </p>
                  </div>
                  <div className="flex justify-center  ">
                    <label htmlFor="option4" className={isDefaultSelectedId == trulyPackage[0]?.membershipId ? "bg-[#039511]  text-[14px] flex justify-start pl-[1rem] absolute w-[20rem]  text-white top-[-0.5rem] font-segeo font-semibold  rounded-lg  py-2 " : "bg-[#D10A11] text-[14px] flex justify-start pl-[1rem] absolute w-[20rem]  text-white top-[-0.5rem] font-segeo font-semibold  rounded-lg  py-2 "}>
                      Pack 4 : {trulyPackage[0]?.membershiptype}
                    </label >
                  </div>
                  <div className={isDefaultSelectedId == trulyPackage[0]?.membershipId ? " bg-[#ECFFE5] shadow-[0px_0px_5px_#039511] grid grid-cols-4 pb-[0.5rem] pt-[0.2rem]  mt-[1.5rem] rounded-lg " : "grid grid-cols-4 pb-[0.5rem] pt-[0.2rem]  bg-[#FFFFFF] shadow-[0px_0px_5px_#FFA5A8]  mt-[1.5rem] rounded-lg"}>
                    <div className=" mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex relative justify-center items-center ">
                        <img
                          className=" w-[1.3rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src="/Assets/Images/Group 708.svg"
                          alt=""
                        />
                        <div>
                          <img className="absolute bottom-[-4px] left-[27px] " src={Greentick} alt="" />
                        </div>
                      </div>
                      <p className="text-[12px] pt-[0.37rem] leading-[18px] text-center text-[#8F8F8F]  ">
                        <div
                          className=" whitespace-nowrap "
                          dangerouslySetInnerHTML={{
                            __html: trulyPackage[0]?.validity.replace(
                              "Unlimited",
                              `<p style="color: #D10A11;font-weight: 600;">Unlimited</p>`
                            ),
                          }}
                        ></div>
                      </p>
                    </div>
                    <div className="mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex relative justify-center ">
                        <img
                          className=" w-[1rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src="/Assets/Images/Group 709.svg"
                          alt=""
                        />
                        <div>
                          <img className="absolute bottom-[-4px] left-[28px] " src={Greentick} alt="" />
                        </div>
                      </div>
                      <p className="text-[12px] pt-2 leading-[18px] text-center text-[#8F8F8F] ">
                        <div
                          className=" md:whitespace-nowrap "
                          dangerouslySetInnerHTML={{
                            __html: trulyPackage[0]?.viewPhoneNumber.replace(
                              "Unlimited",
                              `<p style="color: #D10A11;font-weight: 600;">Unlimited</p>`
                            ),
                          }}
                        ></div>
                      </p>
                    </div>
                    <div className=" mt-[1rem] pl-[0.5rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex relative justify-center ">
                        <img
                          className="w-[1.5rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src={ChatBoxIcon}
                          alt=""
                        />
                        <div>
                          <img className="absolute bottom-[-4px] left-[21px] " src={Greentick} alt="" />
                        </div>
                      </div>
                      <p className="text-[12px] pt-[0.45rem] leading-[17px] text-center text-[#8F8F8F] ">
                        <div
                          className=" md:whitespace-nowrap "
                          dangerouslySetInnerHTML={{
                            __html: trulyPackage[0]?.sendMessage.replace(
                              "Unlimited",
                              `<p style="color: #D10A11;font-weight: 600;">Unlimited</p>`
                            ),
                          }}
                        ></div>
                      </p>
                    </div>
                    <div className="  mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                      <div className="flex relative justify-center items-center">
                        <img
                          className=" w-[1.3rem]  md:w-[1rem] lg:w-[1.5rem]"
                          src={HoroscopeIcon}
                          alt=""
                        />
                        <div>
                          <img className="absolute bottom-[-4px] left-[27px] " src={Greentick} alt="" />
                        </div>
                      </div>
                      <p className="text-[12px] pt-[0.38rem] leading-[17px] text-center text-[#8F8F8F] ">
                        <div
                          className=" whitespace-nowrap "
                          dangerouslySetInnerHTML={{
                            __html: trulyPackage[0]?.sendHoroscope.replace(
                              "Unlimited",
                              `<p style="color: #D10A11;font-weight: 600;">Unlimited</p>`
                            ),
                          }}
                        ></div>
                      </p>
                    </div>
                    <div className="pl-[1rem] col-span-4 ">
                      <div className=" pt-[0.4rem] flex justify-between space-x-2 items-center mx-1">
                        <div className="flex items-center space-x-2">
                          <div
                            onClick={() => setActiveTab(4)}
                            className="bg-white dark:bg-gray-100 rounded-full w-[1.2rem] h-[1.2rem] flex flex-shrink-0 justify-center items-center relative"
                          >
                            <input
                              onClick={() =>
                                packageSelect(
                                  trulyPackage[0]?.membershipId,
                                  trulyPackage[0]?.membershiptype,
                                  trulyPackage[0]?.viewPhoneNumber,
                                  trulyPackage[0]?.price,
                                  3
                                )
                              }
                              checked={
                                isDefaultSelectedId == trulyPackage[0]?.membershipId
                                  ? true
                                  : false
                              }
                              aria-labelledby="label4"
                              type="radio"
                              name="radio"
                              id="option4"
                              className="checkbox appearance-none   accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer w-full h-full"
                            />
                            <div
                              className={`${isDefaultSelectedId == trulyPackage[0]?.membershipId
                                ? "check-icon flex  border-4 border-[#D10A11] rounded-full w-full h-full z-1"
                                : "check-icon hidden  border-4 border-[#D10A11] rounded-full w-full h-full z-1"
                                }`}
                            ></div>
                          </div>
                          <p className="text-[#D71920] mr-2  font-semibold text-[17px] font-segeo whitespace-nowrap">
                            {coupenCodeResponse != "" ? (
                              <>
                                {trulyPackageCoupon[0]?.topprice ?
                                  <span className="text-[#545454] line-through mr-2 block whitespace-nowrap">
                                    ₹ {convertToCurrency(trulyPackageCoupon[0]?.topprice)}
                                  </span>
                                  :
                                  ""}
                                {trulyPackageCoupon[0]?.strikePrice ? (
                                  <>
                                    <span className="text-[#545454] line-through mr-2  block whitespace-nowrap">
                                      ₹ {convertToCurrency(trulyPackageCoupon[0]?.price)}
                                    </span>

                                    <span> ₹ {convertToCurrency(trulyPackageCoupon[0]?.strikePrice)} </span>
                                  </>
                                ) : (
                                  <>

                                    <span> ₹ {convertToCurrency(trulyPackageCoupon[0]?.price)} </span>
                                  </>
                                )
                                }
                              </>
                            ) :
                              <>
                                {trulyPackage[0]?.topprice ?
                                  <span className="text-[#545454] line-through mr-2 block whitespace-nowrap">
                                    ₹ {convertToCurrency(trulyPackage[0]?.topprice)}
                                  </span>
                                  :
                                  ""}

                                {trulyPackage[0]?.strikePrice ? (
                                  <>
                                    <span className="text-[#545454] line-through mr-2 block whitespace-nowrap">
                                      ₹ {convertToCurrency(trulyPackage[0]?.price)}
                                    </span>

                                    <span> ₹ {convertToCurrency(trulyPackage[0]?.strikePrice)} </span>
                                  </>
                                ) : (
                                  <>

                                    <span> ₹ {convertToCurrency(trulyPackage[0]?.price)} </span>
                                  </>
                                )
                                }
                              </>
                            }

                          </p>
                        </div>
                        <label className="flex space-x-2 items-center border border-[#F18181] py-1 px-2 rounded" for="trulyMob">
                          <input id="trulyMob" className="form-checkbox" style={{ width: "13px", height: "13px" }} type="checkbox"
                            checked={isChecked[3]}
                            onChange={(event) => {
                              if (isPaidUser == "N") {
                                offlineSelect(
                                  event,
                                  isOfflineAddOnSelect,
                                  responseData?.AddonPackages[1].membershipId,
                                  responseData?.AddonPackages[1].price, 3
                                );
                                packageSelect(
                                  trulyPackage[0]?.membershipId,
                                  trulyPackage[0]?.membershiptype,
                                  trulyPackage[0]?.viewPhoneNumber,
                                  trulyPackage[0]?.price,
                                  3, event
                                );
                              } else {
                                offlineSelect(
                                  event,
                                  isOfflineAddOnSelect,
                                  responseData?.AddonPackages[1].membershipId,
                                  responseData?.AddonPackages[1].price, 3
                                );
                              }
                            }
                            } />
                          <span className="text-[#D71920] text-[12px] font-semibold flex">
                            <RupeeSymbol /> {" "} {responseData?.AddonPackages[1].price}
                          </span>
                          <div className="flex items-center">
                            <img src={WhatsappIcon} className="w-[0.7rem] h-[0.7rem]" alt="" />
                            <span className="text-[9px] md:text-[10px] lg:text-[12px] text-[#8F8F8F] ml-1 font-bold">{responseData?.AddonPackages[1].message}</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
              <div className=" mt-[2.5rem] border-b-2 border-[#00000029] pb-[2rem] relative mx-[1.2rem]">
                <label htmlFor="option5" >
                  <div className="flex justify-center  ">
                    <label htmlFor="option5" className={isDefaultSelectedId == premierPack[0]?.membershipId ? "bg-[#039511] text-[13px] flex  justify-start pl-[1rem] absolute w-[20rem]  text-white top-[0.5rem] font-segeo font-semibold  rounded-lg  py-2" : "bg-[#D10A11] text-[13px] flex  justify-start pl-[1rem] absolute w-[20rem]  text-white top-[0.5rem] font-segeo font-semibold  rounded-lg  py-2 "}>
                      Pack 5 : {premierPack[0]?.membershiptype}
                    </label>
                  </div>
                  <div className={isDefaultSelectedId == premierPack[0]?.membershipId ? "pb-[0.5rem] pt-[1.2rem] px-[0.3rem] bg-[#ECFFE5] shadow-[0px_0px_5px_#039511]  mt-[1.5rem] rounded-lg " : "pb-[0.5rem] pt-[1.2rem] px-[0.3rem] bg-[#FFFFFF] shadow-[0px_2px_5px_#FFA5A8]  mt-[1.5rem] rounded-lg "}>
                    <div className="flex mt-[1rem] justify-center items-center ">
                      <img src={Advisor} className="" alt="advisor" />
                    </div>
                    <p className="text-[12px] text-center pt-[0.25rem] leading-[17px] text-[#000000] font-bold ">
                      {premierPack[0]?.validity}
                    </p>
                    <div className="grid grid-cols-4 ">

                      <div className="mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] mr-1">
                        <div className="flex relative justify-center items-center ">
                          <img
                            className=" w-[1rem]  md:w-[1rem] lg:w-[1.5rem]"
                            src="/Assets/Images/Group 709.svg"
                            alt=""
                          />
                          <div>
                            <img className="absolute bottom-[-4px] left-[27px] " src={Greentick} alt="" />
                          </div>
                        </div>
                        <p className="text-[12px] pt-[0.4rem] leading-[17px] text-center text-[#8F8F8F] ">
                          <div
                            className=" whitespace-nowrap  "
                            dangerouslySetInnerHTML={{
                              __html: premierPack[0]?.viewPhoneNumber.replace(
                                "Unlimited",
                                `<p style="color: #D10A11;font-weight: 600;">Unlimited</p>`
                              ),
                            }}
                          ></div>
                        </p>
                      </div>
                      <div className=" mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ml-1">
                        <div className="flex relative justify-center ">
                          <img className="w-[1.5rem]" src={ChatBoxIcon} alt="" />
                          <div>
                            <img className="absolute bottom-[-4px] left-[22px] " src={Greentick} alt="" />
                          </div>
                        </div>

                        <p dangerouslySetInnerHTML={{
                          __html: premierPack[0]?.sendMessage?.replace(
                            "Unlimited",
                            `<p style="color: #D10A11;font-weight: 600;">Unlimited</p>`
                          ),
                        }} className="text-[12px] pt-[0.3rem] leading-[17px] text-center text-[#8F8F8F] ">

                        </p>
                      </div>
                      <div className="  mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] ">
                        <div className="flex relative justify-center  items-center ">
                          <img
                            className=" w-[1.3rem]  md:w-[1rem] lg:w-[1.5rem]"
                            src={HoroscopeIcon}
                            alt=""
                          />
                          <div>
                            <img className="absolute bottom-[-4px] left-[26px] " src={Greentick} alt="" />
                          </div>
                        </div>
                        <p dangerouslySetInnerHTML={{
                          __html: premierPack[0]?.sendHoroscope?.replace(
                            "Unlimited",
                            `<p style="color: #D10A11;font-weight: 600;">Unlimited</p>`
                          ),
                        }} className="text-[12px] pt-[0.23rem] leading-[17px] text-center text-[#8F8F8F] ">

                        </p>
                      </div>

                      <div className=" mt-[1rem] col-span-1 border-b-[1px] border-b-[#E9E9E9] pb-[1rem] mr-1">
                        <div className="flex relative justify-center items-center h-[20px]">
                          <img
                            className=" w-[1.3rem]  md:w-[1rem] lg:w-[1.5rem]"
                            src={IncreasedVisibility}
                            alt=""
                          />
                          <div>
                            <img className="absolute bottom-[-6px] left-[24px] " src={Greentick} alt="" />
                          </div>
                        </div>
                        <p className="text-[12px] pt-[0.25rem] leading-[17px] text-center text-[#8F8F8F] ">
                          {premierPack[0]?.visiblility}
                          {/* Increased Visibility */}
                        </p>
                      </div>

                      <div
                        className={
                          premierPack[0]?.strikePrice
                            ? "pl-[1rem] col-span-4 "
                            : "pl-[1rem] col-span-4 "
                        }
                      >
                        <div
                          onClick={() => setActiveTab(3)}
                          className="  pt-[0.4rem] flex justify-center space-x-2 items-center "
                        >
                          <div className="bg-white dark:bg-gray-100 rounded-full w-[1.2rem] h-[1.2rem] flex flex-shrink-0 justify-center items-center relative">
                            <input
                              onClick={() =>
                                packageSelect(
                                  premierPack[0]?.membershipId,
                                  premierPack[0]?.membershiptype,
                                  premierPack[0]?.viewPhoneNumber,
                                  premierPack[0]?.price,
                                  4
                                )
                              }
                              checked={
                                isDefaultSelectedId ==
                                  premierPack[0]?.membershipId
                                  ? true
                                  : false
                              }
                              aria-labelledby="label3"
                              type="radio"
                              name="radio"
                              id="option5"
                              className="checkbox appearance-none   accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer w-full h-full"
                            />
                            <div
                              className={`${isDefaultSelectedId ==
                                premierPack[0]?.membershipId
                                ? "check-icon flex  border-4 border-[#D10A11] rounded-full w-full h-full z-1"
                                : "check-icon hidden  border-4 border-[#D10A11] rounded-full w-full h-full z-1"
                                }`}
                            ></div>
                          </div>
                          <p className="text-[#D71920] mr-2  font-semibold text-[17px] font-segeo whitespace-nowrap">
                            {coupenCodeResponse != "" ? (
                              premierPackCoupon[0]?.strikePrice ? (
                                <>
                                  <span className="text-[#545454] line-through mr-2 whitespace-nowrap">
                                    ₹ {convertToCurrency(premierPackCoupon[0]?.price)}
                                  </span>

                                  <span>
                                    {" "}
                                    ₹ {convertToCurrency(premierPackCoupon[0]?.strikePrice)}{" "}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span> ₹ {convertToCurrency(premierPackCoupon[0]?.price)} </span>
                                </>
                              )
                            ) : premierPack[0]?.strikePrice ? (
                              <>
                                <span className="text-[#545454] line-through mr-2 whitespace-nowrap">
                                  ₹ {convertToCurrency(premierPack[0]?.price)}
                                </span>

                                <span> ₹ {convertToCurrency(premierPack[0]?.strikePrice)} </span>
                              </>
                            ) : (
                              <>
                                <span> ₹ {convertToCurrency(premierPack[0]?.price)} </span>
                              </>
                            )}


                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div></div>
            <div className="px-8 hidden md:block md:border-t-2 md:border-[#00000029] md:mt-[2rem] mb-3 md:mb-0">
              {coupenCodeResponse != "" && (
                <div className=" block ">
                  <p className="mt-[2.75rem] text-[#7A7A7A] text-[14px] lg:text-[16px]  md:font-segeo  text-end ">
                    Offers Valid till &nbsp;
                    {applyCoupenData?.offer_valid}
                  </p>
                </div>
              )}
            </div>
            <div className={`${isDefaultSelectedId == "22" ? "cursor-not-allowed" : ""} md:mt-[2rem] flex  justify-center  border-b-2 border-[#00000029] pb-[3rem] md:pb-0 mx-8 mt-[2rem]  md:border-b-0 md:border-none `}>
              {/* <div className="md:col-span-3"></div> */}
              <label htmlFor="option7" id="addonPackSection" className="">
                <div className=" relative">
                  <div className="flex  justify-center  ">
                    <label htmlFor="option7" className="bg-[#FDC300] md:bg-[#FFC758] font-cabin_medium  flex justify-start md:justify-center text-[16px] absolute  top-[0.5rem]  text-[#000000]  rounded-lg pl-[1rem]  md:px-[2rem] py-2 w-[18rem] md:w-auto ">
                      Add-on Pack
                    </label >
                  </div>
                  <div className="bg-[#FFFFFF] shadow-[0px_2px_5px_#FFA5A8] md:shadow-[1px_3px_11px_#00000029] rounded-lg ">
                    <div className=" md:pl-[2rem] md:pr-[1rem] md:border-t-[0.7rem] md:border-t-[#FFC758] rounded-lg lg:pt-[2.1rem] md:pb-[1.2rem] lg:pl-[3rem] lg:pr-[1rem] flex space-x-3  justify-center  md:justify-start mt-[1.5rem]  ">
                      <div className="pt-[3rem] md:pt-[2rem] lg:pt-0 ">
                        <div className="flex px-[1rem] pb-[1rem] md:pb-0 md:px-0 space-x-3 md:space-x-5 border-b-[1px] border-b-[#E9E9E9] md:border-none ">
                          <div className=" flex justify-center">
                            <img
                              className=" w-[3rem] md:w-[3.5rem]"
                              src={HoroscopeIcon}
                              alt=""
                            />
                          </div>
                          <div className="md:flex md:items-center">
                            <p className="text-center  text-[#7A7A7A] text-[19px] md:text-[23px] font-bold font-segeo md:font-normal md:font-cabin_regular ">
                              {responseData?.AddonPackages[0]?.membershiptype}
                            </p>
                            <p className={`${isDefaultSelectedId == "22" ? "text-gray-400" : " text-[#D10A11]"}  whitespace-normal md:mt-[0.8rem] text-center block md:hidden text-[12px] font-normal `}>
                              {responseData?.AddonPackages[0]?.message}
                            </p>
                          </div>
                        </div>
                        <p className={`${isDefaultSelectedId == "22" ? "text-gray-400" : " text-[#D10A11]"}  md:mt-[0.5rem] hidden md:flex text-center md:text-[14px] lg:text-[16px] font-normal  `}>
                          {responseData?.AddonPackages[0]?.message}
                        </p>
                        <div className="flex justify-center space-x-3 py-4 md:hidden">
                          <input
                            type="checkbox"
                            disabled={isDefaultSelectedId == "22"}
                            style={{ border: "0.1em solid #7A7A7A" }}
                            className="relative border-[#707070] top-[0.5rem] md:hidden "
                            name="check"
                            id="option7"
                            checked={isAddOnSelect}
                            onClick={(event) => {
                              addOnSelectMob(
                                event,
                                responseData?.AddonPackages[0].membershipId,
                                responseData?.AddonPackages[0].price
                              );
                            }}
                          />
                          <p className={`${isDefaultSelectedId == "22" ? "text-gray-400" : "text-[#D10A11]"} text-start text-[20px] font-bold font-sans`}>
                            Just ₹ {convertToCurrency(responseData?.AddonPackages[0]?.price)}
                          </p>
                        </div>
                        <p className={`${isDefaultSelectedId == "22" ? "text-gray-400" : " text-[#000000]"} hidden md:flex mt-[0.5rem]  text-start text-[30px] font-bold font-sans`}>
                          Just ₹ {convertToCurrency(responseData?.AddonPackages[0]?.price)}
                        </p>

                      </div>
                    </div>
                    {isDefaultSelectedId == "22"
                      ? <></>
                      : <div className="justify-end hidden md:flex">
                        {isAstroAddOnSelect == false ? (
                          <button
                            onClick={(event) => {
                              setIsAstroAddOnSelect(true)
                              addOnSelectWeb(
                                isAddOnSelect,
                                responseData?.AddonPackages[0].membershipId,
                                responseData?.AddonPackages[0].price
                              );
                              onClickTrack(EventName.paymentSelectAstroMatch);
                            }}
                            className=" bg-[#D71920] font-cabin_regular rounded-br-lg text-white rounded-tl-lg md:px-[3.5rem] lg:px-[3.5rem] py-2 "
                          >
                            Select
                          </button>
                        ) : (
                          <button
                            onClick={(event) => {
                              setIsAstroAddOnSelect(false)
                              addOnSelectWeb(
                                isAddOnSelect,
                                responseData?.AddonPackages[0].membershipId,
                                responseData?.AddonPackages[0].price
                              );
                              onClickTrack(EventName.paymentSelectAstroMatch);
                            }}
                            className=" bg-[#FFC758] font-cabin_regular rounded-br-lg text-black rounded-tl-lg px-[3rem] py-2 "
                          >
                            Selected
                          </button>
                        )}
                      </div>}
                  </div>
                </div>
                {/* <div className="bg-[#D10A11] text-white md:col-span-3 mx-3 rounded-[14px]  ">
               <p className=" text-center p-2  font-bold text-[18px] "> Add-on Packages </p>
            </div> */}
                {/* <div className="mt-[3rem]">
                  <div className="px-[2rem] flex justify-center  ">
                  {responseData?.AddonPackages?.map((add,i) => (
                     //console.log(responseData?.AddonPackages,"add.membershipId,add.price"),
                    <>
                    <input type="checkbox" className='relative top-[0.6rem] right-[-2rem] md:top-[2rem] md:mr-[2rem]' name="check" 
                    onClick={(event)=>{addOnSelect(event,add.membershipId,add.price)
                    
                  }
                     
                  }
                    />
               <div className="" id={add.membershipId}>
            <div className=" flex justify-center">
             <img className="  " src={HoroscopeIcon} alt="" />
             </div>
                    <p className="text-center  pt-3  text-[#7A7A7A] text-[14px] font-bold ">
                        {add.membershiptype}
                    </p>
                    <p className="text-[#D10A11] text-center text-[14px] font-bold ">
                        {add.message}
                    </p>
                     <p className=" text-[#000000] text-center text-[30px] font-bold">
                        Just ₹ {add.price}
                    </p>
                </div>
                </>
                ))}
               
               </div>
                </div> */}
              </label>
              {/* <div className="md:col-span-3"></div> */}
            </div>
            <div className=" hidden md:block md:px-8 md:mt-[4rem] ">
              <div className="border-t-2 border-[#00000029]"></div>
            </div>
            <div className=" mb-[1rem] md:mb-0 ">
              <div className=" hidden md:mt-[2rem]  md:grid grid-cols-12 md:pl-6 lg:px-8 mb-7">
                <div
                  className={
                    responseData?.discountCode?.length > 0
                      ? " md:col-span-6 lg:col-span-4 grid place-content-center md:pl-[2rem] lg:pl-[4rem] "
                      : "md:col-span-6 lg:col-span-4 grid place-content-center "
                  }
                >
                  <div className="flex justify-around ">
                    <p className="  col-span-9 p-2  font-segeo text-[21px] lg:text-[25px] text-[#D71920] pr-[3rem]">
                      Order Summary
                    </p>
                  </div>
                  <div
                    className={
                      responseData?.discountCode?.length > 0
                        ? " flex md:mt-[1rem] lg:mt-[1.5rem] md:pl-[2rem] lg:pl-[5rem] xl:pl-[4rem] "
                        : "flex md:mt-[1rem] lg:mt-[1.5rem]"
                    }
                  >
                    <div className=" whitespace-nowrap flex justify-center text-[#7A7A7A] md:text-[14px] lg:text-[17px] font-segeo ">
                      <p> Package Selected </p>
                      <span className="pl-2"> :</span>
                    </div>
                    <p
                      className={
                        responseData?.discountCode?.length > 0
                          ? " font-segeo text-[#000000] md:text-[14px] lg:text-[20px] pl-3 "
                          : "font-segeo text-[#000000] md:text-[11px] lg:text-[17px] pl-3"
                      }
                    >
                      {" "}
                      {selectedPackage.replace("*", "").replace("₹", "₹")}{" "}
                    </p>
                  </div>
                  <div
                    className={
                      responseData?.discountCode?.length > 0
                        ? " flex md:mt-[1rem] lg:mt-[1.5rem] md:pl-[2rem] lg:pl-[5rem] xl:pl-[4rem]  "
                        : " flex md:mt-[1rem] lg:mt-[1.5rem] "
                    }
                  >
                    <p className=" flex justify-center text-[#7A7A7A] md:text-[14px] lg:text-[17px] font-segeo ">
                      Add-on Selected <span className="pl-4"> :</span>
                    </p>
                    <p
                      className={
                        responseData?.discountCode?.length > 0
                          ? " font-segeo text-[#000000] md:text-[14px] lg:text-[20px] pl-[1.1rem] "
                          : " font-segeo text-[#000000] md:text-[11px] lg:text-[17px] pl-[1.1rem] "
                      }
                    >
                      {" "}
                      {selectedAddOnPackage}{" "}
                      {selectedAddOnPackage == "" ? null : <br />}
                      {selectedOfflinePackage}{" "}
                    </p>
                  </div>
                  {responseData?.discountCode?.length > 0 && (
                    <div className="  mt-[1.5rem] lg:ml-[3rem] shadow-[3px_3px_18px_#00000029] rounded-md w-[22rem] lg:w-[32rem]">
                      <Formik
                        initialValues={{
                          discountCode: "",
                        }}
                        // validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={DisplayingErrorMessagesSchema}
                        onSubmit={(values) => {
                          ApplyCoupenFormSubmission(values);
                        }}
                      >
                        {({ errors }) => (
                          <Form className="">
                            <div className=" md:px-[1rem]  lg:pl-[1rem] lg:pr-[1.75rem] py-[1.75rem] ">
                              <div className=" ">
                                <div className=" grid grid-cols-9">
                                  <div className=" md:col-span-3 lg:col-span-2 flex ">
                                    <p className="  text-[#000000] text-[14px] pt-[0.5rem] lg:text-[16px] whitespace-nowrap font-segeo ">
                                      Discount code :
                                    </p>
                                  </div>
                                  <div className=" md:col-span-6 lg:col-span-7">
                                    <div className="grid grid-cols-6 gap-1 ">
                                      <div className=" col-span-4 lg:pl-[0.5rem] rounded-md ">
                                        <Field
                                          type="text"
                                          readonly
                                          id="discountCode"
                                          name="discountCode"
                                          value={
                                            isApplied ? selectedDiscountCode : ""
                                          }
                                          className=" text-[#000000]  p-2 w-full bg-[#dadada]  outline-none rounded-md text-[16px] "
                                        />
                                      </div>
                                      {/* {!isApplied && */}
                                      <button
                                        onClick={() => onClickTrack(EventName.paymentDiscountCodeApplyButton)}
                                        className="bg-[#D71920] col-span-2 md:h-[2.2rem] flex justify-center items-center md:w-[4rem] lg:w-[7rem]  md:mt-1 lg:mt-0 lg:h-[2.5rem] font-segeo  text-white rounded-lg md:px-[0.1rem] md:py-[0.1rem] lg:px-[2rem] lg:py-2 "
                                        type="submit"
                                      >
                                        Apply
                                      </button>
                                    </div>

                                    {isApplied && (
                                      <div className="grid grid-cols-6">
                                        <div className="col-span-4 lg:pl-[0.5rem] ">
                                          <button className=" flex pl-[0.4rem] md:justify-start lg:justify-start mt-[0.4rem]   items-center md:h-[1.5rem] justify-center w-full lg:h-[1.5rem] font-segeo text-white bg-[#D10A11] lg:pl-[1.5rem] lg:pr-[0.5rem]  rounded-tl-lg rounded-br-lg lg:py-2     ">
                                            <div>
                                              {applyCoupenData?.status ==
                                                "Invalid" ? (
                                                <img
                                                  src="/Assets/Images/DrawerBackButton.svg"
                                                  alt=""
                                                  className="brightness-0 invert-[1] w-5 h-5"
                                                />
                                              ) : (
                                                <img
                                                  className="w-[0.7rem] lg:w-[1rem] "
                                                  src="/Assets/Images/Group 850.svg"
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                            <p className=" ml-2 text-[8px] lg:text-[12px] whitespace-nowrap">
                                              {applyCoupenData?.status ==
                                                "Invalid"
                                                ? "Invalid coupen code"
                                                : "Offer code has been applied"}
                                            </p>
                                          </button>
                                        </div>
                                        <div className="col-span-2"></div>
                                      </div>
                                    )}
                                    {!isApplied && errors.discountCode && (
                                      <div className="grid grid-cols-6">
                                        <div className="col-span-4 lg:pl-[0.5rem] ">
                                          <div className="  flex  pl-[0.4rem] md:justify-start lg:justify-start mt-[0.4rem]   items-center md:h-[1.5rem] justify-center w-full lg:h-[1.5rem] font-segeo text-white bg-[#D10A11]  lg:pl-[1.5rem] lg:pr-[0.5rem] whitespace-nowrap  rounded-tl-lg rounded-br-lg lg:py-2    ">
                                            <button className=" flex items-center   ">
                                              <div>
                                                <img
                                                  className="w-[0.7rem] lg:w-[1rem] "
                                                  src="/Assets/Images/Group 850.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <p className=" ml-2 text-[10px] lg:text-[12px] whitespace-nowrap ">
                                                {errors.discountCode}
                                              </p>
                                            </button>
                                          </div>
                                        </div>
                                        <div className="col-span-2"></div>
                                      </div>
                                    )}
                                    <div className=" mt-[1rem]  grid grid-cols-9 pl-[0.5rem] ">
                                      <div className=" text-[#7A7A7A] md:grid lg:flex md:col-span-4 lg:col-span-2 font-segeo text-[10px] lg:text-[10px]  ">
                                        <span className="block lg:hidden">
                                          Offers Available :
                                        </span>
                                        <span className="hidden lg:block">
                                          Offers Available
                                        </span>
                                        <span className="hidden lg:block">
                                          {" "}
                                          :
                                        </span>
                                      </div>
                                      <div className=" md:col-span-4 lg:col-span-5  ">
                                        <span className="text-[#7A7A7A]   font-segeo text-[10px] lg:text-[10px] ">
                                          {/* 10off - 100off */}
                                          {responseData?.discountCode
                                            ?.slice(0)
                                            .reverse()
                                            .map((discount, i) => (
                                              <>
                                                <p className="">
                                                  <button
                                                    type="submit"
                                                    onClick={(e) =>
                                                      discountCodeClick(
                                                        discount.amount
                                                      )
                                                    }
                                                    className="text-[#D10A11] "
                                                  >
                                                    {discount.amount}
                                                  </button>{" "}
                                                  - {discount.description}
                                                </p>
                                              </>
                                            ))}
                                        </span>

                                        {/* {responseData?.discountCode?.slice(0).reverse().map((discount,i) => (
                    <>
                    <p className=" text-[#7A7A7A] text-[16px] lg:text-[17px] ">
                    <button type="submit" onClick={(e)=>discountCodeClick(discount.amount)} className='text-[#D10A11]'>
                        {discount.amount}</button> - {discount.description}
                    </p>
                    </>
                 ))} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}

                </div>

                <div className=" md:col-span-6 lg:col-span-7 md:pl-[2rem] lg:pl-[13rem] ">
                  <div className="flex justify-center">
                    <div>
                      {/* {coupenCodeResponse!="" && */}
                      <>
                        <div className="grid grid-cols-2 border-b-[1px] border-[#00000029]  ">
                          <p className=" flex border-r-[1px] py-[1rem] px-[2rem] border-[#00000029] justify-start col-span-1 text-[#000000] md:text-[16px] lg:text-[17px] font-segeo ">
                            Description
                          </p>
                          <p className="  col-span-1 text-[#000000] py-[1rem] px-[2rem] text-[17px] md:text-[16px] lg:text-[17px] font-segeo pl-[1.75rem] ">
                            Amount
                          </p>
                        </div>
                        {coupenCodeResponse?.amount && (
                          <div className="font-segeo">
                            <div className="grid grid-cols-2 border-b-[1px] border-[#00000029] ">
                              <p className=" flex border-r-[1px] py-[1rem] px-[2rem] border-[#00000029] justify-start col-span-1 text-[#7A7A7A] md:text-[16px] lg:text-[17px] font-segeo ">
                                Order value
                              </p>
                              <p className=" font-segeo py-[1rem] line-through px-[2rem] col-span-1 text-[#7A7A7A] text-[17px] md:text-[16px] lg:text-[17px] pl-[1.75rem] ">
                                ₹ {convertToCurrency(selectedPackageAmount)}
                              </p>
                            </div>
                          </div>
                        )}
                        {coupenCodeResponse?.amount && (
                          <div className="font-segeo">
                            <div className="grid grid-cols-2 border-b-[1px] border-[#00000029] ">
                              <p className=" flex border-r-[1px] py-[1rem] px-[2rem] border-[#00000029] justify-start text-[#7A7A7A] md:text-[16px] lg:text-[17px]  ">
                                You Save
                              </p>
                              <p className="  text-[#7A7A7A] py-[1rem] px-[2rem] text-[17px] md:text-[16px] lg:text-[17px] pl-[1.75rem] ">
                                ₹{" "}
                                {convertToCurrency(selectedPackageAmount -
                                  coupenCodeResponse.amount)}
                              </p>
                            </div>
                          </div>
                        )}
                      </>
                      {/* }  */}
                      <div className="font-segeo ">
                        <div className="grid grid-cols-2  ">
                          <p className=" flex justify-center py-[1rem] px-[2rem] text-[#000000] md:text-[13px] lg:text-[17px] ">
                            Amount payable
                          </p>
                          <p className="  text-[#000000] py-[1rem] px-[2rem] font-segeo text-[17px] md:text-[16px] lg:text-[17px] pl-[1.75rem] ">
                            ₹{" "}
                            {convertToCurrency(coupenCodeResponse?.amount
                              ? coupenCodeResponse.amount
                              : selectedPackageAmount)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <p
                      onClick={() => {
                        payNowClicked();
                        onClickTrack(EventName.SelectPaynowButton);
                      }
                      }
                      className=" w-[15rem] bg-[#D10A11] cursor-auto text-center p-2 text-white  font-segeo  text-[18px] rounded-[8px] cursor-pointer"
                    >
                      Pay Now
                    </p>
                  </div>
                  {showPayNowErrorMsg && (
                    <p className="text-[#D10A11] font-[12px] text-center font-cabin_medium underline ">
                      Please select any package
                    </p>
                  )}
                  <p className=" hidden md:flex  justify-center text-[#7A7A7A] text-[15px] font-cabin_medium  underline mt-[0.5rem]  ">
                    {" "}
                    {responseData?.serviceTax}{" "}
                  </p>
                  <p className=" hidden md:flex  justify-center text-[#7A7A7A] text-[15px] font-cabin_medium underline mt-[0.2rem]  ">
                    <span
                      onClick={() => {
                        setShowPolicyPopup(true);
                      }}
                      className="cursor-pointer"
                    >
                      *Fair Usage Policy{" "}
                    </span>
                  </p>
                  <Link to={{ pathname: RouteSegments.TERMS_AND_CONDITION, search: "premier-pack" }} target="_blank" className=" flex  justify-center text-[#7A7A7A] text-[14px] underline  mt-[0.2rem]  ">
                    *Terms & Conditions of Kalyan Premier membership
                  </Link>
                  <p className=" hidden md:flex  justify-center text-[#7A7A7A] text-[14px] font-cabin_medium   mt-[0.2rem]  ">
                    {responseData?.kalyanJewellers}
                  </p>
                </div>
              </div>
              {isV3User ? null :
                <Footer page="payment" />
              }
              {/* <!-- order summary mobile view --> */}
              <div className=" block md:hidden ">
                <div className=" block md:hidden ">
                  <div className="md:bg-[#D10A11] mx-[3rem] mt-[2rem]  text-[#D10A11] md:text-white   rounded-[14px]  ">
                    <p className=" text-center p-2  font-bold text-[18px]    ">
                      Order Summary
                    </p>
                  </div>
                </div>
                <div className="mt-[1.5rem] mx-[1.5rem] pb-[1.5rem] border-b-2 border-[#00000029] ">
                  <div className=" grid grid-cols-12  mt-[1rem] ">
                    <div className=" col-span-5 flex justify-start text-[#575556] text-[14px]  whitespace-nowrap">
                      <p> Package Selected :</p>
                    </div>

                    <div className=" col-span-7 flex justify-start pl-3 ">
                      {isV3User ?
                        <p className="   text-[#575556] text-[14px]  w-[87%]">
                          {" "}
                          {typeof selectedPackage === 'string' && selectedPackage.includes("₹") ? (
                            <>
                              {selectedPackage.split("₹")[0]}
                              <RupeeSymbol />
                              {selectedPackage.split("₹").slice(1).join("₹")}
                            </>
                          ) : (
                            selectedPackage
                          )} </p>
                        : <p className="   text-[#575556] text-[14px]  ">
                          {" "}
                          {selectedPackage}

                        </p>}
                    </div>
                  </div>
                  <div className=" grid grid-cols-12  mt-[1rem] ">
                    <div className=" col-span-5 flex justify-start text-[#575556] text-[14px]  whitespace-nowrap">
                      <p> Add-on Selected :</p>
                    </div>

                    <div className=" col-span-7 flex justify-start ">
                      {isV3User ?
                        <p className="   text-[#575556] text-[14px]  pl-3 w-[93%]">
                          {typeof selectedAddOnPackage === 'string' && selectedAddOnPackage.includes("₹") ? (
                            <>
                              {selectedAddOnPackage.split("₹")[0]} 
                              <RupeeSymbol />
                              {selectedAddOnPackage.split("₹").slice(1).join("₹")}
                              {selectedOfflinePackage && selectedAddOnPackage ? <br /> : " "}
                              {selectedOfflinePackage}
                            </>
                          ) : (
                            <>
                              {isAddOnSelect ? responseData?.AddonPackages[0].membershiptype + " " + "-" +" ": ""}
                              {isAddOnSelect ? <RupeeSymbol /> : ""}
                              {" "}{selectedAddOnPackage}
                              {selectedOfflinePackage && selectedAddOnPackage ? <br /> : " "}
                              {selectedOfflinePackage.split("₹")[0]}{selectedOfflinePackage || selectedAddOnPackage ? <RupeeSymbol />:""}{selectedOfflinePackage.split("₹").slice(1).join("₹")}
                            </>
                          )}
                        </p>
                        : <p className="   text-[#575556] text-[14px]  pl-3">
                          {" "}
                          {selectedAddOnPackage}{" "}
                        </p>}
                    </div>
                  </div>
                  {responseData?.discountCode?.length > 0 && (
                    <div className="  mt-[1.5rem] lg:ml-[3rem] ">
                      <Formik
                        initialValues={{
                          discountCode: "",
                        }}
                        // validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={DisplayingErrorMessagesSchema}
                        onSubmit={(values) => {
                          ApplyCoupenFormSubmission(values);
                        }}
                      >
                        {({ errors }) => (
                          <Form className="">
                            <div className=" md:px-[2.75rem] lg:px-[2.75rem]  ">
                              <div className="grid grid-cols-12  flex space-x-3 ">
                                <div className="col-span-5 flex ">
                                  <p className="  text-[#575556] text-[14px]  ">
                                    Discount code :
                                  </p>
                                </div>

                                <div className=" col-span-3 border-2 border-[#8F8F8F] rounded-md w-[6rem]">
                                  <Field
                                    type="text"
                                    id="discountCode"
                                    name="discountCode"
                                    value={isApplied ? selectedDiscountCode : ""}
                                    className=" text-[#575556] p-2 w-full  rounded-md text-[14px] "
                                  />
                                </div>
                              </div>
                              {!isApplied && (
                                <button className="hidden md:block" type="submit" onClick={() => onClickTrack(EventName.paymentDiscountCodeApplyButton)}>
                                  Apply
                                </button>
                              )}
                              <br />

                              {isApplied && (
                                <button className="float-right flex items-center md:h-[1.5rem] lg:h-[2.5rem] text-[#D10A11] bg-white md:pl-[0.5rem] md:pr-[1.5rem] lg:pl-[0.5rem] lg:pr-[2.5rem] whitespace-nowrap  rounded-tl-lg rounded-br-lg lg:py-2 md:text-[12px] lg:text-[13px]  -mt-[57px] -mr-[12px] pr-[0.5rem] ">
                                  <div>
                                    {applyCoupenData?.status == "Invalid" ? (
                                      <img
                                        src="/Assets/Images/CloseIconRed.svg"
                                        alt=""
                                        className="w-5 h-5"
                                      />
                                    ) : (
                                      <img
                                        src="/Assets/Images/Group 1603.svg"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <p className="ml-2">
                                    {applyCoupenData?.status == "Invalid"
                                      ? "Invalid"
                                      : "Applied"}
                                  </p>
                                </button>
                              )}
                              {!isApplied && errors.discountCode && (
                                <div className="hidden md:flex justify-end md:justify-end lg:justify-start mt-[1rem] lg:ml-[8.7rem] ">
                                  <button className=" flex items-center md:h-[1.5rem] lg:h-[2.5rem] text-white bg-[#D10A11] md:pl-[0.5rem] md:pr-[1.5rem] lg:pl-[0.5rem] lg:pr-[2.5rem] whitespace-nowrap  rounded-tl-lg rounded-br-lg lg:py-2 md:text-[12px] lg:text-[13px]  ">
                                    <div>
                                      <img
                                        src="/Assets/Images/Group 850.svg"
                                        alt=""
                                      />
                                    </div>
                                    <p>{errors.discountCode}</p>
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className=" grid grid-cols-12  space-x-4">
                              <div className="col-span-5 text-[#575556] text-[14px]  whitespace-nowrap">
                                Offers Available :
                              </div>
                              {/* <div className='col-span-1'><p>:</p></div> */}
                              <div className="col-span-7">
                                {responseData?.discountCode
                                  ?.slice(0)
                                  .reverse()
                                  .map((discount, i) => (
                                    <>
                                      <p className=" text-[#575556] text-[14px] ">
                                        <button
                                          type="submit"
                                          onClick={(e) =>
                                            discountCodeClick(discount.amount)
                                          }
                                          className="text-[#D10A11]"
                                        >
                                          {discount.amount}
                                        </button>{" "}
                                        - {discount.description}
                                      </p>
                                      <br />
                                    </>
                                  ))}
                              </div>
                            </div>
                            {/* </div> */}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
                <div className="mt-[1.5rem] mx-[1.5rem] md:pb-[1.5rem]  ">
                  {coupenCodeResponse && (
                    <>
                      {coupenCodeResponse?.amount &&
                        <div className=" grid grid-cols-12  mt-[1rem] ">
                          <div className=" col-span-6 flex justify-between text-[#575556] text-[14px]  ">
                            <p> Order value : </p>
                          </div>
                          {/* <div className="col-span-1 flex justify-center"> <p className=" flex items-center " ></p></div> */}
                          <div className=" col-span-6 flex justify-start ">
                            <p className="   text-[#575556] text-[16px] line-through flex">
                              {" "}
                              <span className="rupeefont">₹ {convertToCurrency(selectedPackageAmount)}{" "}</span>
                            </p>
                          </div>
                        </div>
                      }
                      {coupenCodeResponse?.amount &&
                        <div className=" grid grid-cols-12  mt-[1rem] ">
                          <div className=" col-span-6 flex justify-between text-[#575556] text-[14px]  ">
                            <p> You Save : </p>{" "}
                          </div>

                          {/* <div className="col-span-1 flex justify-center"><p className=" flex items-center " ></p></div> */}
                          <div className=" col-span-6 flex justify-start ">
                            <p className="text-[#575556] text-[14px]  ">
                              {" "}
                              <span className="rupeefont">₹ {" "}</span>
                              {convertToCurrency(selectedPackageAmount -
                                coupenCodeResponse.amount)}{" "}
                            </p>
                          </div>
                        </div>
                      }
                    </>
                  )}
                  <div className=" grid grid-cols-12  mt-[1rem] ">
                    <div className=" col-span-6 flex justify-start text-[#575556] text-[14px] font-semibold  whitespace-nowrap">
                      <p> Amount payable : </p>
                    </div>
                    {/* <div className="col-span-1 flex justify-center"><p className=" flex items-center "></p></div> */}
                    <div className=" col-span-6 flex justify-start ">
                      <p className=" font-semibold text-[#575556] text-[16px]  ">
                        {" "}
                        <span className="rupeefont">₹</span>{" "}
                        {convertToCurrency(coupenCodeResponse?.amount
                          ? coupenCodeResponse.amount
                          : selectedPackageAmount)}{" "}
                      </p>
                    </div>
                  </div>
                  <div className=" flex justify-center my-[1rem] ">
                    <button
                      onClick={() => {
                        payNowClicked();
                        onClickTrack(EventName.SelectPaynowButton);
                      }
                      }
                      className=" bg-[#D10A11] px-[4rem]   text-center  py-2  text-white font-bold text-[18px] rounded-[8px]  "
                    >
                      Pay Now
                    </button>
                  </div>
                  {showPayNowErrorMsg && (
                    <p className="text-[#D10A11] font-[12px] text-center font-cabin_medium underline ">
                      Please select any package
                    </p>
                  )}
                  <p className=" flex  justify-start text-[#7A7A7A] text-[14px] underline  mt-[0.5rem]  ">
                    {" "}
                    Inclusive of GST{" "}
                  </p>
                  <p className="text-[#62ABFE] hidden  justify-end  text-[14px] underline  ">
                    Continue to add on packages
                  </p>
                  <p className=" flex  justify-start text-[#7A7A7A] text-[14px]  underline  mt-[0.2rem] ">
                    <span
                      onClick={() => {
                        setShowPolicyPopup(true);
                      }}
                      className="cursor-pointer"
                    >
                      *Fair Usage Policy{" "}
                    </span>{" "}
                  </p>
                  <Link to={{ pathname: RouteSegments.TERMS_AND_CONDITION, search: "premier-pack" }} target="_blank" className=" flex   text-[#7A7A7A] text-[14px] underline  mt-[0.2rem]  ">
                    *Terms & Conditions of Kalyan Premier membership
                  </Link>
                  <p className=" flex  justify-start text-[#7A7A7A] text-[14px] mt-[0.2rem]  ">
                    {responseData?.kalyanJewellers}
                  </p>
                  {/* <p className=" flex  justify-start text-[#7A7A7A] text-[14px]   ">
                  of Kalyan Jewellers India Pvt Ltd
                </p> */}
                </div>
              </div>
            </div>
            {isCommunitySite ? null : <DownloadAppBar dashboardData={ProfileData} />}
          </div>
        </div>


      ) : (
        <center>
          <img src={LoaderGif} alt=""/>
        </center>
      )}
    </>
  );
}
