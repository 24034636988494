import React, { useEffect, useState, useContext } from "react";

import { HelpProfileAccordianComponent } from "../../Components/ViewMessageComponents/AccordianComponents";
import "../../index.css";

import RouteSegments from "../../Routes/RouteSegment";
import { Link } from 'react-router-dom';
// images
import DeletephotoStep1img from '../../Images/DeletephotoStep1img.png'
import DeletephotoStep2img from '../../Images/DeletephotoStep2img.png'
import DeletephotoStep3img from '../../Images/DeletephotoStep3img.png'
import DeletephotoStep4img from '../../Images/DeletephotoStep4img.png'
import DeletephotoStep6img from '../../Images/DeletephotoStep6img.png'
import DeletephotoStep7img from '../../Images/DeletephotoStep7img.png'

const HelpPage = (props) => {



    return (
        <>


            <div className="p-3">
                <div>
                    <p className="text-[#575556] font-bold text-[17px] pl-2"> FAQ</p>

                </div>

                <div className=" mx-auto mt-3">
                    <div
                    //  activeclassName= ' focus:outline-none'
                    //  bordeclassName=' border-radiums:10px; border-width:1px; border-color:#707070 '

                    // className=""
                    >
                        <div className="rounded-[7px] ">
                            <HelpProfileAccordianComponent


                                title={"How to login?"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2 font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={true}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Visit www.kalyanmatrimony.com
                                        </li>
                                        <li>
                                            Enter the Registered e-mail ID and Password
                                        </li>
                                        <li>
                                            Click on the "SIGN IN" option on the homepage
                                        </li>
                                    </ul>



                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"Forgot Password"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Please select the "Forgot Password?" option and enter the registered e-mail ID
                                        </li>
                                        <li>
                                            A link to reset the password will be sent to your registered e-mail ID
                                        </li>
                                        <li>
                                            Click on the link and enter the new password
                                        </li>
                                        <li>
                                            Now you can use the new password to login to your profile
                                        </li>
                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"How to Edit Profile?"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Login to your profile with your registered e-mail ID and Password
                                        </li>
                                        <li>
                                            After you login to your profile, please select the "Edit Profile" option from the top navigation menu
                                        </li>
                                        <li>
                                            Select "My Profile" in the Edit Profile section
                                        </li>
                                        <li>
                                            Click on the pen symbol that appears next to the section you wish to modify
                                        </li>
                                        <li>
                                            Make the necessary changes and click on the "Save" option
                                        </li>
                                        <li>
                                            Modified content will be displayed on your profile after approval
                                        </li>
                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"How to Search Profile?"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Login to your profile with your registered e-mail ID and Password
                                        </li>
                                        <li>
                                            After logging-in please select the "Search" option from the top navigation menu                                        </li>
                                        <li>
                                            Your partner Expectations criteria will be prefilled by default                                        </li>
                                        <li>
                                            Make the necessary changes if required and click on the "Search" button
                                        </li>
                                        <li>
                                            Profiles that match the search criteria will be displayed
                                        </li>
                                        <li>
                                            If you wish to filter the profiles further, you can choose the necessary filter options from the left navigation menu
                                        </li>
                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"How to Send Messages?"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <p className="text-[15px] font-bold px-3 py-3">Free member:</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Login to your profile with your registered e-mail ID and Password from the login screen


                                        </li>

                                        <li>
                                            Once you login to your profile, please select the profile you wish to contact from the Search results or from the Dashboard
                                        </li>
                                        <li>
                                            Click the "Send Message" option and select the message you wish to send
                                        </li>
                                        <li>
                                            You will be notified once the member replies to your message
                                        </li>

                                    </ul>
                                    <p className="text-[15px] font-bold px-3">Paid member:</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Login to your profile with your registered e-mail ID and Password

                                        </li>

                                        <li>
                                            After you login to your profile, please select the member you wish to contact from the Search results or from the Dashboard                                        </li>
                                        <li>
                                            Click the "Send Message" option and select the message you wish to send or type your own message and send it to the selected member                                        </li>
                                        <li>
                                            For a quick response we suggest you share your contact details in the message
                                        </li>
                                        <li>
                                            You will be notified once the member replies to your message
                                        </li>

                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"How to Reply to messages?"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Login to your profile with your registered e-mail ID and Password

                                        </li>

                                        <li>
                                            Once you login to your profile, please select the "Inbox" option from the left navigation menu
                                        </li>
                                        <li>
                                            Unread messages will be displayed; if you wish to reply to a message that has already been read, change the status from "Unread" to "Read" to display the message                                        </li>
                                        <li>
                                            Select the message and click the "Reply" button
                                        </li>

                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"How to add Photo?"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Login to your profile with your registered e-mail ID and Password
                                        </li>

                                        <li>
                                            Once you login to your profile, please select the "Edit Profile" option from the top navigation menu
                                        </li>
                                        <li>
                                            Select "My Profile" in the Edit Profile section and choose the option "Photos"
                                        </li>
                                        <li>
                                            Select the photo you wish to display and upload it to your profile
                                        </li>
                                        <li>
                                            Please add images that are less than 10MB
                                        </li>

                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"How to add Horoscope?"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Login to your profile with your registered e-mail ID and Password
                                        </li>

                                        <li>
                                            Once you login to your profile, please select the "Edit Profile" option from the top navigation menu
                                        </li>
                                        <li>
                                            Enter the Date of Birth, Place of Birth and Time of birth to generate a system generated horoscope
                                        </li>
                                        <li>
                                            If you do not wish to generate a horoscope, click on "Upload Horoscope" to add a scanned image
                                        </li>
                                        <li>
                                            Please add images that are less than 10MB
                                        </li>

                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"How to add Trust document?"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Login to your profile with your registered e-mail ID and Password
                                        </li>

                                        <li>
                                            Once you login to your profile, please select the "Edit Profile" option from the top navigation menu
                                        </li>
                                        <li>
                                            Select "My Profile" in the Edit Profile section and choose the option "Trust Mark"
                                        </li>
                                        <li>
                                            You can add ID Proof, Education Proof and Income proof by selecting the respective icons

                                        </li>
                                        <li>
                                            Documents will be visible only to paid members
                                        </li>
                                        <li>
                                            Please add an image that is less than 10MB
                                        </li>
                                        <li>
                                            Following are the list of accepted documents
                                            <ol className="mx-9 mt-2 space-y-1 list-decimal list-outside">
                                                <li>Any Government-issued ID cards for ID proof</li>
                                                <li>Copy of your latest education certificate for Education proof</li>
                                                <li>Copy of your latest employment certificate or pay slip for Occupation proof</li>
                                            </ol>
                                        </li>

                                    </ul>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>
                <div className=" mx-auto mt-3">
                    <div

                    >
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"What are the Packages available?"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}

                                Callback={() => { }}
                            >
                                <div>
                                    <p className="text-[#7A7A7A] text-[13px] px-3 py-3">
                                        We have two major types of packages: Unlimited Validity Packages and Unlimited
                                        Phone Number Packages. Each package has three different types of packages ranging from Rs. 4890 to Rs. 8490.
                                        We also offer an add-on package to avail our Astrological matching facility
                                    </p>
                                    <p className="text-[15px] font-bold px-3">Unlimited Validity Packages:</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Rs.4890 - View 25 Phone numbers and Unlimited* (Validity Days, Chat with Online matches, Send Message to Matches, View Horoscopes)


                                        </li>
                                        <li>
                                            Rs.5490 - View 100 Phone numbers and Unlimited* (Validity Days, Chat with Online matches, Send Message to Matches, View Horoscopes)
                                        </li>
                                        <li>
                                            Rs.8490 - View 150 Phone numbers and Unlimited* (Validity Days, Chat with Online matches, Send Message to Matches, View Horoscopes)
                                        </li>

                                    </ul>
                                    <p className="text-[15px] font-bold px-3">Unlimited Phone Numbers:</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Rs.4890 - 50 Validity Days and Unlimited* (Phone Numbers, Chat with Online matches, Send Message to Matches, View Horoscopes)


                                        </li>
                                        <li>
                                            Rs.5490 - 100 Validity Days and Unlimited* (Phone Numbers, Chat with Online matches, Send Message to Matches, View Horoscopes)
                                        </li>
                                        <li>
                                            Rs.8490 - 200 Validity Days and Unlimited* (Phone Numbers, Chat with Online matches, Send Message to Matches, View Horoscopes)
                                        </li>

                                    </ul>
                                    <p className="text-[15px] font-bold px-3">Add on Packages:</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Astro Match Reports (25 matched profile) - Rs.500

                                        </li>
                                    </ul>

                                    <p className="text-[15px] font-bold px-3">Truly Unlimited:</p>
                                    <ul className="px-12 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400">
                                        <li>
                                            Rs.12790 Unlimited*(Days, Phone Numbers, Chat with online matches, Send Message to Matches, View Horoscopes)
                                        </li>
                                    </ul>

                                    <div className=" px-3 mb-4 flex text-[13px] text-[#7A7A7A] dark:text-gray-400 ">

                                        <p> <Link to={RouteSegments.MEMBERSHIP_REGISTER()} className="text-[#297CFE] whitespace-nowrap hover:underline cursor-pointer">Click here
                                        </Link>
                                        <span className="pl-1 "> to make a payment and upgrade your profile to paid services.</span></p>

                                    </div>
                                </div>

                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>

                <div className=" mx-auto mt-3">
                    <div>
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"How to Buy Muhurat Wedding Jewellery – Wedding Gold, Wedding Diamonds & Wedding Necklace?"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}
                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-9 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400" type="a">
                                        <li>
                                            Most Preferred Method: Get store appointment booked by calling 7338874726, avail best discounts on wide range of collections.
                                        </li>

                                        <li>
                                            Visit Kalyan Jewellers store near me <a href="https://stores.kalyanjewellers.net/" className="underline" target="_blank">Store Locator</a>
                                        </li>
                                        <li>
                                            Visit Online <a href="https://www.kalyanjewellers.net/" className="underline" target="_blank">Kalyan Jewellers</a> 
                                        </li>
                                    </ul>
                                </div>
                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>

                <div className=" mx-auto mt-3">
                    <div>
                        <div className="rounded-[7px]  ">
                            <HelpProfileAccordianComponent
                                title={"Delete Photo"}
                                titleClassName={`flex items-center justify-between  w-full pl-3 pr-2  font-semibold text-[#575556] bg-[#E9E9E9] md:py-[0.5rem] lg:py-2 cursor-pointer md:text-[12px] lg:text-[15px]`}
                                defaultView={false}
                                Callback={() => { }}
                            >
                                <div>
                                    <ul className="px-4 text-[#7A7A7A] text-[13px] py-3 list-disc list-outside dark:text-gray-400" type="a">
                                    <div className=" font-medium text-[#575556] text-[12px] md:text-[14px] lg:text-[16px] ">
               
                <p className=" font-bold text-[15px] md:text-[16px] lg:text-[16px] ">
                    How to delete Photos?
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[1rem]">
                    1. Login to your profile with your registered email id and password.
                    <img src = {DeletephotoStep1img} className='mt-[1rem]' alt=""/>
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]">
                    2. Once you login to your profile, please select the "My Profile" option from the top navigation menu.
                    <img src = {DeletephotoStep2img} className='mt-[1rem]'alt=""/>
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]" >
                    3. Once the user clicks on the My Profile icon, redirect the user to My Profile page.
                    <img src = {DeletephotoStep3img} className='mt-[1rem]'alt=""/>
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]">
                    4. In the “My Profile” section, choose the option "Photos".
                    <img src = {DeletephotoStep4img} className='mt-[1rem]'alt=""/>
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]">
                    5. All the added photos by the user will appear here with a three dots option.
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]" >
                    6. If the user clicks on three dots
                 
                    <p className="text-[#7A7A7A] text-[13px] mt-[1rem] ml-[2rem]" >
                        i)Set as Profile
                    </p> 
                    <p className="text-[#7A7A7A] text-[13px] mt-[1rem] ml-[2rem]" >
                        ii)Delete option present inside.
                    </p>
                    <img src = {DeletephotoStep6img} className='mt-[1rem]' alt="" />
                </p>
                <p className="text-[#7A7A7A] text-[13px] mt-[1rem] md:mt-[2rem]">
                    7. If the user clicks on Delete, the selected photo gets Deleted from the Photos section.
                    <img src = {DeletephotoStep7img} className='mt-[1rem]' alt="" />
                </p>
                
                
            </div>
                                    </ul>
                                </div>
                            </HelpProfileAccordianComponent>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default HelpPage;