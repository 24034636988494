import React, { useState, useEffect } from "react";
import { EducationCheckView } from "../../Components/Edit Profile/PartnerPreference/PartnerPreferenceUtils";
import { EduactionListTag } from "../../Components/Edit Profile/EditProfileUtills";

let selectedID= []

const EducationComponent = (props) => {
 const [eduactiondata, setEducationData] = useState([]);
   /// education 
    const setInitialEducation=()=>{
    setEducationData([]);
    let tempEduData = props?.educationinfofreshData?.education;
    //console.log(tempEduData,"temp");
    tempEduData?.map((e, i) => {
      if (e.heading === "Y") {
        let newsection = {
          title: e,
          options: [],
        };
        for (var index = i + 1; index < tempEduData.length; index++) {
          if (tempEduData[index].heading === "N") {
            newsection.options = [
              ...newsection.options,
              tempEduData[index],
            ];
          } else {
            break;
          }
        }

        let selected = e.selected;
        const len = newsection?.options?.filter((element) => element.selected === "Y")?.length ?? 0

        if (len === 0) {
          selected = "N"
        } else if (newsection?.options?.length !== len) {
          selected = "P"
        } else {
          selected = "Y"
        }

        newsection = {
          ...newsection,
          title: { ...newsection.title, selected }
        }


        setEducationData((oldvalue) => [...oldvalue, newsection]);
      }
    });
  }
    const handleSingleSelect = (value) => {
    let newVal = eduactiondata?.map((ele, i) => {
      return {
        ...ele,
        options: eduactiondata[i].options.map((e, ind) => {
          if (e.id === value) {
            return { ...e, selected: e.selected === "Y" ? "N" : "Y" };
          } else {
            return e;
          }
        }),
      };
    });
    setEducationData(newVal);
    console.log(eduactiondata,"eduactiondata");

    let setAllValue = newVal?.map((ele, i) => {
      return {
        ...ele,
        title: {
          ...ele.title,
          selected:
            newVal[i].options.length > 0 &&
            newVal[i].options
              .map((element, index) => element.selected === "Y")
              .every((v) => v === true)
              ? "Y"
              : newVal[i].options
              .map((element, index) => element.selected === "N")
              .every((v) => v === true)
              ? "N"
              :"P",
        },
      };
    });

    setEducationData(setAllValue);
    setSelectedData(setAllValue)
  };
  const handleSelectAll = (value) => {
    let newVal = eduactiondata?.map((ele, i) => {
      if (ele.title.id === value) {
        return {
          ...ele,
          title: {
            ...ele.title,
            selected: ele.title.selected === "Y" ? "N" : "Y",
          },
          options: eduactiondata[i].options.map((e, ind) => {
            return { ...e, selected: ele.title.selected === "Y" ? "N" : "Y" };
          }),
        };
      } else {
        return ele;
      }
    });

    setEducationData(newVal);
    setSelectedData(newVal)
  };
  const setSelectedData=(value)=>{
    selectedID= []
    {value?.map((ele, index) => {
      
          {ele.title.selected === "Y" && selectedID.push(ele.title.id)}
          {ele?.options.map(
            (e, i) =>
              e.selected === "Y" && selectedID.push(e.id)
          )}
        
          })}
          props?.setEditPreference({ ...props?.editPreference, ["educationId"]: selectedID })
          props.data.setFieldValue('education',selectedID)
          console.log("selectedID",selectedID)
  }

   useEffect(() => {
    setInitialEducation();
    // setInitialOcupation();
  }, [props?.educationinfofreshData]);
  useEffect(() => {
   
  }, [eduactiondata]);

  //console.log(eduactiondata,"eduactiondataeduactiondata");

    return(
        <>
         <div className=" md:flex  md:space-x-3 ">
                          <div className="flex items-center ">
                            <div 
                                className = {`${
            props?.menu === "advancesearch" ? "border border-[#8F8F8F] w-full space-y-2 md:space-y-2 lg:space-y-3 h-[15rem] overflow-y-auto rounded-[4px] p-[1rem] md:p-[0.9rem] lg:p-2.5 " 
            :  "border border-[#8F8F8F] w-full space-y-2 md:space-y-2 lg:space-y-3 h-[15rem] overflow-y-auto rounded-[4px] p-[1rem] md:p-[0.4rem] lg:p-2.5 "
          }  `}
                             >
                              {eduactiondata?.map((e, i) => (
              <EducationCheckView 
              selectedID={selectedID}
              setIsPPEdited={props?.setIsPPEdited}
              fromPath={props?.fromPath}
              setFilterOnchange={props?.setFilterOnchange}
              // onChange={props?.setFilterOnchange}
              setEducationError={props?.setEducationError}
                eduactiondata={eduactiondata}
                key={i}
                title={e.title} 
                value={e.options}
                data={props?.data}
                callBackSelectAll={handleSelectAll}
                callBackSingleSelect={handleSingleSelect}
              />
            ))}
                            </div>
                          </div>
                          <div className=" border border-[#8F8F8F] mt-[2rem] md:mt-0 w-full md:w-[16rem] h-[15rem] overflow-y-auto  rounded-[4px]">
                             <div className="flex flex-wrap m-2">
            {eduactiondata?.map((ele, index) => (
              <>
                {/* {ele.title.selected === "Y" && (
                  <EduactionListTag key={index} text={ele.title.name} />
                )} */}
                {ele?.options.map(
                  (e, i) =>
                    e.selected === "Y" && <EduactionListTag key={i} text={e.name} />
                )}
              </>
            ))}
          </div>
                          </div>
                        </div>
        </>
    )
}

export default EducationComponent
