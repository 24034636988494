
import React, { useEffect, useMemo, useRef, useState } from "react";
import RouteSegments from "../../Routes/RouteSegment";
import {Link} from 'react-router-dom'
import CloseIcon from "../../Images/Group 761@2x.png"
import IdproofImage from '../../Images/Group 950.svg'
import Slider from "react-slick";
import { Dialog, Transition } from "@headlessui/react";
import img1 from '../../Images/left-arrow.svg';
import img2 from '../../Images/right-arrow.svg';

function CustomNextArrow(props) {
  const { className, style, onClick, currentSlide, slideCount } = props;
  const hasNextCard = currentSlide < slideCount - props?.count;
  const handleNextClick = () => {
    if (hasNextCard) {
      onClick();
    }
  };
  return hasNextCard ? (
    <>
      <img alt="" src={img2} className="custom-right-arrow arrowright cursor-pointer" onClick={handleNextClick} />
    </>
  ) : (
    <img alt="" src={img2} className="custom-right-arrow arrowright opacity-75 cursor-not-allowed" />
  );
}
function CustomPrevArrow(props) {
  const { className, style, onClick, currentSlide } = props;
  const hasPrevCard = currentSlide > 0;

  const handlePrevClick = () => {
    if (hasPrevCard) {
      onClick();
    }
  };

  return hasPrevCard ? (
    <>
      <img alt="" src={img1} className="custom-left-arrow arrowleft cursor-pointer" onClick={handlePrevClick} />
    </>
  ) : (
    <img alt="" src={img1} className="custom-left-arrow arrowleft opacity-75 cursor-not-allowed" />
  )
}


const TrustDocumentPopup = (props) => {
  const [image, setImage] = useState();
  const [selectedTrustDocument, setSelectedTrustDocument] = useState("Add ID Proof");
  useEffect(() => {
    if(props?.idproof){
      setImage(props?.idproof);
      setSelectedTrustDocument('Add ID Proof');
    }
    else if(props?.eduproof){
      setImage(props?.eduproof);
      setSelectedTrustDocument('Add Education Proof');
    }
    else if(props?.incomeproof){
      setImage(props?.incomeproof);
      setSelectedTrustDocument('Add Income Proof');
    }
    else{
      setImage("");
    }
  }, [props?.idproof, props?.eduproof, props?.incomeproof]);


  
  const TrustIcons = (props) => {
    return (
      <div
        onClick={() => props.onClick()}
        className="flex flex-col items-center justify-end cursor-pointer"
      >
        <img className="w-[2rem] md:w-[2rem] lg:w-[4rem]" src={props.icon} alt=""/>
        <p className="text-[10px] md:text-[8px] lg:text-[15px] xl:text-[16px] text-center flex justify-center font-bold" style={selectedTrustDocument === props.title? {color:'#D10A11'}:{color:'black'}}>
          {props.title}
        </p>
      </div>
    );
  };

  return (
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt=""/>
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              View Trust Document
            </h3>
          </div>
          <div className="relative p-6 flex-auto ">
            <div className="flex lg:w-[40vw] justify-evenly border-b pb-2 border-[#a09f9f] border-opacity-50">
              <TrustIcons
                title={"Add ID Proof"} 
                icon={selectedTrustDocument ==='Add ID Proof'?"/Assets/Images/Group 950.svg":"/Assets/Images/Group 947.svg"}
                onClick={() => {setImage(props?.idproof)
                  setSelectedTrustDocument("Add ID Proof")}}
              />
              <TrustIcons
                title={"Add Education Proof"}
                icon={selectedTrustDocument ==='Add Education Proof'?"/Assets/Images/Group 951.svg":"/Assets/Images/Group 948.svg"}
                onClick={() => {setImage(props?.eduproof)
                  setSelectedTrustDocument("Add Education Proof")}}
              />
              <TrustIcons
                title={"Add Income Proof"}
                icon={selectedTrustDocument ==='Add Income Proof'?"/Assets/Images/Group 952.svg":"/Assets/Images/Group 949.svg"}
                onClick={() => {setImage(props?.incomeproof)
                  setSelectedTrustDocument("Add Income Proof")}}
              />
            </div>
            <div className="flex justify-center items-center">
              {image ? (
                <img src={image} alt="" className="w-[70vw] lg:w-[40vw]  h-[50vh]" />
              ) : (
                <div className="flex justify-center items-center w-[40vw] h-[50vh]">
                  <p className="text-[16px] font-bold text-center">Empty data</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-center mb-8">
            <button
              onClick={() => {
                props.onButtonCallback();
                props.close();
              }}
              className=" flex items-center justify-center lg:w-[2.5rem] lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
            >
              Edit
            </button>
            {image &&
            <button
              onClick={() => {
                if(props.deleteImage === true){
                  props.callBackDelete(image,selectedTrustDocument);
                }
                else{
                  props.onButtonCallback();
                }
                props.close();
              }}
              className="ml-2 flex items-center justify-center lg:w-[2.5rem] lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
            >
              Remove
            </button>
          }
          </div>
        </div>
      </div>
    </div>
  );
};

const TrustDocumentViewPopup = (props) => {
  const [image, setImage] = useState();
  const [selectedTrustDocument, setSelectedTrustDocument] = useState("ID Proof");
  useEffect(() => {
    if(props?.idproof){
        setImage(props?.idproof);
      }
      else if(props?.eduproof){
        setImage(props?.eduproof);
      }
      else if(props?.incomeproof){
        setImage(props?.incomeproof);
      }
      else{
        setImage("");
      }
  }, [props?.idproof, props?.eduproof, props?.incomeproof]);
  useEffect(()=>{
    //console.log("image pop",image);
  },[image])
  const TrustIcons = (props) => {
    return (
      <div
        onClick={() => props.onClick()}
        className="flex flex-col items-center justify-end cursor-pointer"
      >
        <img className="w-[2rem] md:w-[2rem] lg:w-[4rem]" src={props.icon} alt=""/>
        <p className={`${selectedTrustDocument === props.title ?"text-[#D10A11]" : "text-[#000000]" } 
        " text-[10px] md:text-[8px] lg:text-[15px] xl:text-[16px] text-center flex justify-center font-bold"`}>
          {props.title}
        </p>
      </div>
    );
  };

  return (
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt=""/>
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              View Trust Document
            </h3>
          </div>
          <div className="relative p-6 flex-auto ">
            <div className="flex lg:w-[40vw] justify-evenly border-b pb-2 border-[#a09f9f] border-opacity-50">
              <TrustIcons
                title={"ID Proof"}
                icon={selectedTrustDocument ==='ID Proof'?"/Assets/Images/Group 950.svg":"/Assets/Images/Group 947.svg"}
                onClick={() => {setImage(props?.idproof)
                  setSelectedTrustDocument("ID Proof")}}
              />
              <TrustIcons
                title={"Education Proof"}
                icon={selectedTrustDocument ==='Education Proof'?"/Assets/Images/Group 951.svg":"/Assets/Images/Group 948.svg"}
                onClick={() => 
                  {setImage(props?.eduproof) 
                  setSelectedTrustDocument("Education Proof")}}
              />
              <TrustIcons
                title={"Income Proof"}
                icon={selectedTrustDocument ==='Income Proof'?"/Assets/Images/Group 952.svg":"/Assets/Images/Group 949.svg"}
                onClick={() => 
                  {setImage(props?.incomeproof)
                  setSelectedTrustDocument("Income Proof")}
                }
              />
            </div>
            <div className="flex justify-center items-center">
              {image ? (
                <img src={image} alt="" className="w-[70vw] lg:w-[40vw]  h-[50vh]" />
              ) : (
                <div className="flex justify-center items-center w-[40vw] h-[50vh]">
                  <p className="text-[16px] font-bold text-center">Empty data</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {props?.dashboardProfileData?.status == "pending"?
              ""
              :
              selectedTrustDocument == "ID Proof"?
              props?.matchViewProfile1?.statusMessageIdproof &&
                <p className="popup-warning-msg-paid">
                  <center>
                    
                    {props?.matchViewProfile1?.statusMessageIdproof == "Only Paid Members can view the Horoscope Upgrade your Profile Now"?
                    <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>
                      {props?.matchViewProfile1?.statusMessageIdproof}
                      </Link>
                    :
                    // props?.matchViewProfile1?.statusMessageIdproof
                    <p>  <p> Only Paid Members can view the Horoscope </p>
                      <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>
                  {/* {props?.matchViewProfile1?.statusMessageIdproof} */}
                  Upgrade your Profile Now

                </Link>
                </p>
                    }
                  </center>
                  
                </p>
                :selectedTrustDocument == "Education Proof"?
                props?.matchViewProfile1?.statusMessageEducationproof &&
                <p className="popup-warning-msg-paid">
                  <center>
                    
                    {props?.matchViewProfile1?.statusMessageEducationproof == "Only Paid Members can view the Horoscope Upgrade your Profile Now"?
                      <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>{props?.matchViewProfile1?.statusMessageEducationproof}</Link>
                    :
                    // props?.matchViewProfile1?.statusMessageEducationproof
                    <p>  <p> Only Paid Members can view the Horoscope </p>
                        <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>
                  {/* {props?.matchViewProfile1?.statusMessageIdproof} */}
                  Upgrade your Profile Now

                </Link>
                </p>
                    }
                  </center>
                </p>
                :
                props?.matchViewProfile1?.statusMessageIncomeproof &&
                <p className="popup-warning-msg-paid">
                  <center>
                    
                    {props?.matchViewProfile1?.statusMessageIncomeproof == "Only Paid Members can view the Horoscope Upgrade your Profile Now"?
                      <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>{props?.matchViewProfile1?.statusMessageIncomeproof}</Link>
                    :
                    // props?.matchViewProfile1?.statusMessageIncomeproof
                    <p>  <p> Only Paid Members can view the Horoscope </p>
                        <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>
                  {/* {props?.matchViewProfile1?.statusMessageIdproof} */}
                  Upgrade your Profile Now

                </Link>
                </p>
                    }

                  </center>
                </p>

              }
      </div>
     
    </div>
  );
};

const TrustDocumentViewPopupLite = (props) => {
  console.log("TrustDocumentViewPopup", props);
  const [image, setImage] = useState();
  const [selectedTrustDocument, setSelectedTrustDocument] = useState("ID Proof");
  const slider1 = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(1)

  const images = useMemo(() => {
    return [
      {
        title: "ID Proof",
        doc: props.idproof,
      },
      {
        title: "Education Proof",
        doc: props.eduproof,
      },
      {
        title: "Income Proof",
        doc: props.incomeproof,
      }
    ]
  }, [props.eduproof, props.idproof, props.incomeproof])

  useEffect(() => {
    if(currentIndex == 1){
        setImage(props?.idproof);
        setSelectedTrustDocument("ID Proof")
      }
      else if(currentIndex == 2){
        setImage(props?.eduproof);
        setSelectedTrustDocument("Education Proof")
      }
      else if(currentIndex == 3){
        setImage(props?.incomeproof);
        setSelectedTrustDocument("Income Proof")
      }
      else{
        setImage("");
      }
  }, [currentIndex]);
  
  useEffect(() => {
    //console.log("image pop",image);
  }, [image])
  const TrustIcons = (props) => {
    return (
      <div
        onClick={() => props.onClick()}
        className="flex flex-col items-center justify-end cursor-pointer"
      >
        <img className="w-[2rem] md:w-[2rem] lg:w-[4rem]" src={props.icon} alt=""/>
        <p className={`${selectedTrustDocument === props.title ? "text-[#D10A11]" : "text-[#000000]"} 
        " text-[10px] md:text-[8px] lg:text-[15px] xl:text-[16px] text-center flex justify-center font-bold"`}>
          {props.title}
        </p>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    draggable: true,
    rows: 1,
    afterChange: (num) => setCurrentIndex(num + 1),
    prevArrow: <CustomPrevArrow count={1} />,
    nextArrow: <CustomNextArrow count={1} />,
  };

  return <Transition appear show={true} >
    <Dialog as="div" className="relative z-10" onClose={props.close}>
      <Transition.Child
        // as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black opacity-[0.75]" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            // as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className=" relative">
              <div className="absolute -top-[48px] left-0 right-0 flex justify-center">
                <div className="bg-[#D10A11] rounded-xl text-center p-2 font-Poppins-Regular font-medium text-white">
                  {currentIndex} of 3
                </div>
              </div>
              <button
                className=" bg-transparent z-40 border-0 text-black  absolute -top-[10px] -right-[10px]"
                onClick={() => {
                  props.close();
                }}
              >
                <img className="h-5 w-5 " src={CloseIcon} alt="" />
              </button>
              <div className=" max-h-[80vh] lg:max-h-[86vh] mx-auto mt-7 space-y-5 block image-Slider w-[76vw] md:w-[70vw] lg:w-[50vw] bg-white p-4 rounded-lg">
                <Slider {...settings} ref={slider1}>
                  {
                    images?.map((data, i) => {
                      return (
                        <div className={`flex items-center flex-col justify-center bg-white ${props.isPaid ? "h-[70vh]" : "h-[70vh]"}`} key={i}>
                          <h3 className="text-[20px] text-[#D10A11] pb-2 font-Poppins-SemiBold text-center">{data.title}</h3>
                          {data.doc ?
                            <img src={data.doc} className="h-full w-full lg:max-w-full object-contained lg:max-h-full mx-auto" alt={""} />
                            : <div className=" h-full w-full flex items-center justify-center">
                              No Proof
                            </div>}

                        </div>)
                    })
                  }
                </Slider>
                {!props.isPaid ? <p className="popup-warning-msg-paid">
                  <center>
                    <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>{props?.matchViewProfile1?.statusMessageHoroscope}</Link>
                  </center>
                </p> : null}

                {props?.isFrom == "edit" &&
                  <div className="flex justify-center mb-8 mt-2">
                    <button
                      onClick={() => {
                        props.onButtonCallback();
                        props.close();
                      }}
                      className=" flex items-center justify-center lg:w-[2.5rem] lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                    >
                      Edit
                    </button>
                    {image &&
                      <button
                        onClick={() => {
                            props.callBackDelete(image,selectedTrustDocument);
                            props.close();
                        }}
                        className="ml-2 flex items-center justify-center lg:w-[2.5rem] lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                      >
                        Remove
                      </button>
                      }
                  </div>
              }
              </div>
              
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>

  return (
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              View Trust Document
            </h3>
          </div>
          <div className="relative p-6 flex-auto ">
            <div className="flex lg:w-[40vw] justify-evenly border-b pb-2 border-[#a09f9f] border-opacity-50">
              <TrustIcons
                title={"ID Proof"}
                icon={selectedTrustDocument === 'ID Proof' ? "/Assets/Images/Group 950.svg" : "/Assets/Images/Group 947.svg"}
                onClick={() => {
                  setImage(props?.idproof)
                  setSelectedTrustDocument("ID Proof")
                }}
              />
              <TrustIcons
                title={"Education Proof"}
                icon={selectedTrustDocument === 'Education Proof' ? "/Assets/Images/Group 951.svg" : "/Assets/Images/Group 948.svg"}
                onClick={() => {
                  setImage(props?.eduproof)
                  setSelectedTrustDocument("Education Proof")
                }}
              />
              <TrustIcons
                title={"Income Proof"}
                icon={selectedTrustDocument === 'Income Proof' ? "/Assets/Images/Group 952.svg" : "/Assets/Images/Group 949.svg"}
                onClick={() => {
                  setImage(props?.incomeproof)
                  setSelectedTrustDocument("Income Proof")
                }
                }
              />
            </div>
            <div className="flex justify-center items-center">
              {image ? (
                <img src={image} alt="" className="w-[70vw] lg:w-[40vw]  h-[50vh]" />
              ) : (
                <div className="flex justify-center items-center w-[40vw] h-[50vh]">
                  <p className="text-[16px] font-bold text-center">Empty data</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {props?.dashboardProfileData?.status == "pending" ?
          ""
          :
          selectedTrustDocument == "ID Proof" ?
            props?.matchViewProfile1?.statusMessageIdproof &&
            <p className="popup-warning-msg-paid">
              <center>

                {props?.matchViewProfile1?.statusMessageIdproof == "Only Paid Members can view the Horoscope Upgrade your Profile Now" ?
                    <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>
                    {props?.matchViewProfile1?.statusMessageIdproof}
                  </Link>
                  :
                  // props?.matchViewProfile1?.statusMessageIdproof
                  <p>  <p> Only Paid Members can view the Horoscope </p>
                      <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>
                      {/* {props?.matchViewProfile1?.statusMessageIdproof} */}
                      Upgrade your Profile Now

                    </Link>
                  </p>
                }
              </center>

            </p>
            : selectedTrustDocument == "Education Proof" ?
              props?.matchViewProfile1?.statusMessageEducationproof &&
              <p className="popup-warning-msg-paid">
                <center>

                  {props?.matchViewProfile1?.statusMessageEducationproof == "Only Paid Members can view the Horoscope Upgrade your Profile Now" ?
                      <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>{props?.matchViewProfile1?.statusMessageEducationproof}</Link>
                    :
                    // props?.matchViewProfile1?.statusMessageEducationproof
                    <p>  <p> Only Paid Members can view the Horoscope </p>
                        <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>
                        {/* {props?.matchViewProfile1?.statusMessageIdproof} */}
                        Upgrade your Profile Now

                      </Link>
                    </p>
                  }
                </center>
              </p>
              :
              props?.matchViewProfile1?.statusMessageIncomeproof &&
              <p className="popup-warning-msg-paid">
                <center>

                  {props?.matchViewProfile1?.statusMessageIncomeproof == "Only Paid Members can view the Horoscope Upgrade your Profile Now" ?
                      <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>{props?.matchViewProfile1?.statusMessageIncomeproof}</Link>
                    :
                    // props?.matchViewProfile1?.statusMessageIncomeproof
                    <p>  <p> Only Paid Members can view the Horoscope </p>
                        <Link to={RouteSegments?.MEMBERSHIP_REGISTER()}>
                        {/* {props?.matchViewProfile1?.statusMessageIdproof} */}
                        Upgrade your Profile Now

                      </Link>
                    </p>
                  }

                </center>
              </p>

        }
      </div>

    </div>
  );
};

export { TrustDocumentViewPopup, TrustDocumentPopup, TrustDocumentViewPopupLite };
