import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//component
import OTPForm from './OTPForm'
import ChangeMobileNumberForm from './ChangeMobileNumberForm'
import { mobileVerificationFresh, requestAgain } from '../../Store/Login/Action'
import Constants from '../../Constants/constants'

//context
import { LoginContext } from '../../Contexts/LoginContext'
import LoaderGif from "../../Gif/loader.gif"
import DocumentIcon from "../../Images/Group 938@2x.png"
import { capitalizeFirstLetter } from '../Kalyanlite/utils'

const data = [localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)]
const OtpSection = () => {
  const {logout} = useContext(LoginContext)
  const dispatch = useDispatch();
  const [requestData, setRequestData] = useState([])
  const [isChangeNumber, setIsChangeNumber] = useState(false)
  const [isLoading, setisLoading] = useState(true);
  const [isMobileVerfication, setIsMobileVerfication] = useState(false)

  const [statusMessage, setStatusMessage] = useState("")
  const { sendOTPInputData } = useSelector(state => ({
    sendOTPInputData: state.Login?.mvfdata?.data
  }));

  console.log(sendOTPInputData,"123sendOTPInputData");

  useEffect(() => {
    window.onpopstate = ()=> {
        // alert(window.location.href)
        if(window.location.href.indexOf('mobileVerification')>-1){
          logout();
        }
      }
  },[])

  useEffect(()=>{
    if(sendOTPInputData){
      setStatusMessage(sendOTPInputData?.statusMessage)
    }
  },[sendOTPInputData,statusMessage])

  useEffect(() => {
    console.log(statusMessage,"requestDatarequestData");
    if(Changeddata){
      if(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)){
        setRequestData(JSON.parse[localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)])
      }
      else{
        dispatch(mobileVerificationFresh("data"))
      }
    }
    else{
      if(!localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)){
        if(!isMobileVerfication){
          dispatch(mobileVerificationFresh("data"))
          setIsMobileVerfication(true)
        }
      }
      // else{
      //   localStorage.removeItem(Constants.loginLocalStorageKeys.afterRegister)
      // }
    }
  },[requestData]) 

  const { Changeddata } = useSelector(state => ({
    Changeddata: state.Login?.Changeddata?.data
  }));

  useEffect(() => {
    if(Changeddata){
      if(Changeddata?.status == "SUCCESS"){
        setIsChangeNumber(false)
      }
    }
  },[Changeddata])

  useEffect(()=>{
    if(sendOTPInputData != undefined)
    {
    setisLoading(false)
  }

  },[sendOTPInputData])

  console.log(Changeddata,"Changeddata");

  const registerDetails = JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))

  console.log(JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)),"afterregister");

  return (
    
    <>
    {!isLoading && sendOTPInputData || JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)) ?
    
    <div className=" md:pl-[1rem] font-segeo  lg:px-[1rem] md:mt-[2rem] lg:mt-[3rem] col-span-6" >
              <div>
                <p className="text-[#575556] md:text-[12px] lg:text-[19px] font-bold flex justify-center ">
                 <span className="text-[#039511] mr-2"> Congratulations! </span>
              You have successfully profiled yourself on {capitalizeFirstLetter(window.location.host.replace("uat.", "").replace("kalyanmatrimony", " kalyanmatrimony"))}.
                 </p>
                 <p className="  text-[#575556] md:text-[12px] lg:text-[19px] font-bold flex justify-center  ">Finding your Partner on our site is a secure process and we would like you to</p>
                    <p className=" text-[#575556] md:text-[12px] lg:text-[19px] font-bold flex justify-center  "> validate your mobile number. Please enter OTP to proceed. </p>
               </div>
             <div className=" flex justify-center items-center md:mt-[2rem] lg:mt-[4rem] mb-5">
                 <div className=" flex justify-center items-center">
                    <img className=" md:w-[4rem] lg:w-[8rem] " src={DocumentIcon} alt=""/>
                  </div>
                  <div className=" md:pl-[1rem] lg:pl-[3rem] ">
                    {Changeddata?.isdcode !== undefined ? 
                      (Changeddata?.isdcode == "+91" ? 
                          <p className=" text-[#575556] md:text-[12px] lg:text-[19px] font-bold flex justify-center ">
                              A SMS with your verification PIN has been sent to your
                          </p>
                      :
                          <p className=" text-[#575556] md:text-[12px] lg:text-[19px] font-bold flex justify-center ">
                              A WhatsApp Message with your verification PIN has been sent to your
                          </p>
                      )
                    :
                      (registerDetails?.isdcode == "+91" || sendOTPInputData?.isdcode == "+91" ? 
                          <p className=" text-[#575556] md:text-[12px] lg:text-[19px] font-bold flex justify-center ">
                              A SMS with your verification PIN has been sent to your
                          </p>
                      :
                          <p className=" text-[#575556] md:text-[12px] lg:text-[19px] font-bold flex justify-center ">
                              A WhatsApp Message with your verification PIN has been sent to your
                          </p>
                      )
                    }
                         <p className=" text-[#575556] md:text-[12px] lg:text-[19px] font-bold flex justify-center">
                          mobile number - {" "}{
                          Changeddata?.mobileNo?
                          Changeddata?.isdcode+" "+Changeddata?.mobileNo:
                          JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))?
                          JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))?.isdcode+" "+JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))?.mobileNo
                          :
                          Changeddata?.mobileNo?
                          Changeddata?.isdcode+" "+Changeddata?.mobileNo
                          :
                          sendOTPInputData?.isdcode+" "+sendOTPInputData?.mobileNo
                          }
                          <button className=" pl-3 underline text-[#62ABFE]  md:text-[12px] lg:text-[19px] font-bold " onClick={()=>setIsChangeNumber(true)}> Change number </button>
                          </p>
                          
                          {isChangeNumber?
                            localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)?
                              <ChangeMobileNumberForm requestData={JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))}/>
                              :
                              <ChangeMobileNumberForm requestData={sendOTPInputData}/>
                            :""
                            }
                       
                   </div>
                  
                </div>
                <p className="text-[red] text-[12px] flex justify-center  items-start md:pl-[3rem] lg:pl-[5rem]">
                          {JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))?
                          ""
                          :
                          statusMessage
                          }
                          </p>
                {localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)?
                <OTPForm  key={Changeddata?.mobileNo} requestData={requestData}/>
                :
                <OTPForm key={Changeddata?.mobileNo} requestData={sendOTPInputData}/>
                }
                
               
              
    </div>    
      :
    isLoading &&
      <div className=" md:pl-[1rem] font-segeo  lg:px-[1rem] md:mt-[2rem] lg:mt-[3rem] col-span-6" >
      <center><img src={LoaderGif} className='pt-[9rem] pb-[2rem]' alt=''/></center>
      </div>}
      </>
          )
}



export default OtpSection
