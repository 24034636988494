import React, { useEffect, useState, useContext } from "react";

import { MyPlansTextView, MyPlansTextViewWithAction } from "./EditProfileUtills";
import { useNavigate, useSearchParams } from "react-router-dom";
import RouteSegments from "../../Routes/RouteSegment";
import LoaderGif from "../../Gif/loader.gif"
import { CommonPopup, PaymentWhatsAppPopup,CommonSuccessPopup } from "../CommonPopup";
import { useSelector } from "react-redux";

const ProfileMyPlanComponent = (props) => {
  const navigate = useNavigate();
  const [showFailurePopup, setShowFailurePopup] = useState(false)  
  const [searchParams,setSearchParams] = useSearchParams()
  const isV3User = localStorage.getItem("isV3User")
  const [isClose, setIsClose] = useState(false);
  const [whatsAppSuccessPopup, setwhatsAppSuccessPopup] = useState(false);

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );

  useEffect(() => {
    if(searchParams?.get('status') == "success"){
      searchParams.delete("status")
      setSearchParams(searchParams, {replace:true})
      setIsClose(localStorage.getItem("isClose") == 23)
      setShowFailurePopup(true);
    }
    
  }, []);
  
  // useEffect(() => {
  //   if(userData?.isOfflinePackFilled === true && searchParams?.get('status') == "success" ){
  //     setIsClose(false)
  //     setShowFailurePopup(true);
  //   }
    
  // }, [userData?.isOfflinePackFilled,location])


  const close = () => {
    setShowFailurePopup(false)
  }

  

  // <img src={LoaderGif}/>

  return (
    <div x-show="tab === 8">
      
         {/* {showFailurePopup &&
            <CommonPopup title="Transaction Success" close={close}>
                <p className='w-[100%] text-center'>
                    Payment Success
                </p>
            </CommonPopup>
        } */}
        
        
       {showFailurePopup ?
        isClose && userData?.isOfflinePackFilled === true? <PaymentWhatsAppPopup
                heading={"Transaction Successful !"}
                title={"Add Phone Number !"}
                close={close}
                isClose={isClose}
                setwhatsAppSuccessPopup={setwhatsAppSuccessPopup}
                isFrom={"Plan"}
            />
          : <CommonPopup title="Transaction Success" close={close}>
            <p className='w-[100%] text-center'>
              Payment Success
            </p>
          </CommonPopup>
        : null
          } 

{whatsAppSuccessPopup && (
          <CommonSuccessPopup
            close={() => {
              setwhatsAppSuccessPopup(false)
            }}
            title={"Success!"}
            content={"WhatsApp Number Saved Successfully"}
          />
        )}
        
      <div className={`flex pl-[1rem] md:pl-0 md:mt-0 ${isV3User ? "" : "mt-[1rem]  justify-between"}`}>
        <p className=" text-[#000000] text-start text-[16px] font-semibold ">
          My Plan
        </p>
      </div>
      <div className=" px-[1rem] mb-[8rem] md:mb-0  md:px-0 mt-[1rem] md:col-span-6">
        {
          props.isLoading
            ? <div className="flex h-52 items-center justify-center">
              <img alt="loader" src={LoaderGif} />
            </div>
            : <>
              {props?.isPaid === "Y"
                ? <MyPlansTextView
                  title={"Membership Type"}
                  value={props?.data?.membershipType}
                  backgroundColor={'#F1F1F1'}
                />
                : <MyPlansTextViewWithAction
                  title="Membership Type"
                  buttonText="UPGRADE NOW"
                  text="Free Member"
                  backgroundColor={'#F1F1F1'}
                  onClick={() => navigate(RouteSegments.MEMBERSHIP_REGISTER())}
                />}
              <MyPlansTextView
                title={"Date of Payment"}
                backgroundColor={'#DADADAE0'}
                value={props?.data?.dateofPayment}
              />
              <MyPlansTextView
                title={"Date of Expiry"}
                value={props?.data?.dateofExpiry}
                backgroundColor={'#E9E9E9'}
              />
              <MyPlansTextView title={"Validity"} backgroundColor={'#DADADAE0'} value={props?.data?.validity} />
              {props?.data?.daysInBalance < 0
                ? <MyPlansTextViewWithAction
                  title="Days in Balance"
                  backgroundColor={'#E9E9E9'}
                  buttonText="RENEW NOW"
                  onClick={() => navigate(RouteSegments.MEMBERSHIP_REGISTER())}
                />
                : <MyPlansTextView
                  title={"Days in Balance"}
                  backgroundColor={'#E9E9E9'}
                  value={props?.data?.daysInBalance}
                />}
              {props?.data?.phoneNumberTotal < 0
                ? <MyPlansTextViewWithAction
                  title="Phone Numbers Total / Balance"
                  backgroundColor={'#DADADAE0'}
                  buttonText="RENEW NOW"
                  onClick={() => navigate(RouteSegments.MEMBERSHIP_REGISTER())}
                />
                : <MyPlansTextView
                  title={"Phone Numbers Total / Balance"}
                  backgroundColor={'#DADADAE0'}
                  value={props?.data?.phoneNumberTotal}
                />}

              <MyPlansTextView
                title={"Astro Match Total / Balance"}
                backgroundColor={'#E9E9E9'}
                value={props?.data?.astroMatchBalance}
              />
              {/* <MyPlansTextView
                title={"SMS Pack Validity"}
                backgroundColor={'#DADADAE0'}
                value={props?.data?.smsvalidity}
              /> */}
              <MyPlansTextView
                title={"Matches via WhatsApp"}
                backgroundColor={'#DADADAE0'}
                value={props?.data?.offlineservicepack}
              />
            </>
        }

      </div>
    </div>
  );
};

export default ProfileMyPlanComponent;
