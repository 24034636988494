import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//component
import OTPForm from './OTPForm'
import ChangeMobileNumberForm from './ChangeMobileNumberForm'
import { mobileVerificationFresh, requestAgain } from '../../Store/Login/Action'
import Constants from '../../Constants/constants'
import LoaderGif from "../../Gif/loader.gif"
import DocumentIcon from "../../Images/Group 938@2x.png"
import { capitalizeFirstLetter } from '../Kalyanlite/utils'

const MobileviewOptSection = () => {
  const dispatch = useDispatch();
  const [requestData, setRequestData] = useState([])
  const [isChangeNumber, setIsChangeNumber] = useState(false)
  const [statusMessage, setStatusMessage] = useState("")
  const { sendOTPInputData } = useSelector(state => ({
    sendOTPInputData: state.Login?.mvfdata?.data
  }));

  //console.log(sendOTPInputData,"sendOTPInputData");
  const registerDetails = JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))

  

  useEffect(()=>{
    if(sendOTPInputData){
      setStatusMessage(sendOTPInputData?.statusMessage)
    }
  },[sendOTPInputData,statusMessage])

  useEffect(() => {
    if(Changeddata){
    localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)?
    setRequestData(JSON.parse[localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)])
    :
    dispatch(mobileVerificationFresh("data")) 
 } },[requestData]) 

  const { Changeddata } = useSelector(state => ({
    Changeddata: state.Login?.Changeddata?.data
  }));

  useEffect(() => {
    if(Changeddata){
      if(Changeddata?.status == "SUCCESS"){
        setIsChangeNumber(false)
      }
    }
  },[Changeddata])

  //console.log(Changeddata,"Changeddata");


  //console.log(requestData,"requestData");
  return (
    sendOTPInputData || JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)) ?
    <div className='font-segeo '>
              <div>
                <p className="text-[#575556] md:text-[12px] lg:text-[19px] font-bold flex justify-center ">
                 <span className="text-[#039511] mr-2"> Congratulations! </span>
                 </p>
                <p className="text-[#575556] text-[12px]  font-bold flex justify-center ">You have successfully profiled yourself </p>
          <p className="text-[#575556] text-[12px]  font-bold flex justify-center ">on {capitalizeFirstLetter(window.location.host.replace("uat.","").replace("kalyanmatrimony"," kalyanmatrimony"))}. Finding your Partner on our </p>
                 <p className="  text-[#575556] text-[12px]  font-bold flex justify-center  "> site is a secure process and we would like you to</p>
                    <p className=" text-[#575556] text-[12px]  font-bold flex justify-center  "> validate your mobile number. Please enter OTP to </p>
                  <p className=" text-[#575556] text-[12px]  font-bold flex justify-center  ">  proceed. </p>
               </div>
              <div className=" flex justify-center items-center mt-[2rem]  ">
                 <div className=" flex justify-center items-center">
                    <img className=" w-[3rem]  " src={DocumentIcon} alt=""/>
                  </div>
                  <div className="  ">
                    {Changeddata?.isdcode !== undefined ? 
                      (Changeddata?.isdcode == "+91" ?
                       <p className=" text-[#575556] text-[12px]  font-bold flex justify-center ">
                           A SMS with your verification PIN has been sent  
                         </p>
                         :
                         <p className=" text-[#575556] text-[12px]  font-bold flex justify-center ">
                           A Whatsapp Message with your verification PIN has been sent  
                         </p>
                      )
                      :
                      (registerDetails?.isdcode == "+91" || sendOTPInputData?.isdcode == "+91" ? 
                        <p className=" text-[#575556] text-[12px]  font-bold flex justify-center ">
                           A SMS with your verification PIN has been sent  
                         </p>
                         :
                         <p className=" text-[#575556] text-[12px]  font-bold flex justify-center ">
                           A Whatsapp Message with your verification PIN has been sent  
                         </p>
                      )
                      }
                         <p className=" text-[#575556] text-[12px]  font-bold flex justify-center">
                          to your mobile number - 
{/*                           
                           {JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))?
                          JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))?.mobileNo
                          :
                          Changeddata?.mobileNo?
                          Changeddata?.mobileNo
                          :
                          sendOTPInputData?.mobileNo 
                          }  */}
                          {" "}
                        {   Changeddata?.mobileNo?
                          Changeddata?.isdcode+" "+Changeddata?.mobileNo:
                          JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))?
                          JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))?.isdcode+" "+JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))?.mobileNo
                          :
                          Changeddata?.mobileNo?
                          Changeddata?.isdcode+" "+Changeddata?.mobileNo
                          :
                          sendOTPInputData?.isdcode+" "+sendOTPInputData?.mobileNo
                          }
                           </p>
                           <p className=" flex justify-center pl-2 underline text-[#62ABFE]  text-[12px]  font-bold " onClick={()=>setIsChangeNumber(true)}> Change number </p>

                           {isChangeNumber?
                            localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)?
                              <ChangeMobileNumberForm requestData={JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))}/>
                              :
                              <ChangeMobileNumberForm requestData={sendOTPInputData}/>
                            :""
                            }
                         
                   </div>
                  
                </div>
                 {/* <div className=" flex justify-center items-start mt-[1rem] ">
                     <button className=" flex items-center justify-center font-normal h-[1.5rem]  text-[#7A7A7A] bg-white border border-[#9F9F9F] w-[7rem] whitespace-nowrap rounded-[5px] py-[1rem]  text-[12px]   ">
                        Enter PIN
                     </button>
                  </div>
                   <div className=" flex justify-center items-start mt-[0.5rem] md:mt-[1rem]  ">
                     <button className=" flex items-center justify-center  h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2rem]  whitespace-nowrap rounded-[5px] font-bold py-[1rem] text-[12px]   ">
                        Verify Mobile
                     </button>
                  </div>
                   <div className=" ">
                    <p className="text-[#575556] text-[12px]  font-normal flex justify-center mt-[1.6rem]">
                        SMS will reach you shortly.
                    </p>

                     <p className=" text-[#575556] text-[12px]  font-normal flex justify-center">
                           If you have not received it yet please 
                          <span className=" pl-3 underline text-[#62ABFE] text-[12px]  font-normal "> Request Again </span>
                          </p>
                   
                  </div> */}
                   <p className="text-[red] text-[12px] flex justify-center  items-start md:pl-[3rem] lg:pl-[5rem]">
                          {JSON.parse(localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister))?
                          ""
                          :
                          statusMessage
                          }
                          </p>
                {localStorage.getItem(Constants.loginLocalStorageKeys.afterRegister)?
                <OTPForm key={Changeddata?.mobileNo} requestData={requestData}/>
                :
                <OTPForm key={Changeddata?.mobileNo} requestData={sendOTPInputData}/>
                }
                
               
              
    </div>
      :
      <div className=" md:pl-[1rem] font-segeo  lg:px-[1rem] md:mt-[2rem] lg:mt-[3rem] col-span-6" >
      <center><img src={LoaderGif} className='pt-[9rem] pb-[2rem]' alt=''/></center>
      </div>
                  
    //                  <div className=" px-[4rem] mt-[3rem] ">
    //           <div>
    //             <img src="../images/banner@2x.png" alt=""/>
    //             <img src="../images/banner_v2@2x.png" alt=""/>
    //         </div>
    //               </div> 
    // </div>
  )
}

export default MobileviewOptSection
