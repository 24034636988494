import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from 'react-router-dom';
import MessageImage from "../../Images/Group 497.svg";


//validation
import { useFormik } from "formik";
import * as Yup from 'yup';

//redux states
import { blockProfile, getMessage, sentMessage } from '../../Store/Conversation/Action';
import Constants from '../../Constants/constants';
import RouteSegments, { RoutePaths } from '../../Routes/RouteSegment';
import { CommonPopup, CommonProfilePopup } from '../CommonPopup';
import { useLocation } from "react-router";
import LoaderGif from "../../Gif/loader.gif"
import LockIcon from '../../Images/lockicon.svg'
import senderImage from '../../Images/Group 1082@2x.png'
import reciverImage from '../../Images/Group 1083@2x.png'
import messagearrow from '../../Images/messagearrow.svg'
import { CommonCheckButton, CommonRadioButton } from '../DashboardComponents/DashboardUtills';
import { MessageAccordianComponent } from '../ViewMessageComponents/AccordianComponents';
import { getIsLoginFromAdmin } from '../../Utils';

export default function Conversation(props) {
  const navigate = useNavigate()
  //console.log(props, "shjhgftrdfg");
  const [showMenu, setShowMenu] = useState(false)
  const [conversationResponseData, setConversationResponseData] = useState()
  const [conversationSentData, setConversationSentData] = useState()
  const [profileStatus, setProfileStatus] = useState(false)
  const [showBlockPopup, setShowBlockPopup] = useState(false)
  const [blockedByMe, setBlockedByMe] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams({ tab: "" })
  const [heightPixel, setHeightPixel] = useState(100)
  const tab = parseInt(searchParams.get('refId'))
  const [showAdminPopup, setShowAdminPopup] = useState(false)
  const dispatch = useDispatch();
  const location = useLocation();

  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallDevice(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const messagesEndRef = useRef(null)
  console.log("conversationResponseData", conversationResponseData);

  const showParentsNumber = useMemo(() => {
    return conversationResponseData?.requestParentnumbercheck === false
  }, [conversationResponseData])

  const showRequestPhoto = useMemo(() => {
    return conversationResponseData?.messageRead[0]?.isAvatarPic === true && conversationResponseData?.requestPhotocheck === false
  }, [conversationResponseData])

  const formik = useFormik({
    initialValues: {
      message: "",
      requestParentsNumber: showParentsNumber,
      requestPhoto: showRequestPhoto,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      message: Yup.string()
        .required("Please enter message")
    }),
    onSubmit: (values) => {
      if (getIsLoginFromAdmin()) {
        setShowAdminPopup(true)
        return false;
      }
      let requestTypes = {}

      if (values.requestPhoto) {
        requestTypes.requestphotocheck = true
      }
      if (values.requestParentsNumber) {
        requestTypes.requestparentcheck = true
      }

      if (Object.keys(requestTypes).length !== 0) {
        requestTypes.triggerType = "singletrigger"
      }

      values = {
        ...values,
        requestTypes
      }
      console.log("Submi-requestTypes", values);
      SendMessage(values);
    },
  });

  useEffect(() => {
    getMessageApiCall()
  }, [props.threadId])

  const getMessageApiCall = () => {
    formik.values.message = ""

    const loginUrl = sessionStorage.getItem(Constants.loginLocalStorageKeys.loginUrl)
    let notificationId = null;

    if (!loginUrl?.includes("replypending") && loginUrl?.includes("inboxunread")) {
      notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    }
    {
      // props?.threadId &&
      // conversationResponseData?.messageRead[0]?.blockedByMe == "Y" ?
      // setShowBlockPopup(true)

      // :
      dispatch(getMessage(tab ? tab : props?.threadId,
        localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
        localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null : localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
        props?.type, notificationId))
    }
  }

  const { conversationData } = useSelector(state => ({
    conversationData: state.Conversation?.MessageData?.data
  }));

  //console.log(conversationResponseData, "conversationResponseData");

  useEffect(() => {
    setConversationResponseData()
    setConversationResponseData(conversationData)
    props?.type == "Sent" ?
      setConversationSentData(conversationData?.messagesend)
      :
      props?.type == "Read" ?
        setConversationSentData(conversationData?.messageread)
        :
        props?.type == "Unread" || props?.type == "ReplyPending" ?
          setConversationSentData(conversationData?.message)
          :
          setConversationSentData(conversationData?.messageread)


    scrollToBottom()
    if (conversationData) {
      // alert('Inside alert');
      // alert(document.getElementsByClassName("mobile-header")[0]?.clientHeight);

      // console.log('window.innerHeight',window.innerHeight);
      // console.log('document.getElementsByClassName("mobile-header")[0]?.clientHeight', document.getElementsByClassName("mobile-header")[0]?.clientHeight);
      // console.log('document.getElementsByClassName("mobile-profile")[0]?.clientHeight', document.getElementsByClassName("mobile-profile")[0]?.clientHeight);
      // console.log('document.getElementsByClassName("mobile-header")[0]?.clientHeight', document.getElementsByClassName("mobile-header")[0]?.clientHeight);

      // return false;

      const sectionHeight = window.innerHeight - (document.getElementsByClassName("mobile-header")[0]?.clientHeight + document.getElementsByClassName("mobile-profile")[0].clientHeight + document.getElementsByClassName("1mobile-breadcrumb")[0]?.clientHeight + document.getElementsByClassName("mobile-message")[0]?.clientHeight + 25);
      // const sectionHeight = window.innerHeight -(document.getElementsByClassName("section-1")[0]?.clientHeight+document.getElementsByClassName("section-2")[0].clientHeight+document.getElementsByClassName("section-3")[0]?.clientHeight+document.getElementsByClassName("section-5")[0]?.clientHeight+25);
      // alert(sectionHeight);
      setHeightPixel(sectionHeight);
    }
  }, [conversationData])

  useEffect(() => {
    if (conversationData) {
      setHeightPixel(window.innerHeight - (document.getElementsByClassName("mobile-header")[0]?.clientHeight + document.getElementsByClassName("mobile-profile")[0].clientHeight + document.getElementsByClassName("mobile-breadcrumb")[0]?.clientHeight + document.getElementsByClassName("mobile-message")[0]?.clientHeight + 15))
    }
  }, [window.innerHeight])

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
      }
    }, 100)
  }

  // useEffect(() => {

  // },[conversationResponseData])

  const SendMessage = (values) => {
    if (conversationResponseData?.messageRead[0]?.blockedByMe != "Y") {
      //console.log(values, "send message");
      const notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
      dispatch(sentMessage(
        localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
        localStorage.getItem(Constants.loginLocalStorageKeys.loginName) == '' ? null : localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
        values,
        props?.toProfileId, notificationId))
    }
  }

  const { sentMessageData } = useSelector(state => ({
    sentMessageData: state.Conversation?.sentMessageData?.data
  }));

  useEffect(() => {
    if (sentMessageData) {
      getMessageApiCall()
    }
  }, [sentMessageData])

  //console.log(sentMessageData, "sentMessageData");

  const blockProfileApiCall = (status, receiverId) => {
    dispatch(blockProfile(status, receiverId))
  }

  const { blockProfileData } = useSelector(state => ({
    blockProfileData: state.Conversation?.blockProfileData?.data
  }));

  //console.log(blockProfileData, "blockProfileData");

  useEffect(() => {
    console.log(conversationResponseData?.messageRead[0]?.profileId, "idprofile");
  }, [conversationResponseData?.messageRead[0]?.profileId])

  useEffect(() => {
    if (blockProfileData?.status == "SUCCESS") {
      setProfileStatus(true)
    }
  }, [blockProfileData])

  const closePopup = () => {
    setProfileStatus(false)
    if (conversationResponseData?.messageRead[0]?.blockedByMe == "Y") {
      window.location.href = RouteSegments.INBOX
      localStorage.setItem("BLOCKED_STATUS", 0)
      blockProfileApiCall(blockProfileApiCall(0, conversationResponseData?.messageRead[0]?.profileId))
    }
    else window.location.href = RouteSegments.INBOX
  }

  const closePopupForMsgSent = () => {
    setShowBlockPopup(false)
  }


  console.log(conversationSentData?.length, "length");

  const RadioMobileView = ({ messageText }) => {
    return <MessageAccordianComponent
      name={messageText}
      selected={messageText === formik.values.message}
      radiotext={messageText}
      onChange={(e) => {
        formik.setFieldValue("message", e.target.value)
      }}
      title={messageText}
      titleClassName={
        "flex items-center font-segeo justify-between  w-full py-2 text-[12px] px-2 font-semibold text-[#575556] bg-[#eaeaea] cursor-pointer block md:hidden lg:hidden"
      }
      defaultView={false}
      titleIcon1={
        <img
          src={MessageImage}
          className="w-3 h-3 fill-current rotate-180"
          alt=""
        />
      }
      titleIcon2={
        <img
          src={MessageImage}
          className="w-3 h-3 fill-current"
          alt=""
        />
      }
    >
      {messageText}
    </MessageAccordianComponent>
  }


  const messageStatus = useMemo(() => {

    if (conversationData) {

      const messageList = conversationSentData;

      const totalMessageCount = messageList?.length;
      const receivedMessageCount = messageList?.filter(message => message.senderreceiver === "receiver")?.length;
      const sendMessageCount = totalMessageCount - receivedMessageCount;


      //only allow to message if sender is paid or user received message from paid user
      if (conversationData?.isPaid === "Y" || (conversationData?.toprofilePaidStatus === "Y" && receivedMessageCount > 0)) {
        return "ALLOW_MESSAGE";
      }
      else if (sendMessageCount === 0) {
        return "ALLOW_DEFAULT_MESSAGE"
      } else {
        return "NOT_ALLOW"
      }
    }

    return "NOT_ALLOW"
  }, [conversationData, conversationSentData]);

  console.log({ conversationData });

  return (
    conversationData ?
      <div>
        {profileStatus &&
          <CommonPopup title="Profile Status" close={closePopup} menu="profileStatus">
            <p>{conversationResponseData?.messageRead[0]?.blockedByMe == "N" ? "Profile Blocked" : "Profile Unblocked"}</p>
          </CommonPopup>
        }
        {showAdminPopup && <CommonProfilePopup
        close={() => setShowAdminPopup(false)}
        title={"Alert!"}
        content={"You cannot send message/request from Admin"} />
      }
        {showBlockPopup &&
          <CommonPopup title="Profile Status" close={closePopupForMsgSent} menu="profileStatus">
            <p>{conversationResponseData?.messageRead[0]?.name + " Profile Blocked by you. Do you want to unblock now?"}</p>
            <div className='flex justify-between'>
              <button className='text-[#D10A11] ml-[10rem]' onClick={closePopup}>Yes</button>
              <button className='text-[#D10A11] mr-[1rem]' onClick={closePopupForMsgSent}>No</button>
            </div>
          </CommonPopup>
        }
        <div className="   md:block md:col-span-2 lg:col-span-2">
        </div>
        <div className="md:block md:col-span-3 lg:col-span-3">
          <div>

            {/* <div className="hidden md:flex justify-end md:mt-[2px] md:space-x-2 pr-[0.5rem] lg:hidden ">
              <div className=" flex justify-center items-center ">
                <img className=" md:w-3 md:h-3 lg:w-4 lg:h-4 w-4 h-4" src="/Assets/Images/Ellipse 36.svg" />
              </div>
              <span className=" flex items-center font-bold text-[8px] md:text-[9px]   lg:text-[14px]
                                      text-[#000000]">Online Chat Now</span>
            </div> */}

            <div className=" hidden md:flex justify-between   h-[9rem] md:h-[5rem] lg:h-auto pb-2  px-2 lg:px-0  lg:pl-[1.5rem] lg:pr-[3rem] " id={conversationResponseData?.messageRead[0]?.profileId}>
              <div className='flex space-x-3'>
                <div className="col-span-2 relative flex md:items-start lg:items-start lg:pl-[1rem] ">
                  <img className={conversationResponseData?.messageRead[0]?.blurPhoto === "Y" && conversationResponseData?.messageRead[0]?.isAvathar === false ? " blur-[2px] rounded-full w-[3rem] h-[3rem] md:w-[3rem] md:h-[3rem] lg:w-[4rem] lg:h-[4rem]" : " md:w-20  lg:w-[4rem] lg:h-[4rem] w-auto h-auto rounded-full "}
                    src={conversationResponseData?.messageRead[0]?.profilepic} alt="Person" />
                  {conversationResponseData?.messageRead[0]?.fromPasswordProtected === "Y" && conversationResponseData?.messageRead[0]?.isAvathar === false ?
                    <div className="absolute top-[0.8rem] right-[-1rem] left-[-0.3rem] md:left-[-2px] lg:left-[6px]  lg:top-[0rem]  md:right-[-1rem] lg:right-[-1rem] flex justify-center items-start  lg:justify-center lg:items-center h-[100%] w-[100%]  cursor-pointer">
                      <img className=" h-[1rem] w-[1rem] lg:h-[1rem] lg:w-[1rem] " src={LockIcon} alt="Person" />
                    </div>
                    : " "}
                </div>
                <div className=" col-span-5 flex lg:items-start lg:justify-start md:items-center md:justify-start  flex-col mt-0 px-[0.4rem]  lg:pr-[8rem] md:px-0 md:pl-[0rem] lg:pl-[0rem]  sm:col-span-5 ">
                  <div className="flex ">
                    <div className="">

                      {/* <!-- <p className=" py-1 text-[#575556] font-segeo text-[18px] font-bold">G65346728</p> --> */}
                      <div className="flex justify-between md:space-x-2">
                        <p className="text-[#575556] font-segeo text-[12px] md:text-[14px] lg:text-[15px] font-bold">{conversationResponseData?.messageRead[0]?.smProfileId}
                        </p>
                        <div className=" flex pl-3 md:pr-[1rem] lg:pr-0 md:pl-0 space-x-2 md:space-x-0  lg:flex md:mt-[0px] lg:space-x-2 
                                 
                                ">
                          <p className="text-[#039511] flex items-center font-bold text-[7px] md:text-[9px]   lg:text-[12px] text-[#000000] whitespace-nowrap"
                          //  @click.prevent="open = true"
                          // aria-controls="basic-modal"
                          >{conversationResponseData?.messageRead[0]?.lastOnline != "Online Now" ?
                            "Last Online : " + conversationResponseData?.messageRead[0]?.lastOnline
                            : "Online Chat Now"
                            }</p>
                        </div>


                      </div>
                      <p className=" pt-1 md:pt-1 lg:pt-1 text-[12px] md:whitespace-nowrap lg:whitespace-normal md:text-[13px] leading-[13px] md:leading-[16px] lg:text-[14px] text-[#7A7A7A] font-semibold font-segeo opacity-[0.8] ">{conversationResponseData?.messageRead[0]?.name}</p>
                      <p className="pt-1 md:pt-1 lg:pt-1 text-[12px] md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold font-segeo opacity-[0.8] ">{conversationResponseData?.messageRead[0]?.age}yrs, {conversationResponseData?.messageRead[0]?.height} | {conversationResponseData?.messageRead[0]?.religion} | {conversationResponseData?.messageRead[0]?.caste}</p>
                      {/* <p className="pt-1 md:pt-1 lg:pt-2 text-[12px] md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold font-segeo opacity-[0.8]  ">
                      {conversationResponseData?.messageRead[0]?.religion} | {conversationResponseData?.messageRead[0]?.caste}
                    </p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-span-3 flex  justify-end relative ">

                <div className="">
                  {/* <div className="pl-1 md:pl-0 "> */}
                  <button className="space-y-1" onClick={() => setShowMenu(!showMenu)} >
                    <div className=" w-[4px] h-[4px] md:w-[3px] md:h-[3px] lg:w-[6px] lg:h-[6px] bg-[#575556] rounded-full ">
                    </div>
                    <div style={{ margin: "2px 0" }} className="  w-[4px] h-[4px] md:w-[3px] md:h-[3px] lg:w-[6px] lg:h-[6px] bg-[#575556] rounded-full ">
                    </div>
                    <div style={{ margin: "2px 0" }} className="  w-[4px] h-[4px] md:w-[3px] md:h-[3px] lg:w-[6px] lg:h-[6px] bg-[#575556] rounded-full ">
                    </div>
                  </button>
                  {showMenu &&
                    <div className=''>
                      <ul
                        className="absolute  rounded-[10px]  z-[1] bg-[#ffffff] right-[0rem] text-sm text-gray-700 dark:text-gray-200 border border-2-[#E9E9E9]"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <li className='cursor-pointer'>
                          <a
                            onClick={() => navigate(RoutePaths.PROFILE, { state: { id: props.toProfileId } })}
                            className="block md:px-2 px-4 lg:px-4 md:py-1 lg:py-1 text-[12px] md:text-[10px] leading-[13px] md:leading-[16px] lg:text-[14px] text-[#7A7A7A] font-semibold font-segeo opacity-[0.8] hover:bg-gray-100 border-b border-2-[#E9E9E9]"
                          >
                            View Profile
                          </a>
                        </li>
                        <li className='cursor-pointer'>
                          <a
                            onClick={() => navigate(RouteSegments.HELP_AND_FAQ(), { state: { id: conversationResponseData?.messageRead[0]?.profileId, receiverId: conversationResponseData?.messageRead[0]?.smProfileId, senderId: props?.profileId, isfrom: "report" } })}
                            className="block md:px-2 px-4 lg:px-4 md:py-1 lg:py-1 text-[12px] md:text-[10px] leading-[13px] md:leading-[16px] lg:text-[14px] text-[#7A7A7A] font-semibold font-segeo opacity-[0.8] hover:bg-gray-100 border-b border-2-[#E9E9E9]"
                          >
                            Report
                          </a>
                        </li>
                        <li className='cursor-pointer'>
                          {conversationResponseData?.messageRead[0]?.blockedByMe == "N" ?
                            <a
                              onClick={() => blockProfileApiCall(1, conversationResponseData?.messageRead[0]?.profileId)}
                              className="block md:px-2 whitespace-nowrap px-4 lg:px-4 md:py-1 lg:py-1 text-[12px] md:text-[10px] leading-[13px] md:leading-[16px] lg:text-[14px] hover:bg-gray-100 text-[#7A7A7A] font-semibold font-segeo opacity-[0.8] "
                            >
                              Block & Restrict
                            </a>
                            :
                            <a
                              onClick={() => blockProfileApiCall(0, conversationResponseData?.messageRead[0]?.profileId)}
                              className="block whitespace-nowrap md:px-2 px-4 lg:px-4 md:py-1 lg:py-1 text-[12px] md:text-[10px] leading-[13px] md:leading-[16px] lg:text-[14px] hover:bg-gray-100 text-[#7A7A7A] font-semibold font-segeo opacity-[0.8] "
                            >
                              Unblock & Unrestrict
                            </a>
                          }
                        </li>
                      </ul>
                    </div>
                  }
                  {/* </div> */}

                </div>
              </div>
            </div>
            <div
              // class="mobile-profile md:hidden fixed mt-[2rem] inset-x-0  bg-white top-[8rem] z-10 grid grid-cols-10 md:grid  sm:grid-cols-12  h-[9rem] md:h-[8rem] lg:h-auto md:pb-2  px-5 lg:p-2" 
              className='section-3 mobile-profile md:hidden   bg-white  1mt-[2rem] grid grid-cols-10 md:grid  sm:grid-cols-12  h-[9rem] md:h-[8rem] lg:h-auto md:pb-2  px-5 lg:p-2 '
              id={conversationResponseData?.messageRead[0]?.profileId}
            >

              <div className="col-span-2  md:col-span-4 flex items-center mt-0 md:mt-[2.1rem] lg:mt-4">
                <div className='relative'>
                  <img className={conversationResponseData?.messageRead[0]?.blurPhoto == "Y" && conversationResponseData?.messageRead[0]?.isAvathar === false ? " blur-[2px] rounded-full w-[3rem] h-[3rem] md:w-[3rem] md:h-[3rem] lg:w-[3rem] lg:h-[3rem]" : " md:w-20  lg:w-auto lg:h-auto w-[4rem] h-[4em] rounded-full"}
                    src={conversationResponseData?.messageRead[0]?.profilepic} alt="Person" />
                  {conversationResponseData?.messageRead[0]?.fromPasswordProtected == "Y" && conversationResponseData?.messageRead[0]?.isAvathar === false ?
                    <div className="absolute top-[-0.3rem] right-[0rem]   lg:top-[-0.4rem]  md:right-[0rem] lg:right-[0rem] flex justify-center items-center h-[100%] w-[100%]  cursor-pointer">
                      <img
                        className=" h-[1rem] w-[1rem] lg:h-[1rem] lg:w-[1rem] "
                        src={LockIcon}
                        alt="Person"
                      />
                    </div>
                    : " "
                  }
                </div>
              </div>
              <div className=" col-span-6 flex items-center justify-center flex-col mt-0 px-[0.4rem] md:px-0 md:pl-[2.3rem] lg:pl-[0.4rem]  md:col-span-6">
                <div className="flex pl-[2rem] md:pl-0 ">
                  <div className="mr-[2.75rem] md:mr-0 py-0 md:mt-[0.6rem]">
                    {/* <!-- <p className=" py-1 text-[#575556] font-segeo text-[18px] font-bold">G65346728</p> --> */}
                    <div className="flex justify-between md:space-x-2">
                      <p className="text-[#575556] font-segeo text-[14px] md:text-[14px] lg:text-[15px] font-bold">{conversationResponseData?.messageRead[0]?.smProfileId}
                      </p>
                      <div className=" flex pl-3 md:pl-0 whitespace-nowrap space-x-2 md:space-x-0 md:hidden lg:flex md:mt-[0px] lg:space-x-2 
                                 
                                ">
                        <p className="text-[#039511] flex items-center font-bold text-[7px] md:text-[9px]   lg:text-[12px] text-[#000000] whitespace-nowrap"
                          //  @click.prevent="open = true"
                          // aria-controls="basic-modal"
                        >{conversationResponseData?.messageRead[0]?.lastOnline != "Online Now" ?
                          "Last Online : " + conversationResponseData?.messageRead[0]?.lastOnline
                          : "Online Chat Now"
                          }</p>
                      </div>


                    </div>
                    <p className=" pt-1 md:pt-1 lg:pt-2 text-[13px] md:whitespace-nowrap lg:whitespace-normal md:text-[13px] leading-[13px] md:leading-[16px] lg:text-[14px] text-[#7A7A7A] font-semibold font-segeo opacity-[0.8] ">{conversationResponseData?.messageRead[0]?.name}</p>
                    <p className=" md:pt-1 lg:pt-2 text-[13px] md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold font-segeo opacity-[0.8] ">{conversationResponseData?.messageRead[0]?.age}, {conversationResponseData?.messageRead[0]?.height}</p>
                    <p className=" md:pt-1 lg:pt-2 text-[13px] md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold font-segeo opacity-[0.8] ">
                      {conversationResponseData?.messageRead[0]?.religion} | {conversationResponseData?.messageRead[0]?.caste}
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-span-2 flex pt-[2rem] md:pt-[1.5rem] justify-end  ">

                <div className="">
                  {/* <div className="pl-1 md:pl-0 "> */}
                  <button className="space-y-1" onClick={() => setShowMenu(!showMenu)} >
                    <div className=" w-[4px] h-[4px] md:w-[4px] md:h-[4px] lg:w-[6px] lg:h-[6px] bg-[#575556] rounded-full ">
                    </div>
                    <div style={{ margin: "2px 0" }} className="  w-[4px] h-[4px] md:w-[4px] md:h-[4px] lg:w-[6px] lg:h-[6px] bg-[#575556] rounded-full ">
                    </div>
                    <div style={{ margin: "2px 0" }} className="  w-[4px] h-[4px] md:w-[4px] md:h-[4px] lg:w-[6px] lg:h-[6px] bg-[#575556] rounded-full ">
                    </div>
                  </button>
                  {showMenu &&
                    <ul
                      className="absolute w-[6.5rem] md:w-0 md:right-[20rem] rounded-[10px] lg:right-[30rem] z-10 bg-[#ffffff] right-[1rem] text-sm text-gray-700 dark:text-gray-200 border border-2-[#E9E9E9]"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <li className='cursor-pointer'>
                        <a
                          onClick={() => navigate(RoutePaths.PROFILE, { state: { id: props.toProfileId } })}
                          className="block px-2 md:px-4 py-1 md:py-2 text-[9px] font-bold text-[#7A7A7A] md:text-[15px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white border-b border-2-[#E9E9E9]"
                        >
                          View Profile
                        </a>
                      </li>
                      <li className='cursor-pointer'>
                        <a
                          onClick={() => navigate(RouteSegments.HELP_AND_FAQ(), { state: { id: conversationResponseData?.messageRead[0]?.profileId, receiverId: conversationResponseData?.messageRead[0]?.smProfileId, senderId: props?.profileId, isfrom: "report" } })}
                          className="block px-2 md:px-4 py-1 md:py-2 text-[9px] font-bold text-[#7A7A7A] md:text-[15px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white border-b border-2-[#E9E9E9]"
                        >
                          Report
                        </a>
                      </li>
                      <li className='cursor-pointer'>
                        {conversationResponseData?.messageRead[0]?.blockedByMe == "N" ?
                          <a
                            onClick={() => blockProfileApiCall(1, conversationResponseData?.messageRead[0]?.profileId)}
                            className=" whitespace-nowrap block px-2 md:px-4 py-1 md:py-2 text-[9px] font-bold text-[#7A7A7A] md:text-[15px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white border-b border-2-[#E9E9E9]"
                          >
                            Block & Restrict
                          </a>
                          :
                          <a
                            onClick={() => blockProfileApiCall(0, conversationResponseData?.messageRead[0]?.profileId)}
                            className=" whitespace-nowrap block px-2 md:px-4 py-1 md:py-2 text-[9px] font-bold text-[#7A7A7A] md:text-[15px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white border-b border-2-[#E9E9E9]"
                          >
                            Unblock & Unrestrict
                          </a>
                        }
                      </li>
                    </ul>
                  }
                  {/* </div> */}

                </div>
              </div>
            </div>
            <div className={`md:mx-0`}>
              {/* <div className='hidden md:block'>
              {conversationResponseData?.messageRead[0]?.blockedByOthers === "Y" ? "" :
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-[7rem] md:mb-0 md:px-[0rem] lg:px-[2rem] ">
                  {conversationResponseData?.isPaid == "N" && conversationResponseData?.toprofilePaidStatus == "N" ?
                    conversationSentData?.length == 1 ?
                      conversationSentData[0].senderreceiver == "receiver" ?
                        <div className='px-[1rem] py-[1rem] mt-[2rem]'>
                          <div className='items-start flex bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                            <input className="accent-[#D10A11] border mt-1  flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent1} name="message" />
                            <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent1}</label>
                          </div>
                          <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                            <input className="accent-[#D10A11] border mt-1 flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent2} name="message" />
                            <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent2}</label>
                          </div>
                          <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                            <input className="accent-[#D10A11] border mt-1 flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent3} name="message" />
                            <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent3}</label>
                          </div>
                          <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                            <input className="accent-[#D10A11] border mt-1  flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent4} name="message" />
                            <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent4}</label>
                          </div>
                        </div>
                        : conversationSentData[0].senderreceiver == "sender" && ""
                      :
                      conversationSentData?.length == 2 && ""
                    :
                    conversationResponseData?.isPaid == "N" && conversationResponseData?.toprofilePaidStatus == "Y" ?
                      conversationSentData?.length == 1 ?
                        conversationSentData[0].senderreceiver == "sender" ?
                          ""

                          :
                          <div className="bg-[#F1F1F1] flex px-[1rem]  py-[0.1rem] md:px-[1rem] md:py-[0.4rem] lg:py-1rem] mt-[2rem] rounded-[10px] ">
                            <div className="flex items-center">
                              <img className="rounded-full w-[4rem]" src={conversationResponseData?.ownProfilePic} alt="" />
                            </div>
                            <div className="flex items-center w-full px-[1rem] ">
                              <input value={formik.values.message}
                                onChange={formik.handleChange} className="bg-[#F1F1F1] text-[14px] md:text-[16px] lg:text-[18px] py-[1rem] w-full outline-none text-[#575556] font-bold !placeholder-[#575556] " type="text" name="message" id="message" placeholder="Type a message" />
                            </div>
                          </div>
                        :
                        <div className="bg-[#F1F1F1] flex px-[1rem]  py-[0.1rem] md:px-[1rem] md:py-[0.4rem] lg:py-1rem] mt-[2rem] rounded-[10px] ">
                          <div className="flex items-center">
                            <img className="rounded-full w-[4rem]" src={conversationResponseData?.ownProfilePic} alt="" />
                          </div>
                          <div className="flex items-center w-full px-[1rem] ">
                            <input value={formik.values.message}

                              onChange={formik.handleChange} className="bg-[#F1F1F1] py-[1rem] text-[14px] md:text-[16px] lg:text-[20px] w-full outline-none text-[#575556] font-bold !placeholder-[#575556] " autoComplete='off' type="text" name="message" id="message" placeholder="Type a message" />
                          </div>
                        </div>
                      :
                      <div className="sticky z-1 bg-[#F1F1F1] flex px-[1rem] py-[0.2rem]  md:px-[1rem] md:py-[0.4rem] lg:py-[0.4rem] md:mt-[-2rem] lg:mt-[1rem] rounded-[10px] ">
                        <div className="flex items-center">
                          <img className="rounded-full w-[3rem]" src={conversationResponseData?.ownProfilePic} alt="" />
                        </div>
                        <div className="flex items-center w-full px-[1rem] ">
                          <input value={formik.values.message}
                          autoComplete="off"
                            onChange={formik.handleChange} className="bg-[#F1F1F1] py-[0.4rem] text-[14px] md:text-[16px] lg:text-[16px] w-full outline-none text-[#575556] lg:font-bold !placeholder-[#575556] " type="text" name="message" id="message" placeholder="Type a message" />
                        </div>
                      </div>



                  }
                  <p className="text-[red] text-[12px] h-[1rem]">{formik.errors.message}</p>
                  <div className="flex justify-end   md:mb-[-1.6rem] lg:mb-[-1rem] ">
                    {conversationResponseData?.isPaid == "N" && conversationResponseData?.toprofilePaidStatus == "N" ?
                      conversationSentData?.length == 1 ?
                        conversationSentData[0].senderreceiver == "receiver" ?
                          <button type="submit" className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                            Send Message
                          </button>
                          :
                          <Link to={RouteSegments?.MEMBERSHIP_REGISTER()} className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                            Upgrade
                          </Link>
                        :
                        conversationSentData?.length == 2 &&
                        <Link to={RouteSegments?.MEMBERSHIP_REGISTER()} className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                          Upgrade
                        </Link>
                      :
                      conversationResponseData?.isPaid == "N" && conversationResponseData?.toprofilePaidStatus == "Y" ?
                        conversationSentData?.length == 1 ?
                          conversationSentData[0].senderreceiver == "sender" ?
                            <Link to={RouteSegments?.MEMBERSHIP_REGISTER()} className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                              Upgrade
                            </Link>
                            :
                            <button type="submit" className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                              Send Message
                            </button>
                          :
                          <button type="submit" className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                            Send Message
                          </button>
                        :

                        <button
                        onClick={() => {
                                    if ( conversationResponseData?.messageRead[0]?.blockedByMe == "Y" ) {
                                      setShowBlockPopup(true);
                                    }
                                  }}
                        type="submit" className="flex justify-center items-center text-[#FFFFFF] w-18 md:w-26 lg:w-32 text-[9px] md:text-[9px] lg:text-[12px] p-2 rounded-[5px] whitespace-nowrap bg-[#D10A11] font-bold font-segeo ">
                          Send Message
                        </button>
                    }
                  </div>

                </div>
              </form>
               }
               </div>
               <div className='md:hidden'>
              {conversationResponseData?.messageRead[0]?.blockedByOthers === "Y" ? "" :
              <form onSubmit={formik.handleSubmit}>
                <div className="  mb-[1rem] md:mb-[7rem] md:px-2rem  fixed mt-8 inset-x-0 bottom-0 md:mb-0 px-[2rem]  ">
                  {conversationResponseData?.isPaid == "N" && conversationResponseData?.toprofilePaidStatus == "N" ?
                    conversationSentData?.length == 1 ?
                      conversationSentData[0].senderreceiver == "receiver" ?
                        <div className='px-[1rem] py-[1rem] mt-[2rem]'>
                          <div className='items-start flex bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                            <input className="accent-[#D10A11] border mt-1  flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent1} name="message" />
                            <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent1}</label>
                          </div>
                          <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                            <input className="accent-[#D10A11] border mt-1 flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent2} name="message" />
                            <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent2}</label>
                          </div>
                          <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                            <input className="accent-[#D10A11] border mt-1 flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent3} name="message" />
                            <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent3}</label>
                          </div>
                          <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                            <input className="accent-[#D10A11] border mt-1  flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent4} name="message" />
                            <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent4}</label>
                          </div>
                        </div>
                        : conversationSentData[0].senderreceiver == "sender" && ""
                      :
                      conversationSentData?.length == 2 && ""
                    :
                    conversationResponseData?.isPaid == "N" && conversationResponseData?.toprofilePaidStatus == "Y" ?
                      conversationSentData?.length == 1 ?
                        conversationSentData[0].senderreceiver == "sender" ?
                          ""

                          :
                          <div className="bg-[#F1F1F1] flex px-[1rem]  py-[0.1rem] md:px-[1rem] md:py-[0.4rem] lg:py-1rem] mt-[2rem] rounded-[10px] ">
                            <div className="flex items-center">
                              <img className="rounded-full w-[4rem]" src={conversationResponseData?.ownProfilePic} alt="" />
                            </div>
                            <div className="flex items-center w-full px-[1rem] ">
                              <input value={formik.values.message}
                                onChange={formik.handleChange} className="bg-[#F1F1F1] text-[14px] md:text-[16px] lg:text-[18px] py-[1rem] w-full outline-none text-[#575556] font-bold !placeholder-[#575556] " type="text" name="message" id="message" placeholder="Type a message" />
                            </div>
                          </div>
                        :
                        <div className="bg-[#F1F1F1] flex px-[1rem]  py-[0.1rem] md:px-[1rem] md:py-[0.4rem] lg:py-1rem] mt-[2rem] rounded-[10px] ">
                          <div className="flex items-center">
                            <img className="rounded-full w-[4rem]" src={conversationResponseData?.ownProfilePic} alt="" />
                          </div>
                          <div className="flex items-center w-full px-[1rem] ">
                            <input value={formik.values.message}
                              onChange={formik.handleChange} className="bg-[#F1F1F1] py-[1rem] text-[14px] md:text-[16px] lg:text-[20px] w-full outline-none text-[#575556] font-bold !placeholder-[#575556] " type="text" name="message" id="message"
                             autoComplete='off'  placeholder="Type a message" />
                          </div>
                        </div>
                      :

                      <div className="bg-[#F1F1F1] flex px-[1rem] py-[0.2rem]  md:px-[1rem] md:py-[0.4rem] lg:py-[1rem] mt-[2rem] rounded-[10px] ">
                        <div className="flex items-center">
                          <img className="rounded-full w-[3rem]" src={conversationResponseData?.ownProfilePic} alt="" />
                        </div>
                        <div className="flex items-center w-full px-[1rem] ">
                          <input value={formik.values.message}
                          autoComplete="off"
                            onChange={formik.handleChange} className="bg-[#F1F1F1] py-[0.5rem] text-[14px] md:text-[16px] lg:text-[20px] w-full outline-none text-[#575556] lg:font-bold !placeholder-[#575556] " type="text" name="message" id="message" placeholder="Type a message" />
                        </div>
                      </div>



                  }
                  <p className="text-[red] text-[12px] h-[1rem]">{formik.errors.message}</p>
                  <div className="flex justify-end   md:mb-[-1.6rem] lg:mb-[-1rem] ">
                    {conversationResponseData?.isPaid == "N" && conversationResponseData?.toprofilePaidStatus == "N" ?
                      conversationSentData?.length == 1 ?
                        conversationSentData[0].senderreceiver == "receiver" ?
                          <button type="submit" className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                            Send Message
                          </button>
                          :
                          <Link to={RouteSegments?.MEMBERSHIP_REGISTER()} className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                            Upgrade
                          </Link>
                        :
                        conversationSentData?.length == 2 &&
                        <Link to={RouteSegments?.MEMBERSHIP_REGISTER()} className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                          Upgrade
                        </Link>
                      :
                      conversationResponseData?.isPaid == "N" && conversationResponseData?.toprofilePaidStatus == "Y" ?
                        conversationSentData?.length == 1 ?
                          conversationSentData[0].senderreceiver == "sender" ?
                            <Link to={RouteSegments?.MEMBERSHIP_REGISTER()} className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                              Upgrade
                            </Link>
                            :
                            <button type="submit" className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                              Send Message
                            </button>
                          :
                          <button type="submit" className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                            Send Message
                          </button>
                        :

                        <button
                        onClick={() => {
                                    if ( conversationResponseData?.messageRead[0]?.blockedByMe == "Y" ) {
                                      setShowBlockPopup(true);
                                    }
                                  }}
                        type="submit" className="text-[#FFFFFF]  test text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal ">
                          Send Message
                        </button>
                    }
                  </div>

                </div>
              </form>
               }
               </div> */}
              {/* <div ref={messagesEndRef} className={props?.type==="Unread"?'md:px-[0rem] lg:px-[2rem] relative top-[14rem] md:top-0 md:mt-[2rem] message-container thinScroll' */}
              <div ref={messagesEndRef}
                style={{ height: (heightPixel) + "px", overflowY: "auto" }}
                className={props?.type === "Unread" ? 'md:px-[0rem] lg:px-[2rem] relative top-[0rem] md:top-0 md:mt-0 message-container thinScroll'
                  : location?.state?.type ?
                    ' section-4 md:px-[0rem] lg:px-[2rem] relative top-[0rem] md:top-0 md:mt-0 message-container1 thinScroll' : "md:px-[0rem] lg:px-[2rem] relative top-[0rem] md:top-0 md:mt-0 message-container thinScroll"}>
                <div className={`${showRequestPhoto || showParentsNumber ? "mb-[36px] md:mb-0" : ""}`}>
                  {conversationSentData?.map((message, i) => (
                    <>
                      <div>
                        {i != 0 ?
                          conversationSentData[i].mailDate == conversationSentData[i - 1].mailDate ?
                            ""
                            :
                            <center><p className=' text-[13px] md:text-[16px] mt-[0.3rem]'>{message?.mailDate}</p></center>
                          :
                          <center><p className='text-[13px] md:text-[16px] mt-[0.3rem]'>{message?.mailDate}</p></center>
                        }
                        {i != 0 ?
                          conversationSentData[i].senderreceiver == conversationSentData[i - 1].senderreceiver ?
                            ""
                            :
                            message?.senderreceiver == "sender" ?
                              <div className={message?.senderreceiver == "sender" ? `justify-end mt-[0.5rem] md:mt-[0.5rem] lg:mt-[0.5rem] pr-[1rem] flex  ` : `mt-[2rem] md:mt-[0.5rem] lg:mt-[0.5rem] pr-[1rem] flex  `}>
                                <div className='relative'>
                                  <img className="rounded-full w-[3rem] h-[3rem] md:w-[3rem] md:h-[3rem] lg:w-[3rem] lg:h-[3rem] " src={conversationResponseData?.ownProfilePic} alt="" />
                                  {/* {conversationResponseData?.messageRead[0]?.fromPasswordProtected== "Y" && ( */}
                                  {/* <div className="absolute top-[-0.2rem] right-[0rem] md:right-[0rem] lg:right-[0rem] flex justify-center items-center h-[100%] w-[100%]  cursor-pointer">
                                          <img
                                            className=" h-[1rem] w-[1rem]"
                                            src="/Assets/Images/lockicon.svg"
                                            alt="Person"
                                          />
                                        </div> */}
                                  {/* // )} */}
                                </div>
                              </div>
                              :
                              <div className={message?.senderreceiver == "sender" ? `justify-end mt-[0rem] md:mt-[0rem] lg:mt-[0rem] pr-[1rem] flex ` : `mt-[2rem] md:mt-[0rem] lg:mt-[0rem] pr-[1rem] `}>
                                <div className='relative'>
                                  <img className={`${conversationResponseData?.messageRead[0]?.blurPhoto === "Y" && conversationResponseData?.messageRead[0]?.isAvathar === false ? " blur-[2px] rounded-full w-[3rem] h-[3rem] md:w-[3rem] md:h-[3rem] lg:w-[3rem] lg:h-[3rem]" : "rounded-full w-[3rem] h-[3rem] md:w-[3rem] md:h-[3rem] lg:w-[3rem] lg:h-[3rem] "}`} src={conversationResponseData?.messageRead[0]?.profilepic} alt="profile" />
                                  {conversationResponseData?.messageRead[0]?.fromPasswordProtected === "Y" && conversationResponseData?.messageRead[0]?.isAvathar === false ?
                                    <div className="absolute top-[-0.2rem] right-[-15px]   lg:top-[-0.2rem]  md:right-[-1rem] lg:right-[-1rem] flex justify-start items-center h-[100%] w-[100%]  cursor-pointer">
                                      <img className=" h-[1rem] w-[1rem] lg:h-[1rem] lg:w-[1rem] " src={LockIcon} alt="Person" />
                                    </div>
                                    : " "}
                                </div>
                              </div>
                          :
                          message?.senderreceiver == "sender" ?
                            <div className={message?.senderreceiver == "sender" ? `justify-end mt-[rem] md:mt-[0rem] lg:mt-[0rem] pr-[1rem] flex ` : `mt-[2rem] md:mt-[0rem] lg:mt-[0rem] pr-[1rem] flex `}>
                              <div className='relative'>
                                <img className="rounded-full w-[3rem] h-[3rem] md:w-[3rem] md:h-[3rem] lg:w-[3rem] lg:h-[3rem] " src={conversationResponseData?.ownProfilePic} alt="" />
                                {/* {conversationResponseData?.messageRead[0]?.fromPasswordProtected== "Y" && ( */}
                                {/* <div className="absolute top-[-0.2rem] right-[0rem] md:right-[0rem] lg:right-[0rem] flex justify-center items-center h-[100%] w-[100%]  cursor-pointer">
                                    <img
                                      className=" h-[1rem] w-[1rem]"
                                      src="/Assets/Images/lockicon.svg"
                                      alt="Person"
                                    />
                                  </div> */}
                                {/* )} */}
                              </div>
                            </div>
                            :
                            <div className={message?.senderreceiver == "sender" ? `justify-end mt-[0rem] md:mt-[0rem] lg:mt-[0rem] pr-[1rem] flex ` : `mt-[2rem] md:mt-[0rem] lg:mt-[0rem] pr-[1rem] `}>
                              <div className='relative'>
                                <img className={`${conversationResponseData?.messageRead[0]?.blurPhoto === "Y" && conversationResponseData?.messageRead[0]?.isAvathar === false ? " blur-[2px] rounded-full w-[3rem] h-[3rem] md:w-[3rem] md:h-[3rem] lg:w-[3rem] lg:h-[3rem] " : "rounded-full w-[3rem] h-[3rem] md:w-[3rem] md:h-[3rem] lg:w-[3rem] lg:h-[3rem] "}`} src={conversationResponseData?.messageRead[0]?.profilepic} alt="profile" />
                                {conversationResponseData?.messageRead[0]?.fromPasswordProtected === "Y" && conversationResponseData?.messageRead[0]?.isAvathar === false ?
                                  <div className="absolute top-[-0.2rem] right-[-15px]   lg:top-[-0.2rem]  md:right-[-1rem] lg:right-[-1rem] flex justify-start items-center h-[100%] w-[100%]  cursor-pointer">
                                    <img className=" h-[1rem] w-[1rem] lg:h-[1rem] lg:w-[1rem] " src={LockIcon} alt="Person" />
                                  </div>
                                  : " "}
                              </div>
                            </div>
                        }
                      </div>
                      {/* section */}
                      <div className={message?.senderreceiver == "sender" ? "bg-[#FFEEEE] flex-col justify-between px-[1rem] md:px-[1rem] mt-[0.5rem] py-[0.5rem] md:grid md:grid-cols-6 lg:grid lg:grid-cols-6 rounded-[20px] rounded-tr-[0px] " : " py-[0.8rem] mt-[0.5rem] flex-col justify-between px-[1rem] md:px-0 bg-[#E9E9E9] md:grid md:grid-cols-12 lg:grid lg:grid-cols-6  rounded-[20px] rounded-tl-[0px] "}>
                        <div className={message?.senderreceiver == "sender" ? "col-span-4 lg:col-span-5 w-full text-[#575556]  pt-[2px] md:pl-[7px] lg:pl-[26px] text-[11px] md:text-[14px] lg:text-[14px]  font-semibold opacity-[0.9] break-words " : "col-span-9 w-full lg:col-span-5 text-[#575556]  pt-[2px] md:pl-[7px] lg:pl-[26px] text-[11px] md:text-[14px] lg:text-[14px]  font-semibold opacity-[0.9] break-words "}>
                          {message.subject}
                        </div>
                        <div className={message?.senderreceiver == "sender" ? "col-span-2 lg:col-span-1 items-end justify-end flex" : " col-span-3 lg:col-span-1 justify-end flex "}>
                          <div className=' flex items-end '>
                            <div className='col-span-7 lg:col-span-6'>
                              <p className="w-[4rem] text-[13px]    md:text-[14px] lg:text-[14px] font-semibold flex justify-end  text-[#7A7A7A]">
                                {message.mailTime}
                              </p>
                            </div>
                            <div className="col-span-2 flex items-center pt-[0.5rem] md:pb-[0.3rem]  pl-2 mb-1 md:mb-0">
                              {message?.senderreceiver == "sender" ?
                                message?.isViewed ?
                                  <img className="rounded-full w-[0.9rem] lg:w-[1rem] h-[1rem]" src={senderImage} alt="" />
                                  :
                                  <img className="rounded-full w-[0.9rem] lg:w-[1rem] h-[1rem]" src={reciverImage} alt="" />
                                : ""
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}

                </div>
              </div>
              {/* <div className='hidden md:block mt-[2.3rem] '> */}
              <br />
              <div className='hidden md:block  relative w-[100%] bottom-0 '>
                {conversationResponseData?.messageRead[0]?.blockedByOthers === "Y" ? "" :
                  <>
                    {
                      messageStatus === "NOT_ALLOW"
                        ? <div className='lg:mx-[2rem] z-1 bg-[#F1F1F1] flex px-[1rem] py-[0.2rem]  md:px-[1rem] md:py-[0.4rem] lg:py-[0.4rem]  rounded-[10px] mb-4'>
                          <p className=' text-[12px] md:text-[14px] lg:text-[17px] p-[6px] w-full text-center'>
                            <a href={RouteSegments?.MEMBERSHIP_REGISTER()} className='underline text-[15px] text-[#D10A11]'>Pay Now</a>
                            <span className='px-2 text-[15px]'> to Send a Personaized Message or to View Contact number</span>
                          </p>
                        </div>
                        : <form onSubmit={formik.handleSubmit}>
                          {
                            messageStatus === "ALLOW_DEFAULT_MESSAGE"
                              ? <div>
                                <div className="mb-[7rem] md:mb-[0rem] md:px-[0rem] bg-[#F1F1F1]  rounded-[10px] flex space-x-2 justify-between lg:mx-[2rem] md:mt-[-1rem] items-end">
                                  <div className='px-[1rem] py-[1rem] '>
                                    <div className='items-start flex bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                                      <input className="accent-[#D10A11] border mt-1  flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent1} name="message" />
                                      <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent1}</label>
                                    </div>
                                    <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                                      <input className="accent-[#D10A11] border mt-1 flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent2} name="message" />
                                      <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent2}</label>
                                    </div>
                                    <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                                      <input className="accent-[#D10A11] border mt-1 flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent3} name="message" />
                                      <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent3}</label>
                                    </div>
                                    <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                                      <input className="accent-[#D10A11] border mt-1  flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent4} name="message" />
                                      <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent4}</label>
                                    </div>
                                  </div>
                                  <div className="mb-4 pr-2">
                                    <button type="submit"
                                      onClick={() => {
                                        if (conversationResponseData?.messageRead[0]?.blockedByMe == "Y") {
                                          setShowBlockPopup(true);
                                        }
                                      }}
                                      //  className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal "
                                      className='flex justify-center items-start'
                                    >
                                      <img className='flex justify-center items-start w-[2.5rem] pt-[16px] md:pt-[4px]' src={messagearrow} alt="" />
                                    </button>
                                  </div>
                                </div>
                                <p className="text-[red] text-[12px] h-[0rem] md:pl-[0rem] lg:pl-[2rem] ">{formik.errors.message}</p>
                              </div>
                              : <>
                                <div className="sticky lg:mx-[2rem] z-1 bg-[#F1F1F1] flex px-[1rem] py-[0.2rem]  md:px-[1rem] md:py-[0.4rem] lg:py-[0.4rem]  rounded-[10px] ">
                                  <div className="flex items-center">
                                    <img className="rounded-full w-[3rem] h-[2.5rem] md:w-[3rem] md:h-[2.5rem] lg:w-[3rem] lg:h-[2.5rem] " src={conversationResponseData?.ownProfilePic} alt="" />
                                  </div>
                                  <div className="flex items-center w-full px-[1rem] ">
                                    <input value={formik.values.message}

                                      onChange={formik.handleChange} className="bg-[#F1F1F1] py-[0.4rem] text-[14px] md:text-[16px] lg:text-[16px] w-full outline-none text-[#575556] lg:font-bold !placeholder-[#575556] " autoComplete='off' type="text" name="message" id="message" placeholder="Type a message" />
                                  </div>
                                  <button type="submit"
                                    //  className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal "
                                    className='flex justify-center items-start'
                                  >
                                    <img className='flex justify-center items-start w-[2.5rem] pt-[16px] md:pt-[4px]' src={messagearrow} alt="" />
                                  </button>
                                </div>
                                <p className="text-[red] text-[12px] h-[0rem] md:pl-[0rem] lg:pl-[2rem] ">{formik.errors.message}</p>
                                <div className="flex items-center space-x-4 justify-center py-4">
                                  {showRequestPhoto
                                    ? <CommonCheckButton
                                      id={"requestPhoto"}
                                      name={"requestPhoto"}
                                      selected={formik.values.requestPhoto}
                                      checktext={"Request Photo"}
                                      value={formik.values.requestPhoto}
                                      onChange={(item) => {
                                        //console.log("check", item.target.value);
                                        formik.setFieldValue(
                                          "requestPhoto",
                                          !formik.values.requestPhoto
                                        );
                                      }}
                                    /> : null}
                                  {showParentsNumber
                                    ? <CommonCheckButton
                                      id={"requestParentsNumber"}
                                      name={"requestParentsNumber"}
                                      selected={formik.values.requestParentsNumber}
                                      checktext={"Request Parent's number"}
                                      value={formik.values.requestParentsNumber}
                                      onChange={(item) => {
                                        //console.log("check", item.target.value);
                                        formik.setFieldValue(
                                          "requestParentsNumber",
                                          !formik.values.requestParentsNumber
                                        );
                                      }}
                                    />
                                    : null
                                  }
                                </div>
                              </>
                          }
                        </form>
                    }

                  </>
                }
              </div>
              <div className='md:hidden'>
                {conversationResponseData?.messageRead[0]?.blockedByOthers === "Y" ? "" :
                  messageStatus === "NOT_ALLOW"
                    ? <div className="section-5 mobile-message m-0 bottom-[15px] md:mb-[7rem] md:px-2rem  fixed mt-8 inset-x-0 md:mb-0 px-[2rem]  ">
                      <div className='flex  bg-[#F1F1F1] rounded-[10px] '>
                        <div className='w-full flex bg-[#F1F1F1]  rounded-[10px]'>
                          <p className=' text-[12px] md:text-[14px] lg:text-[17px] p-[6px] w-full text-center'>
                            <a href={RouteSegments?.MEMBERSHIP_REGISTER()} className='underline text-[15px] text-[#D10A11]'>Pay Now</a>
                            <span className='px-2 text-[15px]'> to Send a Personaized Message or to View Contact number</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    : <form onSubmit={formik.handleSubmit}>
                      {messageStatus === "ALLOW_DEFAULT_MESSAGE"
                        ? <div className='section-5 mobile-message m-0 bottom-[15px] md:mb-[7rem] md:px-2rem  fixed mt-8 inset-x-0 md:mb-0 px-[1rem]'>
                          <div className='flex items-end'>
                            {
                              !isSmallDevice
                                ? <div className='px-[1rem] py-[1rem] mt-[2rem]'>
                                  <div className='items-start flex bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                                    <input className="accent-[#D10A11] border mt-1  flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent1} name="message" />
                                    <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent1}</label>
                                  </div>
                                  <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                                    <input className="accent-[#D10A11] border mt-1 flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent2} name="message" />
                                    <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent2}</label>
                                  </div>
                                  <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                                    <input className="accent-[#D10A11] border mt-1 flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent3} name="message" />
                                    <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent3}</label>
                                  </div>
                                  <div className='flex items-start bg-[#F1F1F1] p-[1rem] rounded-[9px] mb-[1rem]'>
                                    <input className="accent-[#D10A11] border mt-1  flex" type="radio" onChange={formik.handleChange} value={conversationResponseData?.msgcontent4} name="message" />
                                    <label className='ml-[0.5rem] text-[#575556] text-[15px]' for="message">{conversationResponseData?.msgcontent4}</label>
                                  </div>
                                </div>
                                : <div className='p-2 w-full'>
                                  <RadioMobileView messageText={conversationResponseData?.msgcontent1} />
                                  <RadioMobileView messageText={conversationResponseData?.msgcontent2} />
                                  <RadioMobileView messageText={conversationResponseData?.msgcontent3} />
                                  <RadioMobileView messageText={conversationResponseData?.msgcontent4} />

                                </div>
                            }
                            <div className="mb-4 ">
                              <button type="submit"
                                onClick={() => {
                                  if (conversationResponseData?.messageRead[0]?.blockedByMe == "Y") {
                                    setShowBlockPopup(true);
                                  }
                                }}
                                //  className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal "
                                className='flex justify-center items-start'
                              >
                                <img className='flex justify-center items-start w-[2.4rem] pr-[4px] pt-[12px] md:pt-[5px]' src={messagearrow} alt="" />
                              </button>
                            </div>
                          </div>
                          <p className="text-[red]  text-[12px]">{formik.errors.message}</p>
                        </div>
                        : <div className="section-5 mobile-message m-0 bottom-[15px] md:mb-[7rem] md:px-2rem  fixed mt-8 inset-x-0 md:mb-0 px-[2rem]  ">
                          <div className='flex  bg-[#F1F1F1] rounded-[10px] '>
                            <div className='w-full flex bg-[#F1F1F1]  rounded-[10px]'>
                              <div className="bg-[#F1F1F1] flex px-[1rem] py-[0.5rem]  md:px-[1rem] md:py-[0.4rem] lg:py-[1rem] rounded-[10px] ">
                                <div className="flex items-center">
                                  <img className="rounded-full w-[3rem] h-[2.5rem] " src={conversationResponseData?.ownProfilePic} alt="" />
                                </div>
                                <div className="flex items-center w-full px-[1rem] ">
                                  <input value={formik.values.message}
                                    autoComplete="off"
                                    onChange={formik.handleChange} className="bg-[#F1F1F1] py-[0.5rem] text-[14px] md:text-[16px] lg:text-[20px] w-full outline-none text-[#575556] lg:font-bold !placeholder-[#575556] " type="text" name="message" id="message" placeholder="Type a message" />
                                </div>
                              </div>

                              <div className="flex justify-end   md:mb-[-1.6rem] lg:mb-[-1rem] ">
                                <button type="submit"
                                  onClick={() => {
                                    if (conversationResponseData?.messageRead[0]?.blockedByMe == "Y") {
                                      setShowBlockPopup(true);
                                    }
                                  }}
                                  //  className="text-[#FFFFFF] text-[12px] md:text-[14px] lg:text-[17px] p-2 px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal "
                                  className='flex justify-center items-start'
                                >
                                  <img className='flex justify-center items-start w-[2.4rem] pr-[4px] pt-[12px] md:pt-[5px]' src={messagearrow} alt="" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <p className="text-[red]  text-[12px]">{formik.errors.message}</p>
                          {showRequestPhoto || showParentsNumber
                            ? <div className="flex items-center  justify-between">
                              {showRequestPhoto
                                ? <CommonCheckButton
                                  id={"requestPhoto"}
                                  name={"requestPhoto"}
                                  selected={formik.values.requestPhoto}
                                  checktext={"Request Photo"}
                                  value={formik.values.requestPhoto}
                                  onChange={(item) => {
                                    //console.log("check", item.target.value);
                                    formik.setFieldValue(
                                      "requestPhoto",
                                      !formik.values.requestPhoto
                                    );
                                  }}
                                /> : null}
                              {showParentsNumber
                                ? <CommonCheckButton
                                  id={"requestParentsNumber"}
                                  name={"requestParentsNumber"}
                                  selected={formik.values.requestParentsNumber}
                                  checktext={"Request Parent's number"}
                                  value={formik.values.requestParentsNumber}
                                  onChange={(item) => {
                                    //console.log("check", item.target.value);
                                    formik.setFieldValue(
                                      "requestParentsNumber",
                                      !formik.values.requestParentsNumber
                                    );
                                  }}
                                />
                                : null
                              }
                            </div> : null}
                        </div>}

                    </form>
                }
              </div>


            </div>
          </div>
        </div>
      </div>
      :
      <center><img src={LoaderGif} className='pt-[9rem] pb-[2rem]' alt='' /></center>
  )
}
