import { ADD_POINT_SUCCESS, GET_ACTIVITY_LIST, GET_ACTIVITY_LIST_SUCCESS, GET_ACTIVITY_TOTAL_POINTS, GET_ACTIVITY_TOTAL_POINTS_SUCCESS, HIDE_CELEBRATION, POINT_AWARDED } from "./ActionTypes";



const initialState = {
    totalPonits: 0,
    activityList: [],
    isActivityListLoading: true,
    dateTime: null,
    showCelebration: false,
}


const reducer = (state = initialState, action) => {
    let message = "";
    let points = action?.payload?.points

    if (action?.payload?.activityId == 1) {
        message = `Congratulations! You earned ${points} points for logging in.`
    } else if (action?.payload?.activityId == 2) {
        message = `Congratulations! You earned ${points} points by viewing this profile.`
    } else if (action?.payload?.activityId == 3) {
        message = `Congratulations! You earned ${points} points by selecting this profile.`
    } else if (action?.payload?.activityId == 4) {
        message = `Congratulations! You earned ${points} points by sending a request. `
    } else if (action?.payload?.activityId == 5) {
        message = `Congratulations! You earned ${points} points  by sending a free message. `
    } else if (action?.payload?.activityId == 6) {
        message = `Congratulations! You earned ${points} points by replying to a message.`
    } else if (action?.payload?.activityId == 7) {
        message = `Congratulations! You earned ${points} points by sending your own message.`
    } else if (action?.payload?.activityId == 8) {
        message = `Congratulations! You earned ${points} points by calling a match.`
    }


    switch (action.type) {
        case GET_ACTIVITY_LIST:
            return {
                ...state,
                isActivityListLoading: true,
            }
        case GET_ACTIVITY_LIST_SUCCESS:
            return {
                ...state,
                isActivityListLoading: false,
                activityList: action.payload.list_data ?? []
            }
        case GET_ACTIVITY_TOTAL_POINTS:
            return state

        case GET_ACTIVITY_TOTAL_POINTS_SUCCESS:
            return {
                ...state,
                totalPonits: action.payload.total_points ?? 0
            }
        case ADD_POINT_SUCCESS:
            return {
                ...state,
                // totalPonits: action.payload.total_point ?? 0
            }
        case HIDE_CELEBRATION:
            return {
                ...state,
                showCelebration: false,
                celebrationMessage: ""
            }
        case POINT_AWARDED:
            return {
                ...state,
                totalPonits: state.totalPonits + action?.payload?.points,
                showCelebration: true,
                dateTime: Date.now(),
                celebrationMessage: message,
            }
        default:
            return state
    }
}

export default reducer