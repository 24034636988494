import React, { memo, useEffect, useRef } from 'react'

//route segment
import RouteSegments from '../../Routes/RouteSegment'
import $ from 'jquery'
import { EventName } from '../../Constants/constants';
import { useAnalytics } from '../../Hooks/usePageChange';
import { useLocation } from 'react-router-dom';



export default function Footer(props) {
    const { onClickTrack } = useAnalytics()
    const location = useLocation()
    const isNRIPage = location.pathname === RouteSegments.LOGIN_FROM_NRI

    useEffect(() => {
        
    }, [props?.menu]);
   
  return (
    <div className=''>
        {/* <InjectScript script={scriptToInject} /> */}
       <footer className={props?.menu == "notification"?"hidden md:block font-segeo bg-[#F1F1F1] fixed mt-8 inset-x-0 bottom-[0rem] ": "hidden md:block font-segeo bg-[#F1F1F1]"} aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">Footer</h2> 
            <div className="">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className=" grid grid-cols-2 md:px-3 md:pt-[2.25rem] md:pb-[1.25rem]  lg:pt-[2.25rem] lg:pb-[1.25rem]  xl:col-span-3 xl:mt-0">
                        <div className="md:grid md:grid-cols-2 md:gap-9">
                            <img className=" md:h-18 lg:h-24 mt-[2rem] ml-[1rem] " src={require('../../Images/KM-logo_2x.webp')} alt="" loading="lazy"/>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-[17px] font-semibold text-[#000000]">Help &
                                Support
                                </h3>
                                <ul role="list" className="mt-4 grid grid-cols-5">
                                <div className="col-span-2 space-y-2 md:col-span-3 lg:col-span-2">
                                    <li >
                                              <a onClick={()=>onClickTrack(EventName.Home_page_Help_Support)} href={RouteSegments.HELP_AND_FAQ()} className=" font-segeo font-normal text-[13px] text-[#7A7A7A]" target="_blank">Help & Support</a>
                                    </li>
                                    <li>
                                              <a onClick={()=>onClickTrack(EventName.Home_page_Contact_Us)} href={RouteSegments.HELP_AND_FAQ()} className=" font-segeo font-normal text-[13px]  text-[#7A7A7A]" target="_blank"> Contact
                                        Us
                                        </a>
                                    </li>
                                    <li>
                                              <a onClick={()=>onClickTrack(EventName.Home_page_Feedback)} href={RouteSegments.HELP_AND_FAQ()} className=" font-segeo font-normal text-[13px] text-[#7A7A7A]" target="_blank">Feedback</a>
                                    </li>
                                </div>
                                <div className="col-span-1 space-y-2 ">
                                    <li>
                                              <a onClick={()=>onClickTrack(EventName.Home_page_FAQs)} href={RouteSegments.HELP_AND_FAQ()} className=" font-segeo font-normal text-[13px]  text-[#7A7A7A]" target="_blank">FAQs
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a onClick={()=>onClickTrack(EventName.Home_page_Delete_Photo)} href={RouteSegments.DELETE_PHOTO} className=" font-segeo font-normal text-[13px]  text-[#7A7A7A] whitespace-nowrap" target="_blank">Delete Photo
                                        </a>
                                    </li> */}
                                </div>
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-[16px] font-semibold text-[#000000]">Information</h3>
                                <ul role="list" className="mt-4 grid grid-cols-2">
                                <div className="col-span-1 space-y-2 whitespace-nowrap">
                                    <li>
                                        <a onClick={()=>onClickTrack(EventName.Home_page_About_us)} href={RouteSegments.ABOUT_US} className=" font-segeo font-normal text-[13px]  text-[#7A7A7A]" target="_blank">About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={()=>onClickTrack(EventName.Home_page_Kalyan_Gold_Scheme)} href={RouteSegments.staticLinkForGoldSavingSchemeOnline} className=" font-segeo font-normal text-[13px]  text-[#7A7A7A]" target="_blank"> Buy Gold
                                        Scheme
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={()=>onClickTrack(EventName.Home_page_Book_an_Appointment)} href={RouteSegments.JEWELLERY_MICROSITE} className=" font-segeo font-normal text-[13px]  text-[#7A7A7A]" target="_blank"> Book Appointment for Jewellery
                                        </a>
                                    </li>
                                </div>
                                <div className="col-span-1 space-y-2 whitespace-nowrap  md:ml-8 lg:ml-0">
                                    <li>
                                        <a onClick={()=>onClickTrack(EventName.Home_page_Terms_Conditions)} href={RouteSegments.TERMS_AND_CONDITION} className=" font-segeo font-normal text-[13px]  text-[#7A7A7A]" target="_blank">Terms &
                                        Conditions
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={()=>onClickTrack(EventName.Home_page_Privacy_Policy)} href={RouteSegments.PRIVACY_POLICY} className=" font-segeo font-normal text-[13px]  text-[#7A7A7A]" target="_blank">Privacy
                                        Policy
                                        </a>
                                    </li>
                                </div>
                                </ul>
                            </div>
                            <div className=" space-y-2 footer-download" style={{textAlign:"-webkit-center"}}>
                                <p className="text-[#000000] font-semibold text-[14px]">Download
                                App 
                                </p>
                                <a onClick={()=>onClickTrack(EventName.Home_page_Download_App_Play_Store)} href={isNRIPage ? RouteSegments.staticLinkForAppDownload.androidNri : RouteSegments.staticLinkForAppDownload.android} target="_blank">
                                <div className="flex md:h-10 md:w-28 lg:w-40 lg:h-[3rem] bg-black text-white rounded-lg items-center justify-center">
                                <div>
                                    <svg className="md:w-[1.4rem] lg:w-[2rem]" viewBox="30 336.7 120.9 129.2" width="30">
                                        <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"/>
                                        <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"/>
                                        <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"/>
                                        <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"/>
                                    </svg>
                                </div>
                                <div>
                                    <div className=" text-[9px] md:text-[11px] lg:text-[12px] text-start">GET IT ON</div>
                                    <div className=" text-[9px] md:text-[11px] lg:text-[12px] font-semibold font-segeo -mt-1">Google Play</div>
                                </div>
                            </div>
                            </a>
                            <a onClick={()=>onClickTrack(EventName.Home_page_Download_App_AppStore)} href={RouteSegments.staticLinkForAppDownload.ios} target="_blank">
                            <div className="flex mt-[10px] md:h-10 md:w-28 lg:w-40 lg:h-[3rem] bg-black text-white rounded-lg items-center justify-center">
                                <div>
                                    <svg className="md:w-[1.4rem] lg:w-[2rem]"  viewBox="0 0 384 512" width="30" >
                                        <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                                    </svg>
                                </div>
                                <div>
                                    <div className=" md:text-[10px] lg:text-[12px]">Download on the</div>
                                    <div className=" md:text-[15px] lg:text-[18px] font-semibold font-segeo -mt-1">App Store</div>
                                </div>
                            </div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#D10A11] p-3">
                    <p className=" text-[#FFFFFF] text-center font-segeo font-bold text-[13px]">&copy; Copyright
                        © 2022 www.kalyanmatrimony.com
                    </p>
                </div>
            </div>
      </footer>
    </div>
  )
}
