import React, { useEffect, useContext, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { POST, GET } from "../../Services/api_services";
import { LoginContext } from "../../Contexts/LoginContext";
import Constants, { loginFrom } from "../../Constants/constants";
import '../../index.css'
import Loader from '../Loader'
import config from "../../config";
import CloseIcon from "../../Images/Group 761@2x.png"
import { validateNumber } from "../../Utils";
import { useDispatch } from "react-redux";
import { dashboardProfileData } from '../../Store/DashboardAPI/Action';
import SuccessIcon from '../../Images/Group 809.svg'
import { getDeviceInfo, getGroupAuthId } from "../Kalyanlite/utils";

const ParentsPhonePopupLite = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [isdCodeList, setIsdCodeList] = useState([]);
  const [relationDropDown, setRelationDropDown] = useState([{ id: 5, name: "Mother" }, { id: 6, name: "Father" }]);
  const [alterRelationDropDown, setalterRelationDropDown] = useState([{ id: 5, name: "Mother" }, { id: 6, name: "Father" }]);


  const [onSubmitPopup, setOnSubmitPopup] = useState(false)
  const [isShowMessage, setIsShowMessage] = useState("")
  const [showpopup, setShowpopup] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const dispatch = useDispatch();
  const isMobile = window.innerWidth <= 768
  // const formik = useFormik({
  //   initialValues: {
  //     parentsMobileNumber: props?.parentsMobileNumber ?? "",
  //     parentsIsdCode: props?.parentsIsdCode ?? "",
  //     relationshipId: props?.relationshipId ?? "",
  //     alternateRelationshipId: props?.relationshipId ?? "",
  //     alternateParentsIsdCode: props?.parentsIsdCode ?? "",
  //     alternateParentsMobileNumber: props?.parentsMobileNumber ?? "",
  //     preferredParentsNumber: props?.parentsMobileNumber ? "parentsMobileNumber" : "alternateParentsMobileNumber",
  //   },
  //   enableReinitialize: true,
  //   validationSchema: Yup.object().shape({
  //     parentsMobileNumber: Yup.string()
  //       .test("india", "Please enter a valid Mobile Number", function (value) {
  //         const { parentsIsdCode } = this.parent;
  //         if (parentsIsdCode === '+91') {
  //           return !value || value.length === 10 ? true : this.createError({ message: "Please enter a valid Mobile Number", path: "parentsMobileNumber" });
  //         }
  //         return true;
  //       }).test("other", "Please enter a valid Mobile Number", function (value) {
  //         const { parentsIsdCode } = this.parent;
  //         if (parentsIsdCode !== '+91') {
  //           return !value || (value.length >= 7 && value.length <= 15)
  //             ? true
  //             : this.createError({
  //               message: 'Please enter a valid Mobile Number',
  //               path: 'parentsMobileNumber',
  //             });
  //         }
  //         return true;
  //       }).required("Please enter Mobile Number"),
  //     relationshipId: Yup.string().required("Please select Relation"),


  //     alternateParentsMobileNumber: Yup.string()
  //       .test("india", "Please enter a valid Mobile Number", function (value) {
  //         const { alternateParentsIsdCode } = this.parent;
  //         if (alternateParentsIsdCode === '+91') {
  //           return !value || value.length === 10 ? true : this.createError({ message: "Please enter a valid Mobile Number", path: "alternateParentsMobileNumber" });
  //         }
  //         return true;
  //       }).test("other", "Please enter a valid Mobile Number", function (value) {
  //         const { alternateParentsIsdCode } = this.parent;
  //         if (alternateParentsIsdCode !== '+91') {
  //           return !value || (value.length >= 7 && value.length <= 15)
  //             ? true
  //             : this.createError({
  //               message: 'Please enter a valid Mobile Number',
  //               path: 'alternateParentsMobileNumber',
  //             });
  //         }
  //         return true;
  //       }),
  //     alternateRelationshipId: Yup.string(),

  //   }),

  //   onSubmit: (values) => {
  //     //console.log("values",values)
  //     setOnSubmitPopup(true)
  //     apicallSubmitParentsNum(values)
  //     // setTimeout(function(){
  //     //   setOnSubmitPopup(false)

  //     // },[200])

  //     // setTimeout(function(){
  //     //   setIsShowMessage("Parent's number saved")
  //     // },[500])
  //     //   props.callBackApply(values);
  //   },
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     parentsMobileNumber: props?.parentsMobileNumber ?? "",
  //     parentsIsdCode: props?.parentsIsdCode ?? "",
  //     relationshipId: props?.relationshipId ?? "",
  //     alternateRelationshipId: props?.relationshipId ?? "",
  //     alternateParentsIsdCode: props?.parentsIsdCode ?? "",
  //     alternateParentsMobileNumber: props?.parentsMobileNumber ?? "",

  //   },
  //   enableReinitialize: true,
  //   validationSchema: Yup.object().shape({
  //     parentsMobileNumber: Yup.string()
  //       .test("india", "Please enter a valid Mobile Number", function (value) {
  //         const { parentsIsdCode } = this.parent;
  //         if (parentsIsdCode === "+91") {
  //           return (
  //             !value ||
  //             value.length === 10 ||
  //             this.createError({
  //               message: "Please enter a valid Mobile Number",
  //               path: "parentsMobileNumber",
  //             })
  //           );
  //         }
  //         return true;
  //       })
  //       .test("other", "Please enter a valid Mobile Number", function (value) {
  //         const { parentsIsdCode } = this.parent;
  //         if (parentsIsdCode !== "+91") {
  //           return (
  //             !value ||
  //             (value.length >= 7 && value.length <= 15) ||
  //             this.createError({
  //               message: "Please enter a valid Mobile Number",
  //               path: "parentsMobileNumber",
  //             })
  //           );
  //         }
  //         return true;
  //       }),
  //     relationshipId: Yup.string(),

  //     alternateParentsMobileNumber: Yup.string()
  //       .test("india", "Please enter a valid Mobile Number", function (value) {
  //         const { alternateParentsIsdCode } = this.parent;
  //         if (alternateParentsIsdCode === "+91") {
  //           return (
  //             !value ||
  //             value.length === 10 ||
  //             this.createError({
  //               message: "Please enter a valid Mobile Number",
  //               path: "alternateParentsMobileNumber",
  //             })
  //           );
  //         }
  //         return true;
  //       })
  //       .test("other", "Please enter a valid Mobile Number", function (value) {
  //         const { alternateParentsIsdCode } = this.parent;
  //         if (alternateParentsIsdCode !== "+91") {
  //           return (
  //             !value ||
  //             (value.length >= 7 && value.length <= 15) ||
  //             this.createError({
  //               message: "Please enter a valid Mobile Number",
  //               path: "alternateParentsMobileNumber",
  //             })
  //           );
  //         }
  //         return true;
  //       }),
  //     alternateRelationshipId: Yup.string(),

  //     // Custom validation to ensure at least one set of fields is filled
  //     mandatoryFields: Yup.mixed().test(
  //       "one-set-required",
  //       "At least one set of fields is mandatory (either Parent's or Alternate Parent's Mobile Number with ISD Code and Relationship)",
  //       function () {
  //         const {
  //           parentsMobileNumber,
  //           parentsIsdCode,
  //           relationshipId,
  //           alternateParentsMobileNumber,
  //           alternateParentsIsdCode,
  //           alternateRelationshipId,
  //         } = this.parent;

  //         const isPrimarySetFilled =
  //           parentsMobileNumber && parentsIsdCode && relationshipId;
  //         const isAlternateSetFilled =
  //           alternateParentsMobileNumber &&
  //           alternateParentsIsdCode &&
  //           alternateRelationshipId;

  //         return isPrimarySetFilled || isAlternateSetFilled;
  //       }
  //     ),
  //   }),

  //   onSubmit: (values) => {
  //     setOnSubmitPopup(true);
  //     apicallSubmitParentsNum(values);
  //     props?.onSaveClick()
  //   },
  // });
  // const formik = useFormik({
  //   initialValues: {
  //     parentsMobileNumber: props?.parentsMobileNumber ?? "",
  //     parentsIsdCode: props?.parentsIsdCode ?? "",
  //     relationshipId: props?.relationshipId ?? "",
  //     alternateParentsMobileNumber: props?.alternateParentsMobileNumber ?? "",
  //     alternateParentsIsdCode: props?.alternateParentsIsdCode ?? "",
  //     alternateRelationshipId: props?.alternateRelationshipId ?? "",
  //   },
  //   enableReinitialize: true,
  //   validationSchema: Yup.object().shape({
  //     parentsMobileNumber: Yup.string()
  //       .test("india", "Please enter a valid Mobile Number", function (value) {
  //         const { parentsIsdCode } = this.parent;
  //         if (parentsIsdCode === "+91") {
  //           return (
  //             !value ||
  //             value.length === 10 ||
  //             this.createError({
  //               message: "Please enter a valid Mobile Number",
  //               path: "parentsMobileNumber",
  //             })
  //           );
  //         }
  //         return true;
  //       })
  //       .test("other", "Please enter a valid Mobile Number", function (value) {
  //         const { parentsIsdCode } = this.parent;
  //         if (parentsIsdCode !== "+91") {
  //           return (
  //             !value ||
  //             (value.length >= 7 && value.length <= 15) ||
  //             this.createError({
  //               message: "Please enter a valid Mobile Number",
  //               path: "parentsMobileNumber",
  //             })
  //           );
  //         }
  //         return true;
  //       }),

  //     alternateParentsMobileNumber: Yup.string()
  //       .test("india", "Please enter a valid Mobile Number", function (value) {
  //         const { alternateParentsIsdCode } = this.parent;
  //         if (alternateParentsIsdCode === "+91") {
  //           return (
  //             !value ||
  //             value.length === 10 ||
  //             this.createError({
  //               message: "Please enter a valid Mobile Number",
  //               path: "alternateParentsMobileNumber",
  //             })
  //           );
  //         }
  //         return true;
  //       })
  //       .test("other", "Please enter a valid Mobile Number", function (value) {
  //         const { alternateParentsIsdCode } = this.parent;
  //         if (alternateParentsIsdCode !== "+91") {
  //           return (
  //             !value ||
  //             (value.length >= 7 && value.length <= 15) ||
  //             this.createError({
  //               message: "Please enter a valid Mobile Number",
  //               path: "alternateParentsMobileNumber",
  //             })
  //           );
  //         }
  //         return true;
  //       }),

  //     relationshipId: Yup.string(),
  //     alternateRelationshipId: Yup.string(),

  //     // Custom validation to check if at least one set is filled, otherwise show the common error
  //     mandatoryFields: Yup.mixed().test(
  //       "one-set-required",
  //       "At least one set of fields is mandatory (Parent's Mobile Number, ISD Code, Relationship or Alternate Parent's Mobile Number, ISD Code, Relationship)",
  //       function () {
  //         const {
  //           parentsMobileNumber,
  //           parentsIsdCode,
  //           relationshipId,
  //           alternateParentsMobileNumber,
  //           alternateParentsIsdCode,
  //           alternateRelationshipId,
  //         } = this.parent;

  //         const isPrimarySetFilled =
  //           parentsMobileNumber && parentsIsdCode && relationshipId;
  //         const isAlternateSetFilled =
  //           alternateParentsMobileNumber &&
  //           alternateParentsIsdCode &&
  //           alternateRelationshipId;

  //         // Show an error if none of the fields are filled
  //         return isPrimarySetFilled || isAlternateSetFilled
  //           ? true
  //           : this.createError({
  //             message: "At least one set of fields is mandatory",
  //             path: "mandatoryFields",
  //           });
  //       }
  //     ),
  //   }),

  //   onSubmit: (values) => {
  //     setOnSubmitPopup(true);
  //     apicallSubmitParentsNum(values);
  //     props?.onSaveClick();
  //   },
  // });


  const formik = useFormik({
    initialValues: {
      parentsMobileNumber: props?.parentsMobileNumber ?? "",
      parentsIsdCode: props?.parentsIsdCode ?? "",
      relationshipId: props?.relationshipId ?? "",
      alternateParentsMobileNumber: props?.alternateParentsMobileNumber ?? "",
      alternateParentsIsdCode: props?.alternateParentsIsdCode ?? "+91",
      alternateRelationshipId: props?.alternateRelationshipId ?? ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // Validation for Parent's Mobile Number
      parentsMobileNumber: Yup.string()
        .test("india", "Please enter a valid Mobile Number", function (value) {
          const { parentsIsdCode } = this.parent;
          if (parentsIsdCode === "+91") {
            return (
              !value ||
              value.length === 10 ||
              this.createError({
                message: "Please enter a valid Mobile Number",
                path: "parentsMobileNumber",
              })
            );
          }
          return true;
        })
        .test("other", "Please enter a valid Mobile Number", function (value) {
          const { parentsIsdCode } = this.parent;
          if (parentsIsdCode !== "+91") {
            return (
              !value ||
              (value.length >= 7 && value.length <= 15) ||
              this.createError({
                message: "Please enter a valid Mobile Number",
                path: "parentsMobileNumber",
              })
            );
          }
          return true;
        }),

      // Validation for Alternate Parent's Mobile Number
      alternateParentsMobileNumber: Yup.string()
        .test("india", "Please enter a valid Mobile Number", function (value) {
          const { alternateParentsIsdCode } = this.parent;
          if (alternateParentsIsdCode === "+91") {
            return (
              !value ||
              value.length === 10 ||
              this.createError({
                message: "Please enter a valid Mobile Number",
                path: "alternateParentsMobileNumber",
              })
            );
          }
          return true;
        })
        .test("other", "Please enter a valid Mobile Number", function (value) {
          const { alternateParentsIsdCode } = this.parent;
          if (alternateParentsIsdCode !== "+91") {
            return (
              !value ||
              (value.length >= 7 && value.length <= 15) ||
              this.createError({
                message: "Please enter a valid Mobile Number",
                path: "alternateParentsMobileNumber",
              })
            );
          }
          return true;
        }),

      // Validation for Relationships (empty allowed unless some fields are filled)
      relationshipId: Yup.string(),
      alternateRelationshipId: Yup.string(),

      // Custom validation to ensure all fields are filled for each set or show errors
      mandatoryFields: Yup.mixed().test(
        "one-set-required",
        "At least one set of fields is mandatory (Parent's Mobile Number, ISD Code, Relationship or Alternate Parent's Mobile Number, ISD Code, Relationship)",
        function () {
          const {
            parentsMobileNumber,
            parentsIsdCode,
            relationshipId,
            alternateParentsMobileNumber,
            alternateParentsIsdCode,
            alternateRelationshipId,
          } = this.parent;

          // Primary set validation
          const isPrimarySetFilled =
            parentsMobileNumber && parentsIsdCode && relationshipId;

          // Alternate set validation
          const isAlternateSetFilled =
            alternateParentsMobileNumber &&
            alternateParentsIsdCode &&
            alternateRelationshipId;

          // Case 1: Primary set filled, alternate set partially filled
          if (isPrimarySetFilled) {
            if (alternateParentsMobileNumber && !alternateParentsIsdCode) {
              return this.createError({
                message: "Please select isdcode",
                path: "alternateParentsIsdCode",
              });
            }  else if (alternateParentsMobileNumber && !alternateRelationshipId) {
              return this.createError({
                message: "Please select relation ",
                path: "alternateRelationshipId",
              });
            } else if (alternateRelationshipId && !alternateParentsMobileNumber) {
              return this.createError({
                message: "Please enter phone number ",
                path: "alternateParentsMobileNumber",
              });
            }
          }

          // Case 2: Alternate set filled, primary set partially filled
          if (isAlternateSetFilled) {
            if (parentsMobileNumber && !parentsIsdCode) {
              return this.createError({
                message: "Please select isdcode",
                path: "parentsIsdCode",
              });
            }  if (parentsMobileNumber && !relationshipId) {
              return this.createError({
                message: "Please select relation",
                path: "relationshipId",
              });
            } else if (relationshipId && !parentsMobileNumber) {
              return this.createError({
                message: "Please enter phone number",
                path: "parentsMobileNumber",
              });
            }
          }

          // Case 3: None of the sets filled
          return isPrimarySetFilled || isAlternateSetFilled
            ? true
            : this.createError({
              message: "At least one set of field is mandatory",
              path: "mandatoryFields",
            });
        }
      ),
    }),

    onSubmit: (values) => {
      setOnSubmitPopup(true);
      apicallSubmitParentsNum(values);
      props?.onSaveClick();
    },
  });

  const [parentsCheckbox, setParentsCheckbox] = useState(false);
  const [alternateParentsCheckbox, setAlternateParentsCheckbox] = useState(false);
  const ParentFeilds = !formik.values.parentsMobileNumber || !formik.values.parentsIsdCode || !formik.values.relationshipId
  const AlterNate = formik.values.alternateParentsIsdCode && formik.values.alternateParentsMobileNumber && formik.values.alternateRelationshipId
  const Parents = formik.values.parentsIsdCode && formik.values.parentsMobileNumber && formik.values.relationshipId
  useEffect(()=>{
    if(!Parents && AlterNate){
      setAlternateParentsCheckbox(true)
      setParentsCheckbox(false)
    }else if(Parents && !AlterNate){
      setParentsCheckbox(true)
      setAlternateParentsCheckbox(false)
    }else if(parentsCheckbox && !Parents){
      setParentsCheckbox(false)
    }else if(alternateParentsCheckbox && !AlterNate){
      setAlternateParentsCheckbox(false)
    }
  },[Parents,AlterNate,parentsCheckbox,alternateParentsCheckbox])
  useEffect(() => {
    //console.log("Popup called");
    apicallIsdCode();
    console.log(props, "contentResponseData");

  }, []);
  useEffect(() => {
    //console.log("Popup called");
  }, [isdCodeList]);

  {
    /* api/submitparentsnumber
    api/getisdcode */
  }
  const apicallIsdCode = async () => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.gteIsdCodeUrl()}`
    );

    if (statusCode === 200) {
      setIsdCodeList(data?.data?.isdcode);
    } else if (statusCode === 401) {
      logout();
    }
  };

  const [status, setStatus] = useState(null);

  function changeStatus(e) {
    setStatus(e.target.value);
  }
  //   "{
  //     ""userId"": ""1631292"",
  //     ""email"": ""nayekkarthikeyan@gmail.com"",
  //     ""parentsMobileNumber"": ""7686858823"",
  //     ""loginFrom"": ""android"",
  //     ""parentsNumberFrom"": """",
  //     ""countryId"": ""+91""
  // }"
  const apicallSubmitParentsNum = async (value) => {
    let notificationId = null
    const notificationPath = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationPath)
    const loginUrl = sessionStorage.getItem(Constants.loginLocalStorageKeys.loginUrl);
    const notificationsId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)

    if (props?.notificationId || notificationPath == "AddParentsNumber" || loginUrl?.includes("parentsnumber")) {
      notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
    }
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      parentsIsdCode: value.parentsIsdCode,
      parentsMobileNumber: value.parentsMobileNumber,
      parentsNumberFrom: "",
      isContent: props?.name,
      contentId: props?.contentId,
      ...(props?.isFrom == "chat" && {fromPage : "chat"}),
      ...(value.number != "" && {parentnumberrequesttypeid:87}),
      requestfulfilledfrom:getDeviceInfo(),
     requestfulfilledvia: props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile",
      recieverprofileid:getGroupAuthId(),

      notificationId: notificationsId ? notificationsId : props?.notificationId,
      relationshipId: value.relationshipId,
      alternateRelationshipId: value.alternateRelationshipId,
      alternateParentsMobileNumber: value.alternateParentsMobileNumber,
      alternateParentsIsdCode: value.alternateParentsIsdCode,
      preferredParentsNumber: (parentsCheckbox ? 'parentsMobileNumber' : alternateParentsCheckbox ? 'alternateParentsMobileNumber' : '') // Pass the string 'alternateParentsMobileNumber'
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.submitparentsnumberUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data?.data?.status == "SUCCESS") {
        if (props.onSubmitSuccess) {
          props.onSubmitSuccess(value.parentsMobileNumber, value.parentsIsdCode, value.alternateParentsIsdCode, value?.alternateParentsMobileNumber)
        }
        if (props?.notificationId || notificationPath == "AddParentsNumber" || loginUrl?.includes("parentsnumber")) {
          sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
          sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationPath)
        }
        // window.location.reload(false)
        // alert("Parent's number saved")
        setIsShowMessage("Parent's number saved")
        setShowpopup(true)
        setOnSubmitPopup(false)
        if (props.refresh) {
          props.refresh()
        }
        if (props?.isFrom == 'family') {
          props?.callBackApply();
        }
        dispatch(dashboardProfileData())


      } else if (data?.data?.status == "FAILURE") {
        if (data?.data?.parentsNumberExist === "Yes") {
          setErrorMsg("Parent's Number already exist")
          setOnSubmitPopup(false)
          formik.setFieldError("number", "Parent's Number already exist")
        }
      }
      // props.close();
    } else if (statusCode === 401) {
      logout();
    }
  };


  useEffect(() => {


    return () => {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    }
  }, [])

  const closePopupRef = useRef();


  const handleClickOutside = (event) => {
    if (
      closePopupRef.current &&
      !closePopupRef.current.contains(event.target) &&
      isMobile // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      props.close();
    }
  };

  useEffect(() => {
    if(props?.isFrom != "chat"){
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }
  }, []);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div ref={closePopupRef} className="relative md:my-6 md:mx-6">
        <div className="">

          <button
            className={`${props?.isFrom == "chat" && isMobile ? "block absolute top-3 right-[5px]" : "hidden"} md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]`}
            onClick={() => props.close()}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>

        </div>
        <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            {showpopup ?
              <p className="text-[18px] text-[#D10A11]  mt-2 md:pt-2 lg:pt-1  md:ml-2 font-semibold font-poppins text-center">
                Parent's Phone Number
              </p>
              :<p className="text-[18px] text-[#D10A11]  mt-4 ml-3 lg:pt-1  md:ml-2 font-semibold font-poppins">
                {props.title}
              </p>
            }
              {onSubmitPopup ?
                <div className=" flex items-center justify-center  pb-8 pt-3 lg:py-3 bottom-6 flex-auto  h-[8rem] relative w-[100%] px-10"> <Loader /></div>
                : showpopup ?
                  <div className='relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white '>
                    <div className='flex items-center py-1 justify-center mx-2  rounded-t '>
                      <h3 className='text-[20px] text-[#D10A11] ml-2 font-semibold text-center'>
                        Success
                      </h3>
                    </div>
                    <div className='relative p-6 flex-auto'>
                      <div className='flex flex-col justify-center items-center w-[60vw] md:w-full '>
                        <span className='text-[16px] font-semibold whitespace-nowrap'>
                          Parent's Number Added Successfully
                        </span>
                        <img className='w-16 h-16 mt-2' alt='' src={SuccessIcon} />
                      </div>
                    </div>
                  </div>
                  :
                  <form onSubmit={formik.handleSubmit} className="lg:space-y-3 xl:space-y-3 md:space-y-4 lg:pt-[0.5rem] xl:pt-[1rem] md:pt-[0.5rem] pt-[0.5rem]">
                    <div className="flex space-x-2 lg:space-x-3 my-2 lg:ml-2 md:ml-2 ml-1">
                      <label className="parentpopup select font-Poppins-Regular text-[#575556] w-[5rem] md:w-[8rem] lg:w-[7rem] xl:w-[9rem]">
                        <select
                          name="parentsIsdCode"
                          id="parentsIsdCode"
                          value={formik.values.parentsIsdCode}
                          // onChange={changeStatus}
                          onChange={formik.handleChange}
                          className=" md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] font-Poppins-Regular md:text-[13px] xl:text-[14px] bg-white border border-[#C4C4C4] rounded-[6px] leading-[20px]  "
                        >
                          <option >Select</option>
                          {isdCodeList?.map((e, i) => (
                            <option key={i} value={e.isdcode}>
                              {`${e.isdcode} - ${e.country}`}
                            </option>
                          ))}
                        </select>
                        {formik.errors.parentsIsdCode && (
                          <p className="text-[red] text-[12px] whitespace-nowrap">
                            {formik.errors.parentsIsdCode}
                          </p>
                        )}
                      </label>

                      <label className="">
                        <input
                          name={"parentsMobileNumber"}
                          id={"parentsMobileNumber"}
                          type={"tel"}
                          placeholder="Enter Phone Number"
                          value={formik.values.parentsMobileNumber}

                          onChange={(e) => {
                            if (validateNumber(e.target.value)) {
                              formik.handleChange(e)
                            }
                          }}
                          className="number no-spinner w-[11rem] md:w-full lg:w-full p-2 md:p-2.5 lg:p-2.5 my-1 md:text-[13px] xl:text-[14px] font-Poppins-Regular text-[#575556]  rounded-[6px] border border-[#C4C4C4]"
                        />
                        {formik.errors.parentsMobileNumber ? (
                          <div className="h-2 mb-2">
                            <p className="text-[red] text-[12px] whitespace-nowrap">
                              {formik.errors.parentsMobileNumber}
                              </p>
                          </div>
                        ) : (errorMsg && parentsCheckbox)&&
                          <p className="text-[red] text-[12px]">
                            {errorMsg}
                          </p>}
                      </label>

                      <label className="parentpopup select font-Poppins-Regular text-[#575556] w-[6rem] md:w-[8rem] lg:w-[9rem] xl:w-[11rem]">
                        <select
                          name="relationshipId"
                          id="relationshipId"
                          placeholder="Select Relation"
                          value={formik.values.relationshipId}
                          // onChange={changeStatus}
                          onChange={formik.handleChange}
                          className=" md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] font-Poppins-Regular md:text-[13px] xl:text-[14px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]  placeholder:!text-[#575556]"
                        >
                          <option >Select Relation</option>
                          {relationDropDown?.map((e, i) => (
                            <option key={i} value={e.id}

                            disabled={formik.values.alternateRelationshipId == e.id} className={formik.values.alternateRelationshipId == e.id ? 'hidden' : ''}
                            >
                              {`${e.name}`}
                            </option>
                          ))}
                        </select>
                        {formik.errors.relationshipId && (
                          <p className="text-[red] text-[10px] md:text-[12px] lg:text-[12px] font-medium whitespace-nowrap">
                            {formik.errors.relationshipId}
                          </p>
                        )}
                      </label>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-9 lg:grid-cols-9 lg:mr-0">
                      <label className={`col-span-6 relative flex  md:pl-2 pl-2 ${windowWidth < 500 ? 'items-center' : ''}`}>
                        <input
                          name="parentsCheckbox"
                          type="checkbox"
                          checked={parentsCheckbox
                            // (formik.values.parentsIsdCode && formik.values.parentsMobileNumber && formik.values.relationshipId) &&
                            // !(formik.values.alternateParentsIsdCode && formik.values.alternateParentsMobileNumber && formik.values.alternateRelationshipId) // Ensure the second checkbox is not checked
                          }
                          onChange={(e) => {
                            const checked = e.target.checked;
                            setParentsCheckbox(checked)
                            if (checked) {
                              setAlternateParentsCheckbox(false)
                            }
                            
                          }}
                          className={`mr-3 relative top-[2px] check form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400 
                              
                              `}
                          autoComplete="off"
                          disabled={!(formik.values.parentsIsdCode && formik.values.parentsMobileNumber && formik.values.relationshipId)
                            // || (formik.values.alternateParentsIsdCode && formik.values.alternateParentsMobileNumber && formik.values.alternateRelationshipId)
                          } // Disable checkbox if any of the fields are empty
                        />
                        <span className=" md:ml-[3px] lg:ml-[5px]">
                          <p className="text-[#707070] pl-2 font-Poppins-Regular test-xs md:text-[12px] xl:text-[14px]">
                            Add this number to my profile as Parent’s Number
                          </p>
                        </span>
                      </label>
                    </div>
                    <div className="flex space-x-2 lg:space-x-3 my-2 pt-2 lg:ml-2 md:ml-2 ml-1">
                      <label className="parentpopup select font-Poppins-Regular text-[#575556] w-[5rem] md:w-[8rem] lg:w-[7rem] xl:w-[9rem]">
                        <select
                          name="alternateParentsIsdCode"
                          id="alternateParentsIsdCode"
                          value={formik.values.alternateParentsIsdCode}
                          // onChange={changeStatus}
                          onChange={formik.handleChange}
                          className=" md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] font-Poppins-Regular md:text-[13px] xl:text-[14px] bg-white border border-[#C4C4C4] rounded-[6px] leading-[20px]  "
                        >
                          <option >Select</option>
                          {isdCodeList?.map((e, i) => (
                            <option key={i} value={e.isdcode}>
                              {`${e.isdcode} - ${e.country}`}
                            </option>
                          ))}
                        </select>
                        {formik.errors.alternateParentsIsdCode && (
                          <p className="text-[red] text-[12px] whitespace-nowrap">
                            {formik.errors.alternateParentsIsdCode}
                          </p>
                        )}
                      </label>

                      <label className="">
                        <input
                          name={"alternateParentsMobileNumber"}
                          id={"alternateParentsMobileNumber"}
                          type={"tel"}
                          placeholder="Enter Phone Number"
                          value={formik.values.alternateParentsMobileNumber}

                          onChange={(e) => {
                            if (validateNumber(e.target.value)) {
                              formik.handleChange(e)
                            }
                          }}
                          className="number no-spinner w-[11rem] md:w-full lg:w-full p-2 md:p-2.5 lg:p-2.5 my-1  md:text-[13px] xl:text-[14px] font-Poppins-Regular text-[#575556]  rounded-[6px] border border-[#C4C4C4]"
                        />
                        {formik.errors.alternateParentsMobileNumber ? (
                        <div className="h-2 mb-2">
                          <p className="text-[red] text-[12px] whitespace-nowrap">
                            {formik.errors.alternateParentsMobileNumber}
                          </p>
                        </div>
                        ): (errorMsg && alternateParentsCheckbox)&&
                        <p className="text-[red] text-[12px]">
                          {errorMsg}
                        </p>}
                      </label>

                      <label className="parentpopup select font-Poppins-Regular text-[#575556] w-[6rem] md:w-[8rem] lg:w-[9rem] xl:w-[11rem]">
                        <select
                          name="alternateRelationshipId"
                          id="alternateRelationshipId"
                          placeholder="Select Relation"
                          value={formik.values.alternateRelationshipId}
                          // onChange={changeStatus}
                          onChange={formik.handleChange}
                          className=" md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] font-Poppins-Regular md:text-[13px] xl:text-[14px] bg-white border border-[#C4C4C4] rounded-[6px] leading-[20px]  placeholder:!text-[#575556]"
                        >
                          <option >Select Relation</option>
                          {alterRelationDropDown?.map((e, i) => (
                            <option key={i} value={e.id}
                              disabled={formik.values.relationshipId == e.id} className={`${formik.values.relationshipId == e.id ? 'hidden' : ''}`}
                            >
                              {`${e.name}`}
                            </option>
                          ))}
                        </select>
                        {formik.errors.alternateRelationshipId && (
                          <p className="text-[red] text-[10px] md:text-[12px] lg:text-[12px] font-medium whitespace-nowrap">
                            {formik.errors.alternateRelationshipId}
                          </p>
                        )}
                      </label>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-9 lg:grid-cols-9 lg:mr-0">
                      <label className={`col-span-6 relative flex  md:pl-2 pl-2 ${windowWidth < 500 ? 'items-center' : ''}`}>
                        <input
                          name="alternateParentsCheckbox"
                          type="checkbox"
                          checked={alternateParentsCheckbox
                            // (formik.values.alternateParentsIsdCode && formik.values.alternateParentsMobileNumber && formik.values.alternateRelationshipId) // Ensure the first checkbox is not checked
                          }
                          onChange={(e) => {
                            const checked = e.target.checked;
                            setAlternateParentsCheckbox(true)
                            if (checked) {
                              setParentsCheckbox(false)
                            }
                          }}
                          className={`mr-3 relative top-[2px] check form-radio h-4 w-4 accent-[#D10A11] !border !border-gray-400`}
                          autoComplete="off"
                          disabled={!formik.values.alternateParentsIsdCode || !formik.values.alternateParentsMobileNumber || !formik.values.alternateRelationshipId} // Disable if any field is empty
                        />
                        <span className=" md:ml-[3px] lg:ml-[5px]">
                          <p className="text-[#707070] pl-2 font-Poppins-Regular test-xs md:text-[12px] xl:text-[14px]">
                            Add this number to my profile as Parent’s Number
                          </p>
                        </span>
                      </label>
                    </div>

                    {formik.errors.mandatoryFields && (
                      <p className="text-[red] text-[12px] whitespace-nowrap">
                        {formik.errors.mandatoryFields}
                      </p>
                    ) }
                    <div className="flex  justify-center items-center space-x-8 w-full lg:mt-1 md:mt-1 mt-4 mb-2">
                      <button
                        type="submit"
                        className="w-auto flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.5rem] whitespace-nowrap rounded-[6px] font-segoe font-bold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
                      >
                          {onSubmitPopup ? 'Submitig...' : 'Submit'}
                      </button>
                    </div>
                  </form>
              }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentsPhonePopupLite;
