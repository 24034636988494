import React, { useEffect } from "react";
import MultiRangeSlider from "./MultiRangeSliderAge";
import MultiRangeSliderAge from "./MultiRangeSliderAge";
import MultiRangeSliderHeight from "./MultiRangeSliderAgeHeight";
// import "./styles.css";

const App = (props) => { 

   useEffect(() => {
        //console.log(props?.data?.maxage,"maxage")
    }, [props.data]);

  return (

    

    <div>
      {props.menu =="Age" ?
        <MultiRangeSliderAge
          min={18}
          max={70}
          value="Age"
          data={props.data}
          onChange={(min, max) => props?.rangeFilter(min,max)}
        />
      :
        <MultiRangeSliderHeight
          min={54}
          max={84}
          value="Height"
          data={props.data}
          onChange={( min, max ) => props?.rangeFilter(min,max)}
        />
    
    }
    
    </div>
  );
};

export default App;


