import React, { useEffect, useState } from "react";
import { EducationCheckView, MultiSelectView } from "./PartnerPreferenceUtils";
import { EduactionListTag } from "../EditProfileUtills";

const EducationPreference = (props) => {

  const [eduactiondata, setEducationData] = useState([]);
  const [occupationdata, setOccupationData] = useState([]);
  const [filteredMaxSalaryOptions, setFilteredMaxSalaryOptions] = useState([]);

  const isV3User = localStorage.getItem("isV3User");

  useEffect(() => {
    setInitialEducation();
    setInitialOcupation();
  }, [props?.educationinfofreshData]);

  useEffect(() => {
   
  }, [eduactiondata]);

  const setInitialEducation = () => {
    setEducationData([]);
    let tempEduData = props?.educationinfofreshData?.education;
    tempEduData?.map((e, i) => {
      if (e.heading === "Y") {

        let newsection = {
          title: e,
          options: [],
        };

        for (var index = i + 1; index < tempEduData.length; index++) {
          if (tempEduData[index].heading === "N") {
            newsection.options = [
              ...newsection.options,
              tempEduData[index],
              //   {
              //     name: tempEduData[index].name,
              //     id: tempEduData[index].id,
              //     heading: tempEduData[index].heading,
              //     selected: false,
              //   },
            ];
          } else {
            break;
          }
        }

        let selected = e.selected;
        const len = newsection?.options?.filter((element) => element.selected === "Y")?.length ?? 0

        if (len === 0) {
          selected = "N"
        } else if (newsection?.options?.length !== len) {
          selected = "P"
        } else {
          selected = "Y"
        }

        newsection = {
          ...newsection,
          title: { ...newsection.title, selected }
        }

        setEducationData((oldvalue) => [...oldvalue, newsection]);
      }
    });
  }
  
  const setInitialOcupation=()=>{
    setOccupationData([]);
    let tempOccupationData = props?.educationinfofreshData?.occupation;
    tempOccupationData?.map((e, i) => {
      if (e.heading === "Y") {
        let newsection = {
          title: e,
          options: [],
        };
        for (var index = i + 1; index < tempOccupationData.length; index++) {
          if (tempOccupationData[index].heading === "N") {
            newsection.options = [
              ...newsection.options,
              tempOccupationData[index],
              //   {
              //     name: tempEduData[index].name,
              //     id: tempEduData[index].id,
              //     heading: tempEduData[index].heading,
              //     selected: false,
              //   },
            ];
          } else {
            break;
          }
        }

        let selected = e.selected;
        const len = newsection?.options?.filter((element) => element.selected === "Y")?.length ?? 0

        if (len === 0) {
          selected = "N"
        } else if (newsection?.options?.length !== len) {
          selected = "P"
        } else {
          selected = "Y"
        }

        newsection = {
          ...newsection,
          title: { ...newsection.title, selected }
        }

        setOccupationData((oldvalue) => [...oldvalue, newsection]);
      }
    });
  }
  const handleSingleSelect = (value) => {
    let newVal = eduactiondata?.map((ele, i) => {
      return {
        ...ele,
        options: eduactiondata[i].options.map((e, ind) => {
          if (e.id === value) {
            return { ...e, selected: e.selected === "Y" ? "N" : "Y" };
          } else {
            return e;
          }
        }),
      };
    });
    setEducationData(newVal);

    let setAllValue = newVal?.map((ele, i) => {
      let selected = "N";
      const len = newVal[i].options?.filter((element) => element.selected === "Y")?.length ?? 0
      if (len > 0) {
        if (newVal[i]?.options?.length !== len) {
          selected = "P"
        }else{
          selected = "Y"
        }
      } else {
        selected = "N"
      }

      return {
        ...ele,
        title: {
          ...ele.title,
          selected,
        },
      };
    });

    setEducationData(setAllValue);
    setSelectedData(setAllValue)
  };

  const handleSelectAll = (value) => {
    let newVal = eduactiondata?.map((ele, i) => {
      if (ele.title.id === value) {
        return {
          ...ele,
          title: {
            ...ele.title,
            selected: ele.title.selected === "Y" ? "N" : "Y",
          },
          options: eduactiondata[i].options.map((e, ind) => {
            return { ...e, selected: ele.title.selected === "Y" ? "N" : "Y" };
          }),
        };
      } else {
        return ele;
      }
    });

    setEducationData(newVal);
    setSelectedData(newVal)
  };

  const setSelectedData = (value) => {
    let selectedID = []
    value?.map((ele, index) => {

      // if(ele.title.selected === "Y"|| ele.title.selected === "P"){
      //   selectedID.push(ele.title.id)
      // }

      ele?.options.map(
        (e, i) =>
          e.selected === "Y" && selectedID.push(e.id)
      )

    })
    props.data.setFieldValue('education', selectedID)
    //console.log("selectedID", props.data.values.education)
  }

  const handleSingleSelectOccupation = (value) => {
    let newVal = occupationdata?.map((ele, i) => {
      return {
        ...ele,
        options: occupationdata[i].options.map((e, ind) => {
          if (e.id === value) {
            return { ...e, selected: e.selected === "Y" ? "N" : "Y" };
          } else {
            return e;
          }
        }),
      };
    });
    setOccupationData(newVal);

    let setAllValue = newVal?.map((ele, i) => {
      let selected = "N";
      const len = newVal[i].options?.filter((element) => element.selected === "Y")?.length ?? 0
      if (len > 0) {
        //console.log('setEducationData',len,newVal[i]?.options?.length);
        if (newVal[i]?.options?.length !== len) {
          selected = "P"
        }else{
          selected = "Y"
        }
      } else {
        selected = "N"
      }

      return {
        ...ele,
        title: {
          ...ele.title,
          selected,
        },
      };
    });

    setOccupationData(setAllValue);
    setSelectedOccupationData(setAllValue)
  };
  const handleSelectAllOccupation = (value) => {
    let newVal = occupationdata?.map((ele, i) => {
      if (ele.title.id === value) {
        return {
          ...ele,
          title: {
            ...ele.title,
            selected: ele.title.selected === "Y" ? "N" : "Y",
          },
          options: occupationdata[i].options.map((e, ind) => {
            return { ...e, selected: ele.title.selected === "Y" ? "N" : "Y" };
          }),
        };
      } else {
        return ele;
      }
    });

    setOccupationData(newVal);
    setSelectedOccupationData(newVal)
  };

  const setSelectedOccupationData = (value) => {
    let selectedID = []
    value?.map((ele, index) => {
      // if (ele.title.selected === "Y" || ele.title.selected === "P") {
      //   selectedID.push(ele.title.id)
      // }
      ele?.options.map(
        (e, i) =>
          e.selected === "Y" && selectedID.push(e.id)
      )
    })
    props.data.setFieldValue('occupation', selectedID)
    // //console.log("selectedID",props.data.values.education)
  }

  const handleIncomeFromChange = (e) => {
    const selectedIncomeFrom = e.target.value;
    props.data.handleChange(e);
  
    // Filter the max salary options based on selected income_from
    const filteredOptions = props.educationinfofreshData?.maxsalaryId?.filter(
      (option) => parseInt(option.id.replace(/,/g, '')) >= parseInt(selectedIncomeFrom.replace(/,/g, ''))
    ) || [];
  
    setFilteredMaxSalaryOptions(filteredOptions);
  
    // If income_to is less than income_from, remove selected income_to
    if (props.data.values.income_to && parseInt(props.data.values.income_to.replace(/,/g, '')) < parseInt(selectedIncomeFrom.replace(/,/g, ''))) {
      props.data.setFieldValue("income_to", selectedIncomeFrom);
    }
    if (!props.data.values.income_to){
      props.data.setFieldValue("income_to", selectedIncomeFrom);
    }
  
  };
  
  const handleIncomeToChange = (e) => {
    const selectedIncomeTo = e.target.value;
    props.data.handleChange(e);
  
    // If income_from is not selected, set income_to value as income_from
    if (!props.data.values.income_from) {
      props.data.setFieldValue("income_from", selectedIncomeTo);
    }
  
    // If income_from is greater than income_to, remove selected income_from
    if (props.data.values.income_from && parseInt(props.data.values.income_from.replace(/,/g, '')) > parseInt(selectedIncomeTo.replace(/,/g, ''))) {
      props.data.setFieldValue("income_from", "");
    }
  };

  const handleIncomeToFocus = () => {
    const selectedIncomeFrom = props.data.values.income_from || "";

    let filteredOptions;
  
    if (selectedIncomeFrom === "") {
      // If income_from is an empty string, show all available options
      filteredOptions = props.educationinfofreshData?.maxsalaryId || [];
    } else {
      // Otherwise, filter the max salary options based on the selected income_from
      filteredOptions = props.educationinfofreshData?.maxsalaryId?.filter(
        (option) => parseInt(option.id.replace(/,/g, '')) >= parseInt(selectedIncomeFrom.replace(/,/g, ''))
      ) || [];
    }
  
    setFilteredMaxSalaryOptions(filteredOptions);
};


  useEffect(() => {
    // Initialize filteredMaxSalaryOptions with all maxsalaryId options on the first render
    setFilteredMaxSalaryOptions(props.educationinfofreshData?.maxsalaryId || []);
  }, [props.educationinfofreshData]);

  return (
    <div>
      <div className=" md:pl-[1rem] lg:pl-[2rem]">
      <p className="text-black mt-[1rem] font-semibold text-[15px] lg:text-[18px] ">
                Education
              </p>
        <div className="flex items-center mt-[1rem] mr-[1rem]">
          <div className={`border border-[#D9D9D9] w-full rounded-[4px] p-[0.4rem] lg:p-2.5 max-h-[16rem] ${isV3User?"overflow-y-auto lite-scrollbar":"overflow-y-auto"}  space-y-1`}>
            {eduactiondata?.map((e, i) => (
              <EducationCheckView
                key={i}
                title={e.title}
                value={e.options}
                callBackSelectAll={handleSelectAll}
                callBackSingleSelect={handleSingleSelect}
                editfromadmin={props.editfromadmin}
              />
            ))}
          </div>
        </div>
        <div className=" border border-[#D9D9D9] mt-2 mr-[1rem] h-[8rem] rounded-[4px]   overflow-auto">
          <div className="flex flex-wrap m-2">
            {eduactiondata?.map((ele, index) => (
              <>
                {/* {ele.title.selected === "Y" && (
                  <EduactionListTag key={index} text={ele.title.name} />
                )} */}
                {ele?.options.map(
                  (e, i) =>
                    e.selected === "Y" && <EduactionListTag key={i} text={e.name} />
                )}
              </>
            ))}
          </div>
        </div>

        <MultiSelectView
          title={"Employment Type"}
          inputID={"employment"}
          placeholder="Any"
          inputName={"employment"}
          value={props.data.values.employment}
          defalutValue={[props.data.values.employment]}
          onChange={(e) => {
            props.data.setFieldValue("employment", Array.from(e));
            // props.data.handleChange
            if(props.isTrailPack == 'Y'){
              props.setOnchangeData(false)
            }
          }}
          error={props.data.errors.employment}
          options={props?.educationinfofreshData?.employedIn}
          isDisabled={props.editfromadmin == 'Y'}
        />
        <p className="text-black mt-[1rem] font-semibold text-[15px] lg:text-[18px] ">
          Occupation
        </p>
              <div className="flex items-center mt-[1rem] mr-[1rem]">
<div className={`border border-[#D9D9D9] w-full rounded-[4px] p-[0.4rem] lg:p-2.5 max-h-[16rem] ${isV3User?"overflow-y-auto lite-scrollbar":"overflow-y-auto"} space-y-1`}>
            {occupationdata?.map((e, i) => (
              <EducationCheckView
                key={i}
                title={e.title}
                value={e.options}
                callBackSelectAll={handleSelectAllOccupation}
                callBackSingleSelect={handleSingleSelectOccupation}
                editfromadmin={props.editfromadmin}
                isTrailPack ={props.isTrailPack}
                setOnchangeData={props.setOnchangeData}
              />
            ))}
          </div>
          </div>
          <div className=" border border-[#D9D9D9] mt-2 mr-[1rem] h-[8rem] rounded-[4px]   overflow-auto">
          <div className="flex flex-wrap m-2">
            {occupationdata?.map((ele, index) => (
              <>
                {/* {ele.title.selected === "Y" && (
                  <EduactionListTag key={index} text={ele.title.name} />
                )} */}
                {ele?.options.map(
                  (e, i) =>
                    e.selected === "Y" && <EduactionListTag key={i} text={e.name} />
                )}
              </>
            ))}
          </div>
        </div>
        <div className={`md:mt-[2rem] mt-4`}>
          <div className="md:col-span-3 lg:col-span-4 col-span-9">
            <p className={"md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold"}>
              Monthly Income
            </p>
          </div>
          <div className="grid grid-cols-9 gap-4 mt-1.5">
            <div className="col-span-3">
              <p className="md:text-[12px] lg:text-[14px] text-[#7A7A7A] mb-1">From</p>
              <label className="select font-bold text-[#575556]">
                <select
                  id="income_from"
                  name="income_from"
                  value={props.data.values.income_from}
                  // onChange={handleIncomeFromChange}
                  disabled={props.editfromadmin == 'Y'}
                  className={`w-full md:p-[0.4rem] lg:p-2.5 border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                  onChange={(e)=>{
                    handleIncomeFromChange(e);
                    if(props.isTrailPack == 'Y'){
                      props.setOnchangeData(false)
                    }
                  }}
                >
                  <option value="" disabled selected>Any</option>
                  {props?.educationinfofreshData?.minsalaryId.map((e, i) => (
                    <option key={i} value={e.id}>
                      {e.value}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="col-span-3">
              <p className="md:text-[12px] lg:text-[14px] text-[#7A7A7A] mb-1">To</p>
              <label className="select font-bold text-[#575556]">
                <select
                  id="income_to"
                  name="income_to"
                  value={props.data.values.income_to}
                  onChange={(e)=>{
                    handleIncomeToChange(e);
                    if(props.isTrailPack == 'Y'){
                      props.setOnchangeData(false)
                    }
                  }}
                  onFocus={handleIncomeToFocus}
                  disabled={props.editfromadmin == 'Y'}
                  className={`w-full md:p-[0.4rem] lg:p-2.5 border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                >
                  <option value="" disabled selected>Any</option>
                  {filteredMaxSalaryOptions.map((e, i) => (
                    <option key={i} value={e.id}>
                      {e.value}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationPreference;
