import React, { useRef } from "react";
import { Modal } from 'antd'
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

function ImageCropper({ onCropClick, onClose, image,isFrom }) {

    const cropperRef = useRef(null);

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        cropper.getCroppedCanvas()?.toBlob(onCropClick,'image/jpeg')
        onClose(true)
        // //console.log("cropped-image",cropper.getCroppedCanvas().toDataURL());
    };

    return (
        <Modal
            // title="Modal 1000px widh"
            centered
            okButtonProps={{ style: { backgroundColor: "#D23C27" } }}
            okText={isFrom == "signout" ? "Submit": "Save"}
            open={true}
            onOk={()=>onCrop()}
            onCancel={()=>onClose(false)}
            closable={false}
            width={700}
        >
            <Cropper
                src={image}
                style={{ height: 400, width: "100%",backgroundColor:"white" }}
                initialAspectRatio={1}
                viewMode={1}
                background={false}
                aspectRatio={1}
                zoomOnWheel={false}
                zoomOnTouch={false}
                zoomable={false}
                guides={false}
                ref={cropperRef}
            />
        </Modal>
    )
}

export default ImageCropper