import React from "react";
import CloseIcon from "../../Images/Group 761@2x.png"



const ImageHoroscopePopup = (props) => {
  const isV3User = localStorage.getItem("isV3User");

  return (
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt=""/>
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className={isV3User?"flex items-center py-1   mx-4  rounded-t justify-between ":"flex items-center py-1  border-b border-dashed border-[#D10A11] mx-4  rounded-t justify-between "}>
            <h3 className="text-[18px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
            <h3 className="text-[18px] text-[#D10A11] ml-2 font-semibold align-middle cursor-pointer"
             onClick={props?.openHoroMatch}
             >
              {props.isMatch}
            </h3>
          </div>
        
         <div className="relative p-6 flex-auto">{props.children}</div>
         {/* {isV3User?  */}
            {props?.title == "View Horoscope" || props?.page != "edit" ? "" :
            <div className="flex justify-between mb-8 px-8">
              <button
                onClick={() => {
                  props.changeProfilePhoto();
                  props.close();
                }}
                className="flex items-center justify-center  w-full md:w-[10rem] lg:w-[10rem] lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 text-[10px] md:text-[12px] lg:text-[14px] photo-popup"
              >
                  Change Profile Photo
              </button>
              <button
                onClick={() => {
                  props.addNewPhoto();
                  props.close();
                }}
                className=" flex items-center justify-center ml-2 w-full md:w-[10rem] lg:w-[10rem] lg:h-[1.5rem]  text-black bg-white border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 text-[10px] md:text-[12px] lg:text-[14px]"
              >
                Add a New Photo
              </button>
            </div>
         
        //  : props?.isShowEditBtn && <div className="flex justify-center mb-8">
        //     <button
        //       onClick={() => {
        //         props.onButtonCallback();
        //         props.close();
        //       }}
        //       className=" flex items-center justify-center lg:w-[2.5rem] lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
        //     >
        //       Edit
        //     </button>
        //     <button
        //       onClick={() => {
        //         if(props.deleteImage === true){
        //           props.callBackDelete();
        //         }
        //         else{
        //           props.onButtonCallback();
        //         }
        //         props.close();
        //       }}
        //       className=" flex items-center justify-center ml-2 lg:w-[2.5rem] lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
        //     >
        //       Remove
        //     </button>
        //   </div>
          
            }
        </div>
          
       
      </div>
    </div>
  );
};

export default ImageHoroscopePopup;
