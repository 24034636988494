import React, { useEffect, useState } from "react";
import { SettingsRadioButton } from "../Edit Profile/SettingsComponents/SettingsUtills";
import { CommonCheckButton, CommonRadioButton } from "./DashboardUtills";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CommonPopup } from "../CommonPopup";
import Constants from "../../Constants/constants";
import CloseIcon from "../../Images/Group 761@2x.png"
import ProfilelastonlineImage from '../../Images/Group 619.svg'
import { dateFormat } from "../../Utils";

const RequestPhotoHoroTrustPopup = (props) => {
  console.log("profileData", props);
  useEffect(() => {

  }, [props?.profileData]);
  //   useEffect(() => {}, [radioaMessage]);
  const formik = useFormik({
    initialValues: {
      requestParentsNumber: (((props.profileData?.profileparentsnumber == null || props.profileData?.profileparentsnumber == "")
        && props?.profileData?.parentnumberrequestcheck === false) || props.title === "Parents Number Request") ? true : false,
      sendSms: "N",
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      console.log("message", values);
      values = {
        ...values,
        typeList: [
          ...values?.requestParentsNumber ? ["Parents Number"] : [],
          ...props.title === "Parents Number Request"?[]:["Photo"]
        ]
      }
      props.onButtonCallback(props?.profileData?.profileid, values);
      props.close();
    }

  });


  return (
    <div className="flex justify-center bg-opacityblack items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-[40rem] my-6 mx-6">
        <div className="">
          <button
            className=" bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
            onClick={() => {
              props.close();
            }}
          >
            <img className="h-5 w-5 " src={CloseIcon} alt=""/>
          </button>
        </div>
        <div className="border-0 rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-1 justify-center border-b border-dashed border-[#D10A11] mx-2  rounded-t ">
            <h3 className="text-[20px] text-[#D10A11] ml-2 font-semibold align-middle">
              {props.title}
            </h3>
          </div>
          <div className="relative p-6 flex-auto ">
            <div className="flex flex-row justify-start items-center border-b pb-2">
              <img
                src={props?.profileData?.profileurl} //"/Assets/Images/profile1.png"
                alt=""
                className="w-16 h-16 md:w-20 md:h-20 rounded-[50%]"
              />
              <div className="flex flex-col ml-2">
                <div className="flex flex-row  md:hidden">
                  {props?.profileData?.profilelastOnline === "Online Now" ? (
                    <span className="flex ml-1 justify-center items-center absolute lg:static md:static top-[1rem]">
                      <img
                        className=" w-2 h-2 md:w-2 md:h-2 lg:w-3 lg:h-3"
                        src={ProfilelastonlineImage}
                        alt=""
                      />
                      <span className="whitespace-nowrap px-2 md:px-1 lg:px-2 text-[9px] text-center md:text-[8px] lg:text-[10px] text-[#039511]">
                        Online Chat Now
                      </span>
                    </span>
                  ) : (
                    <span className="flex justify-center font-segeo font-normal md:text-[14px] lg:text-[14px] text-[8pt] md:px-1 text-[#039511] pt-0.5 absolute lg:static md:static top-[1rem]">
                      Last Online: {dateFormat(props?.profileData?.profilelastOnline)}
                    </span>
                  )}
                </div>
                <div className="flex flex-row item-center justify-start">
                  <span className="text-[#575556]   md:text-[16px] lg:text-[16px] text-[8pt]  font-bold">
                    {props?.profileData?.profilename} |{" "}
                    <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[8pt] font-normal">
                      {props?.profileData?.smProfileId} |{" "}
                    </span>
                  </span>
                  {props?.profileData?.profilelastOnline === "Online Now" ? (
                    <span className="flex ml-1 justify-center items-center hidden md:flex absolute lg:static md:static top-[1rem]">
                      <img
                        className=" w-2 h-2 md:w-2 md:h-2 lg:w-3 lg:h-3"
                        src={ProfilelastonlineImage}
                        alt=""
                      />
                      <span className="whitespace-nowrap px-2 md:px-1 lg:px-2 text-[9px] text-center md:text-[8px] lg:text-[10px] text-[#039511]">
                        Online Chat Now
                      </span>
                    </span>
                  ) : (
                    <span className="flex justify-center font-segeo font-normal md:text-[14px] lg:text-[14px] text-[8pt] px-1 text-[#039511] pt-0.5 absolute lg:static md:static top-[1rem] hidden md:flex">
                      Last Online: {dateFormat(props?.profileData?.profilelastOnline)}
                    </span>
                  )}
                </div>
                <span className="text-[#575556]  md:text-[16px] lg:text-[16px] text-[8pt] font-semibold">
                  {props?.profileData?.profileage} |{" "}
                  {props?.profileData?.profileheight} |{" "}
                  {props?.profileData?.profilequalification} |{" "}
                  {props?.profileData?.profilereligion} |{" "}
                  {props?.profileData?.profilecountry}
                </span>
              </div>
            </div>
            {props?.data?.status == "pending" ?
            props?.title == "Send Request"?
            "You can send request only after your profile is validated"
            :
              props?.title == "Request Photo" ?
                "You can request photo only after your profile is validated"
                : props?.title == "Parents Number Request" ?
                  "You can request parents number only after your profile is validated"
                  :
                  props?.title == "Request Trust Document" ?
                    props?.data?.apipendingtrustdoc
                    :
                    props?.data?.apipendinghoroscope :
              // props?.profileData?.profileblockedByMe === "Y"?
              // <CommonPopup title="Profile Status" close={props?.close} menu="profileStatus">
              //   <p>{props?.profileData?.profilename + " Profile Blocked by you. Do you want to unblock now?"}</p>
              //   <div className='flex justify-between'>
              //   <button className='text-[#D10A11] ml-[10rem]' onClick={props?.ok}>Yes</button>
              //   <button className='text-[#D10A11] mr-[1rem]' onClick={props?.close}>No</button>
              //   </div>
              // </CommonPopup>
              // :
              <div>
                {
                  (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden" ?
                    "Your profile is hidden"
                    :
                    (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "pending" ?
                      props?.title == "Request Photo" ?
                        "You can request photo only after your profile is validated"
                        : props?.title == "Parents Number Request" ?
                          "You can request parents number only after your profile is validated"
                          :
                          "You can request photo only after your profile is validated"
                      :
                      props?.profileData?.profileStatus == "open" || props?.profileData?.profileStatus == "pending" ?
                        <form onSubmit={formik.handleSubmit}>
                          <div className="flex justify-center">
                            <p className="text-[14px] my-2 font-normals">
                              {props.title}
                            </p>
                          </div>
                          <div className="flex flex-col justify-center  p-10 border rounded-[5px]">
                            <div className="flex flex-col md:flex-row justify-between">
                              <div>
                                <CommonCheckButton
                                  id={"email"}
                                  name={"email"}
                                  selected={true}
                                  checktext={"Send Email"}
                                  onChange={(item) => {
                                    //console.log("check", item.target.value);
                                  }}
                                />
                                <CommonCheckButton
                                  id={"sendSms"}
                                  name={"sendSms"}
                                  selected={formik.values.sendSms === "Y" ? true : false}
                                  checktext={"Send SMS"}
                                  value={formik.values.sendSms}
                                  onChange={(item) => {
                                    //console.log("check", item.target.value);
                                    formik.setFieldValue(
                                      "sendSms",
                                      `${formik.values.sendSms === "Y" ? "N" : "Y"}`
                                    );
                                  }}
                                />
                              </div>
                              {props.title === "Parents Number Request"
                              ? null
                              : <div>
                                {((props.profileData?.profileparentsnumber == null || props.profileData?.profileparentsnumber == "") && props?.profileData?.parentnumberrequestcheck === false)
                                  ? <CommonCheckButton
                                    id={"requestParentsNumber"}
                                    name={"requestParentsNumber"}
                                    selected={formik.values.requestParentsNumber}
                                    checktext={"Request Parent's number"}
                                    value={formik.values.requestParentsNumber}
                                    onChange={(item) => {
                                      //console.log("check", item.target.value);
                                      formik.setFieldValue(
                                        "requestParentsNumber",
                                        !formik.values.requestParentsNumber
                                      );
                                    }}
                                  />
                                  // : props?.profileData?.parentnumberrequestcheck === true
                                  //   ? <p className="mt-2 font-semibold text-[11px] md:text-[10px] lg:text-[13px] text-[#575556]">Parent's Number Requested</p>
                                    : null
                                }
                              </div>}
                            </div>
                          </div>
                          <div className="flex justify-center mt-2">
                            <button
                              // onClick={() => {
                              //   //   props.onButtonCallback();
                              //   props.close();
                              // }}
                              type="submit"
                              className=" flex items-center justify-center lg:w-[2.5rem] lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                            >
                              Send
                            </button>
                          </div>
                        </form>
                        :
                        <div className="border-0 rounded-lg text-center relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
                          {props?.profileData?.profileStatus == "hidden" ?
                            "profile has been hidden"
                            : props?.profileData?.profileStatus == "inactive" ?
                              "profile has been inactive"
                              : props?.profileData?.profileStatus == "delete" ?
                                "profile has been deleted"
                                : props?.profileData?.profileStatus == "suspension" ?
                                  "profile has been suspended"
                                  : ""}
                        </div>
                }
              </div>
            }
          </div>
        </div>

      </div>
    </div>
  );
};

export default RequestPhotoHoroTrustPopup;
