import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import { LoginContext } from '../Contexts/LoginContext'


function PrivateRoute({ children }) {
  const { isLoggedIn } = useContext(LoginContext);
  const location = useLocation();
  if (!isLoggedIn()) {
    return <Navigate to="/" state={{ from: location.pathname }} />
  }
  return children;
}
export default PrivateRoute;