
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import $ from 'jquery'

//constant
import { registerUser } from "../../Store/Register/Action";
import { registerDetail, registerCasteList ,kjbannerPopupDetail} from "../../Store/Home/Action";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "../withRouter";
import RouteSegments from "../../Routes/RouteSegment";
import Constants, { EventKeys, EventName, blockInvalidCharForNumber, blockInvalidCharForText } from "../../Constants/constants";
import removeicon from '../../Images/Group 761.svg'
import { getStateList, getStoreList, submitBookAppointment } from "../../Store/BookAppointment/Action";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CommonPopup } from "../CommonPopup";
import Loader from "../Loader";
import LoaderGif from "../../Gif/loader.gif"
import { useAnalytics } from "../../Hooks/usePageChange";


const KJbannerPopup = ({closeModel,setShowKjBannerPopup,setShowKjThankPopup}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [skipButtonClicked, setSkipButtonClicked] = useState(false)
  const [showThankModal, setShowThankModal] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [stateListMB, setStateListMB] = useState()
  const { onClickTrack } = useAnalytics()


  useEffect(() => {
    dispatch(getStateList(localStorage.getItem(Constants.loginLocalStorageKeys.loginId),localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null : localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail)))
  },[])

  let {stateList} = useSelector(state => ({
    stateList: state.BookAppointment?.stateListData?.data?.muhuratstate
  }));


  useEffect(() => {
    setStateListMB(stateList)
  },[stateList])

  console.log(stateList,"stateList");
  console.log(stateListMB,"stateList");



  const formik = useFormik({
    initialValues: {
      name: localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName)?localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName):"",
      phone: localStorage.getItem(Constants.loginLocalStorageKeys.loginPhone)?localStorage.getItem(Constants.loginLocalStorageKeys.loginPhone):"",
      state:stateList? stateList
            .filter((e) => e.selected === "Y")
            .map((e) => e.id)[0]
        : "",
      store: "",
      appointmentDate: moment(new Date()).format("YYYY-MM-DD"),
    },
    enableReinitialize:true,
    validationSchema: Yup.object().shape({
      name: skipButtonClicked?Yup.string():Yup.string().required("Please Enter Your Name"),
      state: skipButtonClicked?Yup.string():Yup.string().required("Please Select State"),
      store: skipButtonClicked?Yup.string():Yup.string().required("Please Select Store"),
      appointmentDate: Yup.string().required("Please Select"),
      phone: skipButtonClicked?Yup.string():Yup.string()
      .min(7, "Please enter a valid Mobile Number")
      .max(15, "Please enter a valid Mobile Number")
        .required("Please Enter Mobile Number"),
    }),
    onSubmit: (values) => {
      AppointmentFormSubmission(values);
      onClickTrack(EventName.View_Profile_Banner_Banner_Button_Sumbit,{[EventKeys.Appointment_Popup_Sumbit_Request]:values})
    },
  });



  useEffect(() => {
    formik.values.state && 
    dispatch(getStoreList(formik.values.state, localStorage.getItem(Constants.loginLocalStorageKeys.loginId),localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail)))
  }, [formik.values.state]);

  let {storeList} = useSelector(state => ({
    storeList: state.BookAppointment?.storeListData?.data?.muhuratstore
  }));

  console.log(storeList,"storeList");

  let result = '';

  function tranformDate(strDate) {

    if (strDate) {
      let parts = strDate.split('-');
      result = `${parts[0]}/${parts[1]}/${parts[2]}`;
    }
    return result;
}

  const AppointmentFormSubmission = async (values) => {

    tranformDate(values.appointmentDate)    
    //console.log(values, "12345");
    setShowLoader(true)
    skipButtonClicked?
    dispatch(submitBookAppointment(result,"muhuratskip", values,localStorage.getItem(Constants.loginLocalStorageKeys.loginId),localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail)))
    :
    dispatch(submitBookAppointment(result,"muhuratsubmit", values,localStorage.getItem(Constants.loginLocalStorageKeys.loginId),localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail)))
  };

  let {submitResponse} = useSelector(state => ({
    submitResponse: state.BookAppointment?.BAResponseData?.data?.status
  }));

  //console.log(submitResponse,"submitResponse");

  useEffect(() => {
    submitResponse=""
  },[])

  useEffect(() => {
    if(submitResponse == "SUCCESS"){
      setShowLoader(false)
      setShowKjBannerPopup(false)
      if(!skipButtonClicked){
        setShowThankModal(true)
      }
      window.open(
        RouteSegments.staticLinkForBookAppointment,
        '_blank' // <- This is what makes it open in a new window.
      );    
    }
  },[submitResponse])

  const closePopup = () => {
    setShowThankModal(false)
    setShowKjBannerPopup(false)
  }

  const skipClicked = () => {
    setSkipButtonClicked(true);
    window.open(
      RouteSegments.staticLinkForBookAppointment,
      '_blank' // <- This is what makes it open in a new window.
    );  
  }


  return (

    <div>
      
      {showThankModal ?
        <CommonPopup title="Kalyan Matrimony Service" close={closePopup}>
        <p>Thank you ! Our executive will get in touch with you shortly.</p>
      </CommonPopup>
      :
      <div className=" flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        {showLoader && <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin"/>}
        <div className="mt-[10rem]  relative w-auto my-6 mx-auto Noscroll">

          <div className="bg-white rounded-[1rem] ">
            <button
              onClick={closeModel}

              className=" absolute top-[0.5rem] left-[-2rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2"
            >
              <img

                className="w-[1.8rem] md:w-[2rem] lg:w-[2rem]"
                src={removeicon}
                alt=""
              />
            </button>
            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-[23rem] md:w-[28rem] rounded-2xl">
              <div className="  items-start ">
                <p className="text-[#D10A11] flex  font-bold text-[21px] md:text-[24px] lg:text-[26px] ">
                  Book Appointment
                </p>

                <p className="text-[#575556] text-[14px] font-semibold py-3">
                Kalyan Jewellers is India's no.1 Retailer over 100 stores across India and is known for its famous Muhurat Wedding Collections of highest quality and variety.
                Book an appointment for exciting offers specially for KalyanMatrimony customers.
                </p>
                <div

                  className="    md:block md:col-span-5 lg:col-span-5 mx-[0.2rem] md:mx-0  "
                >
                  <div>
                    {stateListMB?.length>0?
                    <div className="">
                      <p className="text-[#7A7A7A]  text-[12px] lg:text-[14px] ">
                        <div className=" pr-[1rem] ">
                          <div className="rounded-t-[10px] h-auto rounded-b-[10px] w-[100%] ">

                            <div className="App px-[12px] h-[21.5rem] md:h-[27.5rem] overflow-auto">
                              {/* {stateList?.length>0 ? */}
                                <form onSubmit={formik.handleSubmit}>
                                  <div className="space-y-2 mt-[1rem]">
                                    <div>
                                      <label className="relative block">
                                        <input
                                          type="text"
                                          onKeyDown={blockInvalidCharForText}
                                          name="name"
                                          id="name"
                                          autoComplete="off"
                                          placeholder="Enter Your Name"
                                          className="form-control  shadow-sm  bg-[#FFFFFF] text-[#000000]  rounded-[6px] text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
                                          value={formik.values.name}
                                          onChange={formik.handleChange}
                                        />
                                      </label>
                                    </div>

                                    {formik.errors.name && formik.touched.name && (
                                      <p className="text-[red] text-[12px]">{formik.errors.name}</p>
                                    )}
                                  </div>                                 
                                   
                                    <div className="space-y-2 mt-[1rem] col-span-4">
                                      <div>
                                        <label className="relative block RegPhone">
                                          <input
                                            id="phone"
                                            name="phone"
                                            type="number"
                                            onKeyDown={blockInvalidCharForNumber}
                                            autoComplete="off"
                                            placeholder="Enter Your Phone Number"
                                            value={formik.values.phone}
                                            onChange={formik.handleChange}
                                            className="RegPhone form-input shadow-sm  bg-[#FFFFFF] text-[#000000]  rounded-[6px] text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
                                          />
                                        </label>
                                        {formik.errors.phone && formik.touched.phone && (
                                          <p className="text-[red] text-[12px]">{formik.errors.phone}</p>
                                        )}{" "}
                                      </div>
                                    </div>
                                
                                  <div className="space-y-2 mt-[1rem]">
                                    <div>
                                      <label className="relative block">
                                        <select
                                          id="state"
                                          name="state"
                                          value={formik.values.state}
                                          defalutValue={formik.values.state}
                                          onChange={formik.handleChange}
                                          className="one form-input shadow-sm  bg-[#FFFFFF] text-[#000000]  rounded-[6px] text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
                                        >
                                          <option className="p-2" value="" disabled selected hidden>
                                            Select Any State
                                          </option>
                                          {stateList?.map((state, i) => (
                                            <option className="p-2" value={state.id}>
                                              {state.name}
                                            </option>
                                          ))}
                                        </select>
                                      </label>
                                      {formik.errors.state && formik.touched.state && (
                                        <p className="text-[red] text-[12px]">
                                          {formik.errors.state}
                                        </p>
                                      )}{" "}
                                    </div>
                                  </div>                                  

                                  <div className="space-y-2 mt-[1rem]">
                                    <div>
                                      <label className="relative block">
                                        <select
                                          id="store"
                                          name="store"
                                          value={formik.values.store}
                                          onChange={formik.handleChange}
                                          className="one form-input shadow-sm  bg-[#FFFFFF] text-[#000000]  rounded-[6px] text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
                                        >
                                          <option value="" disabled selected hidden>
                                            Select Any store
                                          </option>
                                          {storeList?.map((store, i) => (
                                            <option className="p-2" value={store.id}>
                                              {store.name}
                                            </option>
                                          ))}
                                        </select>
                                      </label>
                                      {formik.errors.store && formik.touched.store && (
                                        <p className="text-[red] text-[12px]">
                                          {formik.errors.store}
                                        </p>
                                      )}{" "}
                                    </div>
                                  </div>
                                  <div className="space-y-2 mt-[1rem]">
                                    <div>
                                      <label className="relative block">
                                        <input
                                          id="appointmentDate"
                                          name="appointmentDate"
                                          type="date"
                                          autoComplete="off"
                                          value={formik.values.appointmentDate}
                                          onChange={formik.handleChange}
                                          placeholder="Enter Your Email"
                                          className="form-input shadow-sm  bg-[#FFFFFF] text-[#000000]  rounded-[6px] text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#8F8F8F] py-1 px-1 md:py-2 md:px-2  w-full block "
                                        />
                                      </label>
                                      {formik.errors.appointmentDate && formik.touched.appointmentDate && (
                                        <p className="text-[red] text-[12px]">{formik.errors.appointmentDate}</p>
                                      )}{" "}
                                    </div>
                                  </div>

                                  <div className="space-y-2 mt-[1rem]">
                                    <div className="flex justify-between mt-5">
                                      <label className="relative top-[6px] ">

                                        <button
                                        type="button"
                                          onClick={()=>{onClickTrack(EventName.View_Profile_Banner_Banner_Button_Skip);skipClicked()}}
                                          className="w-[4rem] md:w-full lg:w-full flex px-[4rem] text-center justify-center py-2 border border-[#707070] rounded-[5px] shadow-sm text-[14px] text-[#575556] font-semibold  hover:bg-[#D10A11] hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                        >
                                          Skip
                                        </button>
                                      </label>
                                      <label className="relative top-[6px] ">

                                        <button
                                          type="submit"
                                          onClick={()=>setSkipButtonClicked(false)}
                                          className="w-[4rem] md:w-full lg:w-full flex justify-center px-[4rem] text-left py-2 border border-transparent rounded-[5px] shadow-sm text-[14px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                        >
                                          Submit
                                        </button>
                                      </label>
                                    </div>
                                  </div>
                                </form>
                                {/* : <img src={LoaderGif} className="mr-[14rem] ml-[4rem]" /> */}
                              {/* } */}
                            </div>
                          </div>
                        </div>
                      </p>

                    </div>
                    :
                    <center><img src={LoaderGif} className='pt-[9rem] pb-[2rem]' alt=""/></center>

                                      }
                  </div>
                </div>

              </div>

            </div>

          </div>


        </div>
      </div>
    }


    </div>
  )
}
export default withRouter(KJbannerPopup);

