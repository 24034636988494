import React, {useState, useEffect} from 'react'

//validation
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";

//components
import {mobileVerification, requestAgain} from "../../Store/Login/Action";

import withRouter from '../withRouter';
import { validateNumber } from '../../Utils';

import { useAnalytics } from "../../Hooks/usePageChange";
import { EventName ,EventKeys} from "../../Constants/constants";
import Loader from '../Loader';
//Error msg for Login form
const DisplayingErrorMessagesSchema = Yup.object().shape({
    PINNumber: Yup.string()
      .required("PIN Number is a required field")
  });

 const OTPForm = (props) => {
  const [statusMessage, setStatusMessage] = useState("")
  const [statusMessageError, setStatusMessageError] = useState("")
  const [statusMessageRequestData, setStatusMessageRequestAgain] = useState("")
  const { requestAgainData } = useSelector(state => ({
    requestAgainData: state.Login?.RequestAgaindata?.data
  }));
  const [counter, setCounter] = useState(30);
  const { onClickTrack } = useAnalytics()
   const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  //console.log(requestAgainData,"requestAgainData");

  const { sendOTPInputData } = useSelector(state => ({
    sendOTPInputData: state.Login?.mvdata?.data

    
  }));

  console.log(sendOTPInputData,"sendOTPInputData");

  useEffect(()=>{
    if(sendOTPInputData || requestAgainData){
      setStatusMessage(sendOTPInputData?.status)
      setStatusMessageError(sendOTPInputData?.statusMessage)
      setStatusMessageRequestAgain(requestAgainData?.statusMessage)
    }
  },[sendOTPInputData,requestAgainData,statusMessage, statusMessageRequestData])

    const dispatch = useDispatch();

    const OTPFormSubmission = (values) => {
      //console.log(values ,"lll");
      setIsSubmitting(true);
      dispatch(mobileVerification(values, props?.requestData, props.router.navigate, setIsSubmitting))
      //console.log(values,props.router.navigate,"values,props?");
    }
    const clickRequestAgain = () => {
      setStatusMessage("")
      dispatch(requestAgain("data"))
    }

useEffect(()=>{
  console.log(statusMessage,"statusMessagestatusMessage");

},[statusMessage])

const requestAgainCb = () => {
  if(counter > 0){

  }else{
    setCounter(30)
    clickRequestAgain()
  }
}


  return (
    <div>
         <Formik
            initialValues={{
              PINNumber: ""
            }}
            validateOnBlur={false}
            validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={(values, formikHelpers) => {
          if (isSubmitting) return;
          console.log({ formikHelpers });
              OTPFormSubmission(values)
              onClickTrack(EventName.MobileVerify_VerifyButtonClick)
            }}
            >
            {({ errors }) => (
              <Form className=''>
                <div className=" flex justify-center items-start md:pl-[3rem] lg:pl-[5rem] mt-5 md:mt-0">

                    <Field
                      name="PINNumber">
                      {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }) => (
                            <input 
                            id="PINNumber"
                            type="text"
                            autoComplete="off"
                            placeholder='Enter PIN'
                            className=" flex items-center justify-center font-normal h-[1.5rem]  text-[#8F8F8F] bg-white border border-[#8F8F8F] py-[1.2rem] w-[8rem] text-[12px] pl-[2rem] md:pl-[3rem] md:w-[10rem] lg:w-[12rem] whitespace-nowrap rounded-[5px] md:py-6  lg:py-8 md:text-[12px] lg:text-[19px]  "
                            {...field} 
                            onChange={(e)=>{
                              if(validateNumber(e.target.value) && (e.target.value.length <=6)) {
                              field.onChange(e)
                              }
                            }}/>
                        )}
                    </Field>

                </div>
                <center>
                <div className="md:pl-[3rem] lg:pl-[5rem]  text-[red] text-[12px] h-[1rem] items-center justify-center">{errors.PINNumber ?
                errors.PINNumber:""}</div   >

                <center>
                    <div className="md:pl-[3rem] lg:pl-[5rem]  text-[red]  md:text-[12px] text-[10px] lg:text-[15px] items-center justify-center">
                      {statusMessage == "FAILURE"?statusMessageError?statusMessageError:"OTP Mismatch":""}
                      </div   >
                  </center>  
                  </center>
                <div className=" flex justify-center items-start md:pl-[3rem] mt-4 lg:pl-[5rem] md:mt-[1rem] lg:mt-[1rem] ">
                    <button type='submit' className="text-center flex items-center justify-center h-[2.5rem] md:h-[1.5rem] text-[15px] text-white bg-[#D10A11] border border-[#D10A11] px-7 md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]  ">
                        Verify Mobile
                     </button>
                  </div>
                </Form>
        )}
        </Formik>
        <div className=" md:pl-[3rem] lg:pl-[5rem] ">
          <p className="text-[#575556] md:text-[12px] text-[10px] lg:text-[19px] font-normal flex justify-center mt-[1.6rem]">
            SMS will reach you shortly.
          </p>
          <p className=" text-[#575556] md:text-[12px] text-[10px] lg:text-[19px] font-normal flex justify-center">
            If you have not received it yet please 
            <button className=" pl-3 underline text-[#62ABFE] text-[10px]  md:text-[12px] lg:text-[19px] font-normal " onClick={requestAgainCb}> {counter > 0 ? `Wait for (${counter}s)` :"Request Again"} </button>
          </p>
        
        </div>
        <center>
          <div className="md:pl-[3rem] lg:pl-[5rem]  text-[red] text-[12px] h-[1rem] items-center justify-center">{statusMessageRequestData}</div   >
        </center>  
        {isSubmitting &&  <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />}
    </div>
  )
}
export default withRouter(OTPForm)
