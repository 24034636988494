import React, { useState, useContext, useEffect } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasicTextViewWithImg,
  BasiSelectView,
  EditButton,
  SaveCancelButton,
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST } from "../../../Services/api_services";
import { select } from "redux-saga/effects";
import { SettingsRadioButton } from "../SettingsComponents/SettingsUtills";
import config from "../../../config";
import LoaderGif from "../../../Gif/loader.gif"
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import FoodHabitImage from '../../../Images/Group 509.svg'
import SmookingImage from '../../../Images/Group 508.svg'
import DrinkingImage from '../../../Images/Group 507.svg'
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { getGroupAuthId } from "../../Kalyanlite/utils";

const HabitsComponents = (props) => {
  const [isShowEdit, setEditView] = useState(true);
  const { loginDetail, logout } = useContext(LoginContext);
  const [submitStatus, setSubmitStatus] = useState(false)

  let foodHabits = [
    {
      foodHabits: "Vegetarian",
      selected: "Y",
      id: "1",
    },
    {
      foodHabits: "Non Vegetarian",
      selected: "N",
      id: "2",
    },
    {
      foodHabits: "Eggetarian",
      selected: "N",
      id: "3",
    },
  ];
  let smokingDrinkingHabits = [
    {
      name: "No",
      selected: "Y",
      id: "1",
    },
    {
      name: "Occasionally",
      selected: "N",
      id: "2",
    },
    {
      name: "Yes",
      selected: "N",
      id: "3",
    },
  ];
  const [foodHabitsData, setFoodHabits] = useState(foodHabits);
  const [smokingHabits, setSmokingHabits] = useState(smokingDrinkingHabits);
  const [drinkingHabits, setDrinkingHabits] = useState(smokingDrinkingHabits);
  // const formik = useFormik({
  //   initialValues: {
  //     foodHabits: props?.data?.name ? props?.data?.name : "",
  //     smoking: props?.data?.dob ? props?.data?.dob : "",
  //     drinking: props?.data?.height ? props?.data?.height[0].name : "",
  //   },
  //   enableReinitialize: true,
  //   validationSchema: Yup.object().shape({
  //     foodHabits: Yup.string().required("Please Enter Name"),
  //     smoking: Yup.string().required("Please Enter Weight"),
  //     drinking: Yup.string().required("Please Select height"),
  //   }),

  //   onSubmit: (values) => {
  //     SubmitCall(values);
  //   },
  // });
  const SubmitCall = (value) => {
    //console.log(value);
  };
  useEffect(() => {}, [foodHabitsData, smokingHabits, drinkingHabits]);

  const initialSet = ()=>{
    setFoodValue(props?.data?.food ? props?.data?.food[0].name : "");
    setSmokeValue(props?.data?.smoking ? props?.data?.smoking[0].id : "");
    setDrinkingValue(
      props?.data?.drinking ? props?.data?.drinking[0].name : ""
    );
  }

  useEffect(initialSet, [props?.data]);
  const setFoodValue = (item) => {
    var newVal = foodHabitsData.map((ele, i) => {
      if (item === ele.foodHabits) {
        return { ...ele, selected: "Y" };
      } else {
        return { ...ele, selected: "N" };
      }
    });
    setFoodHabits(newVal);
  };
  const setSmokeValue = (item) => {
    var newVal = smokingHabits.map((ele, i) => {
      if (item == ele.id) {
        return { ...ele, selected: "Y" };
      } else {
        return { ...ele, selected: "N" };
      }
    });
    setSmokingHabits(newVal);
  };
  const setDrinkingValue = (item) => {
    var newVal = drinkingHabits.map((ele, i) => {
      if (item === ele.name) {
        return { ...ele, selected: "Y" };
      } else {
        return { ...ele, selected: "N" };
      }
    });
    setDrinkingHabits(newVal);
  };
  const apicallHabits = async () => {

    // let request = {
    //   email: loginDetail()[0],
    //   userId: loginDetail()[1],
    //   foodId: foodHabitsData.filter((e) => e.selected === "Y")[0].id,
    //   smokingId: smokingHabits.filter((e) => e.selected === "Y")[0].id,
    //   drinkingId: drinkingHabits.filter((e) => e.selected === "Y")[0].id,
    // };
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      foodId: foodHabitsData.filter((e) => e.selected === "Y").length > 0 ? foodHabitsData.filter((e) => e.selected === "Y")[0].id : "",
      smokingId: smokingHabits.filter((e) => e.selected === "Y").length > 0 ? smokingHabits.filter((e) => e.selected === "Y")[0].id : "",
      drinkingId: drinkingHabits.filter((e) => e.selected === "Y").length > 0 ? drinkingHabits.filter((e) => e.selected === "Y")[0].id : "",
      ...(foodHabitsData.filter((e) => e.selected === "Y").length > 0 && {  foodhabitrequesttypeid:24 }),
      ...(smokingHabits.filter((e) => e.selected === "Y").length > 0 && {  smokingrequesttypeid:25 }),
      ...(drinkingHabits.filter((e) => e.selected === "Y").length > 0 && {  drinkingrequesttypeid:26 }),
      requestfulfilledfrom:"Desktop" ,
      requestfulfilledvia:"viewprofile" ,
      recieverprofileid:getGroupAuthId() ,
    };
    console.log(request,'jsfkjkdfdkhdg');

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.edithabitsinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        setSubmitStatus(false)
        props.callBackReload();
        // setEditView(false);
        onClickTrack(EventName.profileHabitsSaveButton)
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const { onClickTrack } = useAnalytics()

  return (
    <div>
      {isShowEdit ? (
        <></>
      ) : (
        <div className="">
          <ActionRestrict>
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />
          </ActionRestrict>
          <div className="md:mb-8 p-5 space-y-3 md:space-x-0">
            <BasicTextViewWithImg
              image={FoodHabitImage}
              title={"Food Habits"}
              value={
                props?.data?.food ? props?.data?.food[0].name : "Not Specified"
              }
            />
            <BasicTextViewWithImg
              image={SmookingImage}
              title={"Smoking"}
              value={props?.data?.smoking ? props?.data?.smoking[0].name : "Not Specified"}
            />
            <BasicTextViewWithImg
              image={DrinkingImage}
              title={"Drinking"}
              value={
                props?.data?.drinking ? props?.data?.drinking[0].name : "Not Specified"
              }
            />
          </div>
        </div>
      )}

      {/* <!-- edit part  --> */}
      {isShowEdit ? (
        <div className="p-5 md:p-0">
          <div className="space-y-3">
          <div className="md:pl-[1rem] mt-[1rem]">
            <div className="text-black font-semibold text-[9px] md:text-[10px] lg:text-[13px]   ">
              Food Habits
            </div>

            <div className="mt-[0.5rem] sm:w-full lg:w-[70%]">
              <div className="grid grid-cols-3">
                {foodHabitsData.map((e, i) => (
                  <div className="col-span-1">
                  <SettingsRadioButton
                    id={e.id}
                    key={i}
                    name={e.foodHabits}
                    selected={e.selected}
                    radiotext={e.foodHabits}
                    onChange={(item) => {
                      //console.log("radio", item.target.value);
                      setFoodValue(item.target.value);
                      // var newVal = foodHabitsData.map((ele, i) => {
                      //   if (item.target.value === ele.foodHabits) {
                      //     return { ...ele, selected: "Y" };
                      //   } else {
                      //     return { ...ele, selected: "N" };
                      //   }
                      // });
                      // setFoodHabits(newVal);
                    }}
                    isFrom={"food"}
                    disabled={props.editfromadmin == 'Y'}
                  />
                  </div>
                ))}
                </div>
            </div>
          </div>
          <div className="md:pl-[1rem] mt-[1rem]">
            <div className="text-black font-semibold text-[9px] md:text-[10px] lg:text-[13px]   ">
              Smoking
            </div>

            <div className="mt-[0.5rem] sm:w-full lg:w-[70%]">
              <div className="grid grid-cols-3">
                {smokingHabits.map((e, i) => (
                 <div className="col-span-1">
                  <SettingsRadioButton
                    id={e.id}
                    key={i}
                    name={e.id}
                    selected={e.selected}
                    radiotext={e.name}
                    onChange={(item) => {
                      //console.log("radio", item.target.value);
                      setSmokeValue(item.target.value);
                      // var newVal = smokingHabits.map((ele, i) => {
                      //   if (item.target.value == ele.id) {
                      //     return { ...ele, selected: "Y" };
                      //   } else {
                      //     return { ...ele, selected: "N" };
                      //   }
                      // });
                      // setSmokingHabits(newVal);
                    }}
                    isFrom={"smoke"}
                    disabled={props.editfromadmin == 'Y'}
                  />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="md:pl-[1rem] mt-[1rem]">
            <div className="text-black font-semibold text-[9px] md:text-[10px] lg:text-[13px]   ">
              Drinking
            </div>

            <div className="mt-[0.5rem] sm:w-full lg:w-[70%]">
            <div className="grid grid-cols-3">
              {drinkingHabits.map((e, i) => (
                <div className="col-span-1">
                <SettingsRadioButton
                  id={e.id}
                  key={i}
                  name={e.name}
                  selected={e.selected}
                  radiotext={e.name}
                  onChange={(item) => {
                    //console.log("radio", item.target.value);
                    setDrinkingValue(item.target.value);
                    // var newVal = drinkingHabits.map((ele, i) => {
                    //   if (item.target.value === ele.name) {
                    //     return { ...ele, selected: "Y" };
                    //   } else {
                    //     return { ...ele, selected: "N" };
                    //   }
                    // });
                    // setDrinkingHabits(newVal);
                  }}
                  isFrom={"drink"}
                  disabled={props.editfromadmin == 'Y'}
                />
                </div>
              ))}
            </div>
            </div>
          </div>
          </div>
          <div className=" flex justify-center space-x-3  items-center mt-[2rem] mb-[2rem] ">
            <button
              onClick={() => {
                setSubmitStatus(true)
                apicallHabits();
              }} disabled={props.editfromadmin == 'Y'}
              className={` flex items-center justify-center text-white bg-[#D10A11] border border-[#D10A11] whitespace-nowrap rounded-[5px] font-bold lg:w-[8rem] w-[9rem] py-1 md:text-[12px] lg:text-[14px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''} `}
            >
            {submitStatus ? <><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>Saving...</span></> : <span>Save</span>}
            </button>
            <button
              onClick={() => {
                // setEditView(false);
                initialSet();
                onClickTrack(EventName.profileHabitsCancelButton)
              }} disabled={props.editfromadmin == 'Y'}
              className={` flex items-center justify-center text-[#575556] bg-white border border-[#707070] whitespace-nowrap rounded-[5px] font-bold lg:w-[8rem] w-[9rem] py-1 md:text-[12px] lg:text-[14px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}  `}
            >
              Discard Changes
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HabitsComponents;
