import React from "react";
import { getFormattedDateTimeWithPeriod } from "../../Utils";
import LoaderGif from "../../Gif/loader.gif"
import { Link } from 'react-router-dom';
import { useAnalytics } from "../../Hooks/usePageChange";
import { EventName } from "../../Constants/constants"; 
const isV3User = localStorage.getItem("isV3User")


const ViewedPhoneProfileTable = (props) => {
  const { onClickTrack } = useAnalytics()
  return (
    <>
    {isV3User ?
      <div>
      <div className="hidden md:block">
      <div className="flex justify-between mt-[1rem] md:mt-0 pl-[1rem] md:pl-0 3">
        <p className=" text-[#000000] text-[16px] font-semibold ">{props.title}</p>
      </div>
      <div className="mt-[1rem] mb-[8rem] md:mb-0 px-[1rem] md:px-0 md:col-span-6 bg-white">
        <div className=" grid grid-cols-9 space-x-1 mt-[0.1rem] text-[12px] md:text-[12px] lg:text-[15px] ">

          <p className=" col-span-4 text-[#FFFFFF]  bg-[#D10A11] font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
            {props.tableHeader[0]}
          </p>
          <p className=" col-span-2 text-[#FFFFFF]   bg-[#D10A11] font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
            {props.tableHeader[1]}
          </p>
          <p className=" col-span-3 text-[#FFFFFF]   bg-[#D10A11] font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
            {props.tableHeader[2]}
          </p>
        </div> 
        
        {props.isLoading
          ? <div className="flex h-52 items-center justify-center">
            <img alt="loader" src={LoaderGif} />
          </div>
          : props?.tableBody?.length <= 0
            ? <div className="flex h-36 items-center justify-center bg-white">
              <p className="text-[#D10A11] text-[16px] font-semibold">No data found</p>
            </div>
            : 
            <div className="bgColor">
            {props?.tableBody?.map((e, i) => (
              <div className=" grid grid-cols-9 space-x-1 mt-[0.1rem] text-[12px] md:text-[12px] lg:text-[14px] ">
                <p className=" col-span-4 text-[#7A7A7A]   font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                  {e.groomName}
                </p>
                <p onClick={() => {
                    if(props.title == "Who Viewed My Profile" || props.title == "Who Viewed My Phone Number")
                      props?.callBackNav(e?.viewerId?.replace("KM",""));
                      if(props.title == "Who Viewed My Phone Number"){
                        onClickTrack(EventName.profileWhoViewedMyPhoneNumberProfileIdButton)
                      } else if(props.title == "Who Viewed My Profile"){
                        onClickTrack(EventName.profileWhoViewesMyProfileIdButton)
                      }
                    
                  }} className={`${props?.title == "Who Viewed My Profile" || props?.title == "Who Viewed My Phone Number" ? "cursor-pointer" : "cursor-auto"} col-span-2 text-[#7A7A7A]    font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  `}>
                {props?.title == "Phone Numbers Viewed by Me" || props?.title == "Profile Viewed by Me"?e.vieweeId:e.viewerId}
                </p>
                <p className="col-span-3 text-[#7A7A7A]    font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center h-[40px]  ">
                  {getFormattedDateTimeWithPeriod(e.created)}
                </p>
              </div>
            ))}
            </div>
        }


      </div>
      {props?.tableBody?.length <= 0
          ? <></>
          : <div className=" flex justify-end mt-3  ">
            <button onClick={() => { props.loadMore() }} className=" flex items-center justify-center  font-bold text-white bg-[#D10A11] px-[1.5rem] md:px-[3rem] whitespace-nowrap rounded-[3px] md:rounded-[4px] py-2 md:py-2 text-[11px] md:text-[11px] lg:text-[13px] ">
              Load More
            </button>
          </div>}
    </div>
    <div className="md:hidden">
      <div className="flex justify-center bg-[#D10A11] py-1 md:mt-0 pl-[1rem] md:pl-0 3">
        <p className=" text-white text-[16px] font-semibold ">{props.title}</p>
      </div>
     
      <div className="mt-[1rem] mb-[2rem] md:mb-0  md:px-0  bg-white">

        {props.isLoading
          ? <div className="flex h-52 items-center justify-center">
            <img alt="loader" src={LoaderGif} />
          </div>
          : props?.tableBody?.length <= 0
            ? <div className="flex h-36 items-center justify-center bg-white">
              <p className="text-[#D10A11] text-[16px] font-semibold">No data found</p>
            </div>
            :
            <div className="">
              {props?.tableBody?.map((e, i) => (
                <div className="  mt-[0.1rem] text-[12px] rounded-b-[1.5rem] shadow-[rgba(0,_0,_0,_0.2)_0px_11px_40px_-7px] md:text-[12px] lg:text-[14px] ">
                  <div className="space-x-2 flex px-2 ">
                    <p className="  text-block  font-semibold  flex items-center h-[40px]  ">
                      {props.tableHeader[0]}
                    </p>
                    <span className="font-bold flex justify-center items-center">: </span>
                    <p className="  text-[#7A7A7A]   font-semibold  flex items-center h-[40px]  ">
                      {e.groomName}
                    </p>
                  </div>
                  <div className="space-x-2 flex px-2">
                    <p className="  text-block   font-semibold  flex items-center h-[40px]  ">
                      {props.tableHeader[1]}
                    </p>
                    <span className="font-bold flex justify-center items-center">: </span>

                    <p className=" text-[#7A7A7A]    font-semibold  flex items-center h-[40px]  ">
                      {props?.title == "Phone Numbers Viewed by Me" || props?.title == "Profile Viewed by Me" ? e.vieweeId : e.viewerId}
                    </p>
                  </div>
                  <div className="space-x-2 flex px-2">
                    <p className="  text-block   font-semibold  flex items-center h-[40px]  ">
                      {props.tableHeader[2]}
                    </p> 
                    <span className="font-bold flex justify-center items-center">: </span>

                    <p className=" text-[#7A7A7A]    font-semibold  flex items-center h-[40px]  ">
                      {getFormattedDateTimeWithPeriod(e.created)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
        }


      </div>


      {props?.tableBody?.length <= 0
        ? <></>
        : <div className=" flex justify-end mt-3  ">
          <button onClick={() => { props.loadMore() }} className=" flex items-center justify-center  font-bold text-white bg-[#D10A11] px-[1.5rem] md:px-[3rem] whitespace-nowrap rounded-[3px] md:rounded-[4px] py-2 md:py-2 text-[11px] md:text-[11px] lg:text-[13px] ">
            Load More
          </button>
        </div>}
    </div>
    </div>:
    <div>
    <div className="flex justify-between mt-[1rem] md:mt-0 pl-[1rem] md:pl-0 ">
      <p className=" text-[#000000] text-[16px] font-semibold ">{props.title}</p>
    </div>
    <div className={`mt-[1rem] mb-[8rem] md:mb-0 px-[1rem] md:px-0 md:col-span-6 ${isV3User ? "bg-white" : ""}`}>
      <div className=" grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] ">

        <p className={`col-span-4 text-[#FFFFFF]  bg-[#D10A11] font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
          {props.tableHeader[0]}
        </p>
        <p className={`col-span-2 text-[#FFFFFF]  bg-[#D10A11] font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
          {props.tableHeader[1]}
        </p>
      
          <p className={`col-span-3 text-[#FFFFFF]  bg-[#D10A11] font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
            {props.tableHeader[2]}
          </p>

        
      </div>
      {props.isLoading
        ? <div className="flex h-52 items-center justify-center">
          <img alt="loader" src={LoaderGif} />
        </div>
        : props?.tableBody?.length <= 0
          ? <div className={`flex h-36 items-center justify-center ${isV3User ? "bg-white" : ""}`}>
            <p className="text-[#D10A11] text-[16px] font-semibold">No data found</p>
          </div>
          : 
          <div className={`${isV3User ? "bgColor" : ""}`}>
            {props?.tableBody?.map((e, i) => (
            <div className=" grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] ">
              <p className={`col-span-4 text-[#7A7A7A]  bg-[#F1F1F1] font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
                {e.groomName}
              </p>
              <p
             
                onClick={() => {
                  if(props.menu == "viewphoneno" || props.menu == "viewmyprofile")
                    props?.callBackNav(e?.viewerId?.replace("KM",""));
                  
                }} className={`col-span-2 text-[#7A7A7A]   bg-[#F1F1F1] font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}  ${props.menu == "viewphoneno" || props.menu == "viewmyprofile" ? "cursor-pointer" : ""}`}>
                {props?.title == "Phone Numbers Viewed by Me" || props?.title == "Profile Viewed by Me" ? e.vieweeId : e.viewerId}
              </p>

              <p className={`col-span-3 text-[#7A7A7A]   bg-[#F1F1F1] font-semibold pl-[0.3rem] lg:pl-[1.5rem] flex items-center ${isV3User ? "h-[40px]" : "h-[50px]"}`}>
                {getFormattedDateTimeWithPeriod(e.created)}
                
              </p>
            </div>
          ))}
          </div>
      }

    </div>
    {props?.tableBody?.length < 10
        ? <></>
        : <div className=" flex justify-end mt-3  ">
          <button onClick={() => { props.loadMore() }} className=" flex items-center justify-center  font-bold text-white bg-[#D10A11] px-[1.5rem] md:px-[3rem] whitespace-nowrap rounded-[3px] md:rounded-[4px] py-2 md:py-2 text-[11px] md:text-[11px] lg:text-[13px] ">
            Load More
          </button>
        </div>}
  </div>
}
    </>
    
  );
};

export default ViewedPhoneProfileTable;
