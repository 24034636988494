import React, { useEffect, useState } from 'react'

import Slider from 'react-slick';
import { loginForAkhayatritiya, loginFromAdminPartialUser, loginFromAdminUser, loginFromEmailUser, loginFromSMSUser, loginUser } from "../Store/Login/Action";

//components
import RegisterForm from '../Components/RegisterForm/Form'
import KalyanMatrimonySpecification from '../Components/HomeRegisterContent/Specification';

//Route segment
import RouteSegments from '../Routes/RouteSegment'
import ForgetPasswordPopup from '../Components/LoginForm/ForgetPasswordPopup';
import Constants, { EventName } from '../Constants/constants';
import Loader from '../Components/Loader';
import withRouter from '../Components/withRouter';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import $ from 'jquery'
import LoginForm from '../Components/LoginForm/Form'

import { LoginVia, SuspendedPopup } from '../Components/CommonPopup';
import { registerDetail } from '../Store/Home/Action';
import { useAnalytics } from '../Hooks/usePageChange';
import FamilyfriedloginPopup from '../Components/Kalyanlite/Popup/FamilyfriedloginPopup';

import MobileFooter from './Footer/MobileFooter';
import * as Images from "../Components/Kalyanlite/assets/images"
import AppStoreIcon from "../Images/appstorebadge.svg";
import PlayStoreIcon from "../Images/google.png";




function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NRIRegister = (props) => {
  const navigate = useNavigate()
  const [registerOrLogin, setRegsiterOrLogin] = useState("1")
  const [showLoader, setShowLoader] = useState(false)

  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

  const [searchParams, setSearchParams] = useSearchParams();
  const [showSuspendedPopup, setshowSuspendedPopup] = useState(false)
  const { onClickTrack } = useAnalytics()
  const [pageScrool, setPageScrool] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setPageScrool(true);
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  })


  useEffect(() => {

    let text = new URL(window.location.href)
    if (text.pathname == "/loginfromsms") {
      let values = {
        profiletoken: searchParams.get("profiletoken"),
        jwt: searchParams.get("jwt"),
        photo: searchParams.get("photo"),
        otherMailFlag: searchParams.get("otherMailFlag"),
        toProfileId: searchParams.get("toProfileId"),
        matching: searchParams.get("matching"),
        editpreferences: searchParams.get("editpreferences"),
        upgrade: searchParams.get("upgrade"),
        horoscope: searchParams.get("horoscope"),
        trust: searchParams.get("trust"),
        manage: searchParams.get("manage"),
        bridexmail: searchParams.get("bridexmail"),
        profileId: searchParams.get("profileId"),
        settings: searchParams.get("settings"),
        inboxunread: searchParams.get("inboxunread"),
        appoinment: searchParams.get("appoinment"),
        fairPolicyFlag: searchParams.get("fairPolicyFlag"),
        muhurat: searchParams.get("muhurat"),
        parentsnumber: searchParams.get("parentsnumber"),
        upgradeFlag: searchParams.get('upgradeFlag'),
        mobileverification: searchParams.get("mobileverification"),
        pp_matches: searchParams.get("pp_matches"),
        replypending: searchParams.get("replypending"),
        notificationId: searchParams.get("notificationId"),
        recommendation: searchParams.get("recommendation"),
        parentsMobileNumber: searchParams.get("parentsMobileNumber"),
        whatsappverified: searchParams.get("whatsappverified"),
        startmessage: searchParams.get("startmessage"),
        iswhatsappClick: searchParams.get("iswhatsappClick"),
        messageId: searchParams.get("messageId"),
        trialpackpopup: searchParams.get("trialpackpopup"),
        privacypopup:searchParams.get("privacypopup"),
        redirection:searchParams.get("redirection"), 
      }
      dispatch(loginFromSMSUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }
    else if (text.pathname == "/loginfromadmin") {
      let values = {
        jwt: searchParams.get("jwt"),
        admuserid: searchParams.get("admuserid"),
        afterregister: searchParams.get("afterregister")
      }
      dispatch(loginFromAdminUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }
    else if (text.pathname == "/loginfromadminPartial") {
      let values = {
        jwt: searchParams.get("jwt"),
        admuserid: searchParams.get("admuserid"),
      }
      dispatch(loginFromAdminPartialUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }
    else if (text.pathname == "/loginfromemail") {
      let values = {
        photo: searchParams.get("photo"),
        otherMailFlag: searchParams.get("otherMailFlag"),
        fairPolicyFlag: searchParams.get("fairPolicyFlag"),
        jwt: searchParams.get("jwt"),
        toProfileId: searchParams.get("toProfileId"),
        appoinment: searchParams.get("appoinment"),
        matching: searchParams.get("matching"),
        editpreferences: searchParams.get("editpreferences"),
        upgrade: searchParams.get("upgrade"),
        horoscope: searchParams.get("horoscope"),
        trust: searchParams.get("trust"),
        manage: searchParams.get("manage"),
        bridexmail: searchParams.get("bridexmail"),
        profileId: searchParams.get("profileId"),
        settings: searchParams.get("settings"),
        inboxunread: searchParams.get("inboxunread"),
        muhurat: searchParams.get("muhurat"),
        parentsnumber: searchParams.get("parentsnumber"),
        upgradeFlag: searchParams.get('upgradeFlag'),
        email: searchParams.get('email'),
        password: searchParams.get('password'),
        profileRegisterFrom: searchParams.get('profileRegisterFrom')
      }
      dispatch(loginFromEmailUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }
    else if (text.pathname == "/akhayatritiya") {
      let values = {
        profileId: searchParams.get("profileId") ? searchParams.get("profileId") : localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
        mobileNumber: searchParams.get("mobileNumber"),
        sentFrom: searchParams.get("sentFrom")
      }
      dispatch(loginForAkhayatritiya(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    } else if (text.pathname == "/offline-profile") {
      let values = {
        jwt: searchParams.get("jwt"),
        matching: searchParams.get("matching"),
        mobilenumber: searchParams.get("mobilenumber"),
        profiletoken: searchParams.get("profiletoken"),
        toProfileId: searchParams.get("toProfileId"),
        offlinepack: searchParams.get("offlinepack"),
        loginfrom: searchParams.get("offlinepack") == "Y" ? localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice) : "",

      }
      dispatch(loginFromSMSUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }

  }, [searchParams])

  let { responseData } = useSelector(state => ({
    responseData: state.Login?.adminLoginData?.data
  }));

  console.log(responseData, "responseData");

  useEffect(() => {
    $('#name,#password').bind("cut copy paste", function (e) {
      e.preventDefault();
    });
    window.history.pushState(null, null, window.location.href);
  })


  useEffect(() => {

  }, [props.state], [props?.popup])

  const dispatch = useDispatch();



  const [showHidePassword, changeShowHidePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [showFamilyPopup, setShowFamilyPopup] = useState(false)
  const [isChecked, setIsChecked] = useState(localStorage.getItem("checkbox") == "true" ? true : false)
  const IsNRIPage = localStorage.getItem(Constants.loginLocalStorageKeys.isNRIPage)


  useEffect(() => {

    if (isChecked) {
      localStorage.setItem("checkbox", true);
    } else {
      localStorage.setItem("checkbox", false);
    }

  }, [isChecked]);


  const onFamilyFriendPopupClick = () => {
    setShowFamilyPopup(true)
  }

  useEffect(() => {
    dispatch({ type: "CLEAR_STORE" })
    dispatch(registerDetail({isFrom:"NRI"}));

  }, []);



  //Error msg for Login form
  const formik = useFormik({
    initialValues: {
      name: localStorage.getItem("email") ? localStorage.getItem("email") : '',
      password: localStorage.getItem("pass") ? localStorage.getItem("pass") : ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Please enter email or mobile no'),
      password: Yup.string()
        .required('Please enter password'),
    }),
    onSubmit: (values) => {
      LoginFormSubmission(values);
    }
  })

  // form submit
  const LoginFormSubmission = (values) => {
    setShowLoader(true)
   
    if (values.name !== "" && values.password !== "") {
      values.profileid = searchParams.get("profileid")
      values.action = searchParams.get("action")
      values.offlinepack = searchParams.get("offlinepack")
      dispatch(loginUser(values, props.router.navigate))
      localStorage.setItem(Constants.loginLocalStorageKeys.isFrom, "login");
    }

    if (isChecked && values.name !== "" && values.password !== "") {
      localStorage.setItem("checkbox", isChecked);
      localStorage.setItem("email", values.name);
      localStorage.setItem("pass", values.password);
    } else {
      localStorage.setItem("email", "");
      localStorage.setItem("pass", "");
      localStorage.setItem("checkbox", "");
    }
  }


  useEffect(() => {
    const rmCheck = document.getElementById("rememberMe"),
      emailInput = document.getElementById("name"),
      passwordd = document.getElementById("password");
    if (localStorage.getItem("checkbox") == true) {
      rmCheck.setAttribute("checked", "checked");
      emailInput.value = localStorage.getItem("email");
      passwordd.value = localStorage.getItem("pass");
    }
  }, [])

  let { error } = useSelector(state => ({
    error: state.Login?.adminLoginData?.data
  }));

  console.log(error, "errorr");

  useEffect(() => {
    if (error?.status === "FAILURE" && error?.title === "Your Profile is suspended due to the following reason") {
      setshowSuspendedPopup(true)
      setErrorMessage("")
    } else {
      // setshowSuspendedPopup(false)
      setErrorMessage(error?.message)
    } setShowLoader(false)
  }, [error])

  useEffect(() => {
    setErrorMessage("")
  }, [formik.values])


  var sliderImage = [
    // {
    //   id: 1,
    //   img: "https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist1/images/slider_1.jpg",
    //   text: "Register now to find your special someone."

    // },
    // {
    //   id: 2,
    //   img: "https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist1/images/slider_3.jpg",
    //   text: "Come find the one you want to spend the rest of your life with."
    // },
    // {
    //   id: 3,
    //   img: "https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist1/images/slider_2.jpg",
    //   text: "We simplify your search for the perfect match."
    // },
    {
      id: 4,
      img: "https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist1/images/slider_4.jpg",
      text: "The best NRI matrimonial site"
    }
  ]
  var settings = {
    dots: false,
    infinite: true,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {

    $('head').append(`<link  class="canonical" rel="canonical" href="https://www.kalyanmatrimony.com" />`);


    return () => {
      $('.canonical').remove()
    }
  }, [])


  const scriptToInject = `
  <div class="pixelScript">
  <meta class="pixelScript" http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  <meta class="pixelScript" name="viewport" content="width=device-width, initial-scale=1" />
  <meta class="pixelScript" name="author" content="Rage 5.0" />
  <meta class="pixelScript" name="copyright" content="&copy; Project 2017" />
  <meta class="pixelScript" itemprop="name" content="Kalyanmatrimony.com"/>
  <meta class="pixelScript" itemprop="url" content="https://www.kalyanmatrimony.com/"/> 
  <meta class="pixelScript" itemprop="telephone" content="044 4014 6969"/>
  <meta class="pixelScript" itemprop="email" content="info@kalyanmatrimony.com"/>
  <meta class="pixelScript"  name="description" content="Find Lakhs of Indian brides and grooms on kalyanmatrimony.com. Register now to find the right life partner!"/>
  <meta class="pixelScript" name="keywords" content="matrimonial websites, Marriage, match making, free matrimonial sites, matrimonial for tamil, matrimony in tamilnadu, kerala bride, tamil brides, telugu brides, kerala grooms, telugu grooms, tamil grooms, matrimony sites in Kerala, matrimonial for kerala, matrimony for malayalam, matrimony for kerala christian, matrimony for tamil christian, matrimonial for kerala, matrimony for christian, matrimony for muslim, matrimony for nair, matrimony for brahmin, matrimony for ezhava, matrimony in kerala, matrimony for nadar, matrimony for iyer, matrimony for yadav, matrimony for jain, matrimony for iyengar, matrimony for sikh, matrimony for Adidravidar, matrimony for telugu, matrimony for reddy, matrimony for naidu" />
  <meta class="pixelScript" name="abstract" content="Kalyan Matrimony is a matrimonial website that uses intelligent matchmaking technology to help you find the most compatible match. Register Free." />
  <meta class="pixelScript" name="facebook-domain-verification" content="kkp6u82qh7hma8gu1w6fsgibwzrmir" />
  <meta class="pixelScript" name="facebook-domain-verification" content="2yhwv3rnals6sgsyivv9izs7lwumo3" />

  <!-- Google tag (gtag.js) -->
  <script class="pixelScript" defer src="https://www.googletagmanager.com/gtag/js?id=AW-875555676"></script>
  <script class="pixelScript" defer>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'AW-875555676');
  </script>

<!-- Google tag (gtag.js) -->
<script class="pixelScript" defer src="https://www.googletagmanager.com/gtag/js?id=G-G4GYGWS9XB"></script>
<script class="pixelScript" defer> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-G4GYGWS9XB'); </script>

<!-- Meta Pixel Code -->
<script class="pixelScript" defer>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '276119551481259');
fbq('track', 'PageView');
</script>
<noscript class="pixelScript"><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=276119551481259&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->


<!-- Meta Pixel Code -->
<script defer>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1522870085153048');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1522870085153048&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->


<!-- Meta Pixel Code -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '757518326483604');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=757518326483604&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->


<!-- Meta Pixel Code -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '398290656299866');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=398290656299866&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->


<script>
  (function (w, d, s, l, i) {
      w['scSdkId'] = i;
      w[l] = w[l] || []
      w.scq = function (eventName, eventType, p) {
      var props = p || {}
        w[l].push({ eventName: eventName, eventType: eventType, meta: props, eventFireTs: Date.now() })
      };
      w.scq("PAGE_VIEW", "AUTO", {
        pageUrl: w.location.href
      });
      var scr = d.createElement(s);
      scr.type = 'text/javascript';
      scr.async = true;
      scr.src = 'https://sc-events-sdk.sharechat.com/web-sdk.js';
      var x = d.getElementsByTagName(s)[0];
      x.parentNode.insertBefore(scr, x);
    })(window, document, "script", "scLayer", "k0EyRoALq8");
</script>



</div>
`


  useEffect(() => {
    if (isCommunitySite) {
      //no script for this community site
    } else {
      if ($('.pixelScript').length > 0) {
        $('.pixelScript').remove()
        $('head').append(scriptToInject)
      } else {
        $('head').append(scriptToInject)
      }
    }
    return () => {
      $('.pixelScript').remove()
    }
  }, [])

  useEffect(() => {
      $("meta[name='description']").remove();
      const metaTag = `<meta name="description" content="${Constants.metaDescriptionNRI}" />`;
      $("head").append(metaTag);
      document.title = Constants.metaTitleNRI;
      localStorage.setItem(Constants.loginLocalStorageKeys.isNRIPage, true);
    
  }, [])
  

  return (
    <div>
      {showFamilyPopup &&
        <FamilyfriedloginPopup
          close={() => setShowFamilyPopup(false)}
          title={"FAMILY/FRIEND LOGIN"}
        />
      }
      {props?.from == "admin" ?
        responseData?.status == 'alreadyloggedin' || error?.status == "failure" ?
          <LoginVia
            close={() => {
              navigate("/")
            }}
            status={responseData.status}
          />
          :
          <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />
        :
        <main>
          {
            showSuspendedPopup ?
              <SuspendedPopup
                close={() => {
                  setshowSuspendedPopup(!showSuspendedPopup)
                }}
                title={error.title}
                content={error.reason}
              />

              : <></>
          }
          <div className="nriBackgroundImage">
            {showLoader && <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />}
            <div className='md:hidden bg-opacityblack'>

              <div className="  space-x-3  pt-[3rem] px-[1.4rem] md:hidden ">
                <div className='flex items-center justify-center'>
                    <p
                      className='text-[18px] font-bold text-white fot-bold pb-2' style={{textShadow: "0 0 2px #D10A11"}}>The best NRI matrimonial site</p>
                  </div>
                <>
                  <form className=' pt-1 md:pt-0' onSubmit={formik.handleSubmit}>
                    <div className='flex items-center justify-center md:absolute md:right-[2rem]'>
                    <a onClick={() => { onFamilyFriendPopupClick(); }}
                      className='underline text-[11px] cursor-pointer font-bold text-white fot-bold' style={{textShadow: "0 0 2px #D10A11"}}>Login as Family Member/ Friend</a>
                    </div>
                    <>
                      <div className="relative  md:mt-[1.3rem] md:hidden">
                        <div className="  ">
                          {/* Email or phone no */}
                          <div className='flex justify-between'>
                            <div className="space-y-1 mt-[0rem]">

                              <label className="relative block" >

                                <input
                                  id="name"
                                  name="name"
                                  type="text"
                                  autoComplete="off"
                                  value={formik.values.name}
                                  onChange={formik.handleChange}
                                  placeholder="e-mail ID / Mobile Number"
                                  // className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-[0.3rem] lg:py-2 md:px-2  w-full block " 
                                  className='placeholder-[#575556] text-[8px] font-semibold border border-[#F18181]  block p-2 pr-[3rem]  md:pr-0 lg:pr-[4.75rem] w-[100%] md:w-[90%]  rounded-md sm:text-sm '
                                />
                              </label>

                              <div className='flex'>

                                <label className=''>
                                  <input type="checkbox" className=" border-[#F18181]  focus:ring-red-200 rounded-[3px]  " id="rememberMe" value="lsRememberMe" onClick={() => setIsChecked(!isChecked)} checked={isChecked} />
                                </label>
                                <p className=" text-[#FFFFFF] md:text-[#8F8F8F] text-[11px] md:text-[11px] lg:text-[14px] pl-2 "> Keep me logged in</p>
                              </div>
                            </div>
                            {/* Password */}

                            <div>

                              <label className="relative flex justify-end	mbl-forget_passwort">
                                <input id="password" name="password" autoComplete="off"
                                  placeholder="Password"
                                  value={formik.values.password}
                                  onChange={formik.handleChange}
                                  type={showHidePassword ? "text" : "password"}
                                  // className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-[0.3rem] md:px-2  lg:py-2 w-full block "
                                  className='className="placeholder-[#575556] font-semibold border border-[#F18181] text-[8px]  block p-2 pr-[3rem]  md:pr-0 lg:pr-[3.75rem]  w-[100%] md:w-[90%]  rounded-md sm:text-sm "'
                                />
                                <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                                  <img onClick={() => changeShowHidePassword(!showHidePassword)}
                                    src={showHidePassword ? ("/Assets/Images/passwordeyeopen.png") : ("/Assets/Images/passwordeye.svg")}
                                    className=" w-[1rem] h-[.8rem] md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5" alt="" />
                                </div>
                              </label>
                            </div>


                          </div>
                          <div className='flex justify-between'>

                            {errorMessage ?
                              <div className=" text-[red] text-[9px] md:text-[red] md:text-[12px] h-[1rem]">{errorMessage}</div> :
                              formik.errors.name && formik.touched.name ?
                                <div className="text-[red] text-[9px] md:text-[red] md:text-[12px] h-[1rem]">{formik.errors.name}</div>
                                :
                                <div className="text-[red] text-[9px] md:text-[red] md:text-[12px] h-[1rem]"></div   >
                            }

                            {formik.errors.password && formik.touched.password ?
                              <p className="text-[red] text-[9px] md:text-[red] md:text-[12px] h-[1rem] absolute right-[4rem]">{formik.errors.password}</p>
                              : <div className="text-[red] text-[9px] md:text-[red] md:text-[12px] h-[1rem] absolute right-[4rem]"></div   >
                            }

                          </div>
                          {/* Login Button      */}
                          <div className='flex justify-center pb-[1rem] md:hidden space-y-1 mt-[0.9rem]'>
                            <div>
                              <label className="" >
                                <button type="submit"
                                  className="flex items-center h-[1.5rem] font-bold text-white bg-[#D10A11] px-[1rem] whitespace-nowrap  rounded-lg py-2 text-[9px] md:hidden">
                                  LOG IN
                                </button>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </form>

                  <div className="absolute left-[12rem] top-[15.4rem] flex  md:text-[#8F8F8F] text-[11px] md:text-[11px] lg:text-[14px] pl-2 mbl-forget-password">
                    <ForgetPasswordPopup />
                  </div>

                </>
              </div>
            </div>



          </div>
          <div className='hidden md:block'>
            <Slider {...settings} className='home-page-slider'>
              {sliderImage?.map((image, i) => (
                <div
                  className=""
                >
                  <div key={i} className=''
                    style={{
                      width: "100% !important",
                      backgroundImage: `url(${image.img}) `,
                      backgroundPosition: "initial",
                      backgroundAttachment: "fixed",
                      backgroundSize: 'cover',
                    }}>

                    <div className="hidden md:grid md:grid-cols-9 lg:grid lg:grid-cols-6 bg-opacityblack ">
                      <div className=" mt-[6rem] mb-[1rem] md:col-span-5 lg:col-span-4  lg:mt-[9rem] xl:mt-[12rem] 2xl:mt-[9rem]">
                        <div className=" md:pl-[1rem] lg:pl-0 ">
                          {image.id === 1 ?
                            <p className="md:w-[16rem] lg:w-[34rem] xl:w-[55rem] text-white drop-shadow-md font-[900] md:text-[40px] lg:text-[2.67rem] text-center 2xl:w-[40rem] 2xl:h-[10rem]" >
                              {image.text}
                            </p>
                            :
                            image.id === 3 ?
                              <p className="md:w-[18rem] lg:w-[34rem] xl:w-[55rem] text-white drop-shadow-md font-[900] md:text-[40px] lg:text-[41px] text-center 2xl:w-[40rem] 2xl:h-[10rem]" >
                                {image.text}
                              </p>
                              :
                              image.id === 4 ?
                                <>
                                  <h1 className="md:w-[20rem] lg:w-[34rem] xl:w-[40rem] text-white drop-shadow-md font-bold md:text-[40px] lg:text-[41px] text-center 2xl:w-[40rem] 2xl:h-[6rem]" >
                                    {image.text}
                                  </h1>
                                  <h2 className="md:w-[20rem] lg:w-[34rem] xl:w-[50rem] text-white drop-shadow-md font-bold md:text-[20px] lg:text-[22px] text-justify 2xl:w-[50dvw] 2xl:h-[6rem] pl-8">
                                    Matrimony for NRI - Find lakhs of brides and grooms from your community and religion around the globe on Kalyan Matrimony
                                  </h2>
                                </>
                                :
                                <p className="md:w-[20rem] lg:w-[34rem] xl:w-[55rem] text-white drop-shadow-md font-[900] md:text-[40px] lg:text-[41px] text-center 2xl:w-[40rem] 2xl:h-[10rem]" >
                                  {image.text}
                                </p>

                          }
                        </div><br /><br /><br />
                        <KalyanMatrimonySpecification page="homeRegister"  isFrom={"NRI"}/>
                        <br />
                      </div>
                      <div className=" md:col-span-4 lg:col-span-2  my-[0.8rem] ">


                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <div className="hidden md:block  md:pr-[0.1rem] lg:pr-[1rem] absolute md:top-[11rem] lg:top-[12.5rem] xl:top-[12.5rem] 2xl:top-[12.5rem] md:ml-[53%] lg:ml-[57%] xl:ml-[65%] ">
              <div className={registerOrLogin == "1" ? `bg-[#DDD8D3] md:px-[0.5rem] lg:px-[2rem] rounded-t-[10px] rounded-b-[10px] md:w-[98.3%] lg:w-[82%] ` :
                `bg-[#DDD8D3] md:px-[0.5rem] lg:px-[2rem] rounded-t-[10px] rounded-b-[10px] w-[100%]`}>
                <div className="space-y-4 ">
                  <div className="flex justify-center space-x-4 pt-[1.25rem]   lg:pt-[1rem] ">
                    <div className=" flex justify-center ">
                      <button onClick={() => { setRegsiterOrLogin("1"); onClickTrack(EventName.Home_page_Register_Register_Button) }} className={classNames(" flex items-center md:h-[1.5rem] lg:h-[2.5rem] font-bold text-white  w-[10rem] justify-center whitespace-nowrap  rounded-tl-lg rounded-br-lg lg:py-2 md:text-[12px] lg:text-[13px]  "
                        , registerOrLogin == "1" ? "bg-[#D10A11]" : "bg-[#575556]")}>
                        REGISTER
                      </button>
                    </div>
                    <div className=" flex justify-center ">
                      <button onClick={() => { setRegsiterOrLogin("2"); onClickTrack(EventName.Home_page_Signin_Signin_Field) }} className={classNames(" flex items-center md:h-[1.5rem] lg:h-[2.5rem] font-bold text-white  w-[10rem] justify-center whitespace-nowrap rounded-tl-lg rounded-br-lg lg:py-2 md:text-[12px] lg:text-[13px]  "
                        , registerOrLogin == "2" ? "bg-[#D10A11]" : "bg-[#575556]")}>
                        SIGN IN
                      </button>
                    </div>
                  </div>
                  <div className=''>


                    {registerOrLogin == "1" ?
                      <RegisterForm utmsource="SEO" utmcampaign="Matrimony for NRI" isFrom="NRI"/>
                      : <LoginForm menu="signup" />
                    }
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div className="md:hidden block mx-5">
            <div className={registerOrLogin == "1" ? `md:px-[0.5rem] lg:px-[2rem] rounded-t-[10px] rounded-b-[10px] md:w-[98.3%] lg:w-[82%]` :
              ` md:px-[0.5rem] lg:px-[2rem] rounded-t-[10px] rounded-b-[10px] w-[100%]`}>
              <div className="space-y-4 ">
                <div className="flex justify-center space-x-4 pt-[1.25rem]    ">
                  <div className=" flex justify-center ">
                    <button onClick={() => setRegsiterOrLogin("1")} className={classNames(" flex items-center md:h-[1.5rem] lg:h-[2.5rem] font-bold text-white  w-[10rem] justify-center whitespace-nowrap  rounded-tl-lg rounded-br-lg py-1 lg:py-2 md:text-[12px] lg:text-[13px]  "
                      , registerOrLogin == "1" ? "bg-[#D10A11]" : "bg-[#575556]")}>
                      REGISTER
                    </button>
                  </div>
                  <div className=" flex justify-center ">
                    <button onClick={() => setRegsiterOrLogin("2")} className={classNames(" flex items-center md:h-[1.5rem] lg:h-[2.5rem] font-bold text-white  w-[10rem] justify-center whitespace-nowrap rounded-tl-lg rounded-br-lg py-1 lg:py-2 md:text-[12px] lg:text-[13px]  "
                      , registerOrLogin == "2" ? "bg-[#D10A11]" : "bg-[#575556]")}>
                      SIGN IN
                    </button>
                  </div>
                </div>
                <div className=''>
                  {registerOrLogin == "1" ?
                    <RegisterForm utmsource="" isFrom="NRI"/>
                    : <LoginForm menu="signup" />
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="font-segeo pb-2 pt-[3rem]">
            <h1 className="text-center font-bold text-[18px] md:text-[18px] lg:text-[28px] px-6 md:px-8">
              Matrimony for NRI: Your Premier Matrimonial Platform for NRIs
            </h1>
            <div className="grid gap-8 py-4 px-6 md:gap-8 md:py-8 md:px-8 lg:gap-8 lg:py-8 lg:px-12">
              <p className="text-[12px] md:text-[14px] lg:text-[18px] tracking-normal text-justify">Welcome to Kalyan Matrimony, your premier platform for NRI matchmaking from the trusted brand Kalyan Jewellers. We cater specifically to Non-Resident Indians, offering a comprehensive service to connect you with ideal NRI brides and NRI grooms from diverse cultures and religions worldwide.</p>
              <p className="text-[12px] md:text-[14px] lg:text-[18px] tracking-normal text-justify">Whether you're looking for a partner from your home country or exploring new possibilities, Kalyan Matrimony provides a broad network to find someone who matches your vision. Our platform connects you with profiles from Tamil, Malayalam, Kannada, Telugu communities, and NRIs in countries like the UAE, USA, UK, Saudi Arabia, Canada, Australia, Qatar, Oman, Kuwait, and Singapore.</p>
              <p className="text-[12px] md:text-[14px] lg:text-[18px] tracking-normal text-justify">Understanding the demands of busy professional lives, Kalyan Matrimony offers a trusted service with mobile verified profiles and robust privacy settings, helping you find a partner who shares your career goals and personal aspirations. Explore our diverse network to discover your perfect match with ease.</p>
            </div>
          </div>

          {/* Why Kalyan Section */}
          <div className="font-segeo py-2 px-8 md:px-12">
            <p className="text-center font-bold text-[18px] md:text-[18px] lg:text-[28px]">
              Why Choose Kalyan Matrimony?
            </p>
            <div className="grid md:grid-cols-3 gap-4 md:gap-6 py-4 md:py-8">
              <div className="flex flex-col gap-2">
                <div className="">
                  <img src={Images.KalyanLeaf} alt="" className="w-[2rem] h-[2rem] md:h-[3.1rem] md:w-[3rem]" />
                </div>
                <p className="text-[#D2951B] font-semibold text-[13px] md:text-[14px] lg:text-[20px]">From A Trusted Brand</p>
                <p className="text-black text-[12px] md:text-[14px] lg:text-[18px] tracking-normal text-justify">With a legacy of exceptional service offered by Kalyan Jewellers, we are a dedicated Matrimonial site who facilitate NRI marriage search through our online website and App.</p>
              </div>
              <div className="flex flex-col gap-2">
                <div className="">
                  <img src={Images.LakhsProfilesImg} alt="" className="w-[2rem] h-auto md:w-[3rem]" />
                </div>
                <p className="text-[#D2951B] font-semibold text-[13px] md:text-[14px] lg:text-[20px]">Lakhs of Profiles</p>
                <p className="text-black text-[12px] md:text-[14px] lg:text-[18px] tracking-normal text-justify">Explore an extensive collection of profiles, including those from Tamil, Telugu, Malayalam, and Kannada communities, as well as from various other communities and religions around the globe. Discover NRI brides and grooms from diverse backgrounds to find a match that aligns perfectly with your life and preferences.</p>
              </div>
              <div className="flex flex-col gap-2">
                <div className="">
                  <img src={Images.PrivacyImg} alt="" className="w-[2rem] h-auto md:w-[3rem]" />
                </div>
                <p className="text-[#D2951B] font-semibold text-[13px] md:text-[14px] lg:text-[20px]">Privacy and Security</p>
                <p className="text-black text-[12px] md:text-[14px] lg:text-[18px] tracking-normal text-justify">At Kalyan Matrimony, your privacy and security are paramount. As a top NRI matrimonial site, we use robust data protection measures to ensure your information stays safe. Trust us to keep your details confidential while you find your ideal NRI partner with peace of mind.</p>
              </div>

              {/* 2nd section */}

              <div className="flex flex-col gap-2">
                <div className="">
                  <img src={Images.VerifiedImg} alt="" className="w-[2rem] h-auto md:w-[3rem]" />
                </div>
                <p className="text-[#D2951B] font-semibold text-[13px] md:text-[14px] lg:text-[20px]">Phone Number Verified Profiles</p>
                <p className="text-black text-[12px] md:text-[14px] lg:text-[18px] tracking-normal text-justify">We ensure authenticity through mobile number verification to guarantee that lakhs of profiles of NRI brides and NRI grooms are contactable easily. This added security enhances trust and reliability, supporting our matchmaking services for a secure experience.</p>
              </div>
              <div className="flex flex-col gap-2">
                <div className="">
                  <img src={Images.UserFriendlyImg} alt="" className="w-[2rem] h-auto md:w-[3rem]" />
                </div>
                <p className="text-[#D2951B] font-semibold text-[13px] md:text-[14px] lg:text-[20px]">User-Friendly Interface</p>
                <p className="text-black text-[12px] md:text-[14px] lg:text-[18px] tracking-normal text-justify">Find your perfect NRI bride or NRI groom easily with our intuitive Kalyan Matrimony app. Designed for seamless navigation, our user-friendly interface ensures a smooth and enjoyable experience in finding the perfect match for your marriage.</p>
              </div>
              <div className="flex flex-col gap-2">
                <div className="">
                  <img src={Images.MatchesBasedImg} alt="" className="w-[2rem] h-auto md:w-[3rem]" />
                </div>
                <p className="text-[#D2951B] font-semibold text-[13px] md:text-[14px] lg:text-[20px]">AI-Based Matches</p>
                <p className="text-black text-[12px] md:text-[14px] lg:text-[18px] tracking-normal text-justify">Utilizing advanced algorithms and intelligent matchmaking techniques, our service ensures that match recommendations for NRI brides and grooms are tailored to your preferences. This dual approach enhances the effectiveness and personalization of your search, helping you find the most compatible partners with ease.</p>
              </div>

            </div>
          </div>

          {/* Download NRI APP Section */}
          <div className="font-segeo py-4">
            <div className="flex">
            <div className="hidden md:flex w-[30%] bg-[#D10A11] items-end justify-center">
            <img 
              src={Images.MobileInHandIcon} 
              alt="" 
              className="h-auto lg:h-full lg:w-[22dvw] xl:w-[17dvw] 2xl:w-[15dvw] object-contain object-bottom" 
            />
          </div>

                  <div className="w-[100%] md:w-[75%] bg-[#F9E1E2] px-4 md:px-8 lg:px-12 pt-8 pb-2">
                    <h1 className="font-bold text-[18px] md:text-[18px] lg:text-[28px] pt-[0rem] md:pt-[.5rem] lg:pt-[1.5rem] text-[#D10A11]">
                      Download the Kalyan Matrimony App for NRI Today
                    </h1>
                    <p className="text-black text-justify text-[12px] md:text-[14px] lg:text-[18px] pt-[1rem] md:pt-[1rem] lg:pt-[1rem] pb-6 md:pb-8 lg:pb-6 font-medium">Finding your perfect NRI bride or NRI groom is now easier than ever. Your app, specifically is designed for Non-Resident Indians and available on both Android and iOS, allows you to connect with numerous happy couples who have successfully found their perfect NRI match through a user-friendly platform. </p>
                    <div className="flex justify-end py-2 md:py-0 lg:pb-4">
                          <div className="flex flex-col gap-2"> 
                              <p className="text-black text-center text-[12px] md:text-[14px] lg:text-[18px]">Download the App</p>
                              <div className="flex gap-2">
                              <a href={RouteSegments.staticLinkForAppDownload.ios} target="_blank" rel="noreferrer">
                                <img src={AppStoreIcon} alt="" className="w-[5rem] md:w-[6.5rem] lg:w-[10rem] h-auto" />
                              </a>
                                <a href={RouteSegments.staticLinkForAppDownload.androidNri} target="_blank" rel="noreferrer">
                                  <img src={PlayStoreIcon} alt="" className="w-auto h-[1.5rem] md:h-[2rem] lg:h-[3rem]" />
                                </a>
                              </div>
                          </div>
                    </div>
                  </div>
            </div>
          </div>

          {/* How Matrimony Section */}
          <div className="font-segeo py-4 px-8 md:px-12">
            <h1 className="text-center font-bold text-[18px] md:text-[18px] lg:text-[28px] pb-[2rem] md:pb-[3rem] lg:pb-[4rem]">
              How Matrimony for NRI Services Work
            </h1>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-2">

              <div className="border-t-4 border-t-[#D10A11] bg-[#F9E1E2] min-h-[12rem] lg:max-w-[21dvw] px-4 pb-6">
                <div className="relative">
                  <img src={Images.CreateProfileIcon} alt="" className="w-[3rem] md:w-[3rem] lg:w-[5rem] absolute -top-[1.5rem] md:-top-[1.5rem] lg:-top-[2.5rem] left-0 right-0 ml-auto mr-auto" />
                </div>
                <div className="pt-7">
                  <p className="text-black font-semibold text-center py-4 text-[13px] md:text-[14px] lg:text-[20px]">Create Your Profile</p>
                  <p className="text-black text-center text-[12px] md:text-[14px] lg:text-[18px]">Begin your journey by creating a detailed profile on matrimony by registering your personal information and expectations.</p>
                </div>
              </div>

              <div className="border-t-4 border-t-[#D10A11] bg-[#F9E1E2] min-h-[12rem] lg:max-w-[21dvw] px-4 pb-6">
                <div className="relative">
                  <img src={Images.DiscoverIcon} alt="" className="w-[3rem] md:w-[3rem] lg:w-[5rem] absolute -top-[1.5rem] md:-top-[1.5rem] lg:-top-[2.5rem] left-0 right-0 ml-auto mr-auto" />
                </div>
                <div className="pt-7">
                  <p className="text-black font-semibold text-center py-4 text-[13px] md:text-[14px] lg:text-[20px]">Discover Matches</p>
                  <p className="text-black text-center text-[12px] md:text-[14px] lg:text-[18px]">Our advanced algorithm works tirelessly to find perfect matches that align with your specified criteria, including age, location, community, and religion.</p>
                </div>
              </div>

              <div className="border-t-4 border-t-[#D10A11] bg-[#F9E1E2] min-h-[12rem] lg:max-w-[21dvw] px-4 pb-6">
                <div className="relative">
                  <img src={Images.ConnectIcon} alt="" className="w-[3rem] md:w-[3rem] lg:w-[5rem] absolute -top-[1.5rem] md:-top-[1.5rem] lg:-top-[2.5rem] left-0 right-0 ml-auto mr-auto" />
                </div>
                <div className="pt-7">
                  <p className="text-black font-semibold text-center py-4 text-[13px] md:text-[14px] lg:text-[20px]">Call and Communicate</p>
                  <p className="text-black text-center text-[12px] md:text-[14px] lg:text-[18px]">Start meaningful conversations with ease using our popular calling package. Kalyan Matrimony ensures a safe and secure environment to help you connect with potential partners.</p>
                </div>
              </div>

              <div className="border-t-4 border-t-[#D10A11] bg-[#F9E1E2] min-h-[12rem] lg:max-w-[21dvw] px-4 pb-6">
                <div className="relative">
                  <img src={Images.MeetIcon} alt="" className="w-[3rem] md:w-[3rem] lg:w-[5rem] absolute -top-[1.5rem] md:-top-[1.5rem] lg:-top-[2.5rem] left-0 right-0 ml-auto mr-auto" />
                </div>
                <div className="pt-7">
                  <p className="text-black font-semibold text-center py-4 text-[13px] md:text-[14px] lg:text-[20px]">Meet Your Life Partner</p>
                  <p className="text-black text-center text-[12px] md:text-[14px] lg:text-[18px]">When you find someone special, take the next step towards a lifelong commitment with Kalyan Matrimony. Our NRI matchmaking services are designed to foster genuine relationships</p>
                </div>
              </div>

            </div>
          </div>


          <div className=" text-[#575556] p-[1.25rem] md:px-[3.25rem] md:pb-[3.25rem] lg:px-[3.25rem] lg:pb-[3.25rem] text-left">
            {/* Success Story Section */}
            <div className="font-segeo py-2">
              <p className="text-center font-bold text-[18px] md:text-[18px] lg:text-[28px] text-black pb-4">
                Success Stories from Kalyan Matrimony
              </p>
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 min-h-[12rem]">
                <div className="bg-[#F9E1E2] rounded-[1rem] p-8">
                  <p className="text-black font-semibold text-[13px] md:text-[14px] lg:text-[20px]">Customer Speak 1</p>
                  <p className="text-black pt-4 text-[12px] md:text-[14px] lg:text-[18px] quotedText">As per feedback from our customers Anjali and Rajesh, NRIs living in different parts of the world, they found their dream match after connecting registering on Kalyan Matrimony</p>
                </div>
                <div className="bg-[#F9E1E2] rounded-[1rem] p-8">
                  <p className="text-black font-semibold text-[13px] md:text-[14px] lg:text-[20px]">Customer Speak 2</p>
                  <p className="text-black pt-4 text-[12px] md:text-[14px] lg:text-[18px] quotedText">As per feedback from our customers Priya and Arun: The advanced matching algorithm truly worked for them! They found each other based on shared values and interests through Kalyan Matrimony. Now, they are happily married and grateful for the NRI service introduced to them, leading to a wonderful journey together</p>
                </div>
              </div>
            </div>
            <div className=" text-[#575556] p-[2rem] space-y-3 md:space-y-0 md:p-[2.25rem] lg:p-[3.25rem]">
              <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 lg:space-x-6 ">
                <div className=" md:col-span-1 lg:col-span-1 border-b-[1px] md:border-b-[0px] md:border-r-[1px] border-[#00000029] px-[10px] mt-[1rem] md:mt-0 ">
                  <div className="flex  items-center">
                    <img src={require("../Images/Group 824.svg").default} alt="" width="80" height="90" />

                    <p className="text-[#D2951B] pl-[0.8rem] text-[18px] font-bold " >
                      ABOUT KALYAN
                      <br />
                      MATRIMONY
                    </p>

                  </div>
                  <p className=" font-semibold text-[14px] mt-3 md:h-[4rem] " >
                    Launched in 2016, by the Kalyan Jewellers Group, Kalyan Matrimony (formerly Sanskriti Matrimony) carries with...
                  </p>
                  {/* <!-- <a>
                                  <img className="h-[24px] w-[2px] mt-1 " src= {require("../Images/ " />
                              </a> --> */}
                  <div className="flex items-center mb-[1.5rem] 2xl:mt-[1.5rem] xl:mt-[3.5rem] lg:mt-[3.5rem] md:mb-0 mt-5 ">
                    <img src={require("../Images/Polygon 4.svg").default} alt="" />
                    <p className=" pl-2  font-bold text-[14px]" ><a onClick={() => onClickTrack(EventName.Home_page_About_Kalyan_Matrimony)} href={RouteSegments.ABOUT_US} target="_blank">Know more</a></p>
                  </div>
                  {/* <div className="md:hidden flex items-center mb-[1.5rem] md:mb-0 mt-5 ">
                  <img src={require("../Images/Polygon 4.svg").default} alt="" />
                  <p className=" pl-2  font-bold text-[14px]" ><a href={RouteSegments.MOBILE_ABOUT_US} target="_blank">Know more</a></p>
                </div> */}
                </div>
                <div className=" md:col-span-1 lg:col-span-1 border-b-[1px] md:border-b-[0px] lg:border-r-[1px] lg:border-[#00000029] px-[10px] mt-[1rem] md:mt-0 ">
                  <div className="flex  items-center">
                    <img src={require("../Images/Group 828.svg").default} alt="" width="80" height="90" />
                    <span className="text-[#D2951B] pl-[0.8rem] text-[18px] font-bold " >
                      SAFETY AND
                      <br /> SECURITY
                    </span>
                  </div>
                  <p className=" font-semibold text-[14px] mt-3 md:h-[4rem]" >
                    We do our best to keep the fake profiles out. But for your own safety, please follow the following tips.
                  </p>
                  <div className=" flex items-center mt-5 mb-[1.5rem] 2xl:mt-[1.5rem] xl:mt-[3.5rem] lg:mt-[3.5rem] md:mb-0  ">
                    <img src={require("../Images/Polygon 4.svg").default} alt="" />
                    <p className=" pl-2  font-bold text-[14px]" ><a onClick={() => onClickTrack(EventName.Home_page_Safety_Security)} href={RouteSegments.SAFETY_AND_SECURITY} target="_blank">Know more</a></p>
                  </div>
                </div>
                <div className=" md:col-span-1 mt-[1rem] lg:mt-0 lg:col-span-1 border-b-[1px] md:border-b-[0px] md:border-r-[1px] border-[#00000029]  px-[10px]">
                  <div className="flex  items-center">
                    <img src={require("../Images/Group 829.svg").default} alt="" width="80" height="90" />
                    <span className="text-[#D2951B] pl-[0.8rem] text-[18px] font-bold " >
                      HOW IT
                      <br />
                      WORKS
                    </span>
                  </div>
                  <p className=" font-semibold text-[14px] mt-3 md:h-[4rem]" >
                    We help customers like you to easily register - furnish details - verify your phone number -  define your preferences and share your success story
                  </p>
                  <div className=" flex items-center mt-5 mb-[1.5rem] 2xl:mt-[1.5rem] xl:mt-[3.5rem] lg:mt-[3.5rem] md:mb-0  ">
                    <img src={require("../Images/Polygon 4.svg").default} alt="" />
                    <p className=" pl-2  font-bold text-[14px]"><a onClick={() => onClickTrack(EventName.Home_page_How_it_Works)} href={RouteSegments.HOW_IT_WORKS} target="_blank">Know more</a></p>
                  </div>
                </div>
                <div className=" md:col-span-1 mt-[1rem]  lg:mt-0 lg:col-span-1 px-[10px] ">
                  <div className="flex  items-center">
                    <img src={require("../Images/kalyan_logo.svg").default} alt="" />
                    <span className="text-[#D2951B] pl-[0.8rem] text-[18px] font-bold " >
                      ABOUT KALYAN
                      <br />
                      JEWELLERS
                    </span>
                  </div>
                  <p className="font-semibold text-[14px] mt-3 md:h-[4rem]" >
                    We are one of the oldest business families in India with a family legacy of 109 years in business. Started for the noble....
                  </p>
                  <div className=" flex items-center mt-5 mb-[1.5rem] 2xl:mt-[1.5rem] xl:mt-[3.5rem] lg:mt-[3.5rem] md:mb-0  ">
                    <img src={require("../Images/Polygon 4.svg").default} alt="" />
                    <p className="pl-2 font-bold text-[14px]" ><a onClick={() => onClickTrack(EventName.Home_page_About_Kalyan_Jewellers)} href={RouteSegments.ABOUT_KALYAN_JEWELLERS} target="_blank">Know more</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MobileFooter />
        </main>
      }
    </div>
  )
}

export default withRouter(NRIRegister)