import React, { useState, useContext, useEffect, useMemo } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { useNavigate } from "react-router";
import { NavBarContext } from "../../Contexts/NavBarContext";
import RouteSegments, { RouteHelper } from "../../Routes/RouteSegment";

const NavItem = (props) => {

  // const [active, setActive] = useState(false)

  return(
    <a className="flex flex-col items-center" 
     onClick={() => {
        props.callBack();
         
      }}>
      {/* { */}
      {/* ({ isActive }) => ( */}
        <>
          <img alt="nav item" className={`w-7 h-7`} 
          src={props?.image} />
          <p className={`text-[9pt] text-center 1 ${props?.isSelected  ? "text-[#FFFFFF] brightness-200" : "text-[#FEBABA]"} `}>
            {props?.label}</p>
        </>
      {/* ) */}
    {/* } */}
    </a>
  )
}


const nav 
= ({ to, label, isSelected, selectedImageSrc, imageSrc }) => {
   
  return(
    
  <NavLink to={to} className="flex flex-col items-center">
    {
      ({ isActive }) => (
        <>
          <img alt="nav item" className={`w-7 h-7`} src={isActive || isSelected ? selectedImageSrc : imageSrc} />
          <p className={`text-[9pt] text-center ${isActive || isSelected ? "text-[#FFFFFF] brightness-200" : "text-[#FEBABA]"} `}>{label}</p>
        </>
      )
    }
  </NavLink>
  )
}


const ViewMessageBottomNav = (props) => {

  const navigate = useNavigate();
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);
  console.log(menuSelect,"active-09")
  const onInboxClick = () => {
      navigate(RouteSegments.INBOX, {
                state: { menu: "MESSAGE", subMenu: 1 }, 
                replace: true
              });
     
     ;
    // setNavList(['Inbox', 'All Messages'])
  }
   const [active, setActive] = useState(false)
   //console.log(active,"state12");

  const isSavePrefMatch = useMatch(`${RouteSegments.SAVEPARTNERPREFERENCES}`)

  useEffect(() => {

    const isMobileView = window.matchMedia("(max-width: 768px)").matches;
    if(menuSelect == "7"){
      if (isMobileView) {
        setMenuSelect("7");
      } else {
        setMenuSelect("1");
      }
    }

    const handleResize = () => {
      const isMobileView = window.matchMedia("(max-width: 768px)").matches;
      if(menuSelect == "7"){
        if (isMobileView) {
          setMenuSelect("7");
        } else {
          setMenuSelect("1");
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isV3User = localStorage.getItem("isV3User")

  return (
    <footer className="md:hidden fixed mt-8 inset-x-0 bottom-0">
      <div className="bg-[#D10A11] pt-3  px-3 grid grid-cols-5  rounded-t-[30px]">
        <NavItem
          callBack={() => {
            navigate(RouteSegments.DASHBOARD);
            setMenuSelect("1");
          }}
          image={
            menuSelect == "1"
              ? "/Assets/Images/BottomNavigation/matches-active.svg"
              : "/Assets/Images/BottomNavigation/matches.svg"
          }
          label="Matches"
          isSelected={menuSelect == "1"}
          imageSrc="/Assets/Images/BottomNavigation/matches.svg"
          selectedImageSrc="/Assets/Images/BottomNavigation/matches-active.svg"
        />

        <NavItem
          callBack={() => {
            navigate(RouteHelper.getSelectedProfileUrl, {
              state: { isfrom: "selectedprofile" },
            });
            setMenuSelect("7");
          }}
          isSelected={menuSelect == "7"}
          image={
            menuSelect == "7"
              ? "/Assets/Images/BottomNavigation/profile-active.svg"
              : "/Assets/Images/BottomNavigation/profile.svg"
          }
          // to={RouteSegments.SELECTEDPROFILES}
          label="Selected Profiles"
          imageSrc=""
          selectedImageSrc=""
        />
        <NavItem
          // to={RouteSegments.SEARCH}
          callBack={() => {
            navigate(RouteSegments.SEARCH);
            setMenuSelect("2");
          }}
          state={active}
          label="Search"
          // isSelected={isSavePrefMatch != null}
          image={
            menuSelect == "2"
              ? "/Assets/Images/BottomNavigation/search-active.svg"
              : "/Assets/Images/BottomNavigation/search.svg"
          }
          imageSrc=""
          isSelected={menuSelect == "2"}
          selectedImageSrc=""
        />
        <NavItem
          to={RouteSegments.INBOX}
          callBack={() => {
            navigate(RouteSegments.INBOX, {
              state: { menu: "MESSAGE", subMenu: 0 },
            });
            setMenuSelect("3");
          }}
          state={active}
          label="Message"
          isSelected={menuSelect == "3"}
          image={
            menuSelect == "3"
              ? "/Assets/Images/BottomNavigation/message-active.svg"
              : "/Assets/Images/BottomNavigation/message.svg"
          }
          imageSrc=""
          selectedImageSrc=""
        />

        <NavItem
          // to={RouteSegments.NOTIFICATION}
          isSelected={menuSelect == "5"}
          callBack={() => {
            if (isV3User) {
              navigate(RouteSegments.NOTIFICATION_LITE)
            } else {
              navigate(RouteSegments.NOTIFICATION)
            }
            setMenuSelect("5");
          }}
          state={active}
          label="Notifications"
          image={
            menuSelect == "5"
              ? "/Assets/Images/BottomNavigation/notifications-active.svg"
              : "/Assets/Images/BottomNavigation/notifications.svg"
          }
          imageSrc=""
          selectedImageSrc=""
        />
      </div>
    </footer>
  );
}

export default ViewMessageBottomNav
