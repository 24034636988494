import React, { memo, useEffect, useRef, useState } from 'react'

import AppStoreIcon from "../../Images/app-store-svgrepo-com.svg"
import FBIcon from "../../Images/facebook-icon-logo-svgrepo-com.svg"
import TwitIcon from "../../Images/twitter-svgrepo-com.svg"
import $ from 'jquery'
import RouteSegments from '../../Routes/RouteSegment'

const MobileFooter = (props) => {
  

  return (
    <div>
           {/* <InjectScript script={scriptToInject} /> */}
     {props?.menu==="microfooter" ?
      <footer className="md:hidden  mt-8
      inset-x-0
      bottom-0">
   <div className="bg-[#D10A11] p-3 justify-between  flex  ">
     <p className=" text-[#FFFFFF] text-center text-[17px]">
     Copyright © 2022 www.kalyanmatrimony.com
     </p>
    
   </div>
 </footer>
     :

     
        <footer className="md:hidden  mt-8
             inset-x-0
             bottom-0">
          <div className="bg-[#D10A11] p-3 justify-between  flex  ">
            <p className=" text-[#FFFFFF] text-center text-[17px]">
              © Copyright © 2022
            </p>
            <div className="flex justify-between space-x-3  ">
              <div className="flex justify-center">
                <a href={RouteSegments.staticLinkForAppDownload?.android} target="_blank" rel="noopener">

                <svg className=" w-[1.7rem] " viewBox="30 336.7 120.9 129.2" width="30">
                  <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"></path>
                  <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"></path>
                  <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"></path>
                  <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"></path>
                </svg>
                </a>
              </div>
              <div className="flex justify-center">
              <a href="https://itunes.apple.com/in/app/kalyan-matrimony/id1237568752" target="_blank"  title="Kalyan Matrimony" rel="noopener">
                <img className="w-[33px]" src={AppStoreIcon} alt="" width="33" height="33" /></a>
              </div>
              <div className="flex justify-center">
              <a href="https://www.facebook.com/Kalyan-Matrimony-1728614214073892/" className="fb" target="_blank" title="Kalyan Matrimony" rel="noopener">
                <img className=" w-[1.7rem] " src={FBIcon} alt="" width="17" height="17" /></a>
              </div>
              <div className="flex justify-center">
              <a href="https://twitter.com/KalyanMatri" className="fb" target="_blank" title="Kalyan Matrimony" rel="noopener"> 
                <img className=" w-[33px] " src={TwitIcon} alt="" width="33" height="33" /></a>
              </div>
            </div>
          </div>
        </footer>
}
   
    </div>
  )
}

export default MobileFooter