import React, { useEffect, useMemo } from 'react'
import GreySearchIcon from "../../Images/Group 256 grey.svg"
import RedTickIcon from "../../Images/singleredtick.svg"
import GreyTickIcon from "../../Images/singlegreytick.svg"
import { useLocation } from 'react-router-dom'

const FilterContents = (props) => {
const location = useLocation();
const isSearch = location?.state?.isfrom === "basic_search" || location?.state?.isfrom === "advance_search";

  useEffect(() => {
  }, [props.data]);

  console.log(props.data,"ppp");

  const activityOptions = useMemo(()=>{
    if(isSearch){
      return props?.data?.activities
    }else{
      return props?.data?.activity?.map(value=>{
        return {
          "name": value,
        }
      })
    }
  },[isSearch, props?.data?.activities, props?.data?.activity])


  return (
      <div >
        {props.menu !== "activity" && props.menu !== "dosham" && props.menu !== "food" && props.menu !== "maritalStatus" &&  props.menu !== "physicalStatus" &&  props.menu !== "smoking" && props.menu !== "drinking" && props.menu !== "showProfile" && props.menu !== "dontshowProfile" && 
          <div className="flex space-x-3 " style={{ borderBottom: "1px solid" }}>
            <div className="flex items-center ">
              <img className="w-[0.8rem] h-[0.8rem]" alt='' src={GreySearchIcon} />
            </div>
            <div>
              <input className="!placeholder-[#9F9F9F] text-[#9F9F9F]   bg-[#F3F3F3] md:w-[14rem] w-fit font-segeo outline-none font-bold flex items-center  text-[11px] md:text-[13px] lg:text-[13px]"
                placeholder={`Search for ${props.title}`} type="search"
                onChange={(event) => props?.handleSearchFIlter(event, props.menu)}
              />

            </div>
          </div>
 
        }
        {props.menu === "activity" ?
          <div
            className={`${activityOptions?.length > 5 ? "h-[12rem] overflow-y-auto overflow-x-hidden "
                : " "
              }  `}
          >

            <div className=" mt-[1rem] ">
              {activityOptions?.length > 0 ?
                activityOptions?.map((item, i) => {
                  return (
                    <div className='flex'>
                      <input type="checkbox" defaultChecked={item?.selected === "Y"} onClick={(event) => props?.filterOnchangeForActivity(item?.name)}></input>
                      {/* <img className="w-[0.8rem] h-[0.8rem]" alt='' src="/Assets/Images/singlegreytick.svg"/> */}
                      <span key={i} className="text-[#9F9F9F] font-segeo font-bold flex items-center  text-[12px] ml-[1rem]">
                        {item?.name}
                      </span>
                    </div>
                  )
                })
                :
                <div>No Data</div>
              }

            </div>

          </div>
          :

          <div className={`${props?.data?.[props.menu]?.length > 5 ? "h-[34rem] md:h-[41rem] lg:h-[12rem] overflow-y-scroll scrollbar-none lg:overflow-y-auto lg:overflow-x-hidden "
                : " "
              }  `}
          >
            {props?.data?.[props.menu]?.length > 0 ?
              props?.data?.[props.menu]?.map((item, i) => {
                return (
                  <div className={`flex mt-[1rem] ${item?.heading != 'Y' ? "space-x-3" : ""}`}>

                    <div className="flex items-center ">
                      {item?.selected === "Y" ?
                        <div className={`cursor-pointer ${item?.heading == 'Y' ? 'pointer-events-none' : ''}`} onClick={(event) => props?.filterOnchange(item.id, item.name, item.selected, props.menu, event)}>
                          {item?.heading != 'Y' &&
                            <img className="w-[0.8rem] h-[0.8rem]" alt='' src={RedTickIcon} />}
                        </div>
                        :
                        <div className={`cursor-pointer ${item?.heading == 'Y' ? 'pointer-events-none' : ''}`} onClick={(event) => props?.filterOnchange(item.id, item.name, item.selected, props.menu, event)}>
                          {item?.heading != 'Y' &&
                            <img className="w-[0.8rem] h-[0.8rem]" alt='' src={GreyTickIcon} />}
                        </div>
                      }
                    </div>
                    {item?.selected === "Y" ?
                      <p className={`text-[#D10A11] font-segeo font-bold flex items-center  text-[11px] md:text-[13px] lg:text-[14px] cursor-pointer ${item?.heading == 'Y' ? 'pointer-events-none' : ''} w-fit`} onClick={(event) => props?.filterOnchange(item.id, item.name, item.selected, props.menu, event)}>
                        {item.name}
                      </p>
                      :
                      <p className={`text-[#9F9F9F] font-segeo font-bold flex items-center  text-[11px] md:text-[13px] lg:text-[14px] cursor-pointer ${item?.heading == 'Y' ? 'pointer-events-none' : ''} w-fit`} onClick={(event) => props?.filterOnchange(item.id, item.name, item.selected, props.menu, event)}>
                        {item.name}
                      </p>
                    }

                  </div>
                )
              })
              :
              props.menu === "state" ?
                <div className='text-[#575556] font-semibold font-normal text-[13px] py-2'>{'Please select country'}</div>
              :props.menu === "city" ?
                <div className='text-[#575556] font-semibold font-normal text-[13px] py-2'>{'Please select state'}</div>
              :props.menu === "raasi" ?
                <div className='text-[#575556] font-semibold font-normal text-[13px] py-2'>{'Please select star'}</div>
              // :props.menu === "caste" ?
              //   <div className='text-[#575556] font-semibold font-normal text-[13px] py-2'>{'Please select religion'}</div>              
              :<div className='text-[#575556] font-semibold font-normal text-[13px] py-2'>No Data</div>
              
            }
          </div>

        }

      </div>
  )
}

export default FilterContents
