import { useCallback, useEffect } from "react";
import * as Images from "./assets/images"
import moment from "moment";
import Constants from "../../Constants/constants";
import EndPoints from "../../Constants/EndPoints";

export const getRequestData = (request, isSender) => {

    let content = "";
    let imageSrc = "";
    let type = "";


    const isPhoto = request == 5
    const isHoroScope = request == 6
    const isParent = request == 7
    const isTrust = request == 8

    const isPhotoReminder = request == 9
    const isHoroScopeReminder = request == 10
    const isParentReminder = request == 11
    const isTrustReminder = request == 12

    const isValidatedPhoto = request == 13
    const isValidatedHoroScope = request == 14
    const isValidatedParent = request == 15
    const isValidatedTrust = request == 16    

    if (isPhoto) {
        content = isSender ? "You have requested photo" : "Has Requested Photo"
        imageSrc = Images.RequestPhotoBlack;
        type = "Photo";
    } else if (isHoroScope) {
        content = isSender ? "You have requested horoscope" : "Has Requested Horoscope"
        imageSrc = Images.RequestHoroBlack;
        type = "Horoscope";
    } else if (isTrust) {
        content = isSender ? "You have requested trust document" : "Has Requested trust document"
        imageSrc = Images.RequestTrustBlack;
        type = "Trust";
    } else if (isParent) {
        content = isSender ? "You have requested parent's phone number" : "Has Requested Parent's Phone Number"
        imageSrc = Images.RequestParentBlack;
        type = "Parents Number";
    }  else if (isValidatedPhoto) {
        content = "Photo"
        imageSrc = Images.RequestPhotoBlack;
    } else if (isValidatedHoroScope) {
        content = "Horoscope"
        imageSrc = Images.RequestHoroBlack;
    } else if (isValidatedTrust) {
        content = "Trust document"
        imageSrc = Images.RequestTrustBlack;
    } else if (isValidatedParent) {
        content = "Parent Number"
        imageSrc = Images.RequestParentBlack;
    } else if (isPhotoReminder) {
        type = "Photo"

    } else if (isHoroScopeReminder) {
        type = "Horoscope"

    } else if (isTrustReminder) {
        type = "Trust"

    } else if (isParentReminder) {
        type = "Parent Number"

    }

    return { content, imageSrc, type }
}


export const chatOptions = [
    {
        label: (
            <div className="px-2 ">
                Reply
            </div>
        ),
        key: "1",
    },
    {
        label: (
            <div className="px-2">
                Delete
            </div>
        ),
        key: "2",
    },
    {
        label: (
            <div className="px-2">
                Star
            </div>
        ),
        key: "3",
    },
];

export const CustomerServicechatOptions = [
    {
        label: (
            <div className="px-2 ">
                Reply
            </div>
        ),
        key: "1",
    },
   
];
export const TextWithLinks = ({ text, className, linkClassName }) => {
    // Regular expression to identify URLs in the text
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Function to replace URLs with anchor tags
    const renderTextWithLinks = () => {
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                // If the part is a URL, render it as a link
                return (
                    <a className={linkClassName} key={index} href={part} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            } else {
                // If the part is not a URL, render it as plain text
                return <span key={index}>{part}</span>;
            }
        });
    };

    return <p className={className}>{renderTextWithLinks()}</p>;
};

export const TextWithImage = ({ text = "", data }) => {



 /**
  * The function `renderTextWithLinks` splits text based on a PNG regex pattern and renders image paths
  * as images and other parts as plain text.
  * @returns The `renderTextWithLinks` function is returning an array of JSX elements. Each element in
  * the array is either an `<img>` element with an image path as the `src` attribute, or a `<span>`
  * element with plain text content. The function splits the `text` variable using the `pngRegex`
  * regular expression, and then maps over the resulting array to determine whether each part should
  */
    const renderTextWithLinks = () => {
        const pngRegex = /(\S+\.jpg)/;
        console.log(text.split(pngRegex), "match13233");
        return text.split(pngRegex).map((part, index ,type, fileName) => {
            if (part.match(pngRegex)) {
                // If the part is a image path, render it as a image
                return (
                    <img alt="" key={index} src={EndPoints.thumbnailUrl('png', part ,type, fileName)} className="w-4 h-4 inline-block"/>
                );
            } else {
                console.log(part,'part');
                // If the part is not a URL, render it as plain text
                return <span key={index}>{part}</span>;
            }
        });
    };

    return <p>{renderTextWithLinks()}</p>;
}


export default function useDebounce(effect, dependencies, delay) {
    const callback = useCallback(effect, dependencies);

    useEffect(() => {
        const timeout = setTimeout(callback, delay);
        return () => clearTimeout(timeout);
    }, [callback, delay]);
}


export const isSearchBySMID = (str) => /^KM\d+$/i.test(str);


export const isRequestMessage = (id) => [5, 6, 7, 8].includes(parseInt(`${id}`))

export const isRequestSuccessMessage = (id) => [13, 14, 15, 16].includes(parseInt(`${id}`))

export const isRequestReminder = (id) => [9, 10, 11, 12].includes(parseInt(`${id}`))

export const lastSeenTime = (messageTimestamp) => {
    // const currentTime = moment();
    // const messageTime = moment(messageTimestamp);
    // const duration = moment.duration(currentTime.diff(messageTime));

    // Calculate the time difference in hours
    // const hoursDifference = duration.asHours();

    // if (messageTime.isSame(currentTime, 'day')) {
    //     messageTime.subtract(5, 'hours').subtract(30, 'minutes');
    //     // If the message is from today, return the time with AM/PM format
    //     return messageTime.chatt('h:mm A');
    // } else if (messageTime.isSame(currentTime.clone().subtract(1, 'day'), 'day')) {
    //     // If the message is from yesterday, return "Yesterday"
    //     return 'Yesterday';
    // } else {
    //     // If the message is more than 48 hours old, show the full date in MM/DD/YY format
    //     return messageTime.format('DD/MM/YY');
    // }

    if(messageTimestamp == undefined){
        return;
      }
  
      const currentDate = new Date();
      const lastSeenDate = new Date(messageTimestamp);
      lastSeenDate.setHours(lastSeenDate.getHours() - 5); 
      lastSeenDate.setMinutes(lastSeenDate.getMinutes() - 30);
  
      // Check if the user was seen today
      if (
        currentDate.getFullYear() === lastSeenDate.getFullYear() &&
        currentDate.getMonth() === lastSeenDate.getMonth() &&
        currentDate.getDate() === lastSeenDate.getDate()
      ) {
        
        // Format time (e.g., Last seen today at 4:00 PM)
        return `${lastSeenDate.toLocaleTimeString([], { hour: "numeric",hourCycle: "h12", minute: "2-digit"})}`;
      }
  
      // Check if the user was seen yesterday
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);
  
      if (
        yesterday.getFullYear() === lastSeenDate.getFullYear() &&
        yesterday.getMonth() === lastSeenDate.getMonth() &&
        yesterday.getDate() === lastSeenDate.getDate()
      ) {
        // Format time (e.g., Last seen yesterday at 6:00 PM)
        return "Yesterday"
      }
    
      // Format date as DD/MM/YY (e.g., Last seen at 2/7/23)
      const day = lastSeenDate.getDate().toString().padStart(2, "0");
      const month = (lastSeenDate.getMonth() + 1).toString().padStart(2, "0");
      const year = lastSeenDate.getFullYear().toString().slice(2);
    
      return `${day}/${month}/${year}`;
}


export const getGroupAuthId = () => {
    const familyLoginId = localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginId)

    return familyLoginId ?? localStorage.getItem(Constants.loginLocalStorageKeys.profileId)
}


export const getLoginRelation = () => {
    let familyLoginRelation = localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginRelation)
    if (familyLoginRelation == "undefined") {
        familyLoginRelation = null
    }

    return familyLoginRelation ?? localStorage.getItem(Constants.loginLocalStorageKeys.loginUserName)
}

export const getCustomerServiceAuthId = () => {
    const customerServiceLoginId = localStorage.getItem(Constants.loginLocalStorageKeys.customerServiceLoginId)

    return customerServiceLoginId ?? localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
}


/**
 * This JavaScript function capitalizes the first letter of a given string.
 * @param string - The `capitalizeFirstLetter` function takes a `string` as a parameter. This function
 * capitalizes the first letter of the input `string` and returns the modified string.
 * @returns The function `capitalizeFirstLetter` returns the input string with the first letter
 * capitalized.
 */
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getDeviceInfo = () => {
    let loginDevice = window.innerWidth < 1024 ? "Mobile Site" : "Desktop"
    const finalLoginDevice = loginDevice || "Unknown";
    return finalLoginDevice
}

export const requestLookup = {
    19: "Sub Caste",
    20: "Monthly Income",
    21: "star",
    22: "raasi",
    23: "dosham",
    24: "food_habits",
    25: "smoking",
    26: "drinking",
    27: "family_status",
    28: "family_type",
    29: "family_values",
    30: "family_monthly_income",
    43: "description",
    45: "hobbies_and_interests",
    46: "music_genres_you_listen_to",
    47: "favourite_music_directors",
    48: "type_of_books_you_read",
    49: "type_of_movies_you_watch",
    50: "favourite_movie_directors",
    51: "sports_fitness_activities_and_games",
    52: "favourite_cuisine",
    63: "languages_you_know",
    64: "languages_that_i_wish_to_know",
    // additional fields
    67: "Father name",
    68: "Father occupation",
    69: "Mother name",
    70: "Mother occupation",
    71: "Brothers",
    72: "Married brothers",
    73: "Sisters",
    74: "Married sisters",
    75: "Assets owned",
    
    85: "photo",
    86: "horoscope",
    87: "parentsnumber",
    88: "trust_document "
  };

export const brothersCount = [
    {id:0, name: "0"},
    {id:1, name: "1"},
    {id:2, name: "2"},
    {id:3, name: "3"},
    {id:4, name: "4"},
    {id:5, name: "5"},
    {id:6, name: "6"},
    {id:7, name: "7"},
    {id:8, name: "8"},
    {id:9, name: "9"},
]
