import React, { Component, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import RouteSegments, { RouteHelper, RoutePaths } from "../../Routes/RouteSegment";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Constants, { InboxFilters } from "../../Constants/constants";
import { dateFormat } from "../../Utils";


const SearchResultCard2 = (props) => {
  const [selectReject, setSelectReject] = useState({
    select: props?.cardData?.selectedprofile ?? false,
    reject: props?.cardData?.selectedrejected ?? false,
    message: props?.cardData?.profilealreadyContected == "Y" ? true : false,
    photoRequest: props?.cardData?.profilerequestcheck ?? false 
  });
const navigate = useNavigate();
  useEffect(() => {}, [selectReject]);   
  useEffect(() => {}, [props.menu]);  
  useEffect(() => {},[props?.photoRequest])
  useEffect(() => {console.log(props?.cardData?.profileid,"profileid");},[ props?.cardData?.profileid])
  //console.log(props.menu, "response");

  const onMessageClick = () => {
    if (props.cardData?.profileblockedByMe == "Y") {
      props?.callBackBlockProfile();
    } else {
      props?.callbackSelectReject("MESSAGE", "");
    }
    // props.callbackSelectReject("MESSAGE", "")
  }

  const onlineNowClick = ()=>{
    if(props?.cardData?.profilealreadyContected === "Y" && !Constants.profileStatusOptions.includes(props?.cardData?.profileStatus) && props.dashboard1Data?.status !== "pending" && props.dashboard1Data?.status !== "hidden"){
      navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), { state: { profileId: props?.cardData?.profileid, threadId:props?.cardData?.messageSent[0].subjectId, type:"Sent" } })
    }else{
      onMessageClick()
    }
  }
 
  return (
        <div className="relative mt-[2.5rem]">
       {props?.cardData?.adminSelected? 
        <div className=" flex justify-center items-center">
        <div className="">
        <div className =" absolute w-[2rem] top-[-0.6rem] left-[6.7rem] md:top-[-0.6rem] lg:top-[-0.6rem] z-[1] md:left-[9rem]
  border-l-[15px] border-l-transparent
  border-t-[17px] border-t-[#D10A11]
  border-r-[22px] border-r-transparent">
</div>
        <p className="text-white text-[10px] md:text-[12px] lg:text-[15px] absolute top-[-1.3rem] left-[6rem] md:top-[-1.9rem] md:left-[8rem]  z-[1] font-segeo px-[1rem] rounded-lg font-bold bg-[#D10A11] px-[8px] py-[4px] ">
          Profile selected by your Relationship Executive
        </p>
        </div>
      </div>
      : "" }
    <div className=" flex w-full  rounded-lg sahdow-lg overflow-hidden flex-row grid grid-cols-8">
      <div className="flex relative col-span-3 md:col-span-3 lg:col-span-2 h-full">
        <img
          src={props?.cardData?.profileurl}
          alt=""
          onClick={() => 
            props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"  ?
            props.callBackNav()
          :
          props.cardData.profileStatus == "inactive"?
          props.viewProfileStatusPopup("Profile has been inactive")
          :props.cardData.profileStatus == "hidden" ?
          props.viewProfileStatusPopup("Profile has been hidden")
          :props.cardData.profileStatus == "delete" ?
          props.viewProfileStatusPopup("Profile has been deleted")
          :props.cardData.profileStatus == "suspension" ?
          props.viewProfileStatusPopup("Profile has been suspended")
          :""  
        }
          className={`${
            props?.cardData?.profileblurPhoto === "Y"
              ? "blur-[2px] w-[10rem] md:w-[11rem] lg:w-[14rem]  h-full "
              : "w-[10rem] md:w-[11rem] lg:w-[14rem]"
          }  h-full imageCard`}
        />
         {props?.cardData?.profilefromPasswordProtected === "Y" && (
        <div
          className="absolute top-0 flex justify-center items-center h-[100%] w-[8.5rem] md:w-[8.5rem] lg:w-[10.5rem] cursor-pointer"
              >
                <img
                  className="h-[3rem] w-[3rem] md:ml-[0rem] lg:ml-[0rem]"
                  src="/Assets/Images/lockicon.svg"
                  alt="Person"
                  onClick={() => 
                    props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                    props.callBackNav()
                    :
                    props.cardData.profileStatus == "inactive"?
                    props.viewProfileStatusPopup("Profile has been inactive")
                    :props.cardData.profileStatus == "hidden" ?
                    props.viewProfileStatusPopup("Profile has been hidden")
                    :props.cardData.profileStatus == "delete" ?
                    props.viewProfileStatusPopup("Profile has been deleted")
                    :props.cardData.profileStatus == "suspension" ?
                    props.viewProfileStatusPopup("Profile has been suspended")
                    :""    
                    }
                />
              </div>
          )}
           {props?.cardData?.isAvatarPic && (
            <div
               onClick={() => {

                     if(props?.cardData?.profilerequestcheck === true){

                              }
                    else if (props?.cardData?.profilerequestcheck === false) {
                       setSelectReject({
                        ...selectReject,
                        photoRequest: !selectReject.photoRequest,
                      });
                      props.callbackRequestPhoto(
                        
                      );
                    }
                  }}

              className="absolute top-0 flex justify-center items-end h-[100%]  w-[100%] cursor-pointer"
            >
              <span className=" text-[#575556] mb-4 text-[10px]   md:px-3 lg:px-0 md:text-[11px]  lg:text-[14px] lg:mr-0 w-full md:w-[9rem] lg:w-full  md:h-[2rem] md:py-2 lg:py-2 lg:h-[3rem] font-bold text-center flex justify-center items-center h-[3rem] ">
                {props?.cardData?.profilerequestcheck  
                  ? "PHOTO REQUESTED"
                  : "REQUEST PHOTO"}
              </span>
            </div>

            )}
      </div>
      <div className="w-full pb-[1rem] grid col-span-5 md:col-span-5 lg:col-span-6  bg-[#E9E9E9] text-left space-y-1 ">
        <div className="px-3 space-y-1 pt-3">
          <div className="md:block lg:hidden">
            {props.cardData.profilelastOnline === "Online Now" ? (
              <div onClick={onlineNowClick} className="flex cursor-pointer justify-end ">
                <img
                  className=" w-3 h-3"
                  src="/Assets/Images/Group 619.svg"
                  alt=""
                />
                <span
                  className=" px-2 text-[10px]
                                          text-[#575556]"
                >
                  Online Chat Now
                </span>
              </div>
            ) : (
              <span className="flex justify-end text-[#039511] font-segeo font-semibold text-[10px] px-1">
                Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
              </span>
            )}
            <p
               onClick={() => 
                props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                props.callBackNav()
                :
                props.cardData.profileStatus == "inactive"?
                props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.profileStatus == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.profileStatus == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.profileStatus == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                :""                }
              className="text-[#575556] cursor-pointer md:text-[14px] lg:text-[16px] pt-1 text-[13px]  font-bold"
            >
              {props.cardData.smProfileId}
            </p>
            <p
              onClick={() => {
                props.callBackNav();
              }}
              className="text-[#575556] md:text-[14px] cursor-pointer lg:text-[16px] pt-1 text-[13px]  font-bold"
            >
              {props.cardData.profilename ? props.cardData.profilename : "--"}
            </p>
          </div>
          <div className="  hidden lg:flex lg:justify-between ">
            <p className="text-[#575556] md:text-[14px] cursor-pointer lg:text-[16px] pt-1 text-[10px]  font-bold">
              <span
                onClick={() => 
                  props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
                  props.callBackNav()
                  :
                  props.cardData.profileStatus == "inactive"?
                  props.viewProfileStatusPopup("Profile has been inactive")
                  :props.cardData.profileStatus == "hidden" ?
                  props.viewProfileStatusPopup("Profile has been hidden")
                  :props.cardData.profileStatus == "delete" ?
                  props.viewProfileStatusPopup("Profile has been deleted")
                  :props.cardData.profileStatus == "suspension" ?
                  props.viewProfileStatusPopup("Profile has been suspended")
                  :""                }
              >
                {" "}
                {props.cardData.smProfileId}{" "}
              </span>
            </p>

            {props.cardData.profilelastOnline === "Online Now" ? (
              <div onClick={onlineNowClick} className="flex cursor-pointer"> 
                <img
                  className=" w-3 h-3"
                  src="/Assets/Images/Group 619.svg"
                  alt=""
                />
                <span
                  className=" px-2 text-[10px]
                                          text-[#575556]"
                >
                  Online Chat Now
                </span>
              </div>
            ) : (
              <span className="md:flex justify-end text-[#039511] font-segeo font-semibold text-[10px] px-1">
                Last Online: {dateFormat(props?.cardData?.profilelastOnline)}
              </span>
            )}
          </div>
          <p
             onClick={() => 
              props.cardData.profileStatus == "open" || props.cardData.profileStatus == "pending"?
              props.callBackNav()
              :
              props.cardData.profileStatus == "inactive"?
              props.viewProfileStatusPopup("Profile has been inactive")
              :props.cardData.profileStatus == "hidden" ?
              props.viewProfileStatusPopup("Profile has been hidden")
              :props.cardData.profileStatus == "delete" ?
              props.viewProfileStatusPopup("Profile has been deleted")
              :props.cardData.profileStatus == "suspension" ?
              props.viewProfileStatusPopup("Profile has been suspended")
              :""                }
            className={`${
              props?.type === "photorequest"
                ? "text-[#575556] cursor-pointer md:text-[14px] pt-1 lg:text-[16px]  text-[10px]  font-bold lg:block hidden"
                : "text-[#575556] md:text-[14px] lg:text-[16px] cursor-pointer text-[10px]  font-bold lg:block hidden"
            }  `}
          >
            {props.cardData.profilename ? props.cardData.profilename : "--"}
          </p>
          <div className="flex justify-start  items-center ">
            {/* <p className="font-segeo text-[#7A7A7A] text-[12px] lg:text-[14px] ">
                                  {props.cardData.profilealreadyContectedMsg}
                                  </p> */}
            <p
              className={`${
                props?.type === "photorequest"
                  ? " pt-1 md:pt-1 lg:pt-2 text-[12px] md:text-[12px] lg:text-[13px] text-[#7A7A7A] font-semibold font-segeo"
                  : " text-[12px] md:text-[12px] lg:text-[13px] text-[#7A7A7A] font-semibold font-segeo"
              }`}
            >
              {props?.cardData?.profileage}, {props?.cardData?.profileheight}
            </p>
          </div>
          {props?.type === "photorequest" ? (
            props?.menu == "response" || props?.menu == "selectedprofile" || props?.menu == "photorequestreceived" ?
            <p className="text-[#7A7A7A] text-[12px] md:text-[14px] lg:text-[14px] font-segeo h-[1rem]">
                    {props.cardData.profileStatus == "inactive"?
                      "Profile has been inactive"
                      :props.cardData.profileStatus == "hidden"?
                      "Profile has been hidden"
                      :props.cardData.profileStatus == "delete"?
                      "Profile has been deleted"
                      :props.cardData.profileStatus == "suspension"?
                      "Profile has been suspened"
                      :""
                    }
                  </p>
                  :""
          ) : (
            
            <p
              className={`${
                props?.type === "photorequest"
                  ? " pt-1 md:pt-1 lg:pt-2 message-content  text-[11px] md:text-[11px] lg:text-[13px] text-[#7A7A7A] font-segeo"
                  : " message-content  text-[11px] md:text-[11px] lg:text-[13px] text-[#7A7A7A] font-segeo"
              }`}
            >
              {props?.menu == "response" || props?.menu == "selectedprofile" || props?.menu == "photorequestreceived" ?
            <p className="text-[#7A7A7A] text-[12px] md:text-[14px] lg:text-[14px] font-segeo h-[1rem]">
                    {props.cardData.profileStatus == "inactive"?
                      "Profile has been inactive"
                      :props.cardData.profileStatus == "hidden"?
                      "Profile has been hidden"
                      :props.cardData.profileStatus == "delete"?
                      "Profile has been deleted"
                      :props.cardData.profileStatus == "suspension"?
                      "Profile has been suspened"
                      :props?.cardData?.message
                    }
                  </p>
                  :
              props?.cardData?.message}
            </p>
          )}
        </div>
        <div className="flex justify-end pr-[1rem] ">
          {props?.type === "photorequest" ? (
            <button
              onClick={() =>
                navigate(RouteSegments.EDITPROFILE+"?tab=3", {
                  state: { tab: "3", isfrom: "viewall" },
                })
              }
              className="text-[#FFFFFF] text-[9px] md:text-[9px] h-[2.2rem] lg:text-[12px] px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal "
            >
              Send Photo
            </button>
          ) : (
            <button
              onClick={() =>
                (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                props.viewProfileStatusPopup("Your profile is hidden")
                    :
                props.cardData.profileStatus == "inactive"?
                props.viewProfileStatusPopup("Profile has been inactive")
                :props.cardData.profileStatus == "hidden" ?
                props.viewProfileStatusPopup("Profile has been hidden")
                :props.cardData.profileStatus == "delete" ?
                props.viewProfileStatusPopup("Profile has been deleted")
                :props.cardData.profileStatus == "suspension" ?
                props.viewProfileStatusPopup("Profile has been suspended")
                :
                navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), {
                  state: {
                    profileId: props?.cardData?.profileid,
                    threadId: props?.cardData?.messageSent[0].subjectId,
                    type: props?.type,
                  },
                })
              }
              className="text-[#FFFFFF] text-[9px] md:text-[9px] h-[2.2rem] lg:text-[12px] px-3 md:px-2 lg:px-4   rounded-[5px]  bg-[#D10A11] font-bold font-segeo whitespace-nowrap md:whitespace-normal "
            >
              View Message
            </button>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default SearchResultCard2;
