// UAT
module.exports = { 
  api: {
    API_URL: "",
    chat_api: "https://cms-uat.kalyanmatrimony.com",
    agent_api: "https://uat-api-customer-care.kalyanmatrimony.com",
    image_URL:"https://v3-uat.kalyanmatrimony.com"
  },
  base: {
    URL: "/api"
  },
  cms_api: "https://config-uat.kalyanmatrimony.com/api",
  messengerUrl: "messenger-uat.kalyanmatrimony.com",
  configCommunity: "https://config-community-uat.kalyanmatrimony.com",
  image_base_url: "https://s3-us-west-2.amazonaws.com/kmpreprod/source",
  resourceBaseUrl:"https://km-horoscope-response-preprod.s3.us-west-2.amazonaws.com",
  paidBannerUrl: "https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist/img/paidbanner.jpg",
  jitsuUrl: "https://tracker-uat.kalyanmatrimony.com",
  jitsuKey: "Yry4IcYkhxdYIQmeVp7HIObFXVXsHpz7:EwujxsvdgyUSdKUEtv1YFCOd7hBRqjC1",
};


//preprod
// module.exports = { 
//   api: {
//     API_URL: "",
//     chat_api: "https://cms-preprod.kalyanmatrimony.com",
//   },
//   base: {
//     URL: "/api"
//   },
//   cms_api: "https://config-uat.kalyanmatrimony.com/api",
//   messengerUrl: "messenger-uat.kalyanmatrimony.com",
//   image_base_url: "https://s3-us-west-2.amazonaws.com/kmuat/source",
//   paidBannerUrl: "https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist/img/paidbanner.jpg",

//   jitsuUrl: "https://tracker-uat.kalyanmatrimony.com",
//   jitsuKey: "qlDRXrwI2uTEdo2iKxAZ48anXgE2o68V:lMP7XWzwZNlS05GgljGGCvvFvBBQmACT",
// };


//Live

// module.exports = {
//   api: {
//     API_URL: "",
//     chat_api: "https://cms.kalyanmatrimony.com",
//   },
//   base: {
//     URL: "/api"
//   },
//   cms_api: "https://config.kalyanmatrimony.com/api",
//   messengerUrl: "messenger.kalyanmatrimony.com",
//   configCommunity: "https://config-community.kalyanmatrimony.com",
//   image_base_url: "https://s3-us-west-2.amazonaws.com/kmprod-images/source",
//   resourceBaseUrl:"https://km-horoscope-response.s3.us-west-2.amazonaws.com",
//   paidBannerUrl: "https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist/img/paidbanner.jpg",
//   jitsuUrl: "https://tracker.kalyanmatrimony.com",
//   jitsuKey: "uP2MZXgRiMOvKMlbAAudHRIvFFdjT2tp:CKrdv2TdUK1njQ1gwxZ8syycKBz5HHfs",
// };