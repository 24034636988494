import React, { useContext, useEffect, useState } from 'react';
import MatchGroupItem from '../../Components/Kalyanlite/MatchGroup/MatchGroupItem';
import MatchGroupList from '../../Components/Kalyanlite/MatchGroup/MatchGroupList';
import { useDispatch, useSelector } from "react-redux";
import StartNewGroupPage from '../../Components/Kalyanlite/MatchGroup/StartNewGroupPage';
import WelcomePage from '../../Components/Kalyanlite/WelcomePage';
import { SkeletonMatchItem } from '../../Components/Kalyanlite/matchs/MatchItem';
import EndPoints from '../../Constants/EndPoints';
import { GET, POST } from '../../Services/api_services';
import config from '../../config';
import { useFormik } from 'formik';
import Constants, { EventKeys, EventName } from '../../Constants/constants';
import * as Yup from 'yup';
import { registerDetail } from '../../Store/Home/Action';
import SuccessPopup from '../../Components/Kalyanlite/Popup/SuccessPopup';
import ParentsPhonePopup from '../../Components/DashboardComponents/ParentsPhonePopup';
import { NavBarContext } from '../../Contexts/NavBarContext';
import { useAnalytics } from '../../Hooks/usePageChange';
import ParentsPhonePopupLite from '../../Components/DashboardComponents/ParentsPhonePopupLite';
import { getFamilyChatDetail } from '../../Store/Chats/Action';
import { useLocation, useSearchParams } from "react-router-dom";
import { AddFamilyMemberPopup, CommonSuccessPopup } from '../../Components/CommonPopup';
import FamilyCodePopup from '../../Components/Kalyanlite/Popup/FamilyCodePopup';
import { dashboardProfileData } from '../../Store/DashboardAPI/Action';
import { getIsLoginFromAdmin } from "../../Utils";


const MatchGroupPage = () => {


    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const dispatch = useDispatch()
    const { onClickTrack } = useAnalytics();
    const { setMenuSelect } = useContext(NavBarContext);

    const [showgrouplist, setShowGroupList] = useState(false);
    const [showgroupdata, setShowGroupData] = useState(true);
    const [isLoading, setIsLoading] = useState(true)
    const [familydata, setFamilydata] = useState([])
    const [addMoreData, setAddMoreData] = useState([])
    const [successmsg, setSuccessmsg] = useState(false);
    const [addmore, setAddmore] = useState(false);
    const [showdata, setShowData] = useState(true);
    const [isUserNumber, setIsUserNumber] = useState(false);
    const [showParentsNumberPopup, setShowParentsNumberPopup] = useState(false)
    const [whatsAppSuccessPopup, setwhatsAppSuccessPopup] = useState(false);
    const [showVerifiedPopup, setShowVerifiedPopup] = useState(false)
    
    const isV3User = localStorage.getItem("isV3User")
    const [addFamilyMemberPopup, setAddFamilyMemberPopup] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);

    const [showFamilyCodePopup,setShowFamilyCodePopup] = useState(false)


    const userData = useSelector(
        (state) => state?.DashBoardAPI?.ProfileData?.data,
    );

    const { ProfileData } = useSelector(state => ({
        ProfileData: state.DashBoardAPI?.ProfileData?.data
      }));

    const { dropdownData } = useSelector((state) => ({
        dropdownData: state?.Home?.rData?.data,
    }));

    useEffect(() => {
      if(location?.state?.isFrom == "payment"){
        setwhatsAppSuccessPopup(true)
      }
      
    }, [location])
    

    useEffect(() => {

        setMenuSelect("9");

    }, []);



    const apiCallFamilyData = async () => {
        setIsLoading(true)
        try {
            const profileId = userData?.profileId;
            console.log(profileId, "profileId1");
            if (!profileId) {
                // Handle the case when profileId is not available
                console.error('ProfileId not available');
                return;
            }

            const { statusCode, data } = await GET(
                `${config.api.API_URL}${EndPoints.getfamilyUrl(profileId)}`
            );

            if (statusCode === 200) {
                if (data.status === 'Success') {
                    // if(familydata?.length == 0){
                    //     dispatch(dashboardProfileData());
                    // }
                    setFamilydata(data.data.groupMembers);
                    setIsLoading(false)
                    const relation = data.data.groupMembers?.filter(member => member?.parentsMobileNumber == localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginMobileNumber))
                    localStorage.setItem(Constants.loginLocalStorageKeys.familyLoginRelation, relation[0]?.relationshipName)
                    const isParents = data.data?.groupMembers?.filter(data => data?.relationshipName?.includes("Parent"))?.length > 0
                    setAddmore(data.data.groupMembers?.length < (isParents ? 6 : 5))
                }
            } else if (statusCode === 401) {
                // Handle unauthorized error
            } else {
                console.error(`Unexpected status code: ${statusCode}`);
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };


    const addfamilymember = async (mobileNumber, isdCode, relationshipId,parentcheck) => {

        const finalAddMore = addMoreData?.map(addMore => {
            const index = dropdownData?.isdcode?.findIndex(data => data.countryId === addMore.parentsIsdcode)
            return {
                parentsMobileNumber: addMore?.parentsMobileNumber,
                parentsIsdcode: dropdownData?.isdcode[index]?.isdcode,
                loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
                relationshipId: addMore?.relationshipId,
                isParentsNumber:addMore?.parentCheck,
            }
        })

        let request = {
            familyGroup: [
                // ...familydata,
                ...finalAddMore,
                {
                    parentsMobileNumber: mobileNumber,
                    parentsIsdcode: isdCode,
                    loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
                    relationshipId: relationshipId,
                    isParentsNumber:parentcheck,
                }]
        };


        if (location.state?.contentId) {
            request.familyGroup = request.familyGroup?.map(gr => ({ ...gr, isContent: location.state?.name, contentId: location.state?.contentId }))
        }

        onClickTrack(EventName.Family_Add_More_Send_Invite_Button,{[EventKeys.Family_Add_More_Send_Invite_Button_Request]:request})
        console.log("addfamilymember", request);
        try {
            const response = await POST(
                `${config.api.API_URL}${EndPoints.createfamilygroupUrl()}`,
                request
            );

            console.log('API Response:', response);

            if (response.statusCode === 200) {
                // setAddfamily(response?.data)
                setSuccessmsg(response?.data?.message)
                setAddMoreData([])
                formik.resetForm();
                apiCallFamilyData()
                if(ProfileData?.familycode === "N"){
                    // setShowFamilyCodePopup(true)
                    dispatch(dashboardProfileData());

                }
            } else if (response.statusCode === 401) {

            } else {
                console.error(`Unexpected status code: ${response.statusCode}`);
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };


    useEffect(()=>{
        if(ProfileData?.familycode === "Y"){
            setShowFamilyCodePopup(true)
        }
    },[ProfileData])

    const addedNumber = [
        ...familydata?.map(data => data?.parentsMobileNumber),
        ...addMoreData?.map(data => data?.parentsMobileNumber)
    ]
    console.log(familydata,'userData');


    const formik = useFormik({
        initialValues: {
            parentsMobileNumber: '',
            parentsIsdcode: Constants.indiaIsdCodeID,
            relationshipId: '',
            parentCheck: false,
        },
        enableReinitialize: true,
        // validationSchema: Yup.object().shape({
        //     parentsMobileNumber: Yup.string()
        //         .min(10, 'Mobile Number should have 10 digits')
        //         .test('india', 'Mobile number should have 10 digits', function (value) {
        //             const { parentsIsdcode } = this.parent;
        //             console.log("parentsIsdcode === Constants.indiaIsdCodeID", parentsIsdcode, parentsIsdcode == Constants.indiaIsdCodeID, value);
        //             if (parentsIsdcode == Constants.indiaIsdCodeID) {
        //                 return !value || value.length === 10
        //                     ? true
        //                     : this.createError({
        //                         message: 'Mobile Number should have 10 digits',
        //                         path: 'parentsMobileNumber',
        //                     });
        //             }
        //             return true;
        //         })
        //         .test('other', 'Mobile number should have 20 digits', function (value) {
        //             const { parentsIsdcode } = this.parent;
        //             if (parentsIsdcode != Constants.indiaIsdCodeID) {
        //                 return !value || value.length < 21
        //                     ? true
        //                     : this.createError({
        //                         message: 'Please enter a valid mobile number',
        //                         path: 'parentsMobileNumber',
        //                     });
        //             }
        //             return true;
        //         })
        //         .notOneOf(addedNumber, "Mobile number already added")
        //         .required('Please enter a Mobile Number'),
        //     parentsIsdcode: Yup.string().required('Country code is required'),
        //     relationshipId: Yup.string().required('Please select relation'),
        // }),

        validationSchema: Yup.object().shape({
            parentsMobileNumber: Yup.string()
                // .min(10, 'Mobile Number should have 10 digits')
                .test('india', 'Please enter a valid Mobile Number', function (value) {
                    const { parentsIsdcode } = this.parent;
                    console.log("parentsIsdcode === Constants.indiaIsdCodeID", parentsIsdcode, parentsIsdcode == Constants.indiaIsdCodeID, value);
                    if (parentsIsdcode == Constants.indiaIsdCodeID) {
                        return !value || value.length === 10
                            ? true
                            : this.createError({
                                message: 'Please enter a valid Mobile Number',
                                path: 'parentsMobileNumber',
                            });
                    }
                    return true;
                })
                .test('other', 'Please enter a valid Mobile Number', function (value) {
                    const { parentsIsdcode } = this.parent;
                    if (parentsIsdcode != Constants.indiaIsdCodeID) {
                        return !value || (value.length >= 7 && value.length <= 15)
                            ? true
                            : this.createError({
                                message: 'Please enter a valid Mobile Number',
                                path: 'parentsMobileNumber',
                            });
                    }
                    return true;
                })
                .notOneOf(addedNumber, "Mobile Number Already Exist")
                .required('Please enter Mobile Number'),
            parentsIsdcode: Yup.string().required('Country code is required'),
            relationshipId: Yup.string().required('Please select relation'),
        }),
        onSubmit: (values,{setSubmitting}) => {
            duplicateNumCheck(values.parentsMobileNumber);
            if(isUserNumber == false){
                const index = dropdownData?.isdcode?.findIndex(data => data.countryId === values.parentsIsdcode)
                addfamilymember(values.parentsMobileNumber, dropdownData?.isdcode[index]?.isdcode, values.relationshipId, values?.parentCheck);
                apiCallGetFamilyChatDetail()
                
            }else{
                setSubmitting(false);
            }
        },
    });

    console.log(addedNumber,"addedNumber");
    const handleItemClick = () => {
        setShowGroupList(false);
        setShowGroupData(true);
    };

    useEffect(() => {

        if (userData && isLoaded) {

            apiCallFamilyData();
            if (searchParams.get("familynumberverified")) {
                setShowMatchGroup(true);
                setShowVerifiedPopup(true)
            }
            
        }
    }, [userData]);

    useEffect(() => {
        dispatch(registerDetail());
    }, []);

    console.log({ familydata },'familydata');


    const removeNumber = (number) => {
        setAddMoreData(prev => prev?.filter(data => data?.parentsMobileNumber != number))
    }

    const onRefresh = () => {
        setIsLoading(true);
        apiCallFamilyData()
    }

    const duplicateNumCheck = (number) => {
        if(number == userData.userphoneno){
            setIsUserNumber(true);
        }else{
          setIsUserNumber(false);
        }
    }

      const apiCallGetFamilyChatDetail = ()=>{
        dispatch(getFamilyChatDetail({
            "profile_id" : userData?.profileId,
             "auth_id" : userData?.auth_id ,
        }))
      }
    const [showMatchGroup, setShowMatchGroup] = useState(location?.state?.isAdd ? true : false);
      const handleAddMembersClick = (type) => {
        setShowMatchGroup(true); // Show MatchGroupPage
        if(type == "add" && ((familydata?.length + 1) < 7)){
            setAddFamilyMemberPopup(true);
        }
    };



  

 
    return (
        <>
        {showFamilyCodePopup &&
            <FamilyCodePopup close={()=>setShowFamilyCodePopup(false)} />
        }
        {showMatchGroup ?  
        <div className='h-full'>
            {!showFamilyCodePopup && successmsg && <SuccessPopup menu="grouppage" closePopup={() => setSuccessmsg(false)} />}
            {showParentsNumberPopup ?
            isV3User ?
            <ParentsPhonePopupLite
                    close={() => {
                        setShowParentsNumberPopup(false);
                        if(location?.state?.isFrom == "payment"){
                            setShowParentsNumberPopup(true)
                        }
                    }}
                    title={"Add Your Parent's Phone Number"}
                    callBackApply={() => {apiCallGetFamilyChatDetail()
                    }}
                    refresh={onRefresh}
                    parentsIsdCode={userData?.parentsIsdCode}
                    parentsMobileNumber={userData?.parentsMobileNumber}
                    isFrom="family"
                />
            :
                <ParentsPhonePopup
                    close={() => {
                        setShowParentsNumberPopup(false);
                        if(location?.state?.isFrom == "payment"){
                            setShowParentsNumberPopup(true)
                        }
                    }}
                    title={"Add Parent's Phone Number"}
                    callBackApply={() => { }}
                    refresh={onRefresh}
                    parentsIsdCode={userData?.parentsIsdCode}
                    parentsMobileNumber={userData?.parentsMobileNumber}
                    isFrom="family"
                />
                : ""
            }

        {whatsAppSuccessPopup && (
          <CommonSuccessPopup
            close={() => {setwhatsAppSuccessPopup(false)}}
            title={"Success!"}
            content={"WhatsApp Number Saved Successfully"}
          />
        )}

        {showVerifiedPopup && 
            <CommonSuccessPopup
                close={() => {setShowVerifiedPopup(false)}}
                title={"Success!"}
                content={`You have successfully  logged into ${userData?.username}’s profile`}
            />
        }

        {addFamilyMemberPopup  && (
            <AddFamilyMemberPopup
                close={(e) => {
                    if(e == "success"){
                        onRefresh()
                        dispatch(dashboardProfileData())
                    }
                    setAddFamilyMemberPopup(false)
                }}
                title={"Add Family Members"}
            />
        )}         
            
            <div className='flex overflow-y-auto h-full'>
                {isLoading
                    ? <div className='md:w-[36%] lg:w-[30%] w-[100%] h-full overflow-y-auto lite-scrollbar  overflow-x-hidden flex flex-col bg-white'>
                        {Array.from(Array(5))?.map(n => <SkeletonMatchItem />)}
                    </div>
                    : 
                        <div className='md:w-[36%] lg:w-[30%] bg-white w-[100%] h-full overflow-y-auto lite-scrollbar  overflow-x-hidden'>
                            <MatchGroupList
                                formik={formik}
                                setShowgroupdata={() => setShowGroupData(true)}
                                openGroup={() => setShowGroupList(true)}
                                onItemClick={handleItemClick}
                                addMoreData={addMoreData}
                                setAddMoreData={setAddMoreData}
                                familydata={familydata}
                                removeNumber={removeNumber}
                                addmore={addmore}
                                setAddmore={setAddmore}
                                showdata={showdata}
                                setShowData={setShowData}
                                isUserNumber={isUserNumber}
                                setIsUserNumber={setIsUserNumber}
                                duplicateNumCheck={duplicateNumCheck}
                                userData={userData}
                                setIsLoading={setIsLoading}
                                apiCallFamilyData={apiCallFamilyData}
                                
                            />
                        </div> 
                       
                        // <div className='md:w-[36%] lg:w-[30%] bg-[#F7F8FA] w-[100%] h-full'>
                        //     <StartNewGroupPage familydata={familydata} onStart={() =>{onClickTrack(EventName.Family_Add_Friends_Family_Add_Friends_Family_Button);setShowParentsNumberPopup(true)}} />
                        // </div> 
                        
                }
                {/* {familydata?.length > 0 ? */}
                    <div className='w-[100%] md:w-[70%] h-full overflow-y-auto hidden lite-scrollbar  overflow-x-hidden duration-1000 ease-linear bg-[#F0F2F5]  md:block lg:flex-col relative  justify-center'>
                        {getIsLoginFromAdmin() && ProfileData?.viewfamilycode != "" ?
                            <div className='flex items-center justify-end px-5 py-3'>
                                <p className='text-[#D10A11] text-[14px] xl:text-[16px] font-semibold'>Family Code : xxxx</p>
                            </div>
                        :
                        ProfileData?.viewfamilycode != "" ?
                            <div className='flex items-center justify-end px-5 py-3'>
                                <p className='text-[#D10A11] text-[14px] xl:text-[16px] font-semibold'>Family Code : {ProfileData?.viewfamilycode}</p>
                            </div>
                        :
                        <></>
                        }
                        {showgroupdata ? (
                            <MatchGroupItem
                                familydata={familydata}
                                formik={formik}
                                addMoreData={addMoreData}
                                setAddMoreData={setAddMoreData}
                                removeNumber={removeNumber}
                                addmore={addmore}
                                setAddmore={setAddmore}
                                isUserNumber={isUserNumber}
                                setIsUserNumber={setIsUserNumber}
                                duplicateNumCheck={duplicateNumCheck}
                                userData={userData}
                                setIsLoading={setIsLoading}
                                apiCallFamilyData={apiCallFamilyData}
                            />
                        ) : null}
                    </div>
                    {/* :
                    <div className='w-[70%] overflow-y-hidden  overflow-x-hidden duration-1000 ease-linear bg-[#F0F2F5] hidden md:block lg:flex relative  justify-center'>
                        <WelcomePage />
                    </div>
                } */}

            </div>
         
        </div>
        :<StartNewGroupPage familydata={familydata} handleAddMembersClick={handleAddMembersClick} showParentsNumberPopup={showParentsNumberPopup} isLoading={isLoading} />}
        </>
    )
 }

export default MatchGroupPage;
